import React, {useContext, useEffect, useId, useMemo, useState} from "react";
import {DateTime} from "luxon";
import {
    FilterJobEmployeeTimesheetItems,
    filterJobOfferSeatsForVisit,
    JobVisitsTimelineFilter,
    kVisitsListDisplayLimitPage,
    VisitsProcess,
    VisitsProcessScheduleLater
} from "../../../../service/VisitService";
import {
    GetVisitsInput,
    VisitJoinedOthersResponsePage,
    VisitListPureJoinedOthersResponsePage
} from "../../../../generated/graphql/graphql";
import {AppDataContext} from "../../../../AppData";
import {processQueryError} from "../../../../service/ErrorService";
import FormBuilder, {IInputsData, InputType} from "../../form/FormBuilder";
import {tt} from "../../../../core/Localization";
import IVisitEvent from "../../../../model/VisitEvent";
import {Box, Divider, Menu, MenuItem, Theme} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {boxContentStyles} from "../../../../styles/UtilStyles";
import AppButton from "../../buttons/AppButton";
import EmptyListText from "../../textComponents/EmptyListText";
import VisitListItem from "./VisitListItem";
import AppChip from "../../chips/AppChip";
import {usePopupState} from "material-ui-popup-state/hooks";
import {bindMenu} from "material-ui-popup-state";
import Icons8Plus from "../../../../icons/Icons8Plus";
import JobDetailVisitsTabHeadlineWidget from "./JobDetailVIsitsTabHeadlineWidget";
import AppIconButton from "../../buttons/AppIconButton";
import {SetURLSearchParams, useNavigate} from "react-router-dom";
import {routeWithCurrentAsParam} from "../../../../utils/Utils";
import {newJobAndVisitRoute, NewJobAndVisitRouteKind} from "../../../screens/jobs/NewJobAndVisitScreen";
import VisitListItemShimmer from "../../shimmers/VisitListItemShimmer";
import {kAppColors} from "../../../../styles/AppThemeProcessor";
import {filterJobFormsPureByVisit} from "../../../../service/JobFormService";
import {filterProductsForVisit} from "../../../../service/ProductService";
import {filterMaterialsForVisit} from "../../../../service/MaterialService";
import {kActionCreate, kPermissionsJobs, kTopicVisits} from "../../../../core/constants";
import PermissionValid from "../../permissions/PermissionValid";
import HeadlineWithButton from "../../../screenSections/detailListPreviewSection/HeadlineWithButton";
import {getPublicUrls} from "../../../../service/StorageService";
import {RestApiClientContext} from "../../../../core/RestApiProvider";

export const useStyles = makeStyles()((theme: Theme) => ({
    formControlContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "start",
        marginRight: "auto",
        marginBottom: -20,
        "@media (max-width: 767px)": {
            flexDirection: "column-reverse",
        },
    },
    yearChip: {
        minHeight: 40,
        marginLeft: 8,
        "@media (max-width: 767px)": {
            marginLeft: 0,
            marginBottom: 8,
        },
    },
    headlineWidgetResponsivity: {
        "@media (max-width: 767px)": {
            alignItems: "start",
        },
    },
    redDot: {
        position: "absolute",
        background: kAppColors.red.main,
        width: 6,
        height: 6,
        borderRadius: 6,
        top: 13,
        right: 5,
    },
}));

export interface IJobDetailVisitsTabProps {
    jobId: number;
    hasAnyVisit: boolean;
    searchParams: URLSearchParams;
    setSearchParams: SetURLSearchParams;
}

/**
 * Visits tab of the Job detail screen.
 */
export default function JobDetailVisitsTab(props: IJobDetailVisitsTabProps) {
    const {jobId, hasAnyVisit, searchParams, setSearchParams} = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const {subscribe} = restApiClientContext;

    const appDataContext = useContext(AppDataContext);
    const {companyId, setStorage} = appDataContext;

    const {classes} = useStyles();
    const {classes: boxContentClasses} = boxContentStyles();

    const navigate = useNavigate();

    const jobDetailVisitsFilter = searchParams.get('jobDetailVisitsFilter');

    const now = DateTime.now();

    const [fromDate, setFromDate] = useState<DateTime>(now.startOf("day"));
    const [toDate, setToDate] = useState<DateTime>(now.plus({month: 1}).endOf("month"));
    const [visitsCount, setVisitsCount] = useState<number>(0);
    const [visitsDisplayLimit, setVisitsDisplayLimit] = useState<number>(kVisitsListDisplayLimitPage);
    const [canShowLoading, setCanShowLoading] = useState<boolean>(true);
    const [year, setYear] = useState<string>(now.year.toString());
    const [yearValues, setYearValues] = useState<Array<number>>([]);

    const yearDate = DateTime.fromObject({year: parseInt(year)});
    const filterByYear = !now.hasSame(yearDate, "year");

    const [inputs, setInputs] = useState<IInputsData>({
        jobsFilter: {
            testId: 'jobDetailScreenVisitsSwitch',
            type: InputType.ChipSwitch,
            label: '',
            value: jobDetailVisitsFilter ? parseInt(jobDetailVisitsFilter!) : JobVisitsTimelineFilter.FromToday,
            options: [
                {label: tt('jobDetailVisits.jobsFilter.past'), value: JobVisitsTimelineFilter.Past},
                {label: tt('jobDetailVisits.jobsFilter.fromToday'), value: JobVisitsTimelineFilter.FromToday},
                {label: tt('jobDetailVisits.jobsFilter.scheduleLater'), value: JobVisitsTimelineFilter.ScheduleLater},
            ],
            disabled: false,
        },
    });

    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<VisitListPureJoinedOthersResponsePage | NullOrUndefined>();
    useEffect(() => {
        if (companyId) {
            const subscription = subscribe(
                kTopicVisits,
                {
                    uri: '/job/visit/search-list-pure-joined-others',
                    params: {
                        jobId,
                        companyId: companyId!,
                        fromDate: fromDate.toMillis(),
                        toDate: toDate.toMillis(),
                        includeScheduleLater: true,
                    } as GetVisitsInput,
                    setLoading,
                    onData: setData,
                    onError: (error) => {
                        processQueryError(appDataContext, error);
                    },
                },
                (notifications) => true,
            );

            return () => subscription.cancel();
        }
    }, [companyId, fromDate, toDate, jobId]);

    useEffect(() => {
        if (data) {
            setStorage((prev) => {
                return {
                    filesToProcess: [
                        ...prev.filesToProcess,
                        ...(data.files || []),
                    ],
                };
            });
        }
    }, [data]);

    useEffect(() => {
        let tempArray = [];
        const currentYear = now.year;

        for (let i = currentYear - 5; i <= currentYear + 1; i++) {
            tempArray.push(i);
        }

        setYearValues(tempArray);
    }, []);

    useEffect(() => {
        setSearchParams(prev => {
            prev.set('jobDetailVisitsFilter', inputs.jobsFilter.value);

            return prev;
        });
    }, [inputs.jobsFilter.value]);

    useEffect(() => {
        if (filterByYear) {
            setFromDate(yearDate.startOf("year"));
            setToDate(yearDate.endOf("year"));
        } else if (inputs.jobsFilter.value === JobVisitsTimelineFilter.FromToday) {
            setFromDate(now.startOf("day"));
            setToDate(now.plus({year: 1}).endOf("month"));
        } else {
            setFromDate(now.minus({year: 1}).startOf("month"));
            setToDate(now.minus({day: 1}).endOf("day"));
        }

        setVisitsDisplayLimit(kVisitsListDisplayLimitPage);
        setCanShowLoading(true);

        setInputs(prev => {
            return {
                ...prev,
                jobsFilter: {
                    ...prev.jobsFilter,
                    disabled: filterByYear,
                },
            };
        });
    }, [inputs.jobsFilter.value, year]);

    const scheduleLaterEvents = useMemo(() => {
        let list: IVisitEvent[] | undefined;

        if (data) {
            list = [...VisitsProcessScheduleLater(
                data.scheduleLater || [],
            )];
        }

        if (list?.length) {
            setInputs(prev => {
                return {
                    ...prev,
                    jobsFilter: {
                        ...prev.jobsFilter,
                        options: [
                            {label: tt('jobDetailVisits.jobsFilter.past'), value: JobVisitsTimelineFilter.Past},
                            {
                                label: tt('jobDetailVisits.jobsFilter.fromToday'),
                                value: JobVisitsTimelineFilter.FromToday
                            },
                            {
                                label: tt('jobDetailVisits.jobsFilter.scheduleLater'),
                                value: JobVisitsTimelineFilter.ScheduleLater,
                                icon: (
                                    <Box className={classes.redDot}></Box>
                                ),
                            },
                        ],
                    },
                };
            });
        } else {
            setInputs(prev => {
                return {
                    ...prev,
                    jobsFilter: {
                        ...prev.jobsFilter,
                        options: [
                            {label: tt('jobDetailVisits.jobsFilter.past'), value: JobVisitsTimelineFilter.Past},
                            {
                                label: tt('jobDetailVisits.jobsFilter.fromToday'),
                                value: JobVisitsTimelineFilter.FromToday
                            },
                            {
                                label: tt('jobDetailVisits.jobsFilter.scheduleLater'),
                                value: JobVisitsTimelineFilter.ScheduleLater
                            },
                        ],
                    },
                };
            });
        }

        return list;
    }, [data, jobId]);

    const visitEvents = useMemo(() => {
        let list: IVisitEvent[] | undefined;

        if (data) {
            list = [];

            const fromToday = inputs.jobsFilter.value === JobVisitsTimelineFilter.FromToday;

            list.push(...VisitsProcess({
                nonRepeating: data.nonRepeating,
                repeating: data.repeating,
                visitRepeatDays: data.repeatingDayData,
                from: fromDate,
                to: toDate,
                sortDesc: !fromToday && !filterByYear,
                multipleEventsForMultiDay: true,
            }));

            setVisitsCount(list.length);

            setTimeout(() => {
                setCanShowLoading(false);
            }, 1);
        }

        return list;
    }, [data, fromDate, toDate, inputs.jobsFilter.value, year, jobId]);

    const scheduleLaterJSX = scheduleLaterEvents?.map((visit, index) => {
        const employees = data?.employees || [];

        const job = data?.jobs.find(job => job.id === visit.jobId);
        const client = data?.clients.find(client => client.id === visit.clientId);
        const locationId = visit.visitRepeatDay?.locationId || visit.locationId;
        const location = locationId ? data?.locations.find(location => location.id === locationId) : undefined;
        const jobForms = filterJobFormsPureByVisit(visit.id, visit.repeatingDay, data?.jobForms || []);
        const timesheets = FilterJobEmployeeTimesheetItems({
            jobEmployeeTimesheetItems: data?.timesheets,
            repeatingDay: visit.repeatingDay,
            filterByVisitId: visit.id,
        });
        const products = filterProductsForVisit({
            products: data?.products,
            visitId: visit.id,
            repeatingDay: visit.repeatingDay,
        });
        const materials = filterMaterialsForVisit({
            materials: data?.materials,
            visitId: visit.id,
            repeatingDay: visit.repeatingDay,
        });
        const jobOfferSeats = filterJobOfferSeatsForVisit(visit, data?.jobOfferSeats);

        const theEmployeeIds = visit.visitRepeatDay?.employeeIds || visit.employeeIds;
        const theEmployees = employees.filter(employee => {
            const jobOfferSeatForEmployee = jobOfferSeats?.find(jobOfferSeat => jobOfferSeat.acceptedIds.includes(employee.id));

            return theEmployeeIds.includes(employee.id) || jobOfferSeatForEmployee;
        });

        return (
            <VisitListItem
                key={visit.dynamicId || visit.id}
                data={visit}
                showBorderBottom={index !== scheduleLaterEvents.length - 1}
                job={job}
                client={client}
                location={location}
                employees={theEmployees}
                jobForms={jobForms}
                timesheets={timesheets}
                products={products}
                materials={materials}
                jobOfferSeats={jobOfferSeats}
                files={data?.files}
            />
        );
    });

    let visitsDisplaying = 0;

    const visitsJSX = visitEvents?.map((visit, index) => {
        if (visitsDisplayLimit < visitsDisplaying && visitsDisplaying !== 0) {
            return null;
        }
        visitsDisplaying++;

        const employees = data?.employees || [];

        const job = data?.jobs.find(job => job.id === visit.jobId);
        const client = data?.clients.find(client => client.id === visit.clientId);
        const locationId = visit.visitRepeatDay?.locationId || visit.locationId;
        const location = locationId ? data?.locations.find(location => location.id === locationId) : undefined;
        const jobForms = filterJobFormsPureByVisit(visit.id, visit.repeatingDay, data?.jobForms || []);
        const timesheets = FilterJobEmployeeTimesheetItems({
            jobEmployeeTimesheetItems: data?.timesheets,
            repeatingDay: visit.repeatingDay,
            filterByVisitId: visit.id,
        });
        const products = filterProductsForVisit({
            products: data?.products,
            visitId: visit.id,
            repeatingDay: visit.repeatingDay,
        });
        const materials = filterMaterialsForVisit({
            materials: data?.materials,
            visitId: visit.id,
            repeatingDay: visit.repeatingDay,
        });
        const jobOfferSeats = filterJobOfferSeatsForVisit(visit, data?.jobOfferSeats);

        const theEmployeeIds = visit.visitRepeatDay?.employeeIds || visit.employeeIds;
        const theEmployees = employees.filter(employee => {
            const jobOfferSeatForEmployee = jobOfferSeats?.find(jobOfferSeat => jobOfferSeat.acceptedIds.includes(employee.id));

            return theEmployeeIds.includes(employee.id) || jobOfferSeatForEmployee;
        });

        return (
            <VisitListItem
                key={visit.dynamicId || visit.id}
                data={visit}
                showBorderBottom={index !== visitEvents.length - 1}
                job={job}
                client={client}
                location={location}
                employees={theEmployees}
                jobForms={jobForms}
                timesheets={timesheets}
                products={products}
                materials={materials}
                jobOfferSeats={jobOfferSeats}
                files={data?.files}
            />
        );
    });

    const yearSelectPopupState = usePopupState({
        variant: 'popover',
        popupId: useId(),
    });

    return (
        <>
            {hasAnyVisit ? (
                    <Box>
                        <JobDetailVisitsTabHeadlineWidget
                            visitsSwitch={<FormBuilder inputs={inputs} setInputs={setInputs}/>}
                            yearsWidget={<><AppChip
                                className={classes.yearChip}
                                chipstyle={'secondary'}
                                label={year}
                                onClick={(e) => yearSelectPopupState.open(e)}
                            />
                                <Menu {...bindMenu(yearSelectPopupState)}>
                                    {yearValues.map(value => <MenuItem
                                        key={value}
                                        onClick={() => {
                                            yearSelectPopupState.close();
                                            setYear(value.toString())
                                        }}
                                    >
                                        {value}
                                    </MenuItem>)}
                                </Menu>
                            </>}
                            addIconButton={
                                <PermissionValid
                                    permission={kPermissionsJobs}
                                    requiredPermissions={[kActionCreate]}
                                >
                                    <AppIconButton
                                        onClick={() => {
                                            navigate(routeWithCurrentAsParam(
                                                newJobAndVisitRoute({
                                                    jobId: jobId,
                                                    kind: NewJobAndVisitRouteKind.fromJobDetail,
                                                })
                                            ));
                                        }}
                                        color={"primary"}
                                        tooltip={tt('common.add')}
                                        children={<Icons8Plus/>}
                                    />
                                </PermissionValid>
                            }
                        />

                        {loading && canShowLoading ? (
                            <>
                                <VisitListItemShimmer/>
                                <VisitListItemShimmer/>
                            </>
                        ) : inputs.jobsFilter.value === JobVisitsTimelineFilter.ScheduleLater && !filterByYear ? (
                            scheduleLaterJSX
                        ) : (visitsJSX)}

                        {inputs.jobsFilter.value === JobVisitsTimelineFilter.ScheduleLater && !filterByYear && scheduleLaterEvents && scheduleLaterEvents.length === 0 ? (
                            <EmptyListText text={tt('jobDetailVisits.noVisits')}/>
                        ) : inputs.jobsFilter.value !== JobVisitsTimelineFilter.ScheduleLater && visitEvents && visitsCount === 0 ? (
                            <EmptyListText text={tt('jobDetailVisits.noVisits')}/>
                        ) : undefined}

                        {visitsDisplayLimit < visitsCount ? (
                            <>
                                <Box sx={{pb: 2}}/>

                                <Box className={boxContentClasses.centered}>
                                    <AppButton variant="outlined" color="primary" disabled={loading}
                                               onClick={() => setVisitsDisplayLimit(visitsDisplayLimit + kVisitsListDisplayLimitPage)}>
                                        {tt('common.loadMore')}
                                    </AppButton>
                                </Box>

                                <Box sx={{pb: 2}}/>
                            </>
                        ) : undefined}
                    </Box>
                ) :
                <>
                    <PermissionValid
                        permission={kPermissionsJobs}
                        requiredPermissions={[kActionCreate]}
                    >
                        <HeadlineWithButton
                            title={tt('common.visits')}
                            onClick={() => {
                                navigate(routeWithCurrentAsParam(
                                    newJobAndVisitRoute({
                                        jobId: jobId,
                                        kind: NewJobAndVisitRouteKind.fromJobDetail,
                                    })
                                ));
                            }}
                            iconJSX={
                                <Icons8Plus/>
                            }
                        />
                    </PermissionValid>
                    <EmptyListText text={tt('jobDetailVisits.noVisits')}></EmptyListText>
                </>
            }
            <Divider/>
        </>
    );
}
