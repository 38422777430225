import {Box, Button, SxProps, Theme, Tooltip} from "@mui/material";
import React from "react";
import {makeStyles} from "tss-react/mui";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import {Loader} from "@mantine/core";

declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        textThin: true;
        dashed: true;
        roundedOutlined: true;
        textThin30: true;
        containedGreyThin30: true;
    }
}

export const useStyles = makeStyles()((theme: Theme) => ({
        button: {
            height: 48,
            '&:disabled': {
                backgroundColor: theme.palette.mode === "dark" ? '#6f6f6f' : '#EEF0F3',
                color: theme.palette.mode === "dark" ? '#cecfd0' : '#6f6f6f'
            },
        },
        textThin: {
            height: 36,
            paddingRight: 16,
            paddingLeft: 16,
            paddingTop: 10,
            paddingBottom: 10,
            borderRadius: 8,
            ':hover': {
                backgroundColor: kAppColors.hoverColor.mainLighter,
            },
            color: kAppColors.primary.main,
        },
        dashed: {
            paddingRight: 16,
            paddingLeft: 16,
            paddingTop: 10,
            paddingBottom: 10,
            borderRadius: 8,
            ':hover': {
                backgroundColor: kAppColors.hoverColor.mainLighter,
            },
            color: kAppColors.primary.main,
            borderStyle: "dashed",
            border: `2px dashed ${kAppColors.primary.main}`,
        },
        roundedOutlined: {
            borderRadius: 40,
            transition: 'all 0.4s linear',
            ':hover': {background: kAppColors.hoverColor.mainLighter},
            '&:disabled': {
                border: `2px solid #949596`,
                color: '#949596',
                background: "transparent",
            },
            border: `2px solid ${kAppColors.primary.main}`,
            height: 30,
            color: kAppColors.primary.main,
            fontSize: 12,
            paddingLeft: 30,
            paddingRight: 30,
        },
        loading: {
            cursor: "default",
            ':hover': {
                background: kAppColors.primary.main
            },
        },
        contentWhileLoadingOuter: {
            position: "relative",
        },
        contentWhileLoading: {
            opacity: 0,
        },
        loadingSpinnerContainer: {
            display: "flex",
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            alignItems: "center",
        },
        textThin30: {
            height: 30,
            borderRadius: 8,
            transition: 'all 0.4s linear',
            ':hover': {background: kAppColors.hoverColor.mainLighter},
            color: kAppColors.primary.main,
            fontSize: 12,
        },
        containedGreyThin30: {
            height: 30,
            borderRadius: 8,
            transition: 'all 0.4s linear',
            background: kAppColors.background.contactIconButton(theme.palette.mode === "dark"),
            ':hover': {
                backgroundColor: kAppColors.hoverColor.contactIconButton(theme.palette.mode === "dark")
            },
            color: kAppColors.text.primary(theme.palette.mode === "dark"),
            fontSize: 12,
        },
        containedSecondary30: {
            height: 30,
            borderRadius: 40,
            transition: 'all 0.4s linear',
            background: kAppColors.grey.secondaryChipBg,
            color: kAppColors.text.black,
            fontSize: 12,
            'span': {
                width: 14,
                height: 14,
                marginRight: 8,
            },
            'svg': {
                color: kAppColors.text.black,
                width: 14,
                height: 14,
            },
            ':hover': {
                backgroundColor: kAppColors.grey.hexaDFE1E5,
            },
        },
    })
);

export interface IAppButtonProps {
    testId?: string;
    className?: string;
    children?: React.ReactNode;
    sx?: SxProps<Theme>;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    disabled?: boolean;
    fullWidth?: boolean;
    variant?: 'text' | 'outlined' | 'contained' | 'textThin' | 'dashed' | 'roundedOutlined' | 'textThin30' | 'containedGreyThin30';
    appVariant?: 'containedSecondary30';
    color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
    tooltip?: string;
    placement?: | 'bottom-end'
        | 'bottom-start'
        | 'bottom'
        | 'left-end'
        | 'left-start'
        | 'left'
        | 'right-end'
        | 'right-start'
        | 'right'
        | 'top-end'
        | 'top-start'
        | 'top';
    isLoading?: boolean;
}

/**
 * Button component with App theme.
 */
export default function AppButton(props: IAppButtonProps) {
    const {
        sx,
        isLoading,
        tooltip,
        placement,
        className,
        variant,
        appVariant,
        testId,
    } = props;

    const {classes, cx} = useStyles();

    const theClassName = cx(
        classes.button,
        className,
        variant === 'textThin' ? classes.textThin : undefined,
        variant === 'dashed' ? classes.dashed : undefined,
        variant === "roundedOutlined" ? classes.roundedOutlined : undefined,
        variant === "textThin30" ? classes.textThin30 : undefined,
        variant === "containedGreyThin30" ? classes.containedGreyThin30 : undefined,
        appVariant === "containedSecondary30" ? classes.containedSecondary30 : undefined,
        isLoading ? classes.loading : undefined,
    );

    const tempProps = {
        ...props,
    };

    delete tempProps.testId;
    delete tempProps.isLoading;
    delete tempProps.appVariant;

    if (isLoading) {
        tempProps.disabled = false;
        tempProps.onClick = () => {
        };
        tempProps.children = <Box className={classes.contentWhileLoadingOuter}>
            <Box className={classes.loadingSpinnerContainer}>
                <Loader color={'white'} size="sm"/>
            </Box>
            <Box className={classes.contentWhileLoading}>
                {tempProps.children}
            </Box>
        </Box>;
    }

    let contentJSX = <Button
        {...tempProps}
        data-testid={testId}
        disableElevation={true}
        sx={sx}
        className={theClassName}
    />;

    if (tooltip) {
        contentJSX = (
            <Tooltip title={tooltip} placement={placement}>
                {contentJSX}
            </Tooltip>
        );
    }

    return (
        <>{contentJSX}</>
    );
}
