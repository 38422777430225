import {makeStyles} from "tss-react/mui";
import {Box, Divider, Theme, Typography} from "@mui/material";
import React, {Dispatch, SetStateAction, useContext, useEffect, useId, useMemo, useState} from "react";
import {tt} from "../../../../core/Localization";
import ModalBottomSheet from "../../ModalBottomSheet";
import BottomSheetModalAppbar from "../BottomSheetModalAppbar";
import {AppDataContext} from "../../../../AppData";
import AppListItem from "../../listItems/AppListItem";
import {UserFullName, UserPhotoUrl, UserRoleTitle} from "../../../../service/UserService";
import {
    AddJobEmployeeTimesheetItemToVisitDocument,
    AddJobEmployeeTimesheetItemToVisitMutation,
    AddJobEmployeeTimesheetItemToVisitMutationVariables,
    CalculateJobEmployeeTimesheetItemOfVisitForCompanyConfigurationInput,
    ClientPureResponse,
    CreateJobEmployeeTimesheetItemInput,
    DeleteJobEmployeeTimesheetItemFromVisitDocument,
    DeleteJobEmployeeTimesheetItemFromVisitMutation,
    DeleteJobEmployeeTimesheetItemFromVisitMutationVariables,
    EmployeeJoinedUserResponse,
    EmployeeJoinedUserResponsePage,
    FileResponse,
    GetEmployeesJoinedUsersIncludingInactiveInput,
    GetVisitJobEmployeeTimesheetItemsInput,
    ItemPaymentType,
    JobEmployeeDataResponse, JobEmployeeTimesheetItemResponse,
    JobEmployeeTimesheetItemResponsePage,
    JobUpdateRepeats,
    UpdateJobEmployeeTimesheetItemOfVisitDocument,
    UpdateJobEmployeeTimesheetItemOfVisitMutation,
    UpdateJobEmployeeTimesheetItemOfVisitMutationVariables,
} from "../../../../generated/graphql/graphql";
import GreyLabel from "../../decorations/GreyLabel";
import {getTimesheetTypeTitle} from "../../../../service/TimesheetService";
import {v4 as uuidv4} from 'uuid';
import TimesheetModalSection from "./TimesheetModalSection";
import {useMutation} from "@apollo/client";
import {processMutationError, processQueryError} from "../../../../service/ErrorService";
import {uniqueArray} from "../../../../utils/Utils";
import AppChip from "../../chips/AppChip";
import {Loader} from "@mantine/core";
import IEventSystemNotification from "../../../../model/firestore/EventSystemNotification";
import {SuccessToast} from "../../../../service/ToastService";
import {FilterJobEmployeeTimesheetItems, visitDateTimes, VisitNameOrSequenceId} from "../../../../service/VisitService";
import {HideConfirmModal, SetConfirmModal} from "../AppModals";
import TimesheetModalSectionShimmer from "../../shimmers/TimesheetModalSectionShimmer";
import {IOnUpdateVisitIdParams} from "../job/visits/VisitDetailModal";
import {FetchPolicy, RestApiClientContext} from "../../../../core/RestApiProvider";
import {kActionCreate, kActionUpdate, kPermissionsTimesheets, kTopicVisits} from "../../../../core/constants";
import PermissionValid from "../../permissions/PermissionValid";
import ChevronDownIcon from "../../../../icons/ChevronDownIcon";
import {bindMenu, bindTrigger} from "material-ui-popup-state";
import {usePopupState} from "material-ui-popup-state/hooks";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TimesheetsTotalChips from "../../timesheets/TimesheetsTotalChips";
import IVisitEvent from "../../../../model/VisitEvent";
import {DateTime} from "luxon";

export const useStyles = makeStyles()((theme: Theme) => ({
    buttonContainer: {
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        padding: 16,
    },
    container: {
        overflow: "auto",
        "@media (max-width: 767px)": {
            height: '100vh',
            maxHeight: '100vh',
        }
    },
    mobileExtraSpace: {
        "@media (max-width: 767px)": {
            paddingBottom: 180,
        }
    },
    addRow: {
        flexShrink: 0,
        paddingRight: 16,
        paddingLeft: 16,
        display: 'flex',
        paddingTop: 8,
        paddingBottom: 8,
        alignItems: "center",
        overflowX: "auto",
        '*': {
            flexShrink: 0,
        }
    },
    smallerChip: {
        fontSize: 12,
        height: 26,
    },
    chipRow: {
        display: "flex",
        alignItems: "center",
        padding: 0 + ' !important',
        'span': {
            position: "relative",
            top: 1,
            left: 4,
        }
    },
}));

export interface ITimesheetData {
    uuid: string;
    paymentType: ItemPaymentType;
    fixedPrice?: number | NullOrUndefined;
    hours?: number | NullOrUndefined;
    minutes?: number | NullOrUndefined;
    hourRate?: number | NullOrUndefined;
    startTime?: number | NullOrUndefined;
    endTime?: number | NullOrUndefined;
    approved: boolean;
    createdAt?: number;
    note?: string | NullOrUndefined;
    distance?: number | NullOrUndefined;
    distanceRate?: number | NullOrUndefined;
    employeeId: number;
    managedByConfiguration: boolean;
    configurationOverrideActive?: boolean | NullOrUndefined;
}

export interface IWorkerTimesheetModalBottomSheetProps {
    isNewJobCreation?: boolean;
    variant: 'new' | 'edit';
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    employee: EmployeeJoinedUserResponse;
    files?: FileResponse[] | NullOrUndefined;
    newJobExistingData?: CreateJobEmployeeTimesheetItemInput[];
    onUpdateNewJobExistingData?: Dispatch<SetStateAction<CreateJobEmployeeTimesheetItemInput[]>>;
    visitId?: number;
    repeatingDay?: number;
    repeats?: JobUpdateRepeats;
    employeeJobData?: JobEmployeeDataResponse;
    onUpdateVisitId?: (params: IOnUpdateVisitIdParams) => void;
    canOnlyApprove?: boolean;
    visitEvent?: IVisitEvent;
    client?: ClientPureResponse;
    onResetConfigurationOverrideActive?: VoidPromiseCallback;
}

export default function WorkerTimesheetModalBottomSheet(props: IWorkerTimesheetModalBottomSheetProps) {
    const {
        isNewJobCreation,
        variant,
        open,
        setOpen,
        employee,
        files,
        newJobExistingData,
        onUpdateNewJobExistingData,
        visitId,
        repeatingDay,
        repeats,
        employeeJobData,
        onUpdateVisitId,
        canOnlyApprove,
        visitEvent,
        client,
        onResetConfigurationOverrideActive,
    } = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiGet, restApiPost, subscribe} = restApiClientContext;

    const {classes, cx} = useStyles();

    const appDataContext = useContext(AppDataContext);
    const {companyId, employeeId: currentEmployeeId, storage} = appDataContext;

    const [createTimesheets, setCreateTimesheets] = useState<CreateJobEmployeeTimesheetItemInput[]>([]);
    const [newTimesheetUuid, setNewTimesheetUuid] = useState<string>('');
    const [employeeIds, setEmployeeIds] = useState<number[]>([]);

    const [timesheetsLoading, setTimesheetsLoading] = useState(false);
    const [timesheetsData, setTimesheetsData] = useState<JobEmployeeTimesheetItemResponsePage | null>(null);

    const jobTimesheetsPopupState = usePopupState({
        variant: 'popover',
        popupId: useId(),
    });

    const travelTimesheetsPopupState = usePopupState({
        variant: 'popover',
        popupId: useId(),
    });

    useEffect(() => {
        if (open && visitId) {
            const subscription = subscribe(
                kTopicVisits,
                {
                    uri: '/timesheet/get-visit-job-employee-timesheet-items',
                    params: {
                        visitIds: [visitId],
                    } as GetVisitJobEmployeeTimesheetItemsInput,
                    fetchPolicy: FetchPolicy.NetworkOnly,
                    setLoading: setTimesheetsLoading,
                    onData: setTimesheetsData,
                    onError: (error: any) => processQueryError(appDataContext, error),
                },
                (notifications: IEventSystemNotification[]) => {
                    const update = notifications.some(notification => {
                        if (notification.action !== kActionUpdate) {
                            return false;
                        }

                        return notification.data.id === visitId &&
                            (notification.data.repeatingDay === repeatingDay || (!notification.data.repeatingDay && !repeatingDay)) || notification.data.repeats === JobUpdateRepeats.ThisAndAllFuture;
                    });

                    return update;
                },
            );

            return () => {
                subscription.cancel();
            };
        } else {
            setTimesheetsData(null);
        }
    }, [visitId, repeatingDay, open]);

    const [employeesLoading, setEmployeesLoading] = useState(false);
    const [employeesData, setEmployeesData] = useState<EmployeeJoinedUserResponsePage | null>(null);
    useEffect(() => {
        if (open && employeeIds.length > 0) {
            restApiGet({
                uri: '/company/employee/search-joined-users-including-inactive',
                params: {
                    companyId,
                    employeeIds,
                } as GetEmployeesJoinedUsersIncludingInactiveInput,
                setLoading: setEmployeesLoading,
                onData: setEmployeesData,
                onError: (error: any) => processQueryError(appDataContext, error),
            });
        } else {
            setEmployeesData(null);
        }
    }, [companyId, employeeIds, open]);

    /**
     * For edit variant we call BE to get correct timesheet data for CompanyConfiguration.
     */
    const theOnResetConfigurationOverrideActive = onResetConfigurationOverrideActive ? onResetConfigurationOverrideActive :
        variant === 'edit' ? async () => {
            return new Promise<JobEmployeeTimesheetItemResponse[]>((resolve) => {
                restApiPost({
                    uri: '/job/visit/calculate-job-employee-timesheet-item-of-visit-for-company-configuration',
                    params: {
                        visitId: visitId!,
                        repeats: repeats!,
                        repeatingDay: repeatingDay,
                    } as CalculateJobEmployeeTimesheetItemOfVisitForCompanyConfigurationInput,
                    fetchPolicy: FetchPolicy.NetworkOnly,
                    onData: (data) => {
                        resolve(data?.content || []);
                    },
                    onError: (error: any) => processQueryError(appDataContext, error),
                });
            });
        } : undefined;

    const [mutateAddJobEmployeeTimesheetItem, {
        loading: addJobEmployeeTimesheetItemLoading,
    }] = useMutation<AddJobEmployeeTimesheetItemToVisitMutation, AddJobEmployeeTimesheetItemToVisitMutationVariables>(AddJobEmployeeTimesheetItemToVisitDocument);

    const [mutateUpdateJobEmployeeTimesheetItem, {
        loading: updateJobEmployeeTimesheetItemLoading,
    }] = useMutation<UpdateJobEmployeeTimesheetItemOfVisitMutation, UpdateJobEmployeeTimesheetItemOfVisitMutationVariables>(UpdateJobEmployeeTimesheetItemOfVisitDocument);

    const [mutateDeleteJobEmployeeTimesheetItem, {
        loading: deleteJobEmployeeTimesheetItemLoading,
    }] = useMutation<DeleteJobEmployeeTimesheetItemFromVisitMutation, DeleteJobEmployeeTimesheetItemFromVisitMutationVariables>(DeleteJobEmployeeTimesheetItemFromVisitDocument);

    useEffect(() => {
        if (newJobExistingData && variant === 'new') {
            const newEmployeeIds = newJobExistingData.map((item) => item.createdByEmployeeId);

            setEmployeeIds(uniqueArray(newEmployeeIds));
        } else if (timesheetsData && variant === 'edit') {
            const theData = FilterJobEmployeeTimesheetItems({
                jobEmployeeTimesheetItems: timesheetsData.content,
                repeatingDay: repeatingDay,
                filterByDeleted: false,
                filterByEmployeeId: employee.id,
            });

            let newEmployeeIds = createTimesheets.map((item) => item.createdByEmployeeId);

            newEmployeeIds = [...newEmployeeIds, ...theData.map((item) => item.createdByEmployeeId)];

            setEmployeeIds(uniqueArray(newEmployeeIds));
        }
    }, [newJobExistingData, timesheetsData, createTimesheets]);

    useEffect(() => {
        if (createTimesheets.length > 0 && !open) {
            setCreateTimesheets([]);
        }
    }, [createTimesheets, open]);

    /**
     * Add new timesheet of type.
     */
    const addNewTimesheet = async (type: ItemPaymentType) => {
        const newItem: CreateJobEmployeeTimesheetItemInput = {
            uuid: uuidv4(),
            paymentType: type,
            fixedPrice: 0,
            hours: 0,
            minutes: 0,
            hourRate: employee.hourRate || 0,
            approved: true,
            startTime: 0,
            endTime: 0,
            employeeId: employee.id,
            createdByEmployeeId: currentEmployeeId!,
            note: '',
            distance: 0,
            distanceRate: employee.distanceRate || 0,
            managedByConfiguration: false,
            configurationOverrideActive: false,
        };

        setNewTimesheetUuid(newItem.uuid);

        setCreateTimesheets(prev => {
            return [newItem, ...prev];
        });
    };

    /**
     * Update timesheet for uuid.
     */
    const updateTimesheet = async (uuid: string, data: Partial<ITimesheetData>) => {
        if (variant === 'new') {
            const createTimesheet = createTimesheets.find((item) => item.uuid === uuid);

            if (createTimesheet) {
                const newCreateTimesheets = createTimesheets.filter((item) => item.uuid !== uuid);

                setCreateTimesheets(newCreateTimesheets);

                onUpdateNewJobExistingData!(prev => {
                    return [{
                        ...createTimesheet,
                        ...data,
                        configurationOverrideActive: data.configurationOverrideActive || false,
                    }, ...prev];
                });
            } else {
                onUpdateNewJobExistingData!(prev => {
                    return [...prev.map((item) => {
                        if (item.uuid === uuid) {
                            return {
                                ...item,
                                ...data,
                                configurationOverrideActive: data.configurationOverrideActive || false,
                            };
                        }

                        return item;
                    })];
                });
            }

            setNewTimesheetUuid('');
        } else {
            const createTimesheet = createTimesheets.find((item) => item.uuid === uuid);

            if (createTimesheet) {
                try {
                    const combinedData = {
                        ...createTimesheet,
                        ...data,
                    };

                    const variables: AddJobEmployeeTimesheetItemToVisitMutationVariables = {
                        input: {
                            visitId: visitId!,
                            repeats: repeats!,
                            repeatingDay: repeatingDay,
                            uuid: combinedData.uuid,
                            employeeId: employee.id,
                            createdByEmployeeId: currentEmployeeId!,
                            paymentType: combinedData.paymentType,
                            hours: combinedData.hours,
                            minutes: combinedData.minutes,
                            hourRate: combinedData.hourRate,
                            fixedPrice: combinedData.fixedPrice,
                            startTime: combinedData.startTime,
                            endTime: combinedData.endTime,
                            approved: combinedData.approved,
                            note: combinedData.note,
                            distance: combinedData.distance,
                            distanceRate: combinedData.distanceRate,
                        },
                    };

                    setNewTimesheetUuid('');

                    const result = await mutateAddJobEmployeeTimesheetItem({variables});

                    if (!result.errors) {
                        SuccessToast(tt('jobDetailContent.addJobEmployeeTimesheet.success'));

                        const newCreateTimesheets = createTimesheets.filter((item) => item.uuid !== uuid);

                        setCreateTimesheets(newCreateTimesheets);

                        if (result.data!.addJobEmployeeTimesheetItemToVisit.id) {
                            onUpdateVisitId!({
                                visitId: result.data!.addJobEmployeeTimesheetItemToVisit.id,
                                repeatingDay: repeatingDay!,
                            });
                        }
                    }
                } catch (e) {
                    processMutationError(e);
                }
            } else {
                try {
                    const theData = FilterJobEmployeeTimesheetItems({
                        jobEmployeeTimesheetItems: timesheetsData!.content,
                        repeatingDay: repeatingDay,
                        filterByDeleted: false,
                        filterByEmployeeId: employee.id,
                    });

                    const theTimesheet = theData.find((item) => item.uuid === uuid)!;

                    const combinedData = {
                        ...theTimesheet,
                        ...data,
                    };

                    const variables: UpdateJobEmployeeTimesheetItemOfVisitMutationVariables = {
                        input: {
                            repeats: repeats!,
                            repeatingDay: repeatingDay,
                            timesheetId: theTimesheet.id,
                            employeeId: combinedData.employeeId,
                            visitId: visitId!,
                            approved: combinedData.approved,
                            hours: combinedData.hours,
                            minutes: combinedData.minutes,
                            hourRate: combinedData.hourRate,
                            fixedPrice: combinedData.fixedPrice,
                            startTime: combinedData.startTime,
                            endTime: combinedData.endTime,
                            note: combinedData.note,
                            timesheetUuid: combinedData.uuid,
                            distance: combinedData.distance,
                            distanceRate: combinedData.distanceRate,
                            configurationOverrideActive: combinedData.configurationOverrideActive || false,
                        },
                    };

                    const result = await mutateUpdateJobEmployeeTimesheetItem({variables});

                    if (!result.errors) {
                        SuccessToast(tt('jobDetailContent.updateJobEmployeeTimesheet.success'));

                        if (result.data!.updateJobEmployeeTimesheetItemOfVisit.id) {
                            onUpdateVisitId!({
                                visitId: result.data!.updateJobEmployeeTimesheetItemOfVisit.id,
                                repeatingDay: repeatingDay!,
                            });
                        }
                    }
                } catch (e) {
                    processMutationError(e);
                }
            }
        }
    };

    /**
     * Remove timesheet for uuid.
     */
    const removeTimesheet = async (uuid: string) => {
        if (variant === 'new') {
            const createTimesheet = createTimesheets.find((item) => item.uuid === uuid);

            if (createTimesheet) {
                const newCreateTimesheets = createTimesheets.filter((item) => item.uuid !== uuid);

                setCreateTimesheets(newCreateTimesheets);
            } else {
                onUpdateNewJobExistingData!(prev => {
                    return [...prev.filter((item) => item.uuid !== uuid)];
                });
            }
        } else {
            const createTimesheet = createTimesheets.find((item) => item.uuid === uuid);

            if (createTimesheet) {
                const newCreateTimesheets = createTimesheets.filter((item) => item.uuid !== uuid);

                setCreateTimesheets(newCreateTimesheets);
            } else {
                SetConfirmModal(appDataContext, {
                    open: true,
                    title: tt('jobDetailContent.removeTimesheet.confirmModal.title'),
                    subtitle: tt('jobDetailContent.removeTimesheet.confirmModal.description'),
                    confirmationButtonText: tt('common.remove'),
                    cancelButtonText: tt('common.close'),
                    children: <></>,
                    onConfirm: async () => {
                        HideConfirmModal(appDataContext);

                        try {
                            const theData = FilterJobEmployeeTimesheetItems({
                                jobEmployeeTimesheetItems: timesheetsData!.content,
                                repeatingDay: repeatingDay,
                                filterByDeleted: false,
                                filterByEmployeeId: employee.id,
                            });

                            const theTimesheet = theData.find((item) => item.uuid === uuid)!;

                            const variables: DeleteJobEmployeeTimesheetItemFromVisitMutationVariables = {
                                input: {
                                    visitId: visitId!,
                                    repeats: repeats!,
                                    repeatingDay: repeatingDay,
                                    timesheetId: theTimesheet.id,
                                    timesheetUuid: theTimesheet.uuid,
                                    employeeId: employee.id,
                                },
                            };

                            const result = await mutateDeleteJobEmployeeTimesheetItem({variables});

                            if (!result.errors) {
                                SuccessToast(tt('jobDetailContent.deleteJobEmployeeTimesheet.success'));

                                if (result.data!.deleteJobEmployeeTimesheetItemFromVisit.id) {
                                    onUpdateVisitId!({
                                        visitId: result.data!.deleteJobEmployeeTimesheetItemFromVisit.id,
                                        repeatingDay: repeatingDay!,
                                    });
                                }
                            }
                        } catch (e) {
                            processMutationError(e);
                        }
                    },
                });
            }
        }
    };

    let timesheetTotalChip;

    if (newJobExistingData && variant === 'new') {
        timesheetTotalChip = (
            <Box pr={1} pb={1}>
                <TimesheetsTotalChips
                    timesheets={newJobExistingData}
                />
            </Box>
        );
    }

    if (timesheetsData && variant === 'edit') {
        timesheetTotalChip = (
            <Box pr={1} pb={1}>
                <TimesheetsTotalChips
                    timesheets={FilterJobEmployeeTimesheetItems({
                        jobEmployeeTimesheetItems: timesheetsData!.content,
                        repeatingDay: repeatingDay,
                        filterByEmployeeId: employee.id,
                    })}
                />
            </Box>
        );
    }

    const visitEventJSX = useMemo(() => {
        if (visitEvent) {
            const visitName = VisitNameOrSequenceId(visitEvent, visitEvent.visitRepeatDay);
            let description = visitName ? [visitName] : [];
            const dateTimes = visitDateTimes(visitEvent, visitEvent.visitRepeatDay, visitEvent.repeatingDay, visitEvent);

            if (!dateTimes.isSingleDay) {
                description = [
                    `${DateTime.fromMillis(visitEvent.startDate).toFormat('d.M.yyyy')} - ${DateTime.fromMillis(visitEvent.endDate).toFormat('d.M.yyyy')}`,
                    ...description,
                ];
            } else {
                description = [
                    `${DateTime.fromMillis(visitEvent.startDate).toFormat('d.M.yyyy')}`,
                    ...description,
                ];
            }

            if (client) {
                description.push(client.name);
            }

            return (
                <>
                    <Divider/>

                    <Box pl={2} pr={2} pt={0.5} pb={0.5}>
                        <Typography>
                            {description.join(' · ')}
                        </Typography>
                    </Box>
                </>
            );
        } else {
            return null;
        }
    }, [visitEvent, client]);

    const sectionsJSX: React.ReactNode[] = useMemo(() => {
        const items: React.ReactNode[] = [];

        if (createTimesheets.length > 0) {
            for (const item of createTimesheets) {
                const existingTimesheet = timesheetsData?.content.find((timesheet) => timesheet.uuid === item.uuid);
                if (existingTimesheet) {
                    continue;
                }

                const createdByEmployee = employeesData?.content.find((employee) => employee.id === item.createdByEmployeeId);

                items.push(
                    <TimesheetModalSection
                        key={item.uuid}
                        isNewJobCreation={isNewJobCreation}
                        variant={variant}
                        startEditMode={item.uuid === newTimesheetUuid}
                        employee={employee}
                        createdByEmployee={createdByEmployee}
                        data={item}
                        onDelete={removeTimesheet}
                        onUpdate={updateTimesheet}
                        canOnlyApprove={canOnlyApprove}
                        onResetConfigurationOverrideActive={theOnResetConfigurationOverrideActive}
                    />
                );
            }
        }

        if (newJobExistingData && variant === 'new') {
            for (const item of newJobExistingData) {
                const createdByEmployee = employeesData?.content.find((employee) => employee.id === item.createdByEmployeeId);

                items.push(
                    <TimesheetModalSection
                        key={item.uuid}
                        isNewJobCreation={isNewJobCreation}
                        variant={variant}
                        startEditMode={item.uuid === newTimesheetUuid}
                        employee={employee}
                        createdByEmployee={createdByEmployee}
                        data={item}
                        onDelete={removeTimesheet}
                        onUpdate={updateTimesheet}
                        canOnlyApprove={canOnlyApprove}
                        onResetConfigurationOverrideActive={theOnResetConfigurationOverrideActive}
                    />
                );
            }
        }

        if (timesheetsData && variant === 'edit') {
            const theData = FilterJobEmployeeTimesheetItems({
                jobEmployeeTimesheetItems: timesheetsData.content,
                repeatingDay: repeatingDay,
                filterByEmployeeId: employee.id,
            }).sort((a, b) => b.createdAt - a.createdAt);

            for (const item of theData) {
                const createdByEmployee = employeesData?.content.find((employee) => employee.id === item.createdByEmployeeId);

                items.push(
                    <TimesheetModalSection
                        key={item.uuid}
                        isNewJobCreation={isNewJobCreation}
                        variant={variant}
                        startEditMode={item.uuid === newTimesheetUuid}
                        employee={employee}
                        createdByEmployee={createdByEmployee}
                        data={item}
                        onDelete={removeTimesheet}
                        onUpdate={updateTimesheet}
                        canOnlyApprove={canOnlyApprove}
                        onResetConfigurationOverrideActive={theOnResetConfigurationOverrideActive}
                    />
                );
            }
        }

        return items;
    }, [newJobExistingData, employeesData, timesheetsData, createTimesheets, employee, repeatingDay, isNewJobCreation]);

    const saving = addJobEmployeeTimesheetItemLoading || updateJobEmployeeTimesheetItemLoading || deleteJobEmployeeTimesheetItemLoading;

    return (
        <>
            <ModalBottomSheet
                blurBackdrop={true}
                open={open}
                setOpen={setOpen}
                hideHeader={true}
                fullScreenOnMobile={true}
            >
                <BottomSheetModalAppbar
                    onClose={() => setOpen(false)}
                    customClose={saving ? <Loader/> : undefined}
                    title={tt('common.timesheet')}
                    noBorderBottom={true}
                />
                <Box className={classes.container}>
                    <AppListItem
                        profileImage={
                            UserPhotoUrl(employee.user, files, storage.publicUrlsForFiles)
                        }
                        variant={"smaller-title"}
                        title={UserFullName(employee.name || employee.user?.name, employee.surname || employee.user?.surname)}
                        description={UserRoleTitle(employee.role)}
                        actionWidget={<></>}
                        belowListItemWidget={timesheetTotalChip}
                    />

                    {canOnlyApprove ? null : (
                        <PermissionValid
                            permission={kPermissionsTimesheets}
                            requiredPermissions={[kActionCreate]}
                        >
                            <>
                                <Divider/>
                                <Box className={cx(classes.addRow, 'styledScrollbar')}>
                                    <GreyLabel text={tt('timesheetModal.add')}/>

                                    <Box pl={1}/>

                                    <AppChip
                                        className={classes.smallerChip}
                                        chipstyle={'secondary'}
                                        label={<Box className={classes.chipRow}>{tt('timesheetModal.add.jobTimesheets')}
                                            <ChevronDownIcon/></Box>}
                                        {...bindTrigger(jobTimesheetsPopupState)}
                                    />

                                    <Menu {...bindMenu(jobTimesheetsPopupState)}>
                                        <MenuItem onClick={() => {
                                            jobTimesheetsPopupState.close();
                                            addNewTimesheet(ItemPaymentType.Hourly)
                                        }}>
                                            {getTimesheetTypeTitle(ItemPaymentType.Hourly, false)}
                                        </MenuItem>
                                        {variant === "edit" ? (
                                            <MenuItem onClick={() => {
                                                jobTimesheetsPopupState.close();
                                                addNewTimesheet(ItemPaymentType.Extra)
                                            }}>
                                                {getTimesheetTypeTitle(ItemPaymentType.Extra, false)}
                                            </MenuItem>
                                        ) : undefined}
                                        <MenuItem onClick={() => {
                                            jobTimesheetsPopupState.close();
                                            addNewTimesheet(ItemPaymentType.Fixed)
                                        }}>
                                            {getTimesheetTypeTitle(ItemPaymentType.Fixed, false)}
                                        </MenuItem>
                                        {variant === "edit" ? (
                                            <MenuItem onClick={() => {
                                                jobTimesheetsPopupState.close();
                                                addNewTimesheet(ItemPaymentType.StartEnd)
                                            }}>
                                                {getTimesheetTypeTitle(ItemPaymentType.StartEnd, false)}
                                            </MenuItem>
                                        ) : undefined}
                                    </Menu>

                                    <Box pl={1}/>

                                    <AppChip
                                        className={classes.smallerChip}
                                        chipstyle={'secondary'}
                                        label={<Box
                                            className={classes.chipRow}>{tt('timesheetModal.add.travelTimesheets')}
                                            <ChevronDownIcon/></Box>}
                                        {...bindTrigger(travelTimesheetsPopupState)}
                                    />

                                    <Menu {...bindMenu(travelTimesheetsPopupState)}>
                                        <MenuItem onClick={() => {
                                            travelTimesheetsPopupState.close();
                                            addNewTimesheet(ItemPaymentType.TravelHourly)
                                        }}>
                                            {getTimesheetTypeTitle(ItemPaymentType.TravelHourly, false)}
                                        </MenuItem>
                                        <MenuItem onClick={() => {
                                            travelTimesheetsPopupState.close();
                                            addNewTimesheet(ItemPaymentType.TravelDistance)
                                        }}>
                                            {getTimesheetTypeTitle(ItemPaymentType.TravelDistance, false)}
                                        </MenuItem>
                                        <MenuItem onClick={() => {
                                            travelTimesheetsPopupState.close();
                                            addNewTimesheet(ItemPaymentType.TravelFixed)
                                        }}>
                                            {getTimesheetTypeTitle(ItemPaymentType.TravelFixed, false)}
                                        </MenuItem>
                                        <MenuItem onClick={() => {
                                            travelTimesheetsPopupState.close();
                                            addNewTimesheet(ItemPaymentType.TravelStartEnd)
                                        }}>
                                            {getTimesheetTypeTitle(ItemPaymentType.TravelStartEnd, false)}
                                        </MenuItem>
                                    </Menu>
                                </Box>
                            </>
                        </PermissionValid>
                    )}

                    {visitEventJSX}

                    {sectionsJSX.length ? null : <Divider/>}

                    {timesheetsLoading && !timesheetsData || employeesLoading && !employeesData ?
                        <TimesheetModalSectionShimmer/> : sectionsJSX}

                    <Box className={classes.mobileExtraSpace}/>

                    <Box pb={12}/>
                </Box>
            </ModalBottomSheet>
        </>
    );
}
