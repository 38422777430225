import React, { Dispatch, SetStateAction, useContext, useEffect, useId, useMemo, useRef, useState } from "react";
import { AppContext } from "../../../App";
import { tt } from "../../../core/Localization";
import ResponsiveContainer from "../../components/screens/ResponsiveContainer";
import ScreenContent from "../../components/screens/ScreenContent";
import AppPaper from "../../components/paper/AppPaper";
import PaperAppbar from "../../components/paper/PaperAppbar";
import AppIconButton from "../../components/buttons/AppIconButton";
import PencilIcon from "../../../icons/PencilIcon";
import AppListItem from "../../components/listItems/AppListItem";
import CompanyIcon from "../../../icons/CompanyIcon";
import { Box, Divider, Grid, Theme } from "@mui/material";
import AppChip from "../../components/chips/AppChip";
import { makeStyles } from "tss-react/mui";
import { getIndustryIcon } from "../../../service/IndustryServices";
import AppTabsComponent from "../../components/AppTabsComponent";
import CountryRow from "../../components/screens/companyDetail/CountryRow";
import { kCompanyEditRoute } from "./CompanyEditScreen";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import {
    CompanyProfileResponse,
    CreateFileDocument,
    CreateFileInput,
    CreateFileMutation,
    CreateFileMutationVariables,
    DeleteCompanyDocument,
    DeleteCompanyMutation,
    DeleteCompanyMutationVariables,
    GetCompanyProfileInput,
    OsType,
    UpdateCompanyLogoDocument,
    UpdateCompanyLogoMutation,
    UpdateCompanyLogoMutationVariables
} from "../../../generated/graphql/graphql";
import { AppDataContext } from "../../../AppData";
import DetailScreenShimmer from "../../components/shimmers/DetailScreenShimmer";
import { kAppColors, kContentWidthMedium } from "../../../styles/AppThemeProcessor";
import MoreFilledIcon from "../../../icons/MoreFilledIcon";
import { usePopupState } from "material-ui-popup-state/hooks";
import { HideConfirmModal, SetConfirmModal, SetTrialInfoModal } from "../../components/modals/AppModals";
import { bindMenu, bindTrigger } from "material-ui-popup-state";
import { SuccessToast } from "../../../service/ToastService";
import { kMobileMenuRoute } from "../mobile/MobileMenuScreen";
import CurrencyRow from "../../components/screens/companyDetail/CurrencyRow";
import MenuItem from "@mui/material/MenuItem";
import DeleteIcon from "../../../icons/DeleteIcon";
import Menu from "@mui/material/Menu";
import BasicRow from "../../components/screens/companyDetail/BasicRow";
import Icons8Image from "../../../icons/Icons8Image";
import { genericStyles } from "../../../styles/UtilStyles";
import CropImageModal from "../../components/modals/CropImageModal";
import IFileState, { IFileStateType } from "../../../model/FileState";
import { v4 as uuidv4 } from "uuid";
import { kStorageCategoryCompanyLogo, storeFileToFirebase } from "../../../service/StorageService";
import { processMutationError, processQueryError } from "../../../service/ErrorService";
import CircularProgressWithLabel from "../../components/storage/CircularProgressWithLabel";
import SettingsIcon from "../../../icons/SettingsIcon";
import { kCompanySettingsRoute } from "./CompanySettingsScreen";
import { routeWithCurrentAsParam } from "../../../utils/Utils";
import Icons8UserWithLock from "../../../icons/Icons8UserWithLock";
import { kCompanyPermissionsRoute } from "./CompanyPermissionsScreen";
import {
    kActionCreate,
    kActionDelete,
    kActionUpdate,
    kPermissionsCompany,
    kPermissionsCompanySubscription,
    kTopicCompanies
} from "../../../core/constants";
import PermissionValid, { hasPermission } from "../../components/permissions/PermissionValid";
import { RestApiClientContext } from "../../../core/RestApiProvider";
import BannerTextAndButton from "../../components/banners/BannerTextAndButton";
import AvatarSubscriptionProgressDecoration from "../../components/progressIndicators/AvatarTrialProgressDecoration";
import AppAvatar from "../../components/AppAvatar";
import Icons8Invoice from "../../../icons/Icons8Invoice";
import { addressToSingleLine } from "../../../utils/AddressUtils";
import Icons8Done from "../../../icons/Icons8Done";
import { GetCompanyTaxInfoAsText } from "../../../service/CompanyService";
import MergedProductMaterialIcon from "../../../icons/MergedProductMaterialIcon";

export const kCompanyDetailRoute = '/settings/company';

const useStyles = makeStyles()((theme: Theme) => ({
    chipsContainer: {
        marginRight: 16,
        marginLeft: 16,
        marginBottom: 6,
        '.MuiChip-root': {
            marginRight: 12,
            marginBottom: 12,
        }
    },
    iconColor: {
        color: kAppColors.text.primary(theme.palette.mode === 'dark')
    },
}));

export default function CompanyDetailScreen() {
    const restApiClientContext = useContext(RestApiClientContext);
    const { subscribe } = restApiClientContext;

    const appContext = useContext(AppContext);
    const { setTitle } = appContext;

    const appDataContext = useContext(AppDataContext);
    const { companyId, storage, setStorage } = appDataContext;

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [selectedTab, setSelectedTab] = useState<string>(searchParams.get('selectedTab') ? searchParams.get('selectedTab')! : '0');

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<CompanyProfileResponse | NullOrUndefined>();
    useEffect(() => {
        if (companyId) {
            const subscription = subscribe(
                kTopicCompanies,
                {
                    uri: '/company/profile',
                    params: {
                        companyId,
                        includeIndustries: true,
                        includeLogoFile: true,
                    } as GetCompanyProfileInput,
                    setLoading,
                    onData: setData,
                    onError: (error: any) => processQueryError(appDataContext, error),
                },
                (notifications) => {
                    return notifications.some(notification => {
                        return notification.action === kActionUpdate;
                    });
                },
            );

            return () => subscription.cancel();
        } else {
            setLoading(false);
            setData(null);
        }
    }, [companyId]);

    const [mutateCreateFile, {
        loading: createFileLoading,
    }] = useMutation<CreateFileMutation, CreateFileMutationVariables>(CreateFileDocument);

    const [mutateUpdateCompanyLogo, {
        loading: updateCompanyLogoLoading,
    }] = useMutation<UpdateCompanyLogoMutation, UpdateCompanyLogoMutationVariables>(UpdateCompanyLogoDocument);

    const [mutateDelete, {
        loading: deleteLoading,
    }] = useMutation<DeleteCompanyMutation, DeleteCompanyMutationVariables>(DeleteCompanyDocument);

    useEffect(() => {
        setTitle(tt('companyDetail.screen.title'));
    }, []);

    useEffect(() => {
        setSearchParams(prev => {
            prev.set('selectedTab', selectedTab);
            return prev;
        });
    }, [selectedTab]);

    const logoFileId = data?.company.logoFileId;

    useEffect(() => {
        if (data?.logoFile) {
            setStorage((prev) => {
                return {
                    filesToProcess: [
                        ...prev.filesToProcess,
                        data!.logoFile!,
                    ],
                };
            });
        }
    }, [data]);

    useEffect(() => {
        SetConfirmModal(appDataContext, {
            loading: deleteLoading,
        });
    }, [deleteLoading]);

    /**
     * When new Company logo uploaded to Firebase, update to BE.
     * Mutate update of Company logo to BE when new file uploaded to Firebase.
     */
    const CreateCompanyLogoFile = async (input: CreateFileInput) => {
        try {
            const variables: CreateFileMutationVariables = {
                input,
            };

            const result = await mutateCreateFile({ variables });

            if (!result.errors) {
                const variables: UpdateCompanyLogoMutationVariables = {
                    input: {
                        companyId: companyId!,
                        logoFileId: result.data!.createFile!.id,
                    },
                };

                const updateResult = await mutateUpdateCompanyLogo({ variables });

                if (!updateResult.errors) {
                    SuccessToast(tt('companyDetail.screen.logo.success'));
                }
            }
        } catch (e) {
            processMutationError(e);
        }
    };

    /**
     * Mutate Company delete to BE.
     */
    const DeleteCompany = async () => {
        try {
            const variables: DeleteCompanyMutationVariables = {
                input: {
                    companyId: companyId!,
                    currentPlatform: OsType.Web,
                },
            };

            const result = await mutateDelete({ variables });

            if (!result.errors) {
                HideConfirmModal(appDataContext);
            }
        } catch (e) {
            processMutationError(e);
        }
    };

    function bodyJSX(isMobile?: boolean) {
        return (
            <Body
                loading={loading}
                data={data}
                companyLogoUrl={logoFileId ? storage.publicUrlsForIds[logoFileId] : undefined}
                isMobile={isMobile}
                createCompanyLogoFile={CreateCompanyLogoFile}
                deleteCompany={DeleteCompany}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
            />
        );
    }

    return (
        <>
            <ResponsiveContainer
                smallPhoneScreen={bodyJSX(true)}
                largePhoneScreen={bodyJSX(true)}
                tabletScreen={bodyJSX()}
                smallDesktopScreen={bodyJSX()}
                largeDesktopScreen={bodyJSX()}
                extraLargeDesktopScreen={bodyJSX()} />
        </>
    );
}

interface IBodyProps {
    loading: boolean;
    data?: CompanyProfileResponse | NullOrUndefined;
    companyLogoUrl?: string;
    isMobile?: boolean;
    createCompanyLogoFile: (input: CreateFileInput) => void;
    deleteCompany: VoidFunction;
    selectedTab: string;
    setSelectedTab: Dispatch<SetStateAction<string>>;
}

function Body(props: IBodyProps) {
    const {
        loading,
        data,
        companyLogoUrl,
        isMobile,
        createCompanyLogoFile,
        deleteCompany,
        selectedTab,
        setSelectedTab,
    } = props;

    const navigate = useNavigate();

    const { classes } = useStyles();
    const { classes: genericClasses } = genericStyles();

    const appDataContext = useContext(AppDataContext);
    const { companyId, companyData, employeePermissionsMap, companySubscriptionStatus } = appDataContext;

    const theCompanyData = data?.company;
    const industriesData = data?.companyIndustries;

    const logoUpdateInputRef = useRef<HTMLInputElement>(null);
    const [logoUpdateFileState, setLogoUpdateFileState] = useState<IFileState>();
    const [image, setImage] = useState<string>();
    const [cropModal, setCropModal] = useState<boolean>(false);

    const industriesJSX = industriesData ? (
        <>
            <Box sx={{ mb: 3.5 }} />

            <Grid className={classes.chipsContainer}>
                {industriesData.map((industry) => (
                    <AppChip
                        key={industry.id}
                        icon={getIndustryIcon(industry.name)}
                        label={tt(industry.translationKey)}
                        chipstyle={"outlined"}
                    />
                ))}
            </Grid>
        </>
    ) : null;

    const businessTaxInfo = useMemo(() => {
        if (data && data.company.billingAddress) {
            return {
                info: GetCompanyTaxInfoAsText(data.company),
                address: addressToSingleLine(data.company.billingAddress),
            };
        }

        return {};
    }, [data]);

    const tabsJSX = useMemo(() => {
        if (theCompanyData && theCompanyData.address) {
            const tabsData = [
                {
                    label: tt('common.company'),
                    content: <Box sx={{ mb: 1.25 }}>
                        {businessTaxInfo.address ?
                            <BasicRow icon={<Icons8Invoice />} text={businessTaxInfo.address} /> : null}

                        {
                            theCompanyData?.hasVat ?
                                <BasicRow
                                    icon={<Box sx={{ color: kAppColors.green.main }}><Icons8Done /></Box>}
                                    text={tt('vat.label.isVat')} /> : null
                        }

                        <CountryRow country={theCompanyData.address.country as any} />
                        <CurrencyRow currency={theCompanyData.currency as any} />
                    </Box>
                },
            ];

            if (hasPermission(kPermissionsCompany, [kActionUpdate], employeePermissionsMap)) {
                tabsData.push({
                    label: tt('companyDetail.screen.tab.settings'),
                    content: <Box sx={{ mb: 1.25 }}>
                        <AppListItem
                            variant={"smaller-title"}
                            title={tt('companyDetail.screen.settingsItem.general')}
                            onClick={() => navigate(routeWithCurrentAsParam(kCompanySettingsRoute))}
                            customAvatar={<SettingsIcon />}
                        />
                        <AppListItem
                            variant={"smaller-title"}
                            title={tt('common.permissions')}
                            onClick={() => navigate(routeWithCurrentAsParam(kCompanyPermissionsRoute))}
                            customAvatar={<Icons8UserWithLock />}
                        />
                    </Box>
                });
            }

            return (
                <>
                    <Divider />

                    <AppTabsComponent
                        controlledValue={selectedTab}
                        onTabChange={setSelectedTab}
                        data={tabsData}
                    />
                </>
            );
        }

        return null;
    }, [theCompanyData, employeePermissionsMap, selectedTab]);

    const theProgress = logoUpdateFileState?.progress || 0;
    const progressJSX = logoUpdateFileState?.type === IFileStateType.Select || logoUpdateFileState?.type === IFileStateType.Upload ? (
        <CircularProgressWithLabel value={theProgress} />
    ) : null;

    const permission = hasPermission(kPermissionsCompanySubscription, [kActionCreate], employeePermissionsMap);

    const contentJSX = loading && !data ? (
        <DetailScreenShimmer />
    ) : (
        <>
            {companyData.subscriptionIsTrial ? (
                <BannerTextAndButton
                    variant={'trial'}
                    text={tt('companyDetail.trialBanner.title')}
                    buttonText={tt('companyDetail.trialBanner.button')}
                    onTap={permission ? () => {
                        SetTrialInfoModal(appDataContext, {
                            open: true,
                        });
                    } : undefined}
                />
            ) : null}

            <AppListItem
                title={theCompanyData?.name || ''}
                description={businessTaxInfo.info ? businessTaxInfo.info : undefined}
                profileImage={!!progressJSX ? undefined : companyLogoUrl}
                actionWidget={<></>}
                customAvatar={
                    progressJSX ||
                    <AvatarSubscriptionProgressDecoration
                        subscription={companySubscriptionStatus?.subscription}
                        child={<AppAvatar
                            img={companyLogoUrl}
                            icon={<CompanyIcon className={classes.iconColor} />}
                        />}
                    />
                }
            />

            {industriesJSX}

            <Box sx={{ pb: 1 }} />

            {tabsJSX}
        </>
    );

    const companyPopupState = usePopupState({
        variant: 'popover',
        popupId: useId(),
    });

    const editPopupState = usePopupState({
        variant: 'popover',
        popupId: useId(),
    });

    const chooseLogo = () => {
        if (logoUpdateInputRef.current) {
            logoUpdateInputRef.current.click();
        }
    };

    return (
        <ScreenContent
            appBar={!isMobile}
            noContentPadding={isMobile}
            navigationDrawer={!isMobile}
            bottomBar={isMobile}
            centerHorizontally={true}>
            <AppPaper
                sx={{ maxWidth: isMobile ? undefined : kContentWidthMedium }}
            >
                <PaperAppbar
                    hideBackButton={!isMobile}
                    backRoute={kMobileMenuRoute}
                    isMobile={isMobile}
                    title={tt('common.company')}
                    children={
                        [
                            <PermissionValid
                                key={'companyDetailEditBtnKey'}
                                permission={kPermissionsCompany}
                                requiredPermissions={[kActionUpdate]}
                            >
                                <AppIconButton
                                    onClick={(e) => {
                                        editPopupState.open(e);
                                    }
                                    }>
                                    <PencilIcon />
                                </AppIconButton>
                            </PermissionValid>,

                            <PermissionValid
                                key={'companyMoreOptionsBtn'}
                                permission={kPermissionsCompany}
                                requiredPermissions={[kActionDelete]}
                            >
                                <AppIconButton
                                    {...bindTrigger(companyPopupState)}
                                >
                                    <MoreFilledIcon />
                                </AppIconButton>
                            </PermissionValid>
                        ]
                    } />

                <Menu {...bindMenu(editPopupState)}>
                    <MenuItem key={'editCompanyItem'} onClick={() => {
                        editPopupState.close();
                        navigate(kCompanyEditRoute);
                    }}>
                        <CompanyIcon />
                        {tt('common.company')}
                    </MenuItem>
                    <MenuItem key={'editCompanyImageItem'} onClick={() => {
                        editPopupState.close();
                        chooseLogo();
                    }}>
                        <Icons8Image />
                        {tt('common.logo')}
                    </MenuItem>
                </Menu>

                <PermissionValid
                    key={'deleteCompanyItem'}
                    permission={kPermissionsCompany}
                    requiredPermissions={[kActionDelete]}
                >
                    <Menu {...bindMenu(companyPopupState)}>
                        <MenuItem onClick={() => {
                            companyPopupState.close();

                            SetConfirmModal(appDataContext, {
                                open: true,
                                title: tt('deleteCompany.popup.title'),
                                subtitle: tt('deleteCompany.popup.description'),
                                onConfirm: deleteCompany,
                                cancelButtonText: tt('common.close'),
                                confirmationButtonText: tt('deleteCompany.popup.confirm'),
                                children: <></>
                            });
                        }}>
                            <Box color={kAppColors.red.confirmButton}><DeleteIcon /></Box>
                            {tt('common.delete')}
                        </MenuItem>
                    </Menu>
                </PermissionValid>

                {contentJSX}

                <input
                    ref={logoUpdateInputRef}
                    className={genericClasses.hidden}
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                        if (e.target.files && e.target.files.length > 0) {
                            const theFile = e.target.files[0];

                            const reader = new FileReader();
                            reader.readAsDataURL(theFile);
                            reader.onload = function (e) {
                                setLogoUpdateFileState({
                                    type: IFileStateType.Select,
                                    uuid: uuidv4(),
                                    companyId: companyId,
                                    category: kStorageCategoryCompanyLogo,
                                    fileAccept: 'image/*',
                                    inputFile: theFile,
                                });

                                setImage(reader.result as string);
                            };
                        }

                        setCropModal(true);
                    }} />

            </AppPaper>

            <CropImageModal
                open={cropModal}
                setOpen={setCropModal}
                isLoading={logoUpdateFileState?.type == IFileStateType.Upload}
                onSave={(blob: Blob | null) => {
                    if (blob) {
                        const newFileState: IFileState = {
                            ...logoUpdateFileState!,
                            type: IFileStateType.Upload,
                            overrideFileUpdateData: blob,
                        };

                        setLogoUpdateFileState(newFileState);

                        storeFileToFirebase({
                            fileState: newFileState,
                            onUpdateFileState: (updateFileState: (fileState: IFileState) => IFileState, fileUploadFinished?: boolean) => {
                                setLogoUpdateFileState(updateFileState(logoUpdateFileState!));
                            },
                            onDelete: (uuid: string) => {
                            },
                            onComplete: (input: CreateFileInput) => {
                                setLogoUpdateFileState({
                                    ...logoUpdateFileState!,
                                    type: IFileStateType.File,
                                });

                                createCompanyLogoFile(input);

                                setCropModal(false);
                            },
                        });
                    }
                }}
                img={image || ''}
                title={tt('common.logo')}
            />
        </ScreenContent>
    );
}
