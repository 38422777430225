import {
    ClientPureResponse,
    CreateJobEmployeeTimesheetItemInput,
    EmployeeJoinedUserResponse,
    ItemPaymentType,
    JobEmployeeDataResponse,
    JobEmployeeTimesheetItemResponse,
    JobPureResponse,
    LocationPlaceResponse,
    LocationPureResponse, MaterialResponse, ProductResponse
} from "../generated/graphql/graphql";
import {tt} from "../core/Localization";
import {UserFullName, UserRoleTitle} from "./UserService";
import {DateTime} from "luxon";
import {PriceDisplay} from "./CompanyService";
import {ITimesheetsMonthData} from "../ui/screens/timesheets/TimeSheetsScreen";
import {ITimesheetsDetailMonthData} from "../ui/screens/timesheets/TimesheetsDetailScreen";
import {v4 as uuidv4} from "uuid";
import {filterJobEmployeeData, getEmployeeJobStatusTitle, visitDateTimes} from "./VisitService";
import Icons8InTransit from "../icons/Icons8InTransit";
import HammerIcon from "../icons/HammerIcon";
import {filterMaterialsForVisit} from "./MaterialService";
import {filterProductsForVisit} from "./ProductService";
import {addressToSingleLine} from "../utils/AddressUtils";

const XLSX = require("xlsx");

export interface IProcessTimesheetDataToExcelParams {
    year: number;
    month: number;
    monthsData?: ITimesheetsMonthData;
    employee?: EmployeeJoinedUserResponse | NullOrUndefined;
    jobEmployeeData?: JobEmployeeDataResponse[];
    monthData?: ITimesheetsDetailMonthData;
    currency: string;
    language: string;
    clients?: Array<ClientPureResponse>;
    locations?: Array<LocationPureResponse>;
    places?: Array<LocationPlaceResponse>;
    jobs?: Array<JobPureResponse>;
    products?: Array<ProductResponse>;
    materials?: Array<MaterialResponse>;
}

/**
 * Process the timesheet data into excel file.
 */
export function processTimesheetDataToExcel(params: IProcessTimesheetDataToExcelParams) {
    const {
        year,
        month,
        employee,
        jobEmployeeData,
        monthsData,
        monthData,
        currency,
        clients,
        locations,
        places,
        language,
        jobs,
    } = params;

    const workbook = XLSX.utils.book_new();

    if (monthsData) {
        let data: string[][] = [];

        const monthDate = DateTime.fromObject({year, month});
        data.push([monthDate.toFormat('LLLL yyyy')]);

        data.push([]);

        data.push([
            tt('common.total'), //A
            tt('common.done'), //B
            tt('common.inProgress'), //C
            tt('common.scheduled'), //D
        ]);

        data.push([
            PriceDisplay(monthsData.value, currency, language) || '0',
            PriceDisplay(monthsData.done, currency, language) || '0',
            PriceDisplay(monthsData.inProgress, currency, language) || '0',
            PriceDisplay(monthsData.scheduled, currency, language) || '0'
        ]);

        let worksheet = XLSX.utils.aoa_to_sheet(data);

        XLSX.utils.book_append_sheet(workbook, worksheet, tt('common.export.sheet.main'));

        data = [];

        data.push([
            tt('common.export.column.date'), //A
            tt('common.export.column.weekday'), //B
            tt('common.export.column.startTime'), //C
            tt('common.export.column.endTime'), //D
            tt('common.export.column.worker'), //E
            tt('common.export.column.jobNumber'), //F
            tt('common.export.column.job'), //G
            tt('common.export.column.visit'), //H
            tt('common.export.column.client'), //I
            tt('common.export.column.locationOrPlace'), //J
            tt('common.export.column.employee'), //K
            tt('common.export.column.work'), //L
            tt('common.export.column.travel'), //M
            tt('common.export.column.currency'), //N
            tt('common.export.column.hours'), //O
            tt('common.export.column.distance'), //P
            tt('common.export.column.employeeStatus'), //Q
        ]);

        const employeesTotalAggregate: Record<number, IEmployeeTotalAggregate> = {};

        for (const visit of monthsData.monthVisits) {
            const employeeIds = visit.visitRepeatDay?.employeeIds || visit.employeeIds;

            for (const employeeId of employeeIds) {
                const employee = monthsData.monthEmployees[employeeId];

                if (!employee) {
                    continue;
                }

                let perEmployeePerVisit = monthsData.perEmployeePerVisits.find((perEmployeePerJob) => {
                    return perEmployeePerJob.employeeId === employee.employee.id && perEmployeePerJob.visitId === `${visit.dynamicId || visit.id}`;
                });

                if (!employeesTotalAggregate[employeeId]) {
                    employeesTotalAggregate[employeeId] = {
                        worker: UserFullName(employee.employee.name || employee.employee.user?.name, employee.employee.surname || employee.employee.user?.surname),
                        total: 0,
                        work: 0,
                        travel: 0,
                        hours: 0,
                        distance: 0,
                    };
                }

                employeesTotalAggregate[employeeId].total += perEmployeePerVisit?.value || 0;
                employeesTotalAggregate[employeeId].work += perEmployeePerVisit?.work || 0;
                employeesTotalAggregate[employeeId].travel += perEmployeePerVisit?.travel || 0;
                employeesTotalAggregate[employeeId].hours += perEmployeePerVisit?.hours || 0;
                employeesTotalAggregate[employeeId].distance += perEmployeePerVisit?.distance || 0;

                const jobRow: string[] = [];

                const dateTimes = visitDateTimes(visit, visit.visitRepeatDay, visit.repeatingDay, visit);

                if (!dateTimes.isSingleDay) {
                    jobRow.push(`${DateTime.fromMillis(visit.startDate).toFormat('d.M.yyyy')} - ${DateTime.fromMillis(visit.endDate).toFormat('d.M.yyyy')}`);
                } else {
                    jobRow.push(dateTimes.start.toFormat('d.M.yyyy'));
                }

                jobRow.push(dateTimes.start.toFormat('cccc', {locale: language}));

                const startTime = DateTime.fromMillis(visit.visitRepeatDay?.startTime || visit.startTime);
                const endTime = DateTime.fromMillis(visit.visitRepeatDay?.endTime || visit.endTime);

                jobRow.push(startTime.toFormat('H:mm'));

                jobRow.push(endTime.toFormat('H:mm'));

                jobRow.push(UserFullName(employee.employee.name || employee.employee.user?.name, employee.employee.surname || employee.employee.user?.surname));

                const job = jobs?.find(job => job.id === visit.jobId);

                jobRow.push(job?.sequenceId || '');

                jobRow.push(job?.name || '');

                jobRow.push(visit.visitRepeatDay?.name || visit.name || '');

                const client = clients?.find(client => client.id === visit.clientId);
                jobRow.push(client?.name || '');

                const location = locations?.find(location => location.id === visit.locationId);
                const place = visit.locationPlaceId ? places?.find(place => place.id === visit.locationPlaceId) : undefined;

                let locationText = place?.name || location?.name || undefined;

                if (!locationText && location) {
                    locationText = addressToSingleLine(location.address);
                }

                jobRow.push(locationText || '');

                jobRow.push(UserFullName(employee.employee.name || employee.employee.user?.name, employee.employee.surname || employee.employee.user?.surname));

                // is formatted to number column
                jobRow.push(`${perEmployeePerVisit?.work || 0}`);

                // is formatted to number column
                jobRow.push(`${perEmployeePerVisit?.travel || 0}`);

                jobRow.push(currency);

                // is formatted to number column
                jobRow.push(`${perEmployeePerVisit?.hours || 0}`);

                // is formatted to number column
                jobRow.push(`${perEmployeePerVisit?.distance || 0}`);

                const employeeData = filterJobEmployeeData({
                    jobEmployeeData,
                    filterByVisitId: visit.id,
                    repeatingDay: visit.repeatingDay,
                    filterByEmployeeId: employee.employee.id,
                });
                jobRow.push(getEmployeeJobStatusTitle(employeeData[0]?.status));

                data.push(jobRow);
            }
        }

        const employeesTotalData: string[][] = [];

        employeesTotalData.push([
            tt('common.export.column.worker'), //A
            tt('common.export.column.work'), //B
            tt('common.export.column.travel'), //C
            tt('common.export.column.currency'), //D
            tt('common.export.column.hours'), //E
            tt('common.export.column.distance'), //F
        ]);

        for (const employeeId of Object.keys(employeesTotalAggregate)) {
            const theEmployeeId = parseInt(employeeId);
            const employeeTotalAggregate = employeesTotalAggregate[theEmployeeId];

            const workerRow: string[] = [];

            workerRow.push(employeeTotalAggregate.worker);

            workerRow.push(`${employeeTotalAggregate.work}`);

            workerRow.push(`${employeeTotalAggregate.travel}`);

            workerRow.push(currency);

            workerRow.push(`${employeeTotalAggregate.hours}`);

            workerRow.push(`${employeeTotalAggregate.distance}`);

            employeesTotalData.push(workerRow);
        }

        worksheet = XLSX.utils.aoa_to_sheet(employeesTotalData);

        for (let i = 1; i < employeesTotalData.length; i++) {
            worksheet[`B${i + 1}`].t = "n";
            worksheet[`C${i + 1}`].t = "n";
            worksheet[`E${i + 1}`].t = "n";
            worksheet[`F${i + 1}`].t = "n";
        }

        XLSX.utils.book_append_sheet(workbook, worksheet, tt('common.export.sheet.workerTotals'));

        worksheet = XLSX.utils.aoa_to_sheet(data);

        for (let i = 1; i < data.length; i++) {
            worksheet[`L${i + 1}`].t = "n";
            worksheet[`M${i + 1}`].t = "n";
            worksheet[`O${i + 1}`].t = "n";
            worksheet[`P${i + 1}`].t = "n";
        }

        XLSX.utils.book_append_sheet(workbook, worksheet, tt('common.export.sheet.workers'));

        createProductMaterialSheet(params, workbook);

        const worksheetNames: Record<string, number> = {};

        for (const employee of Object.values(monthsData.monthEmployees)) {
            data = [];

            data.push([
                tt('common.export.column.date'), //A
                tt('common.export.column.weekday'), //B
                tt('common.export.column.startTime'), //C
                tt('common.export.column.endTime'), //D
                tt('common.export.column.jobNumber'), //E
                tt('common.export.column.job'), //F
                tt('common.export.column.visit'), //G
                tt('common.export.column.client'), //H
                tt('common.export.column.locationOrPlace'), //I
                tt('common.export.column.employee'), //J
                tt('common.export.column.work'), //K
                tt('common.export.column.travel'), //L
                tt('common.export.column.currency'), //M
                tt('common.export.column.hours'), //N
                tt('common.export.column.distance'), //O
                tt('common.export.column.employeeStatus'), //P
            ]);

            const visits = monthsData.monthVisits.filter(visit => {
                const employeeIds = visit.visitRepeatDay?.employeeIds || visit.employeeIds;

                return employeeIds.includes(employee.employee.id);
            });

            for (const visit of visits) {
                const visitRow: string[] = [];

                const dateTimes = visitDateTimes(visit, visit.visitRepeatDay, visit.repeatingDay, visit);

                if (!dateTimes.isSingleDay) {
                    visitRow.push(`${DateTime.fromMillis(visit.startDate).toFormat('d.M.yyyy')} - ${DateTime.fromMillis(visit.endDate).toFormat('d.M.yyyy')}`);
                } else {
                    visitRow.push(dateTimes.start.toFormat('d.M.yyyy'));
                }

                visitRow.push(dateTimes.start.toFormat('cccc', {locale: language}));

                const startTime = DateTime.fromMillis(visit.visitRepeatDay?.startTime || visit.startTime);
                const endTime = DateTime.fromMillis(visit.visitRepeatDay?.endTime || visit.endTime);

                visitRow.push(startTime.toFormat('H:mm'));

                visitRow.push(endTime.toFormat('H:mm'));

                const job = jobs?.find(job => job.id === visit.jobId);

                visitRow.push(job?.sequenceId || '');

                visitRow.push(job?.name || '');

                visitRow.push(visit.visitRepeatDay?.name || visit.name || '');

                const client = clients?.find(client => client.id === visit.clientId);
                visitRow.push(client?.name || '');

                const location = locations?.find(location => location.id === visit.locationId);
                const place = visit.locationPlaceId ? places?.find(place => place.id === visit.locationPlaceId) : undefined;

                let locationText = place?.name || location?.name || undefined;

                if (!locationText && location) {
                    locationText = addressToSingleLine(location.address);
                }

                visitRow.push(locationText || '');

                let perEmployeePerVisit = monthsData.perEmployeePerVisits.find((perEmployeePerJob) => {
                    return perEmployeePerJob.employeeId === employee.employee.id && perEmployeePerJob.visitId === `${visit.dynamicId || visit.id}`;
                });

                visitRow.push(UserFullName(employee.employee.name || employee.employee.user?.name, employee.employee.surname || employee.employee.user?.surname));

                // is formatted to number column
                visitRow.push(`${perEmployeePerVisit?.work || 0}`);

                // is formatted to number column
                visitRow.push(`${perEmployeePerVisit?.travel || 0}`);

                visitRow.push(currency);

                // is formatted to number column
                visitRow.push(`${perEmployeePerVisit?.hours || 0}`);

                // is formatted to number column
                visitRow.push(`${perEmployeePerVisit?.distance || 0}`);

                const employeeData = filterJobEmployeeData({
                    jobEmployeeData,
                    filterByVisitId: visit.id,
                    repeatingDay: visit.repeatingDay,
                    filterByEmployeeId: employee.employee.id,
                });
                visitRow.push(getEmployeeJobStatusTitle(employeeData[0]?.status));

                data.push(visitRow);
            }

            worksheet = XLSX.utils.aoa_to_sheet(data);

            for (let i = 1; i < data.length; i++) {
                worksheet[`K${i + 1}`].t = "n";
                worksheet[`L${i + 1}`].t = "n";
                worksheet[`N${i + 1}`].t = "n";
                worksheet[`O${i + 1}`].t = "n";
            }

            let worksheetName = UserFullName(employee.employee.name || employee.employee.user?.name, employee.employee.surname || employee.employee.user?.surname);

            if (worksheetNames[worksheetName]) {
                worksheetName += ` (${worksheetNames[worksheetName]})`;
                worksheetNames[worksheetName]++;
            } else {
                worksheetNames[worksheetName] = 1;
            }

            XLSX.utils.book_append_sheet(workbook, worksheet, worksheetName);
        }
    }

    if (employee && monthData) {
        let data: string[][] = [];

        const monthDate = DateTime.fromObject({year, month});
        data.push([monthDate.toFormat('LLLL yyyy')]);

        data.push([UserFullName(employee.name || employee.user?.name, employee.surname || employee.user?.surname)]);

        data.push([UserRoleTitle(employee.role)]);

        data.push([]);

        data.push([tt('common.total'), tt('common.done'), tt('common.inProgress'), tt('common.scheduled')]);

        data.push([
            PriceDisplay(monthData.total, currency, language) || '0',
            PriceDisplay(monthData.done, currency, language) || '0',
            PriceDisplay(monthData.inProgress, currency, language) || '0',
            PriceDisplay(monthData.scheduled, currency, language) || '0'
        ]);

        let worksheet = XLSX.utils.aoa_to_sheet(data);

        XLSX.utils.book_append_sheet(workbook, worksheet, tt('common.export.sheet.main'));

        data = [];

        data.push([
            tt('common.export.column.date'), //A
            tt('common.export.column.weekday'), //B
            tt('common.export.column.startTime'), //C
            tt('common.export.column.endTime'), //D
            tt('common.export.column.jobNumber'), //E
            tt('common.export.column.job'), //F
            tt('common.export.column.visit'), //G
            tt('common.export.column.client'), //H
            tt('common.export.column.locationOrPlace'), //I
            tt('common.export.column.work'), //J
            tt('common.export.column.travel'), //K
            tt('common.export.column.currency'), //L
            tt('common.export.column.hours'), //M
            tt('common.export.column.distance'), //N
            tt('common.export.column.employeeStatus'), //O
        ]);

        for (const dayOf of monthData.days) {
            for (const visit of dayOf.visits) {
                const visitRow: string[] = [];

                const dateTimes = visitDateTimes(visit, visit.visitRepeatDay, visit.repeatingDay, visit);

                if (!dateTimes.isSingleDay) {
                    visitRow.push(`${DateTime.fromMillis(visit.startDate).toFormat('d.M.yyyy')} - ${DateTime.fromMillis(visit.endDate).toFormat('d.M.yyyy')}`);
                } else {
                    visitRow.push(dayOf.date.toFormat('d.M.yyyy'));
                }

                visitRow.push(dayOf.date.toFormat('cccc', {locale: language}));

                const startTime = DateTime.fromMillis(visit.visitRepeatDay?.startTime || visit.startTime);
                const endTime = DateTime.fromMillis(visit.visitRepeatDay?.endTime || visit.endTime);

                visitRow.push(startTime.toFormat('H:mm'));

                visitRow.push(endTime.toFormat('H:mm'));

                const job = jobs?.find(job => job.id === visit.jobId);

                visitRow.push(job?.sequenceId || '');

                visitRow.push(job?.name || '');

                visitRow.push(visit.visitRepeatDay?.name || visit.name || '');

                const client = clients?.find(client => client.id === visit.clientId);
                visitRow.push(client?.name || '');

                const location = locations?.find(location => location.id === visit.locationId);
                const place = visit.locationPlaceId ? places?.find(place => place.id === visit.locationPlaceId) : undefined;

                let locationText = place?.name || location?.name || undefined;

                if (!locationText && location) {
                    locationText = addressToSingleLine(location.address);
                }

                visitRow.push(locationText || '');

                // is formatted to number column
                visitRow.push(`${monthData.workValues[visit.dynamicId || visit.id] || 0}`);

                // is formatted to number column
                visitRow.push(`${monthData.travelValues[visit.dynamicId || visit.id] || 0}`);

                visitRow.push(currency);

                const time = monthData.employeeHours[visit.dynamicId || visit.id] + (monthData.employeeMinutes[visit.dynamicId || visit.id] / 60);
                // is formatted to number column
                visitRow.push(`${time || 0}`);

                // is formatted to number column
                visitRow.push(`${monthData.employeeDistance[visit.dynamicId || visit.id] || 0}`);

                const employeeData = filterJobEmployeeData({
                    jobEmployeeData,
                    filterByVisitId: visit.id,
                    repeatingDay: visit.repeatingDay,
                    filterByEmployeeId: employee.id,
                });
                visitRow.push(getEmployeeJobStatusTitle(employeeData[0]?.status));

                data.push(visitRow);
            }
        }

        worksheet = XLSX.utils.aoa_to_sheet(data);

        for (let i = 1; i < data.length; i++) {
            worksheet[`J${i + 1}`].t = "n";
            worksheet[`K${i + 1}`].t = "n";
            worksheet[`M${i + 1}`].t = "n";
            worksheet[`N${i + 1}`].t = "n";
        }

        XLSX.utils.book_append_sheet(workbook, worksheet, tt('common.export.sheet.jobs'));
    }

    let fileName = `${tt('app.name')}-${year}-${month}.xlsx`;
    if (employee) {
        fileName = `${tt('app.name')}-${UserFullName(employee.name || employee.user?.name, employee.surname || employee.user?.surname)}-${year}-${month}.xlsx`;
    }

    XLSX.writeFile(workbook, fileName);
}

/**
 * Add worksheet for Products/Materials.
 */
function createProductMaterialSheet(params: IProcessTimesheetDataToExcelParams, workbook: any) {
    const {language, monthsData, jobs, clients, locations, places, products, materials} = params;

    let data: string[][] = [];

    data.push([
        tt('common.export.column.date'), //A
        tt('common.export.column.weekday'), //B
        tt('common.export.column.startTime'), //C
        tt('common.export.column.endTime'), //D
        tt('common.export.column.jobNumber'), //E
        tt('common.export.column.job'), //F
        tt('common.export.column.visit'), //G
        tt('common.export.column.client'), //H
        tt('common.export.column.locationOrPlace'), //I
        tt('common.export.column.material'), //J
        tt('common.export.column.count'), //K
        tt('common.export.column.productsServices'), //L
        tt('common.export.column.count'), //M
        tt('common.export.column.priceWithoutVat'), //N
        tt('common.export.column.vat'), //O
    ]);

    for (const visit of monthsData!.monthVisits) {
        const materialsForVisit = filterMaterialsForVisit({
            materials,
            visitId: visit.id,
            repeatingDay: visit.repeatingDay,
            filterDistinctByRepeatingDay: true,
        });

        for (const materialOf of materialsForVisit) {
            const jobRow: string[] = [];

            const dateTimes = visitDateTimes(visit, visit.visitRepeatDay, visit.repeatingDay, visit);

            if (!dateTimes.isSingleDay) {
                jobRow.push(`${DateTime.fromMillis(visit.startDate).toFormat('d.M.yyyy')} - ${DateTime.fromMillis(visit.endDate).toFormat('d.M.yyyy')}`);
            } else {
                jobRow.push(dateTimes.start.toFormat('d.M.yyyy'));
            }

            jobRow.push(dateTimes.start.toFormat('cccc', {locale: language}));

            const startTime = DateTime.fromMillis(visit.visitRepeatDay?.startTime || visit.startTime);
            const endTime = DateTime.fromMillis(visit.visitRepeatDay?.endTime || visit.endTime);

            jobRow.push(startTime.toFormat('H:mm'));

            jobRow.push(endTime.toFormat('H:mm'));

            const job = jobs?.find(job => job.id === visit.jobId);

            jobRow.push(job?.sequenceId || '');

            jobRow.push(job?.name || '');

            jobRow.push(visit.visitRepeatDay?.name || visit.name || '');

            const client = clients?.find(client => client.id === visit.clientId);
            jobRow.push(client?.name || '');

            const location = locations?.find(location => location.id === visit.locationId);
            const place = visit.locationPlaceId ? places?.find(place => place.id === visit.locationPlaceId) : undefined;

            let locationText = place?.name || location?.name || undefined;

            if (!locationText && location) {
                locationText = addressToSingleLine(location.address);
            }

            jobRow.push(locationText || '');

            jobRow.push(materialOf.name);

            jobRow.push(`${materialOf.unitCount || ''}`);

            jobRow.push('');

            jobRow.push('');

            jobRow.push('');

            jobRow.push('');

            data.push(jobRow);
        }

        const productsForVisit = filterProductsForVisit({
            products,
            visitId: visit.id,
            repeatingDay: visit.repeatingDay,
            filterDistinctByRepeatingDay: true,
        });

        for (const productOf of productsForVisit) {
            const jobRow: string[] = [];

            const dateTimes = visitDateTimes(visit, visit.visitRepeatDay, visit.repeatingDay, visit);

            if (!dateTimes.isSingleDay) {
                jobRow.push(`${DateTime.fromMillis(visit.startDate).toFormat('d.M.yyyy')} - ${DateTime.fromMillis(visit.endDate).toFormat('d.M.yyyy')}`);
            } else {
                jobRow.push(dateTimes.start.toFormat('d.M.yyyy'));
            }

            jobRow.push(dateTimes.start.toFormat('cccc', {locale: language}));

            const startTime = DateTime.fromMillis(visit.visitRepeatDay?.startTime || visit.startTime);
            const endTime = DateTime.fromMillis(visit.visitRepeatDay?.endTime || visit.endTime);

            jobRow.push(startTime.toFormat('H:mm'));

            jobRow.push(endTime.toFormat('H:mm'));

            const job = jobs?.find(job => job.id === visit.jobId);

            jobRow.push(job?.sequenceId || '');

            jobRow.push(job?.name || '');

            jobRow.push(visit.visitRepeatDay?.name || visit.name || '');

            const client = clients?.find(client => client.id === visit.clientId);
            jobRow.push(client?.name || '');

            const location = locations?.find(location => location.id === visit.locationId);
            const place = visit.locationPlaceId ? places?.find(place => place.id === visit.locationPlaceId) : undefined;

            let locationText = place?.name || location?.name || undefined;

            if (!locationText && location) {
                locationText = addressToSingleLine(location.address);
            }

            jobRow.push(locationText || '');

            jobRow.push('');

            jobRow.push('');

            jobRow.push(productOf.name);

            jobRow.push(`${productOf.unitCount || ''}`);

            jobRow.push(`${productOf.price || ''}`);

            jobRow.push(`${productOf.vatRate || ''}`);

            data.push(jobRow);
        }
    }

    let worksheet = XLSX.utils.aoa_to_sheet(data);

    for (let i = 1; i < data.length; i++) {
        worksheet[`K${i + 1}`].t = "n";
        worksheet[`M${i + 1}`].t = "n";
        worksheet[`N${i + 1}`].t = "n";
        worksheet[`O${i + 1}`].t = "n";
    }

    XLSX.utils.book_append_sheet(workbook, worksheet, tt('common.export.sheet.products_materials'));
}

interface IEmployeeTotalAggregate {
    worker: string;
    total: number;
    work: number;
    travel: number;
    hours: number;
    distance: number;
}

export function getTimesheetTypeTitle(type: ItemPaymentType, managedByConfiguration: boolean) {
    if (managedByConfiguration) {
        return tt('timesheet.type.managedByConfiguration');
    }

    switch (type) {
        case ItemPaymentType.Hourly:
            return tt('timesheet.type.workingTime');
        case ItemPaymentType.Fixed:
            return tt('timesheet.type.fixedPrice');
        case ItemPaymentType.Extra:
            return tt('timesheet.type.extraTime');
        case ItemPaymentType.StartEnd:
            return tt('timesheet.type.startEnd');
        case ItemPaymentType.TravelDistance:
            return tt('timesheet.type.travelDistance')
        case ItemPaymentType.TravelFixed:
            return tt('timesheet.type.travelFixed')
        case ItemPaymentType.TravelHourly:
            return tt('timesheet.type.travelTime')
        case ItemPaymentType.TravelStartEnd:
            return tt('timesheet.type.travelStartEnd')
        default:
            return '';
    }
}

export function getTimesheetIcon(type: ItemPaymentType) {
    switch (type) {
        case ItemPaymentType.Fixed:
            return <HammerIcon/>;
        case ItemPaymentType.Extra:
            return <HammerIcon/>;
        case ItemPaymentType.StartEnd:
            return <HammerIcon/>;
        case ItemPaymentType.TravelDistance:
            return <Icons8InTransit/>;
        case ItemPaymentType.TravelFixed:
            return <Icons8InTransit/>;
        case ItemPaymentType.TravelHourly:
            return <Icons8InTransit/>;
        case ItemPaymentType.TravelStartEnd:
            return <Icons8InTransit/>;
        case ItemPaymentType.Hourly:
        default:
            return <HammerIcon/>;
    }
}

export function getTimesheetStatusTitle(approved: boolean) {
    switch (approved) {
        case false:
            return tt('timesheet.status.waitingForApproval');
        case true:
            return tt('timesheet.status.approved');
        default:
            return '';
    }
}

/**
 * Convert JobEmployeeTimesheetItemResponse to CreateJobEmployeeTimesheetItemInput.
 */
export function convertJobEmployeeTimesheetItemToCreateInput(item: JobEmployeeTimesheetItemResponse, currentEmployeeId: number): CreateJobEmployeeTimesheetItemInput {
    return {
        uuid: uuidv4(),
        paymentType: item.paymentType,
        fixedPrice: item.fixedPrice || 0,
        hours: item.hours || 0,
        minutes: item.minutes || 0,
        hourRate: item.hourRate || 0,
        approved: true,
        startTime: item.startTime,
        endTime: item.endTime,
        employeeId: item.employeeId,
        createdByEmployeeId: currentEmployeeId,
        note: item.note,
        managedByConfiguration: item.managedByConfiguration,
        configurationOverrideActive: item.configurationOverrideActive || false,
    };
}

/// Check if is kind of "work" Timesheet
export function isWorkTimesheet(paymentType: ItemPaymentType | NullOrUndefined): boolean {
    if (!paymentType) {
        return false;
    }

    return [
        ItemPaymentType.Hourly,
        ItemPaymentType.Fixed,
        ItemPaymentType.Extra,
        ItemPaymentType.StartEnd,
    ].includes(paymentType);
}

/// Check if is kind of "travel" Timesheet
export function isTravelTimesheet(paymentType: ItemPaymentType | NullOrUndefined): boolean {
    if (!paymentType) {
        return false;
    }

    return [
        ItemPaymentType.TravelDistance,
        ItemPaymentType.TravelFixed,
        ItemPaymentType.TravelHourly,
        ItemPaymentType.TravelStartEnd,
    ].includes(paymentType);
}
