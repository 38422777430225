import {useId, useMemo} from "react";
import ICreateProductMaterialInput from "../../../model/CreateProductMaterialInput";
import AppListItem from "../listItems/AppListItem";
import ProductMaterialIcon from "./ProductMaterialIcon";
import {IProductMaterialByTemplate} from "../../../model/ProductMaterial";
import AppIconButton from "../buttons/AppIconButton";
import {bindTrigger} from "material-ui-popup-state";
import MoreFilledIcon from "../../../icons/MoreFilledIcon";
import {bindMenu, usePopupState} from "material-ui-popup-state/hooks";
import {Box, Menu, MenuItem} from "@mui/material";
import PencilIcon from "../../../icons/PencilIcon";
import {tt} from "../../../core/Localization";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import CloseIcon from "../../../icons/CloseIcon";
import ProductMaterialListItemChips from "./ProductMaterialListItemChips";
import {FileResponse} from "../../../generated/graphql/graphql";

export interface IProductMaterialListItemActionsProps {
    combined: IProductMaterialByTemplate;
    createInputs?: ICreateProductMaterialInput[];
}

export interface IProductMaterialListItemProps {
    combined: IProductMaterialByTemplate;
    onDelete?: (params: IProductMaterialListItemActionsProps) => void;
    onEditCombined?: (combined: IProductMaterialByTemplate) => void;
    files?: FileResponse[] | NullOrUndefined;
    modalHeaderStyle?: boolean;
}

/**
 * List item component for combined Product/Materials list.
 */
export default function ProductMaterialListItem(props: IProductMaterialListItemProps) {
    const {combined, onDelete, onEditCombined, files, modalHeaderStyle} = props;

    const optionsPopupState = usePopupState({
        variant: 'popover',
        popupId: useId(),
    });

    const itemJSX = useMemo(() => {
        const items = combined.items;
        const createInputs = combined.inputs;

        const actionWidget = (createInputs.length > 0 && (onEditCombined || onDelete)) || (items.length > 0 && onEditCombined) ? (
            <AppIconButton
                variant={"greyBg"}
                placement={"right"}
                {...bindTrigger(optionsPopupState)}
            >
                <MoreFilledIcon/>
            </AppIconButton>
        ) : (
            <></>
        );

        return (
            <AppListItem
                customAvatarInCircle={<ProductMaterialIcon type={combined.type}/>}
                title={combined.name}
                variant={"smaller-title"}
                description={combined.description}
                actionWidget={actionWidget}
                belowListItemWidget={
                    <>
                        {combined.stats.map((stats) => {
                            return (
                                <ProductMaterialListItemChips
                                    key={stats.identifier}
                                    variant={modalHeaderStyle ? "modalHeaderStyle" : "listItem"}
                                    stats={stats}
                                    files={files}
                                    displayAvatars={modalHeaderStyle ? false : true}
                                />
                            );
                        })}
                    </>
                }
            />
        );
    }, [combined, files]);

    const menuJSX = useMemo(() => {
        const items = combined.items;
        const createInputs = combined.inputs;

        return createInputs.length > 0 && (onEditCombined || onDelete) ? (
            <Menu {...bindMenu(optionsPopupState)}>
                {onEditCombined ? (
                    <MenuItem onClick={() => {
                        optionsPopupState.close();

                        onEditCombined(combined);
                    }}>
                        <PencilIcon/>
                        {tt('common.edit')}
                    </MenuItem>
                ) : null}

                {onDelete ? (
                    <MenuItem onClick={() => {
                        optionsPopupState.close();

                        onDelete({
                            combined,
                            createInputs
                        });
                    }}>
                        <Box sx={{color: kAppColors.red.confirmButton}}><CloseIcon/></Box>
                        {tt('common.removeFromList')}
                    </MenuItem>
                ) : null}
            </Menu>
        ) : items.length > 0 && onEditCombined ? (
            <Menu {...bindMenu(optionsPopupState)}>
                {onEditCombined ? (
                    <MenuItem onClick={() => {
                        optionsPopupState.close();

                        onEditCombined(combined);
                    }}>
                        <PencilIcon/>
                        {tt('common.edit')}
                    </MenuItem>
                ) : null}
            </Menu>
        ) : null;
    }, [combined, optionsPopupState]);

    return (
        <>
            {itemJSX}

            {menuJSX}
        </>
    );
}
