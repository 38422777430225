import CogIcon from "../../../icons/CogIcon";
import Icons8Carpet from "../../../icons/Icons8Carpet";
import Icons8Layers from "../../../icons/Icons8Layers";
import Icons8Product from "../../../icons/Icons8Product";
import SettingsIcon from "../../../icons/SettingsIcon";
import { ProductMaterialType } from "../../../model/ProductMaterial";

export interface IProductMaterialIconProps {
    type: ProductMaterialType;
}

/**
 * Component to display corresponding icon for ProductMaterialType.
 */
export default function ProductMaterialIcon(props: IProductMaterialIconProps) {
    const { type } = props;

    switch (type) {
        case ProductMaterialType.Product:
            return <Icons8Product />;
        case ProductMaterialType.Service:
            return <SettingsIcon />;
        case ProductMaterialType.Material:
            return <Icons8Carpet />;
        default:
            return <></>;
    }
}
