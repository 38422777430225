import {Route, Routes} from "react-router-dom";
import NoMatchScreen, {kNoMatchRoute} from "../ui/screens/NoMatchScreen";
import ProtectedRoute from "./ProtectedRoute";
import SignInScreen, {kSignInRoute} from "../ui/screens/authorization/SignInScreen";
import SignOutScreen, {kSignOutRoute} from "../ui/screens/authorization/SignOutScreen";
import DashboardScreen, {kDashboardRoute} from "../ui/screens/dashboard/DashboardScreen";
import WelcomeScreen, {kWelcomeRoute} from "../ui/screens/WelcomeScreen";
import SignUpScreen, {kSignUpRoute} from "../ui/screens/authorization/SignUpScreen";
import ForgottenPasswordScreen, {kForgottenPasswordRoute} from "../ui/screens/authorization/ForgottenPasswordScreen";
import TimeSheetsScreen, {kTimeSheetsRoute} from "../ui/screens/timesheets/TimeSheetsScreen";
import ClientsScreen, {kClientsRoute} from "../ui/screens/clients/ClientsScreen";
import WorkersScreen, {kWorkersRoute} from "../ui/screens/workers/WorkersScreen";
import JobsScreen, {kJobsRoute} from "../ui/screens/jobs/JobsScreen";
import ProfileScreen, {kProfileRoute} from "../ui/screens/profile/ProfileScreen";
import SettingsScreen, {kSettingsRoute} from "../ui/screens/settings/SettingsScreen";
import WorkerDetailScreen, {kWorkerDetailRoute} from "../ui/screens/workers/WorkerDetailScreen";
import CompanyDetailScreen, {kCompanyDetailRoute} from "../ui/screens/company/CompanyDetailScreen";
import CompanyEditScreen, {kCompanyEditRoute} from "../ui/screens/company/CompanyEditScreen";
import WorkerEditScreen, {kWorkerEditRoute} from "../ui/screens/workers/WorkerEditScreen";
import MobileMenuScreen, {kMobileMenuRoute} from "../ui/screens/mobile/MobileMenuScreen";
import ProfileEditScreen, {kProfileEditRoute} from "../ui/screens/profile/ProfileEditScreen";
import ChangePasswordScreen, {kChangePasswordRoute} from "../ui/screens/appbarMenu/ChangePasswordScreen";
import ChangeLoginEmailScreen, {kChangeLoginEmailRoute} from "../ui/screens/appbarMenu/ChangeLoginEmailScreen";
import CloseAccountScreen, {kCloseAccountRoute} from "../ui/screens/profile/CloseAccountScreen";
import ClientDetailScreen, {kClientDetailRoute} from "../ui/screens/clients/ClientDetailScreen";
import LocationDetailScreen, {kLocationDetailRoute} from "../ui/screens/locations/LocationDetailScreen";
import ContactPersonDetailsScreen, {
    kContactPersonDetailFromListRoute,
    kContactPersonDetailFromLocationRoute,
    kContactPersonDetailFromPlaceRoute,
    kContactPersonDetailRoute
} from "../ui/screens/contactPerson/ContactPersonDetailScreen";
import ContactPersonListScreen, {kContactPersonListRoute} from "../ui/screens/contactPerson/ContactPersonListScreen";
import ClientEditScreen, {kClientEditRoute} from "../ui/screens/clients/ClientEditScreen";
import ContactPersonEditScreen, {
    kContactPersonEditFromListRoute,
    kContactPersonEditFromLocationRoute,
    kContactPersonEditFromPlaceRoute,
    kContactPersonEditRoute
} from "../ui/screens/contactPerson/ContactPersonEditScreen";
import PlaceDetailScreen, {kPlaceDetailRoute} from "../ui/screens/places/PlaceDetailScreen";
import PlacesScreen, {kPlacesRoute} from "../ui/screens/places/PlacesScreen";
import LocationsScreen, {kLocationsRoute} from "../ui/screens/locations/LocationsScreen";
import LocationEditScreen, {kLocationEditRoute} from "../ui/screens/locations/LocationEditScreen";
import PlaceEditScreen, {kPlaceEditRoute} from "../ui/screens/places/PlaceEditScreen";
import NewJobAndVisitScreen, {
    kNewJobAndVisitForClientRoute,
    kNewJobAndVisitForJobRoute,
    kNewJobAndVisitRoute
} from "../ui/screens/jobs/NewJobAndVisitScreen";
import TimeSheetsDetailScreen, {kTimeSheetsDetailRoute} from "../ui/screens/timesheets/TimesheetsDetailScreen";
import JobFormsScreen, {kJobFormsRoute} from "../ui/screens/jobForms/JobFormsScreen";
import InvitationInstructionsScreen, {
    kInvitationInstructionsRoute
} from "../ui/screens/invitation/InvitationInstructionsScreen";
import InvitationScreen, {kInvitationRoute} from "../ui/screens/invitation/InvitationScreen";
import VisitsForStatusScreen, {kVisitsForStatusRoute} from "../ui/screens/jobs/visits/VisitsForStatusScreen";
import JobFormDetailScreen, {kJobFormDetailRoute} from "../ui/screens/jobForms/JobFormDetailScreen";
import JobFormEditScreen, {kJobFormEditRoute} from "../ui/screens/jobForms/JobFormEditScreen";
import NotificationsScreen, {kNotificationsRoute} from "../ui/screens/notifications/NotificationsScreen";
import TransitionalScreen, {kTransitionalRoute} from "../ui/screens/TransitionalScreen";
import AlternativeSignInScreen, {kAlternativeSignInRoute} from "../ui/screens/authorization/AlternativeSignInScreen";
import VisitsSettingsScreen, {kVisitsSettingsRoute} from "../ui/screens/settings/VisitsSettingsScreen";
import CompanySettingsScreen, {kCompanySettingsRoute} from "../ui/screens/company/CompanySettingsScreen";
import VisitsScreen, {kVisitsRoute} from "../ui/screens/jobs/visits/VisitsScreen";
import JobDetailScreen, {kJobDetailRoute} from "../ui/screens/jobs/JobDetailScreen";
import TimesheetsSettingsScreen, {kTimesheetsSettingsRoute} from "../ui/screens/settings/TimesheetsSettingsScreen";
import CompanyPermissionsScreen, {kCompanyPermissionsRoute} from "../ui/screens/company/CompanyPermissionsScreen";
import {
    kActionCreate,
    kActionUpdate,
    kActionView,
    kPermissionsClients,
    kPermissionsCompany,
    kPermissionsForms,
    kPermissionsJobs,
    kPermissionsMaterials,
    kPermissionsProducts,
    kPermissionsTimesheets,
    kPermissionsWorkers
} from "./constants";
import NotificationsSettingsScreen, {
    kNotificationsSettingsRoute
} from "../ui/screens/settings/NotificationsSettingsScreen";
import ClientSettingsScreen, {kClientsSettingsRoute} from "../ui/screens/settings/ClientsSettingsScreen";
import ProductMaterialListScreen, {
    kProductMaterialsRoute
} from "../ui/screens/productMaterials/ProductMaterialListScreen";
import ProductMaterialDetailScreen, {
    kProductMaterialDetailRoute
} from "../ui/screens/productMaterials/ProductMaterialDetailScreen";
import ProductMaterialEditScreen, {
    kProductMaterialEditRoute
} from "../ui/screens/productMaterials/ProductMaterialEditScreen";

const kProtectedRedirect = kWelcomeRoute;
export const kRedirectWhenInvalidPermission = kDashboardRoute;

/**
 * Main App routing component, uses Browser history based routing.
 */
export default function Router() {
    return (
        <Routes>
            {/*Onboarding*/}
            <Route path={kWelcomeRoute} element={<WelcomeScreen />} />
            <Route path={kSignInRoute} element={<SignInScreen />} />
            <Route path={kSignOutRoute} element={<SignOutScreen />} />
            <Route path={kSignUpRoute} element={<SignUpScreen />} />
            <Route path={kForgottenPasswordRoute} element={<ForgottenPasswordScreen />} />
            <Route path={kAlternativeSignInRoute} element={<AlternativeSignInScreen />} />

            <Route path={kInvitationInstructionsRoute} element={<InvitationInstructionsScreen />} />
            <Route path={kInvitationRoute} element={<InvitationScreen />} />

            {/*Dashboard*/}
            <Route path={kDashboardRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect}>
                    <DashboardScreen />
                </ProtectedRoute>
            } />

            {/*Jobs*/}
            <Route path={kNewJobAndVisitRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect} permission={kPermissionsJobs}>
                    <NewJobAndVisitScreen />
                </ProtectedRoute>
            } />
            <Route path={kNewJobAndVisitForJobRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect} permission={kPermissionsJobs} requiredPermissions={[kActionCreate]}>
                    <NewJobAndVisitScreen />
                </ProtectedRoute>
            } />
            <Route path={kNewJobAndVisitForClientRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect} permission={kPermissionsJobs} requiredPermissions={[kActionCreate]}>
                    <NewJobAndVisitScreen />
                </ProtectedRoute>
            } />
            <Route path={kJobsRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect} permission={kPermissionsJobs}>
                    <JobsScreen />
                </ProtectedRoute>
            } />
            <Route path={kJobDetailRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect} permission={kPermissionsJobs}>
                    <JobDetailScreen />
                </ProtectedRoute>
            } />
            <Route path={kVisitsRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect} permission={kPermissionsJobs}>
                    <VisitsScreen />
                </ProtectedRoute>
            } />
            <Route path={kVisitsForStatusRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect} permission={kPermissionsJobs}>
                    <VisitsForStatusScreen />
                </ProtectedRoute>
            } />

            <Route path={kJobFormsRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect} permission={kPermissionsForms}>
                    <JobFormsScreen />
                </ProtectedRoute>} />

            <Route path={kJobFormDetailRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect} permission={kPermissionsForms}>
                    <JobFormDetailScreen />
                </ProtectedRoute>} />

            <Route path={kJobFormEditRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect} permission={kPermissionsForms} requiredPermissions={[kActionUpdate]}>
                    <JobFormEditScreen />
                </ProtectedRoute>} />

            <Route path={kTimeSheetsRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect} permission={kPermissionsTimesheets}>
                    <TimeSheetsScreen />
                </ProtectedRoute>} />
            <Route path={kTimeSheetsDetailRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect} permission={kPermissionsTimesheets}>
                    <TimeSheetsDetailScreen />
                </ProtectedRoute>} />

            {/*Company*/}
            <Route path={kCompanyDetailRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect} permission={kPermissionsCompany} companyProcessorProps={{ whitelistedRoute: true }}>
                    <CompanyDetailScreen />
                </ProtectedRoute>} />
            <Route path={kCompanyEditRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect} permission={kPermissionsCompany} requiredPermissions={[kActionUpdate]} companyProcessorProps={{ whitelistedRoute: true }}>
                    <CompanyEditScreen />
                </ProtectedRoute>} />
            <Route path={kCompanySettingsRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect} permission={kPermissionsCompany} requiredPermissions={[kActionUpdate]} companyProcessorProps={{ whitelistedRoute: true }}>
                    <CompanySettingsScreen />
                </ProtectedRoute>} />
            <Route path={kCompanyPermissionsRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect} permission={kPermissionsCompany} requiredPermissions={[kActionUpdate]} companyProcessorProps={{ whitelistedRoute: true }}>
                    <CompanyPermissionsScreen />
                </ProtectedRoute>} />

            {/*Workers*/}
            <Route path={kWorkersRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect} permission={kPermissionsWorkers}>
                    <WorkersScreen />
                </ProtectedRoute>} />
            <Route path={kWorkerDetailRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect} permission={kPermissionsWorkers}>
                    <WorkerDetailScreen />
                </ProtectedRoute>} />
            <Route path={kWorkerEditRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect} permission={kPermissionsWorkers} requiredPermissions={[kActionUpdate]}>
                    <WorkerEditScreen />
                </ProtectedRoute>} />

            {/*Clients*/}
            <Route path={kClientDetailRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect} permission={kPermissionsClients}>
                    <ClientDetailScreen />
                </ProtectedRoute>
            } />
            <Route path={kClientEditRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect} permission={kPermissionsClients} requiredPermissions={[kActionUpdate]}>
                    <ClientEditScreen />
                </ProtectedRoute>
            } />
            <Route path={kClientsRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect} permission={kPermissionsClients}>
                    <ClientsScreen />
                </ProtectedRoute>
            } />

            <Route path={kLocationsRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect} permission={kPermissionsClients}>
                    <LocationsScreen />
                </ProtectedRoute>
            } />
            <Route path={kLocationDetailRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect} permission={kPermissionsClients}>
                    <LocationDetailScreen />
                </ProtectedRoute>
            } />
            <Route path={kLocationEditRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect} permission={kPermissionsClients} requiredPermissions={[kActionUpdate]}>
                    <LocationEditScreen />
                </ProtectedRoute>
            } />

            <Route path={kPlacesRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect} permission={kPermissionsClients}>
                    <PlacesScreen />
                </ProtectedRoute>
            } />
            <Route path={kPlaceDetailRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect} permission={kPermissionsClients}>
                    <PlaceDetailScreen />
                </ProtectedRoute>
            } />
            <Route path={kPlaceEditRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect} permission={kPermissionsClients} requiredPermissions={[kActionUpdate]}>
                    <PlaceEditScreen />
                </ProtectedRoute>
            } />

            {/*Contact person*/}
            <Route path={kContactPersonDetailRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect} permission={kPermissionsClients}>
                    <ContactPersonDetailsScreen />
                </ProtectedRoute>
            } />
            <Route path={kContactPersonDetailFromListRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect} permission={kPermissionsClients}>
                    <ContactPersonDetailsScreen />
                </ProtectedRoute>
            } />
            <Route path={kContactPersonDetailFromLocationRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect} permission={kPermissionsClients}>
                    <ContactPersonDetailsScreen />
                </ProtectedRoute>
            } />
            <Route path={kContactPersonDetailFromPlaceRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect} permission={kPermissionsClients}>
                    <ContactPersonDetailsScreen />
                </ProtectedRoute>
            } />

            <Route path={kContactPersonEditRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect} permission={kPermissionsClients} requiredPermissions={[kActionUpdate]}>
                    <ContactPersonEditScreen />
                </ProtectedRoute>
            } />
            <Route path={kContactPersonEditFromListRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect} permission={kPermissionsClients} requiredPermissions={[kActionUpdate]}>
                    <ContactPersonEditScreen />
                </ProtectedRoute>
            } />
            <Route path={kContactPersonEditFromLocationRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect} permission={kPermissionsClients} requiredPermissions={[kActionUpdate]}>
                    <ContactPersonEditScreen />
                </ProtectedRoute>
            } />
            <Route path={kContactPersonEditFromPlaceRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect} permission={kPermissionsClients} requiredPermissions={[kActionUpdate]}>
                    <ContactPersonEditScreen />
                </ProtectedRoute>
            } />

            <Route path={kContactPersonListRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect} permission={kPermissionsClients}>
                    <ContactPersonListScreen />
                </ProtectedRoute>
            } />

            <Route path={kProductMaterialsRoute} element={
                <ProtectedRoute
                    redirectTo={kProtectedRedirect}
                    hasSomePermissionsParams={[
                        { permission: kPermissionsProducts, requiredPermissions: [kActionView] },
                        { permission: kPermissionsMaterials, requiredPermissions: [kActionView] },
                    ]}
                >
                    <ProductMaterialListScreen />
                </ProtectedRoute>} />
            <Route path={kProductMaterialDetailRoute} element={
                <ProtectedRoute
                    redirectTo={kProtectedRedirect}
                    hasSomePermissionsParams={[
                        { permission: kPermissionsProducts, requiredPermissions: [kActionView] },
                        { permission: kPermissionsMaterials, requiredPermissions: [kActionView] },
                    ]}
                >
                    <ProductMaterialDetailScreen />
                </ProtectedRoute>} />
            <Route path={kProductMaterialEditRoute} element={
                <ProtectedRoute
                    redirectTo={kProtectedRedirect}
                    hasSomePermissionsParams={[
                        { permission: kPermissionsProducts, requiredPermissions: [kActionView] },
                        { permission: kPermissionsMaterials, requiredPermissions: [kActionView] },
                    ]}
                >
                    <ProductMaterialEditScreen />
                </ProtectedRoute>} />

            {/*appbar menu screens*/}

            <Route path={kProfileRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect} companyProcessorProps={{ whitelistedRoute: true }}>
                    <ProfileScreen />
                </ProtectedRoute>} />
            <Route path={kProfileEditRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect} companyProcessorProps={{ whitelistedRoute: true }}>
                    <ProfileEditScreen />
                </ProtectedRoute>} />
            <Route path={kCloseAccountRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect} companyProcessorProps={{ whitelistedRoute: true }}>
                    <CloseAccountScreen />
                </ProtectedRoute>} />
            <Route path={kSettingsRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect} companyProcessorProps={{ whitelistedRoute: true }}>
                    <SettingsScreen />
                </ProtectedRoute>} />
            <Route path={kVisitsSettingsRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect} companyProcessorProps={{ whitelistedRoute: true }}>
                    <VisitsSettingsScreen />
                </ProtectedRoute>} />
            <Route path={kTimesheetsSettingsRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect} companyProcessorProps={{ whitelistedRoute: true }}>
                    <TimesheetsSettingsScreen />
                </ProtectedRoute>} />
            <Route path={kNotificationsSettingsRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect} companyProcessorProps={{ whitelistedRoute: true }}>
                    <NotificationsSettingsScreen />
                </ProtectedRoute>} />
            <Route path={kClientsSettingsRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect} companyProcessorProps={{ whitelistedRoute: true }}>
                    <ClientSettingsScreen />
                </ProtectedRoute>} />
            <Route path={kChangePasswordRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect} companyProcessorProps={{ whitelistedRoute: true }}>
                    <ChangePasswordScreen />
                </ProtectedRoute>} />
            <Route path={kChangeLoginEmailRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect} companyProcessorProps={{ whitelistedRoute: true }}>
                    <ChangeLoginEmailScreen />
                </ProtectedRoute>} />

            <Route path={kMobileMenuRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect} companyProcessorProps={{ whitelistedRoute: true }}>
                    <MobileMenuScreen />
                </ProtectedRoute>} />

            <Route path={kNotificationsRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect} companyProcessorProps={{ whitelistedRoute: true }}>
                    <NotificationsScreen />
                </ProtectedRoute>} />

            <Route path={kTransitionalRoute} element={
                <ProtectedRoute redirectTo={kProtectedRedirect} companyProcessorProps={{ whitelistedRoute: true }}>
                    <TransitionalScreen />
                </ProtectedRoute>} />

            <Route path={kNoMatchRoute} element={<NoMatchScreen />} />
        </Routes>
    );
}
