import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import ModalBottomSheet from "../../../ModalBottomSheet";
import {tt} from "../../../../../core/Localization";
import BottomSheetModalAppbar from "../../BottomSheetModalAppbar";
import FormBuilder, {IInputsData, InputType, ValidateForm} from "../../../form/FormBuilder";
import {Box, Theme} from "@mui/material";
import AppButton from "../../../buttons/AppButton";
import {
    CompanyConfigurationResponsePage,
    CompanyConfigurationType,
    CreateCompanyConfigurationInput
} from "../../../../../generated/graphql/graphql";
import {makeStyles} from "tss-react/mui";
import {kCompanyConfigurationAutoJobEmployeeTimesheets} from "../../../../../core/constants";
import {getCompanyConfigurationResponsePage} from "../../../../../service/CompanyConfigurationService";
import {RestApiClientContext} from "../../../../../core/RestApiProvider";
import {AppDataContext} from "../../../../../AppData";

const useStyles = makeStyles()((theme: Theme) => ({
    horizontalPadding: {
        paddingLeft: 16,
        paddingRight: 16,
    },
}));

export interface INewVisitConfigurationsModalBottomSheetProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    uuid: string;
    visitConfigurations: CreateCompanyConfigurationInput[];
    setVisitConfigurations: Dispatch<SetStateAction<CreateCompanyConfigurationInput[]>>;
}

/**
 * Modal bottom sheet for new Visit linked CompanyConfigurations.
 */
export default function NewVisitConfigurationsModalBottomSheet(props: INewVisitConfigurationsModalBottomSheetProps) {
    const {
        open,
        setOpen,
        uuid,
        visitConfigurations,
        setVisitConfigurations,
    } = props;

    const restApiClientContext = useContext(RestApiClientContext);

    const appDataContext = useContext(AppDataContext);
    const {companyId} = appDataContext;

    const {classes, cx} = useStyles();

    const [inputs, setInputs] = useState<IInputsData>({
        autoJobEmployeeTimesheets: {
            className: classes.horizontalPadding,
            type: InputType.Switch,
            switchVariant: "Android12Switch",
            label: tt('visitConfigurations.modal.autoJobEmployeeTimesheets.label'),
            value: false,
        },
    });

    const [companyConfigurationLoading, setCompanyConfigurationLoading] = useState<boolean>(false);
    useEffect(() => {
        if (open) {
            const theConfig = visitConfigurations.find(v => v.uuid === uuid);

            if (theConfig) {
                const theValue = JSON.parse(theConfig.valueJSON);

                setInputs({
                    ...inputs,
                    autoJobEmployeeTimesheets: {
                        ...inputs.autoJobEmployeeTimesheets,
                        value: theValue[kCompanyConfigurationAutoJobEmployeeTimesheets] === true,
                    },
                });
            } else {
                getCompanyConfigurationResponsePage(
                    restApiClientContext,
                    appDataContext,
                    {
                        input: {
                            companyId: companyId!,
                            typeIn: [CompanyConfigurationType.JobEmployeeTimesheets],
                        },
                        setLoading: setCompanyConfigurationLoading,
                        onData: (data: CompanyConfigurationResponsePage | NullOrUndefined) => {
                            if (data) {
                                const theConfig = data.content
                                    .find((config) => config.type === CompanyConfigurationType.JobEmployeeTimesheets);

                                if (theConfig) {
                                    const theValue = JSON.parse(theConfig.valueJSON);

                                    setInputs({
                                        ...inputs,
                                        autoJobEmployeeTimesheets: {
                                            ...inputs.autoJobEmployeeTimesheets,
                                            value: theValue[kCompanyConfigurationAutoJobEmployeeTimesheets] === true,
                                        },
                                    });

                                    return;
                                }
                            }

                            setInputs({
                                ...inputs,
                                autoJobEmployeeTimesheets: {
                                    ...inputs.autoJobEmployeeTimesheets,
                                    value: false,
                                },
                            });
                        },
                    },
                );
            }
        }
    }, [open]);

    /**
     * Process form and if config different to Company default, then save for visit uuid.
     * This processing however takes place on screen with subscription, not here.
     */
    const onSubmit = () => {
        if (ValidateForm(inputs, setInputs)) {
            let theConfig = visitConfigurations.find(v => v.uuid === uuid);

            if (!theConfig) {
                theConfig = {
                    uuid: uuid,
                    priority: 0,
                    type: CompanyConfigurationType.JobEmployeeTimesheets,
                    companyId: companyId!,
                    valueJSON: JSON.stringify({
                        [kCompanyConfigurationAutoJobEmployeeTimesheets]: inputs.autoJobEmployeeTimesheets.value,
                    }),
                };
            } else {
                theConfig.valueJSON = JSON.stringify({
                    [kCompanyConfigurationAutoJobEmployeeTimesheets]: inputs.autoJobEmployeeTimesheets.value,
                });
            }

            const newConfigs = visitConfigurations.filter((v) => v.uuid !== uuid);

            newConfigs.push(theConfig);

            setVisitConfigurations(newConfigs);

            setOpen(false);
        }
    };

    return (
        <ModalBottomSheet
            blurBackdrop={true}
            hideHeader={true}
            open={open}
            setOpen={setOpen}
            modalAboveModals={true}>
            <BottomSheetModalAppbar
                title={tt('visitConfigurations.modal.title')}
                noBorderBottom={true}
                onClose={() => setOpen(false)}
            />

            <FormBuilder
                inputs={inputs}
                setInputs={setInputs}
            />

            <Box p={2}>
                <AppButton
                    variant={"contained"}
                    fullWidth={true}
                    onClick={onSubmit}
                    disabled={companyConfigurationLoading}
                >
                    {tt('common.save')}
                </AppButton>
            </Box>
        </ModalBottomSheet>
    );
}
