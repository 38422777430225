import {EmployeeJoinedUserResponse, MaterialResponse, ProductResponse} from "../generated/graphql/graphql";
import ICreateProductMaterialInput from "./CreateProductMaterialInput";

export enum ProductMaterialType {
    Product = 'Product',
    Service = 'Service',
    Material = 'Material',
}

export default interface IProductMaterial {
    type: ProductMaterialType;
    product?: ProductResponse;
    material?: MaterialResponse;
    id: number;
    name: string;
    price: number;
    vatRate: number;
    createdAt: number;
    cost: number;
}

export interface IProductMaterialByTemplate {
    productMaterialId?: number;
    productMaterialUuid?: string;
    type: ProductMaterialType;
    name: string;
    unitName: string;
    description: string | NullOrUndefined;
    items: IProductMaterial[];
    inputs: ICreateProductMaterialInput[];
    stats: IProductMaterialByTemplateStats[];
    employees: EmployeeJoinedUserResponse[];
}

export interface IProductMaterialByTemplateStats {
    identifier: string;
    unitCount: number;
    unitName: string;
    price: number;
    vatRate: number;
    cost: number;
    employees: EmployeeJoinedUserResponse[];
}
