import { useContext, useEffect, useMemo, useState } from "react";
import { FetchPolicy, RestApiClientContext } from "../../../core/RestApiProvider";
import { ProductMaterialType } from "../../../model/ProductMaterial";
import ScreenContent from "../../components/screens/ScreenContent";
import { AppContext } from "../../../App";
import { AppDataContext } from "../../../AppData";
import { useNavigate, useParams } from "react-router-dom";
import { hasPermission } from "../../components/permissions/PermissionValid";
import { kDashboardRoute } from "../dashboard/DashboardScreen";
import { kActionUpdate, kPermissionsMaterials, kPermissionsProducts } from "../../../core/constants";
import { GetMaterialInput, GetProductInput, MaterialResponse, ProductResponse, UpdateMaterialTemplateInput, UpdateProductTemplateInput } from "../../../generated/graphql/graphql";
import { processMutationError, processQueryError } from "../../../service/ErrorService";
import { convertProductMaterial, productMaterialTypeDisplay } from "../../../service/ProductMaterialService";
import AppPaper from "../../components/paper/AppPaper";
import { kContentWidthMedium } from "../../../styles/AppThemeProcessor";
import PaperAppbar from "../../components/paper/PaperAppbar";
import { getBackRoute } from "../../../utils/Utils";
import { productMaterialDetailRoute } from "./ProductMaterialDetailScreen";
import ContentPadding from "../../components/paper/ContentPadding";
import { IInputsData } from "../../components/form/FormBuilder";
import ProductMaterialForm from "../../components/productMaterials/ProductMaterialForm";
import { SuccessToast } from "../../../service/ToastService";
import { tt } from "../../../core/Localization";

export const kProductMaterialEditRoute = '/product-materials/:productMaterialType/:productMaterialId/edit';

/**
 * Create route with parameters.
 */
export function productMaterialEditRoute(type: ProductMaterialType, id: string | number) {
    return kProductMaterialEditRoute
        .replace(':productMaterialType', type)
        .replace(':productMaterialId', `${id}`);
}

/**
 * Screen component for edit combined Product/Material screen.
 */
export default function ProductMaterialEditScreen() {
    const restApiClientContext = useContext(RestApiClientContext);
    const { restApiGet, restApiPost } = restApiClientContext;

    const appContext = useContext(AppContext);
    const { setTitle } = appContext;

    const appDataContext = useContext(AppDataContext);
    const { isMobile, employeePermissionsMap } = appDataContext;

    const navigate = useNavigate();
    const { productMaterialType, productMaterialId } = useParams();
    const type = productMaterialType ? productMaterialType as ProductMaterialType : undefined;

    const [screenTitle, setScreenTitle] = useState<string>();

    useEffect(() => {
        if (type === ProductMaterialType.Material) {
            if (!hasPermission(kPermissionsMaterials, [kActionUpdate], employeePermissionsMap)) {
                navigate(kDashboardRoute);
            }
        } else {
            if (!hasPermission(kPermissionsProducts, [kActionUpdate], employeePermissionsMap)) {
                navigate(kDashboardRoute);
            }
        }
    }, [type, employeePermissionsMap]);

    const [loading, setLoading] = useState(true);
    const [productData, setProductData] = useState<ProductResponse | NullOrUndefined>();
    const [materialData, setMaterialData] = useState<MaterialResponse | NullOrUndefined>();
    useEffect(() => {
        if (type && productMaterialId) {
            if (type === ProductMaterialType.Material) {
                setProductData(null);

                restApiGet({
                    uri: '/material',
                    params: {
                        materialId: parseInt(productMaterialId!),
                    } as GetMaterialInput,
                    fetchPolicy: FetchPolicy.NetworkOnly,
                    setLoading,
                    onData: setMaterialData,
                    onError: (error) => processQueryError(appDataContext, error),
                });
            } else {
                setMaterialData(null);

                restApiGet({
                    uri: '/product',
                    params: {
                        productId: parseInt(productMaterialId!),
                    } as GetProductInput,
                    fetchPolicy: FetchPolicy.NetworkOnly,
                    setLoading,
                    onData: setProductData,
                    onError: (error) => processQueryError(appDataContext, error),
                });
            }
        } else {
            setLoading(false);
            setProductData(null);
            setMaterialData(null);
        }
    }, [type, productMaterialId]);

    useEffect(() => {
        const newTitle = productMaterialTypeDisplay(type);

        if (newTitle !== screenTitle) {
            setScreenTitle(newTitle);

            setTitle(newTitle);
        }
    }, [type]);

    const [submitLoading, setSubmitLoading] = useState(false);
    /**
     * Update either Product or Material to BE.
     */
    const handleUpdate = (inputs: IInputsData) => {
        try {
            const type = inputs.type.value;

            if (type === ProductMaterialType.Material) {
                restApiPost({
                    uri: '/material/template/update',
                    params: {
                        id: parseInt(productMaterialId!),
                        name: inputs.name.value,
                        unitName: inputs.unitName.value,
                        price: parseFloat(inputs.price.value || '0'),
                        vatRate: parseFloat(inputs.vatRate.value || '0'),
                        description: inputs.description.value,
                        cost: parseFloat(inputs.cost.value || '0'),
                    } as UpdateMaterialTemplateInput,
                    fetchPolicy: FetchPolicy.NetworkOnly,
                    setLoading: setSubmitLoading,
                    onData: (data) => {
                        if (data) {
                            SuccessToast(tt('productMaterialEdit.screen.success'));

                            navigate(getBackRoute(
                                productMaterialDetailRoute(type!, productMaterialId!)
                            ));
                        }
                    },
                    onError: (error: any) => processMutationError(error),
                });
            } else {
                restApiPost({
                    uri: '/product/template/update',
                    params: {
                        id: parseInt(productMaterialId!),
                        name: inputs.name.value,
                        unitName: inputs.unitName.value,
                        price: parseFloat(inputs.price.value || '0'),
                        vatRate: parseFloat(inputs.vatRate.value || '0'),
                        description: inputs.description.value,
                        cost: parseFloat(inputs.cost.value || '0'),
                    } as UpdateProductTemplateInput,
                    fetchPolicy: FetchPolicy.NetworkOnly,
                    setLoading: setSubmitLoading,
                    onData: (data) => {
                        if (data) {
                            SuccessToast(tt('productMaterialEdit.screen.success'));

                            navigate(getBackRoute(
                                productMaterialDetailRoute(type!, productMaterialId!)
                            ));
                        }
                    },
                    onError: (error: any) => processMutationError(error),
                });
            }
        } catch (e) {
            processMutationError(e);
        }
    };

    const theData = useMemo(() => {
        if (productData || materialData) {
            return convertProductMaterial({
                product: productData,
                material: materialData,
            });
        }

        return null;
    }, [productData, materialData]);

    return (
        <ScreenContent
            appBar={!isMobile}
            noContentPadding={isMobile}
            navigationDrawer={!isMobile}
            bottomBar={isMobile}
            centerHorizontally={true}
        >
            <AppPaper
                sx={{ maxWidth: isMobile ? undefined : kContentWidthMedium }}
            >
                <PaperAppbar
                    backRoute={getBackRoute(
                        productMaterialDetailRoute(type!, productMaterialId!)
                    )}
                    isMobile={isMobile}
                    title={screenTitle || ""}
                    cancelIconBackButton={true} />

                <ContentPadding>
                    <ProductMaterialForm
                        variant="editTemplate"
                        type={type!}
                        loading={loading}
                        existing={theData}
                        onSubmit={handleUpdate}
                        submitLoading={submitLoading}
                    />
                </ContentPadding>
            </AppPaper>
        </ScreenContent>
    );
}
