import {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import IProductMaterial, {ProductMaterialType} from "../../../../model/ProductMaterial";
import AppModal from "../AppModal";
import {tt} from "../../../../core/Localization";
import ProductMaterialForm from "../../productMaterials/ProductMaterialForm";
import {IInputsData} from "../../form/FormBuilder";
import {FetchPolicy, RestApiClientContext} from "../../../../core/RestApiProvider";
import {processMutationError} from "../../../../service/ErrorService";
import {
    CreateMaterialTemplateInput,
    CreateProductTemplateInput,
    ProductType
} from "../../../../generated/graphql/graphql";
import {AppDataContext} from "../../../../AppData";
import {SuccessToast} from "../../../../service/ToastService";
import {convertProductMaterial} from "../../../../service/ProductMaterialService";

export interface ICreateProductMaterialTemplateModalProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    onCreated?: (input: IProductMaterial, createAnother?: boolean) => void;
    modalAboveModals?: boolean;
}

/**
 * Modal component for creating a new Product/Material template.
 */
export default function CreateProductMaterialTemplateModal(props: ICreateProductMaterialTemplateModalProps) {
    const {
        open,
        setOpen,
        onCreated,
        modalAboveModals,
    } = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const { restApiPost } = restApiClientContext;

    const appDataContext = useContext(AppDataContext);
    const { companyId } = appDataContext;

    const [resetFormTrigger, setResetFormTrigger] = useState(0);

    useEffect(() => {
        if (open) {
            setResetFormTrigger(resetFormTrigger + 1);
        }
    }, [open]);

    const [submitLoading, setSubmitLoading] = useState(false);
    /**
     * Create new either Product or Material to BE.
     */
    const handleCreate = (inputs: IInputsData, createAnother?: boolean, canSaveAsTemplate?: boolean) => {
        try {
            const type = inputs.type.value;

            if (type === ProductMaterialType.Material) {
                restApiPost({
                    uri: '/material/template',
                    params: {
                        companyId: companyId!,
                        name: inputs.name.value,
                        unitName: inputs.unitName.value,
                        price: parseFloat(inputs.price.value || '0'),
                        vatRate: parseFloat(inputs.vatRate.value || '0'),
                        description: inputs.description.value,
                        cost: parseFloat(inputs.cost.value || '0'),
                    } as CreateMaterialTemplateInput,
                    fetchPolicy: FetchPolicy.NetworkOnly,
                    setLoading: setSubmitLoading,
                    onData: (data) => {
                        if (data) {
                            SuccessToast(tt('createProductMaterial.modal.success'));

                            if (onCreated) {
                                onCreated(convertProductMaterial({
                                    material: data,
                                }), createAnother);
                            }

                            if (createAnother) {
                                setResetFormTrigger(resetFormTrigger + 1);
                            }
                        }
                    },
                    onError: (error: any) => processMutationError(error),
                });
            } else {
                restApiPost({
                    uri: '/product/template',
                    params: {
                        companyId: companyId!,
                        type: type === ProductMaterialType.Service ? ProductType.Service : ProductType.Product,
                        name: inputs.name.value,
                        unitName: inputs.unitName.value,
                        price: parseFloat(inputs.price.value || '0'),
                        vatRate: parseFloat(inputs.vatRate.value || '0'),
                        description: inputs.description.value,
                        cost: parseFloat(inputs.cost.value || '0'),
                    } as CreateProductTemplateInput,
                    fetchPolicy: FetchPolicy.NetworkOnly,
                    setLoading: setSubmitLoading,
                    onData: (data) => {
                        if (data) {
                            SuccessToast(tt('createProductMaterial.modal.success'));

                            if (onCreated) {
                                onCreated(convertProductMaterial({
                                    product: data,
                                }), createAnother);
                            }

                            if (createAnother) {
                                setResetFormTrigger(resetFormTrigger + 1);
                            }
                        }
                    },
                    onError: (error: any) => processMutationError(error),
                });
            }
        } catch (e) {
            processMutationError(e);
        }
    };

    return (
        <AppModal
            setOpen={setOpen}
            title={tt('createProductMaterial.modal.title')}
            open={open}
            fullScreenOnMobile={true}
            blurBackdrop={true}
            modalAboveModals={modalAboveModals}
        >
            <ProductMaterialForm
                variant="createTemplate"
                type={ProductMaterialType.Service}
                onSubmit={handleCreate}
                submitLoading={submitLoading}
                submitRight={true}
                createAnotherAble={true}
                isModal={true}
                resetFormTrigger={resetFormTrigger}
            />
        </AppModal>
    );
}
