import React, { useEffect, useState } from "react";
import { useStyles as formUseStyles } from "./FormBuilder";
import { Box, IconButton, InputAdornment, TextField, Theme, Typography } from "@mui/material";
import AppButton from "../buttons/AppButton";
import DeleteIcon from "../../../icons/DeleteIcon";
import { makeStyles } from "tss-react/mui";
import { kAppColors } from "../../../styles/AppThemeProcessor";
import { ButtonTextFieldProps } from "./InputProps";

export const useStyles = makeStyles()((theme: Theme) => ({
    icon: {
        color: kAppColors.red.confirmButton,
        'svg': { height: 21, width: 21 }
    },
    suffixContainer: {
        display: "flex"
    },
    multilineAdornmentPosition: {
        //'.MuiInputAdornment-positionEnd': {
        marginTop: "auto",
        marginBottom: 18,
        //}
    },
    container: {
        position: "relative",
    },
    counter: {
        position: "absolute",
        bottom: 0,
        right: 0,
        fontSize: 12,
        color: kAppColors.text.secondary(theme.palette.mode === "dark"),
    }
}));


export default function ButtonTextField(props: ButtonTextFieldProps) {
    const {
        index,
        className,
        label,
        suffixJSX,
        value,
        updateValue,
        hidden,
        error,
        errorMessage,
        helperText,
        toggleButtonText,
        numbersOnly,
        decimalPoints,
        inputMode,
        minRows,
        testId,
        maxChars,
        showCharCounter,
        changeThisToCloseInput,
        readOnly,
        disabled,
    } = props;
    const [showInput, setShowInput] = useState<boolean>(!!value);
    const [charsCount, setCharsCount] = useState<number>(value.length);

    const theChangeThisToCloseInput = changeThisToCloseInput || 0;

    useEffect(() => {
        if (theChangeThisToCloseInput > 0) {
            updateValue(index, '');
            setShowInput(false);
        }
    }, [theChangeThisToCloseInput]);

    useEffect(() => {
        if (value && !showInput && !(numbersOnly && value === '0')) {
            setShowInput(true);
        }
    }, [showInput, value]);

    const { classes: formClasses, cx } = formUseStyles();
    const { classes } = useStyles();

    const theSuffixClass = cx(
        classes.suffixContainer,
        parseInt(`${minRows}`) > 1 ? classes.multilineAdornmentPosition : undefined,
    );

    let theValue = value;
    if (numbersOnly && decimalPoints) {
        const number: number = parseFloat(theValue || '0');

        theValue = number.toFixed(decimalPoints);
    }

    /**
     * OnChange update data of this field.
     */
    const OnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let theValue = e.target.value;
        if (theValue && numbersOnly && decimalPoints) {
            const number: number = parseFloat(theValue);

            theValue = number.toFixed(decimalPoints);
        }

        setCharsCount(e.target.value.length);

        updateValue(index, theValue);
    };

    const HandleClickToggleInput = () => {
        if (!(readOnly || disabled)) {
            setShowInput(!showInput);
        }
    };

    const endAdornmentJSX = readOnly ? undefined : (
        <InputAdornment position="end">
            <Box className={theSuffixClass}>
                <>
                    {suffixJSX == null ? null : suffixJSX()}
                    <IconButton
                        aria-label="toggle input visibility"
                        onClick={() => {
                            HandleClickToggleInput();
                            updateValue(index, '');
                        }
                        }
                        edge="end"
                    >
                        <DeleteIcon className={classes.icon} />
                    </IconButton>
                </>
            </Box>
        </InputAdornment>
    );

    const charCountJSX = showCharCounter && showInput ?
        <Typography className={classes.counter}>{charsCount}{maxChars ? `/${maxChars}` : ''}</Typography> : null;

    const content = showInput || readOnly ?
        <TextField
            data-testid={testId}
            className={className}
            variant={"filled"}
            type={numbersOnly ? 'number' : undefined}
            label={label}
            hidden={hidden}
            value={theValue}
            error={error}
            fullWidth={true}
            margin="none"
            helperText={error ? errorMessage : helperText}
            disabled={readOnly || disabled}
            onChange={OnChange}
            minRows={minRows}
            multiline={parseInt(`${minRows}`) > 1}
            inputProps={
                {
                    maxLength: maxChars,
                    inputMode: inputMode,
                }
            }
            InputProps={
                {
                    inputMode: inputMode,
                    endAdornment: endAdornmentJSX,
                }
            }

        /> : (
            <Box pt={1}>
                <AppButton
                    testId={`${testId}ShowInputButton`}
                    sx={{ py: 0, height: '36px !important', mb: 2, display: hidden ? 'none' : 'block' }}
                    onClick={HandleClickToggleInput}>{toggleButtonText}
                </AppButton>
            </Box>
        );

    return (<Box className={cx(hidden ? formClasses.hidden : undefined, classes.container)}>

        {content}

        {charCountJSX}

    </Box>);
}
