import {Chip, Theme, Tooltip} from "@mui/material";
import {ChipProps} from "@mui/material/Chip/Chip";
import {makeStyles} from "tss-react/mui";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import {Loader} from "@mantine/core";
import React from "react";

const useStyles = makeStyles()((theme: Theme) => ({
    outlinedChip: {
        background: "transparent !important",
        color: kAppColors.text.primary(theme.palette.mode === "dark"),
        border: `1px solid ${kAppColors.border(theme.palette.mode === "dark")}`,
        '.MuiChip-icon': {
            color: kAppColors.text.primary(theme.palette.mode === "dark"),
        }
    },
    commonStyledChip: {
        borderRadius: 40,
        paddingLeft: 4,
        paddingRight: 4,
        fontSize: 14,
        height: 36,
        fontWeight: 600,
        transition: 'background-color 0.3s linear',
        '.MuiChip-icon': {
            marginLeft: 3,
        }
    },
    primary: {
        background: kAppColors.primary.main,
        color: kAppColors.text.white,
        'svg': {
            color: kAppColors.text.tertiary(theme.palette.mode == "dark"),
        },
        ':hover': {
            backgroundColor: kAppColors.hoverColor.main,
        },
    },
    secondary: {
        background: kAppColors.grey.secondaryChipBg,
        color: kAppColors.text.black,
        'svg': {
            color: kAppColors.text.black,
        },
        ':hover': {
            backgroundColor: kAppColors.grey.hexaDFE1E5,
        },
    },
    tertiary: {
        background: kAppColors.text.white,
        color: kAppColors.text.black,
        'svg, .MuiChip-icon': {
            color: kAppColors.text.black,
        },
        ':hover': {
            backgroundColor: kAppColors.grey.background2,
        },
    },
    outlined: {
        fontSize: 12,
        borderRadius: 8,
        minHeight: 30,
        height: "unset",
        paddingRight: 8,
        paddingLeft: 8,
        border: `1px solid ${kAppColors.border(theme.palette.mode === 'dark')}`,
        background: theme.palette.mode === "dark" ? '#202020' : "white",
        '.MuiChip-label': {
            padding: 0,
        },
        '.MuiChip-icon': {
            width: 20,
            height: 20,
            marginLeft: 0,
            marginRight: 4,
        },
        '.MuiChip-icon:last-of-type': {
            marginRight: 0,
        },
        'svg': {
            color: kAppColors.text.primary(theme.palette.mode === "dark"),
            height: 20 + 'px !important',
            width: 20 + 'px !important',
        },
        ':hover': {
            background: theme.palette.mode === "dark" ? '#202020' : "white",
        },
        color: kAppColors.text.primary(theme.palette.mode === 'dark'),
    },
    danger: {
        backgroundColor: 'rgba(255, 34, 34, 0.2)',
        borderRadius: 8,
        padding: 8,
        marginRight: 16,
        color: 'rgb(255, 34, 34)',
        fontWeight: 600,
        cursor: 'inherit',
        ':hover': {
            backgroundColor: 'rgba(255, 34, 34, 0.2)',
        },
    },
    secondaryThinSmallerRadius: {
        fontSize: 12,
        borderRadius: 8,
        minHeight: 30,
        height: "unset",
        paddingRight: 8,
        paddingLeft: 8,
        '.MuiChip-icon': {
            marginLeft: 0,
            marginRight: 4,
        },
        '.MuiChip-label': {
            padding: 0,
            paddingLeft: 4,
            fontWeight: 600,
        },
        color: kAppColors.text.primary(theme.palette.mode === "dark"),
        background: kAppColors.background.contactIconButton(theme.palette.mode === "dark"),
        'svg': {
            color: kAppColors.text.primary(theme.palette.mode === "dark"),
        },
        ':hover': {
            backgroundColor: kAppColors.background.contactIconButton(theme.palette.mode === "dark"),
        },
    },
    secondaryThinSmallerRadiusClickable: {
        ':hover': {
            backgroundColor: kAppColors.hoverColor.contactIconButton(theme.palette.mode === "dark"),
        },
    },
    noIconMargin: {
        '.MuiChip-icon': {
            marginRight: 0,
        },
    },
    isLoading: {
        '.MuiChip-label': {
            display: "flex",
        }
    },
    green: {
        color: kAppColors.green.profit,
    },
    red: {
        color: kAppColors.red.loss,
    },
}));

export interface IAppChipProps extends ChipProps {
    className?: string;
    noiconmargin?: string;
    chipstyle?: 'primary' | 'secondary' | 'tertiary' | 'outlined' | 'danger' | 'secondaryThinSmallerRadius';
    tooltip?: string;
    placement?: | 'bottom-end'
        | 'bottom-start'
        | 'bottom'
        | 'left-end'
        | 'left-start'
        | 'left'
        | 'right-end'
        | 'right-start'
        | 'right'
        | 'top-end'
        | 'top-start'
        | 'top';
    isLoading?: boolean;
    green?: boolean;
    red?: boolean;
}

export default function AppChip(props: IAppChipProps) {
    const {
        variant,
        chipstyle,
        className,
        noiconmargin,
        tooltip,
        placement,
        isLoading,
        green,
        red,
    } = props;
    const {classes, cx} = useStyles();

    const theChipClass = cx(
        variant == 'outlined' ? classes.outlinedChip : undefined,
        chipstyle == null ? undefined : classes.commonStyledChip,
        chipstyle == 'primary' ? classes.primary : undefined,
        chipstyle == 'secondary' ? classes.secondary : undefined,
        chipstyle == 'tertiary' ? classes.tertiary : undefined,
        chipstyle == 'outlined' ? classes.outlined : undefined,
        chipstyle == 'danger' ? classes.danger : undefined,
        chipstyle == 'secondaryThinSmallerRadius' ? classes.secondaryThinSmallerRadius : undefined,
        chipstyle == 'secondaryThinSmallerRadius' && props.onClick ? classes.secondaryThinSmallerRadiusClickable : undefined,
        noiconmargin ? classes.noIconMargin : undefined,
        isLoading ? classes.isLoading : undefined,
        green ? classes.green : undefined,
        red ? classes.red : undefined,
        className,
    );

    const tempProps = {
        ...props,
    };
    delete tempProps.tooltip;
    delete tempProps.placement;
    delete tempProps.isLoading;
    delete tempProps.green;
    delete tempProps.red;
    if (isLoading) {
        tempProps.disabled = false;
        tempProps.onClick = () => {
        };
        tempProps.label = <Loader color={chipstyle === "tertiary" ? 'black' : 'white'} size="sm"/>;
    }

    return props.tooltip ? (
        <Tooltip title={props.tooltip} placement={props.placement}>
            <Chip
                {...tempProps}
                className={theChipClass}
            />
        </Tooltip>
    ) : (
        <Chip
            {...tempProps}
            className={theChipClass}
        />
    );
}
