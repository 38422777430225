import React, {useContext} from "react";
import {AppDataContext} from "../../../AppData";
import IProductMaterial, {IProductMaterialByTemplateStats} from "../../../model/ProductMaterial";
import {Box, Theme} from "@mui/material";
import AppChip from "../chips/AppChip";
import Icons8Cost from "../../../icons/Icons8Cost";
import {PriceDisplay} from "../../../service/CompanyService";
import {tt} from "../../../core/Localization";
import {makeStyles} from "tss-react/mui";
import {unitNameAndCountText} from "../../../service/VisitService";
import AppAvatar from "../AppAvatar";
import {UserFullName, UserPhotoUrl} from "../../../service/UserService";
import {EmployeeJoinedUserResponse, FileResponse} from "../../../generated/graphql/graphql";
import ICreateProductMaterialInput from "../../../model/CreateProductMaterialInput";
import {hasPermission} from "../permissions/PermissionValid";
import {
    kActionView,
    kPermissionsProductMaterialsCosts,
    kPermissionsProductMaterialsPrices
} from "../../../core/constants";

export const useStyles = makeStyles()((theme: Theme) => ({
    listItemChipsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        paddingLeft: 56 + 16,
        ':first-of-type': {
            marginTop: -12,
        },
    },
    listItemChipsContainerStatsOnly: {
        paddingLeft: 0,
        marginTop: 0,
        ':first-of-type': {
            marginTop: 0,
        },
    },
    modalHeaderStyle: {
        paddingLeft: 0,
        marginTop: 0,
        ':first-of-type': {
            marginTop: 0,
        },
    },
}));

export interface IProductMaterialListItemChipsProps {
    variant: 'listItem' | 'statsOnly' | 'modalHeaderStyle';
    productMaterial?: IProductMaterial;
    createInput?: ICreateProductMaterialInput;
    stats?: IProductMaterialByTemplateStats;
    displayCount?: boolean;
    displayAvatars?: boolean;
    employee?: EmployeeJoinedUserResponse;
    files?: FileResponse[] | NullOrUndefined;
}

/**
 * Component to display chips for combine Product/Material list item.
 */
export default function ProductMaterialListItemChips(props: IProductMaterialListItemChipsProps) {
    const { variant, productMaterial, createInput, stats, displayCount, displayAvatars, employee, files } = props;

    const theDisplayAvatars = displayAvatars === undefined ? true : displayAvatars;

    const appDataContext = useContext(AppDataContext);
    const {company, currency, language, storage, employeePermissionsMap} = appDataContext;

    const canPrices = hasPermission(kPermissionsProductMaterialsPrices, [kActionView], employeePermissionsMap);
    const canCosts = hasPermission(kPermissionsProductMaterialsCosts, [kActionView], employeePermissionsMap);

    const { classes, cx } = useStyles();

    const mainClassName = cx(
        classes.listItemChipsContainer,
        variant === 'statsOnly' ? classes.listItemChipsContainerStatsOnly : undefined,
        variant === 'modalHeaderStyle' ? classes.modalHeaderStyle : undefined
    )
    let contentJSX = undefined;

    if (productMaterial) {
        const unitName = productMaterial.product?.unitName || productMaterial.material?.unitName;
        const unitCount = productMaterial.product?.unitCount || productMaterial.material?.unitCount;

        contentJSX = (
            <Box className={mainClassName}>
                {displayCount ? (
                    <Box pr={0.5} pb={0.5}>
                        <AppChip
                            chipstyle={"outlined"}
                            label={unitNameAndCountText(unitName, unitCount, language)}
                            tooltip={tt('productMaterial.tooltip.unitCount')}
                        />
                    </Box>
                ) : null}

                {canPrices ? (
                    <Box pr={0.5} pb={0.5}>
                        <AppChip
                            chipstyle={"outlined"}
                            icon={<Icons8Cost iconSmaller={true} green={true} rotate180={true} />}
                            label={PriceDisplay(productMaterial.price, currency, language, true)}
                            tooltip={tt('productMaterial.tooltip.price')}
                        />
                    </Box>
                ) : undefined}

                {company?.hasVat && (canPrices || canCosts) ? (
                    <Box pr={0.5} pb={0.5}>
                        <AppChip
                            chipstyle={"outlined"}
                            label={tt('common.vatRate.percentage').replace('{vatRate}', (productMaterial.vatRate).toString())}
                            tooltip={tt('productMaterial.tooltip.vatRate')}
                        />
                    </Box>
                ) : undefined}

                {canCosts ? (
                    <Box pr={0.5} pb={0.5}>
                        <AppChip
                            chipstyle={"outlined"}
                            icon={<Icons8Cost iconSmaller={true} red={true} />}
                            label={PriceDisplay(productMaterial.cost, currency, language, true)}
                            tooltip={tt('productMaterial.tooltip.cost')}
                        />
                    </Box>
                ) : undefined}

                {employee && theDisplayAvatars ? (
                    <Box pb={0.5}>
                        <AppChip
                            chipstyle={"outlined"}
                            icon={(
                                <>
                                    <AppAvatar
                                        title={UserFullName(employee.name || employee.user?.name, employee.surname || employee.user?.surname)}
                                        variant={"tiny"}
                                        tooltip={UserFullName(employee.name || employee.user?.name, employee.surname || employee.user?.surname)}
                                        img={
                                            UserPhotoUrl(employee?.user, files, storage.publicUrlsForFiles)
                                        }
                                    />
                                </>
                            )}
                        />
                    </Box>
                ) : undefined}
            </Box>
        );
    }

    if (createInput) {
        const unitName = createInput.product?.unitName || createInput.material?.unitName;
        const unitCount = createInput.product?.unitCount || createInput.material?.unitCount;
        const price = createInput.product?.price || createInput.material?.price;
        const vatRate = createInput.product?.vatRate || createInput.material?.vatRate;
        const cost = createInput.product?.cost || createInput.material?.cost;

        contentJSX = (
            <Box className={mainClassName}>
                {displayCount ? (
                    <Box pr={0.5} pb={0.5}>
                        <AppChip
                            chipstyle={"outlined"}
                            label={unitNameAndCountText(unitName, unitCount, language)}
                            tooltip={tt('productMaterial.tooltip.unitCount')}
                        />
                    </Box>
                ) : null}

                {canPrices ? (
                    <Box pr={0.5} pb={0.5}>
                        <AppChip
                            chipstyle={"outlined"}
                            icon={<Icons8Cost iconSmaller={true} green={true} rotate180={true} />}
                            label={PriceDisplay(price, currency, language, true)}
                            tooltip={tt('productMaterial.tooltip.price')}
                        />
                    </Box>
                ) : undefined}

                {company?.hasVat && (canPrices || canCosts) ? (
                    <Box pr={0.5} pb={0.5}>
                        <AppChip
                            chipstyle={"outlined"}
                            label={tt('common.vatRate.percentage').replace('{vatRate}', (vatRate || 0).toString())}
                            tooltip={tt('productMaterial.tooltip.vatRate')}
                        />
                    </Box>
                ) : undefined}

                {canCosts ? (
                    <Box pr={0.5} pb={0.5}>
                        <AppChip
                            chipstyle={"outlined"}
                            icon={<Icons8Cost iconSmaller={true} red={true} />}
                            label={PriceDisplay(cost, currency, language, true)}
                            tooltip={tt('productMaterial.tooltip.cost')}
                        />
                    </Box>
                ) : undefined}
            </Box>
        );
    }

    if (stats) {
        contentJSX = (
            <Box className={mainClassName}>
                <Box pr={0.5} pb={0.5}>
                    <AppChip
                        chipstyle={"outlined"}
                        label={unitNameAndCountText(stats.unitName, stats.unitCount, language)}
                        tooltip={tt('productMaterial.tooltip.unitCount')}
                    />
                </Box>

                {canPrices ? (
                    <Box pr={0.5} pb={0.5}>
                        <AppChip
                            chipstyle={"outlined"}
                            icon={<Icons8Cost iconSmaller={true} green={true} rotate180={true} />}
                            label={PriceDisplay(stats.price, currency, language, true)}
                            tooltip={tt('productMaterial.tooltip.price')}
                        />
                    </Box>
                ) : undefined}

                {company?.hasVat && (canPrices || canCosts) ? (
                    <Box pr={0.5} pb={0.5}>
                        <AppChip
                            chipstyle={"outlined"}
                            label={tt('common.vatRate.percentage').replace('{vatRate}', (stats.vatRate).toString())}
                            tooltip={tt('productMaterial.tooltip.vatRate')}
                        />
                    </Box>
                ) : undefined}

                {canCosts ? (
                    <Box pr={0.5} pb={0.5}>
                        <AppChip
                            chipstyle={"outlined"}
                            icon={<Icons8Cost iconSmaller={true} red={true} />}
                            label={PriceDisplay(stats.cost, currency, language, true)}
                            tooltip={tt('productMaterial.tooltip.cost')}
                        />
                    </Box>
                ) : undefined}

                {stats.employees.length > 0 && theDisplayAvatars ? (
                    <Box pb={0.5}>
                        <AppChip
                            chipstyle={"outlined"}
                            icon={(
                                <>
                                    {stats.employees.map((employee, index) => <Box
                                        key={employee.id}
                                        pr={index < stats.employees!.length - 1 ? 0.5 : 0}
                                    >
                                        <AppAvatar
                                            title={UserFullName(employee.name || employee.user?.name, employee.surname || employee.user?.surname)}
                                            variant={"tiny"}
                                            tooltip={UserFullName(employee.name || employee.user?.name, employee.surname || employee.user?.surname)}
                                            img={
                                                UserPhotoUrl(employee?.user, files, storage.publicUrlsForFiles)
                                            }
                                        />
                                    </Box>)}
                                </>
                            )}
                        />
                    </Box>
                ) : undefined}
            </Box>
        );
    }

    return (
        <>
            {contentJSX}
        </>
    );
}
