import {CreateMaterialInput, CreateProductInput, VisitDetailResponse} from "../../../../generated/graphql/graphql";
import {makeStyles} from "tss-react/mui";
import {Box, Theme, Typography} from "@mui/material";
import React, {useContext, useMemo} from "react";
import {AppDataContext} from "../../../../AppData";
import {convertCreateProductMaterialInputs, convertProductMaterials} from "../../../../service/ProductMaterialService";
import ICreateProductMaterialInput, {
    ICreateProductMaterialInputByVatRate
} from "../../../../model/CreateProductMaterialInput";
import {kAppColors} from "../../../../styles/AppThemeProcessor";
import {tt} from "../../../../core/Localization";
import Icons8Cost from "../../../../icons/Icons8Cost";
import {GetVatAmount, numberDisplay, PriceDisplay} from "../../../../service/CompanyService";
import {hasPermission} from "../../permissions/PermissionValid";
import {
    kActionView,
    kPermissionsMaterials, kPermissionsProductMaterialsCosts,
    kPermissionsProductMaterialsPrices,
    kPermissionsProducts
} from "../../../../core/constants";

const useStyles = makeStyles()((theme: Theme) => ({
    totalSectionContainerOuter: {
        marginTop: 16,
        borderTop: `dashed 1px ${kAppColors.border(theme.palette.mode === "dark")}`,
        paddingTop: 16,
        paddingBottom: 16,
        display: "flex",
        justifyContent: "stretch",
    },
    totalSectionContainerInner: {
        width: '100%',
        display: "flex",
        flexDirection: "column",
        alignItems: "end",
    },
    rowContainer: {
        width: '100%',
        justifyContent: "end",
        display: "flex",
        flexDirection: "column",
    },
    singleRow: {
        width: '100%',
        justifyContent: "end",
        display: "flex",
        paddingLeft: 16,
        paddingRight: 16,
    },
    singleCell: {
        padding: 8,
        width: '33.3%',
        maxWidth: 120,
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        textOverflow: "ellipsis",
    },
    singleCellMobile: {
        maxWidth: '100%',
    },
    singleCellWithArrow: {
        justifyContent: "space-between",
    },
    priceRow: {
        borderBottom: `dashed 1px ${kAppColors.border(theme.palette.mode === "dark")}`,
    },
    priceRowLast: {
        borderBottom: "none",
    },
    label: {
        fontSize: 12,
        textAlign: "end",
        color: kAppColors.text.secondary(theme.palette.mode === "dark"),
        fontWeight: 600,
        textTransform: "uppercase",
        marginBottom: 4,
    },
    value: {
        textAlign: "end",
        fontWeight: 600,
        color: kAppColors.text.primary(theme.palette.mode === "dark"),
    },
    valueCost: {
        color: kAppColors.text.secondary(theme.palette.mode === "dark"),
    },
    total: {
        padding: 8,
        fontWeight: 600,
        fontSize: 20,
    },
}));

export interface IVisitProductMaterialsTotalSectionProps {
    products?: CreateProductInput[];
    materials?: CreateMaterialInput[];
    visitData?: VisitDetailResponse | NullOrUndefined;
}

/**
 * Section used to display combined Product/Materials totals.
 */
export default function VisitProductMaterialsTotalSection(props: IVisitProductMaterialsTotalSectionProps) {
    const {products, materials, visitData} = props;

    const {classes, cx} = useStyles();
    const appDataContext = useContext(AppDataContext);
    const {currency, language, isMobile, company, employeePermissionsMap} = appDataContext;

    const companyHasVat = company?.hasVat || false;

    return useMemo(() => {
        const createItems: ICreateProductMaterialInput[] | NullOrUndefined = products && materials ? convertCreateProductMaterialInputs({
            products,
            materials,
        }) : null;
        const combinedItems = visitData ? convertProductMaterials({
            products: hasPermission(kPermissionsProducts, [kActionView], employeePermissionsMap) ? visitData.products : [],
            materials: hasPermission(kPermissionsMaterials, [kActionView], employeePermissionsMap) ? visitData.materials : [],
        }) : null;

        const canPrices = hasPermission(kPermissionsProductMaterialsPrices, [kActionView], employeePermissionsMap);
        const canCosts = hasPermission(kPermissionsProductMaterialsCosts, [kActionView], employeePermissionsMap);

        if (!canPrices && !canCosts) {
            return null;
        }

        if (createItems?.length || combinedItems?.length) {
            const itemsByVatRate: ICreateProductMaterialInputByVatRate[] = [];

            if (createItems) {
                for (const itemOf of createItems) {
                    let vatRate = itemOf.product?.vatRate || itemOf.material?.vatRate || 0;

                    if (!companyHasVat) {
                        vatRate = 0;
                    }

                    const unitCount = itemOf.product?.unitCount || itemOf.material?.unitCount || 0;
                    const price = itemOf.product?.price || itemOf.material?.price || 0;
                    const cost = itemOf.product?.cost || itemOf.material?.cost || 0;

                    const priceWithCount = price * unitCount;
                    const costWithCount = cost * unitCount;

                    const existing = itemsByVatRate.find((item) => {
                        return item.vatRate === vatRate;
                    });

                    if (existing) {
                        existing.price += priceWithCount;
                        existing.cost += costWithCount;
                    } else {
                        itemsByVatRate.push({
                            vatRate,
                            price: priceWithCount,
                            cost: costWithCount,
                        });
                    }
                }
            }

            if (combinedItems) {
                for (const itemOf of combinedItems) {
                    let vatRate = itemOf.product?.vatRate || itemOf.material?.vatRate || 0;

                    if (!companyHasVat) {
                        vatRate = 0;
                    }

                    const unitCount = itemOf.product?.unitCount || itemOf.material?.unitCount || 0;
                    const price = itemOf.product?.price || itemOf.material?.price || 0;
                    const cost = itemOf.product?.cost || itemOf.material?.cost || 0;

                    const priceWithCount = price * unitCount;
                    const costWithCount = cost * unitCount;

                    const existing = itemsByVatRate.find((item) => {
                        return item.vatRate === vatRate;
                    });

                    if (existing) {
                        existing.price += priceWithCount;
                        existing.cost += costWithCount;
                    } else {
                        itemsByVatRate.push({
                            vatRate,
                            price: priceWithCount,
                            cost: costWithCount,
                        });
                    }
                }
            }

            itemsByVatRate.sort((a, b) => b.vatRate - a.vatRate);

            const cellClassName = cx(
                classes.singleCell,
                isMobile ? classes.singleCellMobile : null
            );

            const itemsJSX = itemsByVatRate.map((item, index) => {
                const priceVat = GetVatAmount(item.price, item.vatRate);
                const costVat = GetVatAmount(item.cost, item.vatRate);

                const isLast = index === itemsByVatRate.length - 1;

                return (
                    <Box key={item.vatRate} className={classes.rowContainer}>
                        {canPrices ? (
                            <Box className={cx(classes.singleRow, classes.priceRow, (isLast || !companyHasVat) && !canCosts ? classes.priceRowLast : null)}>
                                {isMobile ? null : (
                                    <Box className={cellClassName}>
                                        <Icons8Cost iconSmaller={true} green={true}
                                                    rotate180={true}/>
                                    </Box>
                                )}

                                {companyHasVat ? (
                                    <>
                                        <Box
                                            className={cx(cellClassName, isMobile ? classes.singleCellWithArrow : undefined)}>
                                            {!isMobile ? null : (
                                                <Icons8Cost iconSmaller={true} green={true}
                                                            rotate180={true}/>
                                            )}

                                            <Typography
                                                className={classes.value}>
                                                {`${numberDisplay(item.vatRate, language, true)!} %`}
                                            </Typography>
                                        </Box>

                                        <Box className={cellClassName}>
                                            <Typography
                                                className={classes.value}>
                                                {PriceDisplay(item.price, currency, language, true)}
                                            </Typography>
                                        </Box>

                                        <Box className={cellClassName}>
                                            <Typography
                                                className={classes.value}>
                                                {PriceDisplay(priceVat, currency, language, true)}
                                            </Typography>
                                        </Box>
                                    </>
                                ) : (
                                    <Box className={cx(cellClassName, isMobile ? classes.singleCellWithArrow : undefined)}>
                                        {!isMobile ? null : (
                                            <Icons8Cost iconSmaller={true} green={true}
                                                        rotate180={true}/>
                                        )}

                                        <Typography
                                            className={classes.value}>
                                            {PriceDisplay(item.price, currency, language, true)}
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        ) : null}

                        {canCosts ? (
                            <Box className={cx(classes.singleRow, !canPrices ? classes.priceRow : null, isLast || !companyHasVat ? classes.priceRowLast : null)}>
                                {isMobile ? null : (
                                    <Box className={cellClassName}>
                                        <Icons8Cost iconSmaller={true} red={true}/>
                                    </Box>
                                )}

                                {companyHasVat ? (
                                    <>
                                        <Box
                                            className={cx(cellClassName, isMobile ? classes.singleCellWithArrow : undefined)}>
                                            {!isMobile ? null : (
                                                <Icons8Cost iconSmaller={true} red={true}/>
                                            )}

                                            <Typography
                                                className={classes.value}>

                                            </Typography>
                                        </Box>

                                        <Box className={cellClassName}>
                                            <Typography
                                                className={cx(classes.value, classes.valueCost)}>
                                                {PriceDisplay(item.cost, currency, language, true)}
                                            </Typography>
                                        </Box>

                                        <Box className={cellClassName}>
                                            <Typography
                                                className={cx(classes.value, classes.valueCost)}>
                                                {PriceDisplay(costVat, currency, language, true)}
                                            </Typography>
                                        </Box>
                                    </>
                                ) : (
                                    <Box
                                        className={cx(cellClassName, isMobile ? classes.singleCellWithArrow : undefined)}>
                                        {!isMobile ? null : (
                                            <Icons8Cost iconSmaller={true} red={true}/>
                                        )}

                                        <Typography
                                            className={cx(classes.value, classes.valueCost)}>
                                            {PriceDisplay(item.cost, currency, language, true)}
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        ) : null}
                    </Box>
                );
            });

            return (
                <Box className={classes.totalSectionContainerOuter}>
                    <Box className={classes.totalSectionContainerInner}>
                        <Box className={classes.singleRow}>
                            {companyHasVat ? (
                                <Box className={cellClassName}>
                                    <Typography className={classes.label}>
                                        {tt('productMaterialSection.totals.vatRate')}
                                    </Typography>
                                </Box>
                            ) : null}

                            <Box className={cellClassName}>
                                <Typography className={classes.label}>
                                    {tt('productMaterialSection.totals.base')}
                                </Typography>
                            </Box>

                            {companyHasVat ? (
                                <Box className={cellClassName}>
                                    <Typography className={classes.label}>
                                        {tt('productMaterialSection.totals.vat')}
                                    </Typography>
                                </Box>
                            ) : null}
                        </Box>

                        {itemsJSX}
                    </Box>
                </Box>
            );
        }

        return null;
    }, [products, materials, visitData, currency, language, isMobile, companyHasVat, employeePermissionsMap]);
}
