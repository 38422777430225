import IVisitEvent from "../../../../model/VisitEvent";
import {
    JobEmployeeTimesheetItemResponse,
    JobOfferSeatResponse,
    MaterialResponse,
    ProductResponse
} from "../../../../generated/graphql/graphql";
import React, {useContext, useMemo} from "react";
import {AppDataContext} from "../../../../AppData";
import {
    calculateTimesheets,
    CalculateTotalPrice,
    paymentTypeToItemPaymentType,
    PriceDisplay
} from "../../../../service/CompanyService";
import {
    combineProductMaterials,
    convertProductMaterials,
    displayProductMaterialListTitle
} from "../../../../service/ProductMaterialService";
import {hasPermission, hasSomePermissions} from "../../permissions/PermissionValid";
import {
    kActionView,
    kPermissionsMaterials, kPermissionsProductMaterialsCosts, kPermissionsProductMaterialsPrices,
    kPermissionsProducts, kPermissionsTimesheets,
    kPermissionsWorkers
} from "../../../../core/constants";
import {Box} from "@mui/material";
import {tt} from "../../../../core/Localization";
import Icons8Cost from "../../../../icons/Icons8Cost";
import AppChip from "../../chips/AppChip";
import {combineMaterialsByTemplate} from "../../../../service/MaterialService";
import ProductMaterialIcon from "../../productMaterials/ProductMaterialIcon";
import {ProductMaterialType} from "../../../../model/ProductMaterial";
import MergedProductMaterialIcon from "../../../../icons/MergedProductMaterialIcon";

export interface IVisitItemChipsProps {
    data: IVisitEvent;
    timesheets: JobEmployeeTimesheetItemResponse[];
    jobOfferSeats: JobOfferSeatResponse[];
    products: ProductResponse[];
    materials: MaterialResponse[];
}

/**
 * Component to display chips for Visit on e.g. lists or map preview.
 */
export default function VisitItemChips(props: IVisitItemChipsProps) {
    const {data, timesheets, jobOfferSeats, products, materials} = props;

    const appDataContext = useContext(AppDataContext);
    const {employeePermissionsMap, currency, language, company} = appDataContext;

    const companyHasVat = company?.hasVat || false;

    return useMemo(() => {
        const canSeeTimesheets = hasPermission(kPermissionsTimesheets, [kActionView], employeePermissionsMap);

        let totalPrice = 0;
        let totalCost = 0;
        let totalCostEmployees = 0;
        let profit = 0;
        let isNegative = false;

        if (canSeeTimesheets) {
            const statsForTimesheets = calculateTimesheets({
                timesheets: timesheets,
                countNotApproved: true,
                filterByEmployeeIds: data.visitRepeatDay?.employeeIds || data.employeeIds,
            });

            totalCost += statsForTimesheets.totalPrice;
            totalCostEmployees += statsForTimesheets.totalPrice;

            const theJobOfferSeats = jobOfferSeats.filter(seat => !seat.completed && !seat.deleted && !seat.cancelled && seat.acceptedIds.length === 0);

            const jobOfferSeatsTotal = CalculateTotalPrice({
                timesheets: theJobOfferSeats.map(seat => ({
                    paymentType: paymentTypeToItemPaymentType(seat.paymentType),
                    hours: seat.hours,
                    minutes: seat.minutes,
                    hourRate: seat.hourRate,
                    fixedPrice: seat.fixedPrice,
                    approved: true,
                })),
                currency,
                language,
            });

            totalCost += jobOfferSeatsTotal;
            totalCostEmployees += jobOfferSeatsTotal;
        }

        const combinedItems = convertProductMaterials({
            products: products,
            materials: materials,
        });

        for (const item of combinedItems) {
            const price = item.product?.price || item.material?.price || 0;
            const cost = item.product?.cost || item.material?.cost || 0;
            const unitCount = item.product?.unitCount || item.material?.unitCount || 0;

            const priceWithCount = price * unitCount;
            const costWithCount = cost * unitCount;

            totalPrice += priceWithCount;
            totalCost += costWithCount;
        }

        profit = totalPrice - totalCost;
        isNegative = totalPrice < totalCost;

        const canSeeProductMaterials = hasPermission(kPermissionsProducts, [kActionView], employeePermissionsMap)
            && hasPermission(kPermissionsMaterials, [kActionView], employeePermissionsMap);
        const canPrices = hasPermission(kPermissionsProductMaterialsPrices, [kActionView], employeePermissionsMap);
        const canCosts = hasPermission(kPermissionsProductMaterialsCosts, [kActionView], employeePermissionsMap);

        const productsByTemplate = combineProductMaterials({
            productMaterials: combinedItems
                .filter((item) => item.type === ProductMaterialType.Product),
            employees: [],
        });
        const servicesByTemplate = combineProductMaterials({
            productMaterials: combinedItems
                .filter((item) => item.type === ProductMaterialType.Service),
            employees: [],
        });
        const materialsByTemplate = combineMaterialsByTemplate({
            materials,
            employees: null,
        });

        let productMaterials= 0;
        if (hasPermission(kPermissionsProducts, [kActionView], employeePermissionsMap)) {
            productMaterials += productsByTemplate.length + servicesByTemplate.length;
        }
        if (hasPermission(kPermissionsMaterials, [kActionView], employeePermissionsMap)) {
            productMaterials += materialsByTemplate.length;
        }
        const productMaterialsJSX = productMaterials > 0 ? (
            <Box pr={0.5} pb={0.5}>
                <AppChip
                    chipstyle={"outlined"}
                    icon={<MergedProductMaterialIcon />}
                    label={productMaterials}
                    tooltip={displayProductMaterialListTitle(employeePermissionsMap)}
                />
            </Box>
        ) : null;

        if (!canSeeProductMaterials || !canPrices || !canCosts) {
            return (
                <>
                    {canSeeTimesheets ? (
                        <Box pr={0.5} pb={0.5}>
                            <AppChip
                                chipstyle={"outlined"}
                                icon={<Icons8Cost iconSmaller={true} red={true}/>}
                                label={PriceDisplay(totalCostEmployees, currency, language, true)}
                                tooltip={tt('visit.totalCostEmployees.cost.tooltip')}
                            />
                        </Box>
                    ) : null}

                    {productMaterialsJSX}
                </>
            );
        } else {
            return (
                <>
                    <Box pr={0.5} pb={0.5}>
                        <AppChip
                            chipstyle={"outlined"}
                            icon={<Icons8Cost iconSmaller={true} green={true} rotate180={true}/>}
                            label={PriceDisplay(totalPrice, currency, language, true)}
                            tooltip={tt('visit.total.price.tooltip')}
                        />
                    </Box>

                    <Box pr={0.5} pb={0.5}>
                        <AppChip
                            chipstyle={"outlined"}
                            icon={<Icons8Cost iconSmaller={true} red={true}/>}
                            label={PriceDisplay(totalCost, currency, language, true)}
                            tooltip={tt('visit.total.cost.tooltip')}
                        />
                    </Box>

                    <Box pr={0.5} pb={0.5}>
                        <AppChip
                            chipstyle={"outlined"}
                            label={PriceDisplay(profit, currency, language, true, true)}
                            green={!isNegative}
                            red={isNegative}
                            tooltip={tt('visit.total.profitLoss.tooltip')}
                        />
                    </Box>

                    {productMaterialsJSX}
                </>
            );
        }
    }, [data, timesheets, jobOfferSeats, products, materials, employeePermissionsMap, currency, language, companyHasVat]);
}
