import React, {Dispatch, SetStateAction, useContext, useEffect, useMemo, useState} from "react";
import ModalBottomSheet from "../../ModalBottomSheet";
import {FetchPolicy, RestApiClientContext} from "../../../../core/RestApiProvider";
import {tt} from "../../../../core/Localization";
import BottomSheetModalAppbar from "../BottomSheetModalAppbar";
import {Box} from "@mui/material";
import ProductMaterialForm from "../../productMaterials/ProductMaterialForm";
import {
    AddVisitMaterialInput,
    AddVisitProductInput,
    CreateMaterialInput,
    CreateMaterialTemplateInput,
    CreateProductInput,
    GetProductMaterialsByCompanyInput, JobUpdateRepeats, MaterialResponse,
    ProductMaterialResponsePage, ProductResponse,
    ProductType, VisitResponse
} from "../../../../generated/graphql/graphql";
import IProductMaterial, {ProductMaterialType} from "../../../../model/ProductMaterial";
import {IInputsData} from "../../form/FormBuilder";
import {kActionView, kPermissionsMaterials, kPermissionsProducts} from "../../../../core/constants";
import {AppDataContext} from "../../../../AppData";
import {
    convertProductMaterialResponsePage, displayProductMaterialListTitle,
    subscribeToProductMaterialResponsePage
} from "../../../../service/ProductMaterialService";
import {hasPermission} from "../../permissions/PermissionValid";
import {v4 as uuidv4} from "uuid";
import {processMutationError} from "../../../../service/ErrorService";
import {IOnUpdateVisitIdParams} from "../job/visits/VisitDetailModal";
import {SuccessToast} from "../../../../service/ToastService";
import ProductMaterialIcon from "../../productMaterials/ProductMaterialIcon";

export interface IAddProductMaterialModalBottomSheetProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    onAddCreateInput?: (params: {
        product?: CreateProductInput;
        material?: CreateMaterialInput;
    }) => void;
    visitDetail?: {
        visitId: number;
        repeatingDay: number | NullOrUndefined;
        repeats: JobUpdateRepeats;
        onUpdateVisitId: (params: IOnUpdateVisitIdParams) => void;
    };
    alreadyUsedProductTemplates?: ProductResponse[] | CreateProductInput[];
    alreadyUsedMaterialTemplates?: MaterialResponse[] | CreateMaterialInput[];
}

/**
 * Modal bottom sheet component to search and add combined Product/Material.
 */
export default function AddProductMaterialModalBottomSheet(props: IAddProductMaterialModalBottomSheetProps) {
    const {
        open,
        setOpen,
        onAddCreateInput,
        visitDetail,
        alreadyUsedProductTemplates,
        alreadyUsedMaterialTemplates
    } = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiPost} = restApiClientContext;

    const appDataContext = useContext(AppDataContext);
    const {companyId, employeePermissionsMap, employeeId} = appDataContext;

    const [instantSetParentInputs, setDynamicSetParentInputsDebouncerDelay] = useState<boolean>(true);
    const [inputs, setInputs] = useState<IInputsData>({});
    const [selectedProduct, setSelectedProduct] = useState<IProductMaterial>();
    const [selectedMaterial, setSelectedMaterial] = useState<IProductMaterial>();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<ProductMaterialResponsePage | NullOrUndefined>();
    useEffect(() => {
        if (open) {
            const subscription = subscribeToProductMaterialResponsePage(
                restApiClientContext,
                appDataContext,
                {
                    input: {
                        companyId: companyId,
                        isTemplate: true,
                        includeProducts: hasPermission(kPermissionsProducts, [kActionView], employeePermissionsMap),
                        includeMaterials: hasPermission(kPermissionsMaterials, [kActionView], employeePermissionsMap),
                        orderAlphabetically: true,
                    } as GetProductMaterialsByCompanyInput,
                    setLoading,
                    onData: setData,
                },
            );

            return () => subscription.cancel();
        } else {
            setLoading(false);
            setData(null);
        }
    }, [open, companyId, employeePermissionsMap]);

    const [resetFormTrigger, setResetFormTrigger] = useState(0);

    useEffect(() => {
        if (open) {
            setResetFormTrigger(resetFormTrigger + 1);

            setTimeout(() => {
                setDynamicSetParentInputsDebouncerDelay(false);
            }, 100);
        } else {
            setDynamicSetParentInputsDebouncerDelay(true);
        }
    }, [open]);

    const [submitLoading, setSubmitLoading] = useState(false);
    /**
     * Process form input to create Product or Material.
     * Also link to template if the core data did not change from form.
     */
    const handleSubmit = async (inputs: IInputsData, createAnother?: boolean, saveAsTemplate?: boolean) => {
        const type = inputs.type.value;
        let theTemplateId = selectedProduct?.id || selectedMaterial?.id;

        let alreadyUsedProductTemplate: ProductResponse | CreateProductInput | undefined;
        let alreadyUsedMaterialTemplate: MaterialResponse | CreateMaterialInput | undefined;
        if (!saveAsTemplate && selectedProduct) {
            for (let i = 0; i < alreadyUsedProductTemplates!.length; i++) {
                if (selectedProduct!.id === alreadyUsedProductTemplates![i]!.templateId) {
                    alreadyUsedProductTemplate = alreadyUsedProductTemplates![i];
                    break;
                }
            }
        }
        if (!saveAsTemplate && selectedMaterial) {
            for (let i = 0; i < alreadyUsedMaterialTemplates!.length; i++) {
                if (selectedMaterial!.id === alreadyUsedMaterialTemplates![i]!.templateId) {
                    alreadyUsedMaterialTemplate = alreadyUsedMaterialTemplates![i];
                    break;
                }
            }
        }

        if (saveAsTemplate) {
            try {
                if (type === ProductMaterialType.Material) {
                    theTemplateId = await new Promise<number>((resolve, reject) => {
                        restApiPost({
                            uri: '/material/template',
                            params: {
                                companyId: companyId!,
                                name: inputs.name.value.label || inputs.name.value,
                                unitName: inputs.unitName.value,
                                price: parseFloat(inputs.price.value || '0'),
                                vatRate: parseFloat(inputs.vatRate.value || '0'),
                                description: inputs.description.value,
                                cost: parseFloat(inputs.cost.value || '0'),
                            } as CreateMaterialTemplateInput,
                            fetchPolicy: FetchPolicy.NetworkOnly,
                            setLoading: setSubmitLoading,
                            onData: (data) => {
                                if (data) {
                                    resolve(data.id);
                                }
                            },
                            onError: (error: any) => reject(error),
                        });
                    });
                } else {
                    theTemplateId = await new Promise<number>((resolve, reject) => {
                        restApiPost({
                            uri: '/product/template',
                            params: {
                                companyId: companyId!,
                                type: type === ProductMaterialType.Service ? ProductType.Service : ProductType.Product,
                                name: inputs.name.value.label || inputs.name.value,
                                unitName: inputs.unitName.value,
                                price: parseFloat(inputs.price.value || '0'),
                                vatRate: parseFloat(inputs.vatRate.value || '0'),
                                description: inputs.description.value,
                                cost: parseFloat(inputs.cost.value || '0'),
                            } as CreateMaterialTemplateInput,
                            fetchPolicy: FetchPolicy.NetworkOnly,
                            setLoading: setSubmitLoading,
                            onData: (data) => {
                                if (data) {
                                    resolve(data.id);
                                }
                            },
                            onError: (error: any) => reject(error),
                        });
                    });
                }
            } catch (e) {
                processMutationError(e);

                return;
            }
        }

        if (onAddCreateInput) {
            if (type === ProductMaterialType.Material) {
                onAddCreateInput({
                    material: {
                        uuid: uuidv4(),
                        name: alreadyUsedMaterialTemplate ? alreadyUsedMaterialTemplate.name : inputs.name.value.label || inputs.name.value,
                        description: alreadyUsedMaterialTemplate ? alreadyUsedMaterialTemplate.description : inputs.description.value,
                        price: parseFloat(inputs.price.value || '0'),
                        vatRate: parseFloat(inputs.vatRate.value || '0'),
                        unitName: alreadyUsedMaterialTemplate ? alreadyUsedMaterialTemplate.unitName : inputs.unitName.value,
                        sortOrder: 0,
                        unitCount: parseFloat(inputs.unitCount.value || '0'),
                        templateId: theTemplateId,
                        employeeId: employeeId!,
                        cost: parseFloat(inputs.cost.value || '0'),
                    },
                });
            } else {
                onAddCreateInput({
                    product: {
                        uuid: uuidv4(),
                        name: alreadyUsedProductTemplate ? alreadyUsedProductTemplate.name : inputs.name.value.label || inputs.name.value,
                        description: alreadyUsedProductTemplate ? alreadyUsedProductTemplate.description : inputs.description.value,
                        price: parseFloat(inputs.price.value || '0'),
                        vatRate: parseFloat(inputs.vatRate.value || '0'),
                        unitName: alreadyUsedProductTemplate ? alreadyUsedProductTemplate.unitName : inputs.unitName.value,
                        type: type === ProductMaterialType.Service ? ProductType.Service : ProductType.Product,
                        sortOrder: 0,
                        unitCount: parseFloat(inputs.unitCount.value || '0'),
                        templateId: theTemplateId,
                        employeeId: employeeId!,
                        cost: parseFloat(inputs.cost.value || '0'),
                    },
                });
            }
        }

        if (visitDetail) {
            if (type === ProductMaterialType.Material) {
                restApiPost({
                    uri: '/job/visit/add-material',
                    params: {
                        visitId: visitDetail.visitId!,
                        repeatingDay: visitDetail.repeatingDay,
                        repeats: visitDetail.repeats,
                        uuid: uuidv4(),
                        templateId: theTemplateId,
                        employeeId: employeeId!,
                        name: alreadyUsedMaterialTemplate ? alreadyUsedMaterialTemplate.name : inputs.name.value.label || inputs.name.value,
                        unitName: alreadyUsedMaterialTemplate ? alreadyUsedMaterialTemplate.unitName : inputs.unitName.value,
                        unitCount: parseFloat(inputs.unitCount.value || '0'),
                        price: parseFloat(inputs.price.value || '0'),
                        vatRate: parseFloat(inputs.vatRate.value || '0'),
                        description: alreadyUsedMaterialTemplate ? alreadyUsedMaterialTemplate.description : inputs.description.value,
                        cost: parseFloat(inputs.cost.value || '0'),
                        saveAsTemplate: false,
                    } as AddVisitMaterialInput,
                    fetchPolicy: FetchPolicy.NetworkOnly,
                    setLoading: setSubmitLoading,
                    onData: (data: VisitResponse) => {
                        if (data) {
                            SuccessToast(tt('addProductMaterial.modal.add.success'));

                            if (data!.id) {
                                visitDetail.onUpdateVisitId({
                                    visitId: data!.id,
                                    repeatingDay: visitDetail.repeatingDay!,
                                });
                            }

                            setOpen(false);
                        }
                    },
                    onError: (error) => processMutationError(error),
                });
            } else {
                restApiPost({
                    uri: '/job/visit/add-product',
                    params: {
                        visitId: visitDetail.visitId!,
                        repeatingDay: visitDetail.repeatingDay,
                        repeats: visitDetail.repeats,
                        uuid: uuidv4(),
                        templateId: theTemplateId,
                        employeeId: employeeId!,
                        type: type === ProductMaterialType.Service ? ProductType.Service : ProductType.Product,
                        name: alreadyUsedProductTemplate ? alreadyUsedProductTemplate.name : inputs.name.value.label || inputs.name.value,
                        unitName: alreadyUsedProductTemplate ? alreadyUsedProductTemplate.unitName : inputs.unitName.value,
                        unitCount: parseFloat(inputs.unitCount.value || '0'),
                        price: parseFloat(inputs.price.value || '0'),
                        vatRate: parseFloat(inputs.vatRate.value || '0'),
                        description: alreadyUsedProductTemplate ? alreadyUsedProductTemplate.description : inputs.description.value,
                        cost: parseFloat(inputs.cost.value || '0'),
                        saveAsTemplate: false,
                    } as AddVisitProductInput,
                    fetchPolicy: FetchPolicy.NetworkOnly,
                    setLoading: setSubmitLoading,
                    onData: (data: VisitResponse) => {
                        if (data) {
                            SuccessToast(tt('addProductMaterial.modal.add.success'));

                            if (data!.id) {
                                visitDetail.onUpdateVisitId({
                                    visitId: data!.id,
                                    repeatingDay: visitDetail.repeatingDay!,
                                });
                            }

                            setOpen(false);
                        }
                    },
                    onError: (error) => processMutationError(error),
                });
            }
        }
    };

    const productMaterialsForQuery = useMemo(() => {
        if (data) {
            return convertProductMaterialResponsePage({
                productMaterialResponsePage: data,
                orderAlphabetically: true,
                filterByType: undefined,
            });
        } else {
            return [];
        }
    }, [data]);

    /**
     * If selected Product or Material, set as existing data.
     * If selected new item, clear selection and form.
     */
    const handleSelectProductMaterial = (type?: ProductMaterialType, id?: number) => {
        if (type && id) {
            const selected = productMaterialsForQuery.find((item) => item.type === type && item.id === id);

            if (type === ProductMaterialType.Material) {
                setSelectedProduct(undefined);
                setSelectedMaterial(selected);
            } else {
                setSelectedProduct(selected);
                setSelectedMaterial(undefined);
            }
        } else {
            setSelectedProduct(undefined);
            setSelectedMaterial(undefined);
        }
    };

    const canSaveAsTemplateAble = useMemo(() => {
        const formName = inputs.name?.value.label;
        const formType = inputs.type?.value;

        if (formType) {
            if (selectedProduct && selectedProduct.name === formName && selectedProduct.type === formType) {
                return false;
            }

            if (selectedMaterial && selectedMaterial.name === formName && selectedMaterial.type === formType) {
                return false;
            }
        }

        return true;
    }, [selectedProduct, selectedMaterial, inputs, alreadyUsedProductTemplates, alreadyUsedMaterialTemplates]);

    const disableTemplateInputs = useMemo(() => {
        if (selectedProduct || selectedMaterial) {
            const canForSelectedProduct = selectedProduct && alreadyUsedProductTemplates && alreadyUsedProductTemplates.some((item) => item.templateId === selectedProduct.id);
            const canForSelectedMaterial = selectedMaterial && alreadyUsedMaterialTemplates && alreadyUsedMaterialTemplates.some((item) => item.templateId === selectedMaterial.id);

            if (selectedProduct) {
                return canForSelectedProduct
            }

            if (selectedMaterial) {
                return canForSelectedMaterial
            }
        }

        return false;
    }, [selectedProduct, selectedMaterial]);

    const nameTypeIconHideTypeSelect = useMemo(() => {
        if (!canSaveAsTemplateAble && (selectedProduct || selectedMaterial)) {
            return (
                <ProductMaterialIcon type={selectedProduct?.type || selectedMaterial!.type}/>
            );
        }

        return undefined;
    }, [canSaveAsTemplateAble, selectedProduct, selectedMaterial]);

    const theName = inputs.name?.value;

    const hideTypeSelect = useMemo(() => {
        if (typeof theName === 'string' && theName.length === 0) {
            return true;
        }

        return undefined;
    }, [theName]);

    return (
        <ModalBottomSheet
            blurBackdrop={true}
            open={open}
            setOpen={setOpen}
            hideHeader={true}
            tallOnMobile={true}
        >
            <BottomSheetModalAppbar
                noBorderBottom={true}
                title={displayProductMaterialListTitle(employeePermissionsMap)}
                onClose={() => setOpen(false)}
            />

            <Box pr={2} pl={2} pb={2}>
                <ProductMaterialForm
                    variant="addToVisit"
                    existing={selectedProduct || selectedMaterial}
                    onSubmit={handleSubmit}
                    submitLoading={submitLoading}
                    onUpdateInputs={setInputs}
                    canSaveAsTemplateAble={canSaveAsTemplateAble}
                    isModal={true}
                    resetFormTrigger={resetFormTrigger}
                    productMaterialsForQuery={productMaterialsForQuery}
                    onSelectProductMaterial={handleSelectProductMaterial}
                    disableTemplateInputs={disableTemplateInputs}
                    nameTypeIconHideTypeSelect={nameTypeIconHideTypeSelect}
                    hideTypeSelect={hideTypeSelect}
                    instantSetParentInputs={instantSetParentInputs}
                />
            </Box>
        </ModalBottomSheet>
    );
}
