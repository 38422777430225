import React, {Dispatch, SetStateAction, useContext, useEffect, useId, useMemo, useState} from "react";
import {AppContext} from "../../../App";
import ResponsiveContainer from "../../components/screens/ResponsiveContainer";
import ScreenContent from "../../components/screens/ScreenContent";
import {tt} from "../../../core/Localization";
import {kContentWidthMedium} from "../../../styles/AppThemeProcessor";
import PaperAppbar from "../../components/paper/PaperAppbar";
import {kMobileMenuRoute} from "../mobile/MobileMenuScreen";
import AppPaper from "../../components/paper/AppPaper";
import ContentPadding from "../../components/paper/ContentPadding";
import BarChart from "../../components/timesheets/barChart/BarChart";
import {DateTime} from "luxon";
import {Box, InputAdornment, Theme, Tooltip} from "@mui/material";
import FormBuilder, {IInputsData, InputType} from "../../components/form/FormBuilder";
import SearchIcon from "../../../icons/SearchIcon";
import {
    ClientPureResponse,
    EmployeeJoinedUserResponse,
    GetClientInput,
    GetVisitJoinedOthersTimesheetsInput,
    JobEmployeeStatus,
    JobEmployeeTimesheetItemResponse,
    VisitListPureJoinedOthersTimesheetsResponsePage,
    VisitRepeating,
    VisitStatus,
} from "../../../generated/graphql/graphql";
import {AppDataContext} from "../../../AppData";
import ListShimmer from "../../components/shimmers/ListShimmer";
import {UserFullName, UserPhotoUrl, UserRoleTitle} from "../../../service/UserService";
import EmptyListText from "../../components/textComponents/EmptyListText";
import {makeStyles} from "tss-react/mui";
import AppChip from "../../components/chips/AppChip";
import Icons8Download from "../../../icons/Icons8Download";
import {usePopupState} from "material-ui-popup-state/hooks";
import {bindMenu} from "material-ui-popup-state";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Icons8XlsExport from "../../../icons/Icons8XlsExport";
import TwoLineChip from "../../components/chips/TwoLineChip";
import {useNavigate, useSearchParams} from "react-router-dom";
import {timeSheetsDetailRoute} from "./TimesheetsDetailScreen";
import {FilterJobEmployeeTimesheetItems, visitDateTimes, VisitsProcess} from "../../../service/VisitService";
import IVisitEvent from "../../../model/VisitEvent";
import {distanceDisplay, HoursMinutesDisplayRaw, PriceDisplay} from "../../../service/CompanyService";
import {ErrorToast} from "../../../service/ToastService";
import AppIconButton from "../../components/buttons/AppIconButton";
import IEventSystemNotification from "../../../model/firestore/EventSystemNotification";
import {processQueryError} from "../../../service/ErrorService";
import {
    IProcessTimesheetDataToExcelParams,
    isTravelTimesheet,
    isWorkTimesheet,
    processTimesheetDataToExcel
} from "../../../service/TimesheetService";
import AppListItemV2 from "../../components/listItems/ApplistItemV2";
import {RestApiClientContext} from "../../../core/RestApiProvider";
import ScheduleEvent from "../../../icons/ScheduleEvent";
import {
    kActionCreate,
    kPermissionsClients,
    kPermissionsTimesheetExport,
    kTopicVisits,
    kUserPreferencesTimesheetsViewMode
} from "../../../core/constants";
import ChooseClientModalBottomSheet from "../../components/modals/job/editJob/ChooseClientModalBottomSheet";
import {hoursToHoursAndMinutes, routeWithCurrentAsParam} from "../../../utils/Utils";
import PermissionValid from "../../components/permissions/PermissionValid";
import VisitStatusChip from "../../components/chips/VisitStatusChip";

export const kTimeSheetsRoute = '/timesheets';

const useStyles = makeStyles()((theme: Theme) => ({
    listItemAmountText: {
        fontWeight: 600,
        paddingRight: 8,
        fontSize: 13,
        textAlign: 'right',
    },
    timesheetSecondLine: {
        color: '#6F6F6F',
        fontSize: 13,
    },
    removeHorizontalMargins: {
        marginRight: -16,
        marginLeft: -16,
        marginBottom: 8,
    },
    chipsContainerOuter: {
        maxWidth: '100Vw',
        overflowX: "auto",
        paddingBottom: 8,
        marginBottom: 8,
    },
    chipsContainer: {
        paddingLeft: 16,
        marginRight: 16,
        paddingTop: 16,
        display: "flex",
        'button': {
            flexShrink: 0,
            marginRight: 8,
            marginBottom: 8,
        }
    },
    formControl: {
        minWidth: 120,
        marginLeft: 16,
        marginBottom: 20,
    },
    listItemChipsContainer: {
        display: "flex",
        flexWrap: "wrap",
        paddingLeft: 72,
    },
    listItemChip: {
        marginRight: 4,
        marginBottom: 4,
    },
    filtersContainer: {
        paddingLeft: 16,
        paddingRight: 16,
        display: "flex",
        flexWrap: "wrap",
        '.MuiGrid-root': {
            width: "auto",
            marginRight: "auto",
        }
    }
}));

export default function TimeSheetsScreen() {
    const appContext = useContext(AppContext);
    const {setTitle} = appContext;

    const restApiClientContext = useContext(RestApiClientContext);
    const {subscribe, restApiGet} = restApiClientContext;

    const appDataContext = useContext(AppDataContext);
    const {companyId, currency, language, userPreferences, setStorage} = appDataContext;

    const [searchParams, setSearchParams] = useSearchParams();
    const today = DateTime.now();

    const [year, setYear] = useState(searchParams.get('year') || today.year.toString());
    const [clientId, setClientId] = useState<number | undefined>(searchParams.get('clientId') ? parseInt(searchParams.get('clientId')!) : undefined);

    const [activeItem, setActiveItem] = useState<number>(searchParams.get('activeItem') ? parseInt(searchParams.get('activeItem')!) : today.month - 1);
    const [yearValues, setYearValues] = useState<Array<number>>([]);
    const [selectedStatus, setSelectedStatus] = useState<JobEmployeeStatus | null>(searchParams.get('timesheets-selectedStatus') ? searchParams.get('timesheets-selectedStatus')! as JobEmployeeStatus : null);

    const yearDate = DateTime.fromObject({year: parseInt(year)});

    const [loading, setLoading] = useState<boolean>(false);
    const [theData, setTheData] = useState<VisitListPureJoinedOthersTimesheetsResponsePage | NullOrUndefined>();
    useEffect(() => {
        const subscription = subscribe(
            kTopicVisits,
            {
                uri: '/job/visit/search-list-pure-joined-others-timesheets',
                params: {
                    companyId: companyId!,
                    fromDate: yearDate.startOf("year").toMillis(),
                    toDate: yearDate.endOf("year").toMillis(),
                    includeProducts: true,
                    includeMaterials: true,
                } as GetVisitJoinedOthersTimesheetsInput,
                setLoading,
                onData: setTheData,
                onError: (error: any) => processQueryError(appDataContext, error),
            },
            (notifications: IEventSystemNotification[]) => true,
        );

        return () => {
            subscription.cancel();
        };
    }, [companyId, year]);

    useEffect(() => {
        if (theData) {
            setStorage((prev) => {
                return {
                    filesToProcess: [
                        ...prev.filesToProcess,
                        ...(theData.files || []),
                    ],
                };
            });
        }
    }, [theData]);

    const [clientLoading, setClientLoading] = useState<boolean>(false);
    const [clientData, setClientData] = useState<ClientPureResponse | NullOrUndefined>();
    useEffect(() => {
        if (clientId) {
            restApiGet({
                uri: '/client/pure',
                params: {
                    clientId: clientId,
                } as GetClientInput,
                setLoading: setClientLoading,
                onData: setClientData,
                onError: (error: any) => processQueryError(appDataContext, error),
            });
        } else {
            setClientData(null);
        }
    }, [clientId]);

    useEffect(() => {
        setTitle(tt('timesheets.screen.title'));
    }, []);

    const [inputs, setInputs] = useState<IInputsData>({
        search: {
            type: InputType.Text,
            label: '',
            placeholder: tt('timesheets.screen.searchForWorker'),
            hideLabel: true,
            inputVariant: 'standard',
            extraStyle: 'thin',
            value: searchParams.get('search') || '',
            required: true,
            isClearable: true,
            innerPrefixJSX: (
                <InputAdornment position={"start"}>
                    <SearchIcon/>
                </InputAdornment>
            ),
        },
    });

    const viewMode = searchParams.get('timesheets-viewMode') ? parseInt(searchParams.get('timesheets-viewMode')!) as ViewMode : undefined;

    const [canSetValues, setCanSetValues] = useState<boolean>(true);
    const [viewInput, setViewInput] = useState<IInputsData>({
        viewMode: {
            type: InputType.ChipSwitch,
            label: '',
            value: viewMode || ViewMode.Time,
            options: [
                {label: tt('timesheets.screen.time'), value: ViewMode.Time},
                {label: currency, value: ViewMode.Currency},
                {label: tt('timesheets.screen.distance'), value: ViewMode.Distance},
            ]
        },
    });

    useEffect(() => {
        let tempArray = [];
        const currentYear = today.year;
        const spread = 10;

        for (let i = currentYear - spread; i < currentYear + spread; i++) {
            tempArray.push(i);
        }

        setYearValues(tempArray);
    }, []);

    useEffect(() => {
        if (userPreferences.length > 0) {
            const prefsViewMode = userPreferences.find(item => item.key === kUserPreferencesTimesheetsViewMode);

            if (prefsViewMode && canSetValues) {
                setCanSetValues(false);

                setViewInput(prev => {
                    return {
                        ...prev,
                        viewMode: {
                            ...prev.viewMode,
                            value: viewMode || prefsViewMode.valueInt || ViewMode.Time,
                        },
                    };
                });
            }
        }
    }, [userPreferences]);

    useEffect(() => {
        setSearchParams(prev => {
            prev.set('year', year);
            return prev;
        });
    }, [year]);

    useEffect(() => {
        setSearchParams(prev => {
            prev.set('activeItem', activeItem.toString());
            return prev;
        });
    }, [activeItem]);

    useEffect(() => {
        if (inputs.search.value) {
            setSearchParams(prev => {
                prev.set('search', inputs.search.value);
                return prev;
            });
        } else {
            setSearchParams(prev => {
                prev.delete('search');
                return prev;
            });
        }
    }, [inputs.search.value]);

    useEffect(() => {
        setSearchParams(prev => {
            prev.set('timesheets-viewMode', viewInput.viewMode.value.toString());
            return prev;
        });
    }, [viewInput.viewMode.value]);

    useEffect(() => {
        if (clientId) {
            setSearchParams(prev => {
                prev.set('clientId', clientId.toString());
                return prev;
            });
        } else {
            setSearchParams(prev => {
                prev.delete('clientId');
                return prev;
            });
        }
    }, [clientId]);

    useEffect(() => {
        if (selectedStatus) {
            setSearchParams(prev => {
                prev.set('timesheets-selectedStatus', selectedStatus.toString());
                return prev;
            });
        } else {
            setSearchParams(prev => {
                prev.delete('timesheets-selectedStatus');
                return prev;
            });
        }
    }, [selectedStatus]);

    const [monthsData, setMonthsData] = useState<ITimesheetsMonthData[]>([]);
    useEffect(() => {
        let tempArray: Array<ITimesheetsMonthData> = [];

        if (theData) {
            const visits = VisitsProcess({
                nonRepeating: theData.nonRepeating,
                repeating: theData.repeating,
                visitRepeatDays: theData.repeatingDayData,
                from: yearDate.startOf("year"),
                to: yearDate.endOf("year"),
                multipleEventsForMultiDay: false,
            });

            for (let i = 1; i <= 12; i++) {
                const monthDate = DateTime.fromObject({year: parseInt(year), month: i});
                const startOfMonth = monthDate.startOf("month");
                const endOfMonth = monthDate.endOf("month");

                let monthEmployees: Record<number, ITimesheetsMonthEmployee> = {};
                let total = 0;
                let chartInnerValue = 0;
                let done = 0;
                let inProgress = 0;
                let scheduled = 0;
                let travelling = 0;
                let employeeHours: Record<string, number> = {};
                let totalHours = 0;
                let doneHours = 0;
                let inProgressHours = 0;
                let onWayHours = 0;
                let scheduledHours = 0;
                let totalDistance = 0;
                let doneDistance = 0;
                let inProgressDistance = 0;
                let onWayDistance = 0;
                let scheduledDistance = 0;
                let perEmployeePerVisits: ITimesheetsMonthPerEmployeePerVisit[] = [];

                const monthVisits = visits.filter((visit) => {
                    const visitStatus = visit.visitRepeatDay?.status || visit.status;

                    const clientFilter = !clientId || visit.clientId === clientId;

                    if (!(visitStatus !== VisitStatus.Canceled && clientFilter)) {
                        return false;
                    }

                    const dateTimes = visitDateTimes(visit, visit.visitRepeatDay, visit.repeatingDay, visit);

                    return dateTimes.start <= endOfMonth && dateTimes.end! >= startOfMonth;
                });

                if (monthVisits.length > 0) {
                    for (const monthVisitOf of monthVisits) {
                        const validEmployeeIds = monthVisitOf.visitRepeatDay?.employeeIds || monthVisitOf.employeeIds;
                        const monthJobOfTimesheetsCache: Record<number, JobEmployeeTimesheetItemResponse> = {};

                        const monthVisitOfEmployeeData = theData.jobEmployeeData.filter((data) => {
                            if (!validEmployeeIds.includes(data.employeeId)) {
                                return false;
                            }

                            if (monthVisitOf.repeatingDay && !data.repeatingDay) {
                                const repeatingDayData = theData.jobEmployeeData.find((otherData) => {
                                    return otherData.repeatingDay === monthVisitOf.repeatingDay && otherData.visitId === monthVisitOf.id && otherData.employeeId === data.employeeId;
                                });

                                if (repeatingDayData) {
                                    return false;
                                }
                            } else if (monthVisitOf.repeatingDay && data.repeatingDay) {
                                return monthVisitOf.repeatingDay === data.repeatingDay && data.visitId === monthVisitOf.id;
                            }

                            return data.visitId === monthVisitOf.id;
                        });

                        const monthJobOfTimesheets = FilterJobEmployeeTimesheetItems({
                            jobEmployeeTimesheetItems: theData.timesheets,
                            repeatingDay: monthVisitOf.repeatingDay,
                            filterByEmployeeIds: validEmployeeIds,
                            filterByVisitId: monthVisitOf.id,
                        });

                        const employeeIds = monthVisitOf.visitRepeatDay?.employeeIds || monthVisitOf.employeeIds;

                        for (const employeeIdOf of employeeIds) {
                            const employeeData = monthVisitOfEmployeeData.find((data) => data.employeeId === employeeIdOf)!;

                            if (employeeData.status !== JobEmployeeStatus.CanceledByWorker && employeeData.status !== JobEmployeeStatus.CanceledByManager) {
                                if (!monthEmployees[employeeIdOf]) {
                                    monthEmployees[employeeIdOf] = {
                                        employee: theData.employees.find(employee => employee.id === employeeIdOf)!,
                                        value: 0,
                                        hours: 0,
                                        minutes: 0,
                                        visitIds: [],
                                        filteredVisitIds: [],
                                        anyNotApproved: false,
                                        distance: 0,
                                    };
                                }

                                const theVisitId = monthVisitOf.repeating === VisitRepeating.Never ? `${monthVisitOf.id}` : `${monthVisitOf.dynamicId || monthVisitOf.id}`;

                                if (!monthEmployees[employeeIdOf].visitIds.includes(theVisitId)) {
                                    monthEmployees[employeeIdOf].visitIds.push(theVisitId);
                                }
                            }
                        }

                        for (const monthJobOfTimesheetOf of monthJobOfTimesheets) {
                            if (!monthJobOfTimesheetOf.approved) {
                                if (!monthEmployees[monthJobOfTimesheetOf.employeeId]) {
                                    monthEmployees[monthJobOfTimesheetOf.employeeId] = {
                                        employee: theData.employees.find(employee => employee.id === monthJobOfTimesheetOf.employeeId)!,
                                        value: 0,
                                        hours: 0,
                                        minutes: 0,
                                        visitIds: [],
                                        filteredVisitIds: [],
                                        anyNotApproved: true,
                                        distance: 0,
                                    };
                                }

                                monthEmployees[monthJobOfTimesheetOf.employeeId].anyNotApproved = true;

                                continue;
                            }

                            const existing = monthJobOfTimesheetsCache[monthJobOfTimesheetOf.id];

                            if (!existing) {
                                monthJobOfTimesheetsCache[monthJobOfTimesheetOf.id] = monthJobOfTimesheetOf;

                                const employeeData = monthVisitOfEmployeeData.find((data) => data.employeeId === monthJobOfTimesheetOf.employeeId)!;
                                let employeeValue = 0;
                                let timesheetHours = 0;
                                let timesheetDistance = 0;

                                if (employeeData.status !== JobEmployeeStatus.CanceledByWorker && employeeData.status !== JobEmployeeStatus.CanceledByManager) {
                                    total += (monthJobOfTimesheetOf.totalPrice || 0);
                                    if (selectedStatus === null) {
                                        employeeValue = (monthJobOfTimesheetOf.totalPrice || 0);
                                    }

                                    switch (employeeData.status) {
                                        case JobEmployeeStatus.Done:
                                            done += (monthJobOfTimesheetOf.totalPrice || 0);
                                            chartInnerValue += (monthJobOfTimesheetOf.totalPrice || 0);
                                            if (selectedStatus === JobEmployeeStatus.Done) {
                                                employeeValue = (monthJobOfTimesheetOf.totalPrice || 0);
                                            }

                                            doneHours += monthJobOfTimesheetOf.hours || 0;
                                            doneHours += (monthJobOfTimesheetOf.minutes || 0) / 60;

                                            doneDistance += monthJobOfTimesheetOf.distance || 0;
                                            break;
                                        case JobEmployeeStatus.InProgress:
                                            inProgress += (monthJobOfTimesheetOf.totalPrice || 0);
                                            if (selectedStatus === JobEmployeeStatus.InProgress) {
                                                employeeValue = (monthJobOfTimesheetOf.totalPrice || 0);
                                            }

                                            inProgressHours += monthJobOfTimesheetOf.hours || 0;
                                            inProgressHours += (monthJobOfTimesheetOf.minutes || 0) / 60;

                                            inProgressDistance += monthJobOfTimesheetOf.distance || 0;
                                            break;
                                        case JobEmployeeStatus.Scheduled:
                                            scheduled += (monthJobOfTimesheetOf.totalPrice || 0);
                                            if (selectedStatus === JobEmployeeStatus.Scheduled) {
                                                employeeValue = (monthJobOfTimesheetOf.totalPrice || 0);
                                            }

                                            scheduledHours += monthJobOfTimesheetOf.hours || 0;
                                            scheduledHours += (monthJobOfTimesheetOf.minutes || 0) / 60;

                                            scheduledDistance += monthJobOfTimesheetOf.distance || 0;
                                            break;
                                        case JobEmployeeStatus.Travelling:
                                            travelling += (monthJobOfTimesheetOf.totalPrice || 0);
                                            if (selectedStatus === JobEmployeeStatus.Travelling) {
                                                employeeValue = (monthJobOfTimesheetOf.totalPrice || 0);
                                            }

                                            onWayHours += monthJobOfTimesheetOf.hours || 0;
                                            onWayHours += (monthJobOfTimesheetOf.minutes || 0) / 60;

                                            onWayDistance += monthJobOfTimesheetOf.distance || 0;
                                            break;
                                    }

                                    timesheetHours = monthJobOfTimesheetOf.hours || 0;
                                    timesheetHours += (monthJobOfTimesheetOf.minutes || 0) / 60;

                                    employeeHours[monthJobOfTimesheetOf.employeeId] = (employeeHours[monthJobOfTimesheetOf.employeeId] || 0) + timesheetHours;

                                    timesheetDistance = monthJobOfTimesheetOf.distance || 0;
                                }

                                let filterByStatus = false;
                                if (!selectedStatus) {
                                    filterByStatus = true;
                                } else if (selectedStatus === JobEmployeeStatus.Done && employeeData.status === JobEmployeeStatus.Done) {
                                    filterByStatus = true;
                                } else if (selectedStatus === JobEmployeeStatus.InProgress && employeeData.status === JobEmployeeStatus.InProgress) {
                                    filterByStatus = true;
                                } else if (selectedStatus === JobEmployeeStatus.Scheduled && employeeData.status === JobEmployeeStatus.Scheduled) {
                                    filterByStatus = true;
                                } else if (selectedStatus === JobEmployeeStatus.Travelling && employeeData.status === JobEmployeeStatus.Travelling) {
                                    filterByStatus = true;
                                }

                                if (filterByStatus) {
                                    if (!monthEmployees[monthJobOfTimesheetOf.employeeId]) {
                                        monthEmployees[monthJobOfTimesheetOf.employeeId] = {
                                            employee: theData.employees.find(employee => employee.id === monthJobOfTimesheetOf.employeeId)!,
                                            value: 0,
                                            hours: 0,
                                            minutes: 0,
                                            visitIds: [],
                                            filteredVisitIds: [],
                                            anyNotApproved: false,
                                            distance: 0,
                                        };
                                    }

                                    const theVisitId = monthVisitOf.repeating === VisitRepeating.Never ? `${monthVisitOf.id}` : `${monthVisitOf.dynamicId || monthVisitOf.id}`;

                                    if (!monthEmployees[monthJobOfTimesheetOf.employeeId].filteredVisitIds.includes(theVisitId)) {
                                        monthEmployees[monthJobOfTimesheetOf.employeeId].filteredVisitIds.push(theVisitId);
                                    }

                                    monthEmployees[monthJobOfTimesheetOf.employeeId].value += employeeValue;

                                    monthEmployees[monthJobOfTimesheetOf.employeeId].hours += monthJobOfTimesheetOf.hours || 0;
                                    monthEmployees[monthJobOfTimesheetOf.employeeId].minutes += monthJobOfTimesheetOf.minutes || 0;

                                    if (monthEmployees[monthJobOfTimesheetOf.employeeId].minutes >= 60) {
                                        monthEmployees[monthJobOfTimesheetOf.employeeId].hours += 1;
                                        monthEmployees[monthJobOfTimesheetOf.employeeId].minutes -= 60;
                                    }

                                    monthEmployees[monthJobOfTimesheetOf.employeeId].distance += monthJobOfTimesheetOf.distance || 0;

                                    let perEmployeePerVisit = perEmployeePerVisits.find((visit) => {
                                        return visit.employeeId === monthJobOfTimesheetOf.employeeId && visit.visitId === `${monthVisitOf.dynamicId || monthVisitOf.id}`;
                                    });

                                    if (!perEmployeePerVisit) {
                                        perEmployeePerVisit = {
                                            visitId: `${monthVisitOf.dynamicId || monthVisitOf.id}`,
                                            employeeId: monthJobOfTimesheetOf.employeeId,
                                            value: 0,
                                            work: 0,
                                            travel: 0,
                                            hours: 0,
                                            distance: 0,
                                        };

                                        perEmployeePerVisits.push(perEmployeePerVisit);
                                    }

                                    perEmployeePerVisit.value += employeeValue;
                                    if (isWorkTimesheet(monthJobOfTimesheetOf.paymentType)) {
                                        perEmployeePerVisit.work += employeeValue;
                                    } else if (isTravelTimesheet(monthJobOfTimesheetOf.paymentType)) {
                                        perEmployeePerVisit.travel += employeeValue;
                                    } else {
                                        console.error('Unknown payment type', monthJobOfTimesheetOf.paymentType);
                                    }
                                    perEmployeePerVisit.hours += timesheetHours;
                                    totalHours += timesheetHours;
                                    perEmployeePerVisit.distance += timesheetDistance;
                                    totalDistance += timesheetDistance;
                                }
                            }
                        }
                    }
                }

                tempArray.push({
                    date: monthDate,
                    monthVisits: monthVisits,
                    monthEmployees,
                    value: total,
                    innerValue: chartInnerValue,
                    done,
                    inProgress,
                    scheduled,
                    travelling,
                    employeeHours,
                    perEmployeePerVisits: perEmployeePerVisits,
                    totalHours: totalHours,
                    doneHours: doneHours,
                    onWayHours: onWayHours,
                    inProgressHours: inProgressHours,
                    scheduledHours: scheduledHours,
                    totalDistance,
                    doneDistance,
                    onWayDistance,
                    inProgressDistance,
                    scheduledDistance,
                });
            }
        }

        setMonthsData(tempArray);
    }, [theData, year, selectedStatus, clientId]);

    /**
     * Export current data to excel.
     */
    const exportXLS = () => {
        const params: IProcessTimesheetDataToExcelParams = {
            year: parseInt(year),
            month: activeItem + 1,
            monthsData: monthsData[activeItem],
            jobEmployeeData: theData?.jobEmployeeData,
            currency,
            clients: theData?.clients,
            locations: theData?.locations,
            places: theData?.places,
            language: language,
            jobs: theData?.jobs,
            materials: theData?.materials || [],
            products: theData?.products || [],
        };

        try {
            processTimesheetDataToExcel(params);
        } catch (error) {
            console.error(error);
            ErrorToast(tt('common.export.error'));
        }
    };

    function bodyJSX(isMobile?: boolean) {
        return (
            <Body
                loading={loading}
                data={theData}
                year={year}
                setYear={setYear}
                yearValues={yearValues}
                activeItem={activeItem}
                setActiveItem={setActiveItem}
                selectedStatus={selectedStatus}
                setSelectedStatus={setSelectedStatus}
                search={inputs.search.value.toLowerCase()}
                inputs={inputs}
                setInputs={setInputs}
                isMobile={isMobile}
                monthsData={monthsData}
                exportXLS={exportXLS}
                clientId={clientId}
                setClientId={setClientId}
                viewInput={viewInput}
                setViewInput={setViewInput}
                clientData={clientData}
            />
        );
    }

    return (
        <ResponsiveContainer
            smallPhoneScreen={bodyJSX(true)}
            largePhoneScreen={bodyJSX(true)}
            tabletScreen={bodyJSX()}
            smallDesktopScreen={bodyJSX()}
            largeDesktopScreen={bodyJSX()}
            extraLargeDesktopScreen={bodyJSX()}
        />
    );
}

export interface ITimesheetsMonthEmployee {
    employee: EmployeeJoinedUserResponse;
    value: number;
    hours: number;
    minutes: number;
    visitIds: string[];
    filteredVisitIds: string[];
    anyNotApproved: boolean;
    distance: number;
}

export interface ITimesheetsMonthPerEmployeePerVisit {
    visitId: string;
    employeeId: number;
    value: number;
    work: number;
    travel: number;
    hours: number;
    distance: number;
}

export interface ITimesheetsMonthData {
    date: DateTime;
    monthVisits: IVisitEvent[];
    monthEmployees: Record<number, ITimesheetsMonthEmployee>;
    totalHours: number;
    doneHours: number;
    inProgressHours: number;
    onWayHours: number;
    scheduledHours: number;
    value: number;
    innerValue: number;
    done: number;
    inProgress: number;
    scheduled: number;
    travelling: number;
    totalDistance: number;
    doneDistance: number;
    inProgressDistance: number;
    onWayDistance: number;
    scheduledDistance: number;
    employeeHours: Record<string, number>;
    perEmployeePerVisits: ITimesheetsMonthPerEmployeePerVisit[];
}

interface IBodyProps {
    loading: boolean;
    data: VisitListPureJoinedOthersTimesheetsResponsePage | NullOrUndefined;
    year: string;
    setYear: React.Dispatch<React.SetStateAction<string>>;
    clientId?: number;
    setClientId: React.Dispatch<React.SetStateAction<number | undefined>>;
    yearValues: number[];
    activeItem: number;
    setActiveItem: React.Dispatch<React.SetStateAction<number>>;
    selectedStatus: JobEmployeeStatus | null;
    setSelectedStatus: React.Dispatch<React.SetStateAction<JobEmployeeStatus | null>>;
    search: string;
    inputs: IInputsData;
    setInputs: Dispatch<SetStateAction<IInputsData>>;
    viewInput: IInputsData;
    setViewInput: Dispatch<SetStateAction<IInputsData>>;
    isMobile?: boolean;
    monthsData: ITimesheetsMonthData[];
    exportXLS: VoidFunction;
    clientData?: ClientPureResponse | NullOrUndefined;
}

function Body(props: IBodyProps) {
    const {
        loading,
        data,
        year,
        setYear,
        yearValues,
        activeItem,
        setActiveItem,
        selectedStatus,
        setSelectedStatus,
        search,
        inputs,
        setInputs,
        isMobile,
        monthsData,
        exportXLS,
        clientId,
        setClientId,
        viewInput,
        setViewInput,
        clientData,
    } = props;

    const viewMode = parseInt(viewInput.viewMode.value) as ViewMode;

    const selectedMonthData: ITimesheetsMonthData | undefined = monthsData[activeItem];

    const [chooseClientModal, setChooseClientModal] = useState<boolean>(false);

    const downloadMenuState = usePopupState({
        variant: 'popover',
        popupId: useId(),
    });

    const yearSelectPopupState = usePopupState({
        variant: 'popover',
        popupId: useId(),
    });

    const appDataContext = useContext(AppDataContext);
    const {currency, language, storage} = appDataContext;

    const {classes, cx} = useStyles();
    const navigate = useNavigate();

    /**
     * Navigate to detail with parameters for return back.
     */
    const ToDetail = (id: number) => {
        navigate(routeWithCurrentAsParam(timeSheetsDetailRoute({
            employeeId: id,
            uriParams: {year, activeItem},
            clientId,
            viewMode,
            selectedStatus,
        })));
    };

    const workersJSX = useMemo(
        () => {
            return loading && !selectedMonthData ?
                <ListShimmer items={2}/> :
                selectedMonthData && Object.keys(selectedMonthData.monthEmployees).length > 0 ?
                    SortEmployeesByName(
                        Object.values(selectedMonthData.monthEmployees)
                            .filter(worker => {
                                const fullName = UserFullName(worker.employee?.name || worker.employee?.user?.name, worker.employee?.surname || worker.employee?.user?.surname);

                                return fullName.toLowerCase().includes(search);
                            })
                    )
                        .map(worker => {
                            const hoursDisplay = HoursMinutesDisplayRaw({
                                hours: worker.hours,
                                minutes: worker.minutes,
                                language,
                            });
                            const priceDisplay = PriceDisplay(worker.value, currency, language);
                            const distance = distanceDisplay(worker.distance, language);

                            return (
                                <AppListItemV2
                                    key={worker.employee.id}
                                    variant={"smaller-title"}
                                    profileImage={
                                        UserPhotoUrl(worker.employee?.user, data?.files, storage.publicUrlsForFiles)
                                    }
                                    onClick={() => ToDetail(worker.employee.id)}
                                    title={UserFullName(worker.employee?.name || worker.employee?.user?.name, worker.employee?.surname || worker.employee?.user?.surname)}
                                    description={UserRoleTitle(worker.employee.role)}
                                    belowListItemWidget={
                                        <Box className={classes.listItemChipsContainer}>
                                            <Tooltip title={tt('timesheets.employeeItem.chipTooltip.visitsCount')}>
                                                <Box>
                                                    <AppChip
                                                        className={classes.listItemChip}
                                                        chipstyle={"outlined"}
                                                        icon={<ScheduleEvent/>}
                                                        label={selectedStatus ? worker.filteredVisitIds.length : worker.visitIds.length}
                                                    />
                                                </Box>
                                            </Tooltip>

                                            {hoursDisplay ? (
                                                <Tooltip title={tt('timesheets.employeeItem.chipTooltip.time')}>
                                                    <Box>
                                                        <AppChip
                                                            className={classes.listItemChip}
                                                            chipstyle={"outlined"}
                                                            label={hoursDisplay}
                                                        />
                                                    </Box>
                                                </Tooltip>
                                            ) : null}

                                            {priceDisplay ? (
                                                <Tooltip title={tt('timesheets.employeeItem.chipTooltip.reward')}>
                                                    <Box>
                                                        <AppChip
                                                            className={classes.listItemChip}
                                                            chipstyle={"outlined"}
                                                            label={PriceDisplay(worker.value, currency, language, true)}
                                                        />
                                                    </Box>
                                                </Tooltip>
                                            ) : null}

                                            {distance ? (
                                                <Tooltip title={tt('timesheets.employeeItem.chipTooltip.distance')}>
                                                    <Box>
                                                        <AppChip
                                                            className={classes.listItemChip}
                                                            chipstyle={"outlined"}
                                                            label={distance}
                                                        />
                                                    </Box>
                                                </Tooltip>
                                            ) : null}

                                            {worker.anyNotApproved ? (
                                                <Box className={classes.listItemChip}>
                                                    <VisitStatusChip
                                                        timeSheetStatusAllApproved={false}
                                                    />
                                                </Box>
                                            ) : null}
                                        </Box>
                                    }
                                />
                            );
                        })
                    : <EmptyListText text={tt('workers.screen.emptyListMessage')}/>;
        },
        [loading, selectedMonthData, search, storage.publicUrlsForFiles, viewMode, selectedStatus]
    );

    return (
        <ScreenContent appBar={!isMobile}
                       noContentPadding={isMobile}
                       navigationDrawer={!isMobile}
                       bottomBar={isMobile}
                       centerHorizontally={true}>
            <AppPaper
                sx={{maxWidth: isMobile ? undefined : kContentWidthMedium}}>
                <PaperAppbar
                    isMobile={isMobile}
                    title={tt('common.timesheets')}
                    hideBackButton={!isMobile}
                    backRoute={isMobile ? kMobileMenuRoute : undefined}>
                    <PermissionValid
                        permission={kPermissionsTimesheetExport}
                        requiredPermissions={[kActionCreate]}
                    >
                        {isMobile
                            ?
                            <AppIconButton
                                variant={"primaryBg"}
                                key={'downloadTimesheetMobileKey'}
                                onClick={(e) => downloadMenuState.open(e)}
                            >
                                <Icons8Download/>
                            </AppIconButton> :
                            <AppChip
                                onClick={(e) => {
                                    downloadMenuState.open(e);
                                }
                                }
                                key={'downloadTimesheetKey'}
                                label={tt('common.download')}
                                icon={<Icons8Download/>}
                            />}
                    </PermissionValid>
                </PaperAppbar>

                <Box className={classes.filtersContainer}>
                    <FormBuilder inputs={viewInput} setInputs={setViewInput}/>
                    <PermissionValid
                        permission={kPermissionsClients}
                    >
                        <Box pb={1}>
                            <AppChip
                                chipstyle={'secondary'}
                                label={!clientId ? tt('common.allClients') : (clientData?.name || '...')}
                                onClick={(e) => setChooseClientModal(true)}
                            />
                        </Box>
                    </PermissionValid>
                    <Box pr={1}/>
                    <Box pb={1}>
                        <AppChip
                            chipstyle={'secondary'}
                            label={year}
                            onClick={(e) => yearSelectPopupState.open(e)}
                        />
                    </Box>
                </Box>

                <Box pb={1}/>

                <BarChart
                    showHours={viewMode === ViewMode.Time}
                    showDistance={viewMode === ViewMode.Distance}
                    data={monthsData}
                    activeItem={activeItem}
                    onChange={(value: number) => {
                        setActiveItem(value);
                    }}
                />

                <Box className={cx('styledScrollbar', classes.chipsContainerOuter)}>
                    <Box className={classes.chipsContainer}>
                        {viewMode === ViewMode.Time ? (
                            <>
                                <StatusChip
                                    status={null}
                                    selectedStatus={selectedStatus}
                                    setStatus={setSelectedStatus}
                                    text={tt('common.total')}
                                    price={hoursToHoursAndMinutes(selectedMonthData?.totalHours || 0)}
                                />

                                <StatusChip
                                    status={JobEmployeeStatus.Done}
                                    selectedStatus={selectedStatus}
                                    setStatus={setSelectedStatus}
                                    text={tt('common.done')}
                                    price={hoursToHoursAndMinutes(selectedMonthData?.doneHours || 0)}
                                />

                                <StatusChip
                                    status={JobEmployeeStatus.InProgress}
                                    selectedStatus={selectedStatus}
                                    setStatus={setSelectedStatus}
                                    text={tt('common.inProgress')}
                                    price={hoursToHoursAndMinutes(selectedMonthData?.inProgressHours || 0)}
                                />

                                <StatusChip
                                    status={JobEmployeeStatus.Travelling}
                                    selectedStatus={selectedStatus}
                                    setStatus={setSelectedStatus}
                                    text={tt('common.travelling')}
                                    price={hoursToHoursAndMinutes(selectedMonthData?.onWayHours || 0)}
                                />

                                <StatusChip
                                    status={JobEmployeeStatus.Scheduled}
                                    selectedStatus={selectedStatus}
                                    setStatus={setSelectedStatus}
                                    text={tt('common.scheduled')}
                                    price={hoursToHoursAndMinutes(selectedMonthData?.scheduledHours || 0)}
                                />
                            </>
                        ) : null}

                        {viewMode === ViewMode.Currency ? (
                            <>
                                <StatusChip
                                    status={null}
                                    selectedStatus={selectedStatus}
                                    setStatus={setSelectedStatus}
                                    text={tt('common.total')}
                                    price={PriceDisplay(selectedMonthData?.value || 0, currency, language, true) || '0'}
                                />

                                <StatusChip
                                    status={JobEmployeeStatus.Done}
                                    selectedStatus={selectedStatus}
                                    setStatus={setSelectedStatus}
                                    text={tt('common.done')}
                                    price={PriceDisplay(selectedMonthData?.done || 0, currency, language, true) || '0'}
                                />

                                <StatusChip
                                    status={JobEmployeeStatus.InProgress}
                                    selectedStatus={selectedStatus}
                                    setStatus={setSelectedStatus}
                                    text={tt('common.inProgress')}
                                    price={PriceDisplay(selectedMonthData?.inProgress || 0, currency, language, true) || '0'}
                                />

                                <StatusChip
                                    status={JobEmployeeStatus.Travelling}
                                    selectedStatus={selectedStatus}
                                    setStatus={setSelectedStatus}
                                    text={tt('common.travelling')}
                                    price={PriceDisplay(selectedMonthData?.travelling || 0, currency, language, true) || '0'}
                                />

                                <StatusChip
                                    status={JobEmployeeStatus.Scheduled}
                                    selectedStatus={selectedStatus}
                                    setStatus={setSelectedStatus}
                                    text={tt('common.scheduled')}
                                    price={PriceDisplay(selectedMonthData?.scheduled || 0, currency, language, true) || '0'}
                                />
                            </>
                        ) : null}

                        {viewMode === ViewMode.Distance ? (
                            <>
                                <StatusChip
                                    status={null}
                                    selectedStatus={selectedStatus}
                                    setStatus={setSelectedStatus}
                                    text={tt('common.total')}
                                    price={distanceDisplay(selectedMonthData?.totalDistance || 0, language, true)}
                                />

                                <StatusChip
                                    status={JobEmployeeStatus.Done}
                                    selectedStatus={selectedStatus}
                                    setStatus={setSelectedStatus}
                                    text={tt('common.done')}
                                    price={distanceDisplay(selectedMonthData?.doneDistance || 0, language, true)}
                                />

                                <StatusChip
                                    status={JobEmployeeStatus.InProgress}
                                    selectedStatus={selectedStatus}
                                    setStatus={setSelectedStatus}
                                    text={tt('common.inProgress')}
                                    price={distanceDisplay(selectedMonthData?.inProgressDistance || 0, language, true)}
                                />

                                <StatusChip
                                    status={JobEmployeeStatus.Travelling}
                                    selectedStatus={selectedStatus}
                                    setStatus={setSelectedStatus}
                                    text={tt('common.travelling')}
                                    price={distanceDisplay(selectedMonthData?.onWayDistance || 0, language, true)}
                                />

                                <StatusChip
                                    status={JobEmployeeStatus.Scheduled}
                                    selectedStatus={selectedStatus}
                                    setStatus={setSelectedStatus}
                                    text={tt('common.scheduled')}
                                    price={distanceDisplay(selectedMonthData?.scheduledDistance || 0, language, true)}
                                />
                            </>
                        ) : null}
                    </Box>
                </Box>

                <ContentPadding>
                    <FormBuilder inputs={inputs} setInputs={setInputs}/>
                    <Box className={classes.removeHorizontalMargins}>{workersJSX}</Box>
                </ContentPadding>
            </AppPaper>

            <Menu {...bindMenu(downloadMenuState)}>
                <PermissionValid
                    permission={kPermissionsTimesheetExport}
                    requiredPermissions={[kActionCreate]}
                >
                    <MenuItem
                        onClick={() => {
                            downloadMenuState.close();

                            exportXLS();
                        }
                        }>
                        <Icons8XlsExport/>
                        {tt('timesheets.screen.downloadMenu.excel')}
                    </MenuItem>
                </PermissionValid>
            </Menu>

            <ChooseClientModalBottomSheet
                open={chooseClientModal}
                setOpen={setChooseClientModal}
                showAllClientsOption={true}
                onSave={(id: number) => {
                    setClientId(id);
                }}
                existingChosenClientId={clientId || undefined}
            />

            <Menu {...bindMenu(yearSelectPopupState)}>
                {yearValues.map(value => <MenuItem
                    key={value}
                    onClick={() => {
                        yearSelectPopupState.close();
                        setYear(value.toString())
                    }}
                >
                    {value}
                </MenuItem>)}
            </Menu>
        </ScreenContent>
    );
}

export enum ViewMode {
    None,
    Time,
    Currency,
    Distance,
}

interface IStatusChipProps {
    status: JobEmployeeStatus | null;
    selectedStatus: JobEmployeeStatus | null;
    setStatus: React.Dispatch<React.SetStateAction<JobEmployeeStatus | null>>;
    text: string;
    price: string;
}

/**
 * Status selection chip component.
 */
function StatusChip(props: IStatusChipProps) {
    const {status, selectedStatus, setStatus, text, price} = props;

    return (
        <TwoLineChip
            onClick={() => setStatus(status)}
            variant={status === selectedStatus ? "primary" : "secondary"}
            text1={text}
            text2={price}
        />
    );
}

/**
 * Sort list of EmployeeJoinedUserResponse by name.
 */
function SortEmployeesByName(employees: ITimesheetsMonthEmployee[]): ITimesheetsMonthEmployee[] {
    return employees.sort((a, b) => {
        const fullNameA = UserFullName(a.employee?.name || a.employee?.user?.name, a.employee?.surname || a.employee?.user?.surname);
        const fullNameB = UserFullName(b.employee?.name || b.employee?.user?.name, b.employee?.surname || b.employee?.user?.surname);

        return fullNameA.localeCompare(fullNameB);
    });
}
