import { makeStyles } from "tss-react/mui";
import { Box, Theme, Typography } from "@mui/material";
import React from "react";
import { kAppColors } from "../../../styles/AppThemeProcessor";

export const useStyles = makeStyles()((theme: Theme) => ({
    columnItem: {
        flexShrink: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        paddingRight: 16,
        paddingLeft: 16,
        paddingTop: 8,
        paddingBottom: 8,
        marginBottom: 4,
    },
    columnItemInner: {
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
    icon: {
        lineHeight: 1,
        paddingRight: 8,
        marginTop: -3,
    },
    columnItemTitle: {
        fontSize: 12,
        fontWeight: 700,
        textTransform: "uppercase",
        color: kAppColors.text.secondary(theme.palette.mode === "dark"),
        marginBottom: 8,
        textAlign: "center",
        "span": {
            verticalAlign: "middle"
        }
    },
    description: {
        fontSize: 20,
        fontWeight: 500,
        lineHeight: 1,
    },
    descriptionGreen: {
        color: kAppColors.green.profit,
    },
    descriptionRed: {
        color: kAppColors.red.loss,
    },
    descriptionBold: {
        fontWeight: 600,
    },
    subDescription: {
        fontSize: 12,
        fontWeight: 700,
        paddingTop: 8,
        color: kAppColors.text.secondary(theme.palette.mode === "dark"),
    },
    inline: {
        display: "inline-block",
    },
    disablePadding: {
        paddingRight: 0,
        paddingLeft: 0,
    }
}));

export interface IDetailColumnProps {
    icon?: React.ReactNode;
    title: string;
    displayEmptyTitle?: boolean;
    description?: string;
    descriptionGreen?: boolean;
    descriptionRed?: boolean;
    descriptionBold?: boolean;
    subDescription?: string;
    children?: React.ReactNode;
    disablePadding?: boolean;
    marginTop?: string;
}

export default function DetailColumnItem(props: IDetailColumnProps) {
    const {
        icon,
        title,
        displayEmptyTitle,
        children,
        description,
        descriptionGreen,
        descriptionRed,
        descriptionBold,
        subDescription,
        disablePadding,
        marginTop,
    } = props;

    const { classes, cx } = useStyles();

    const columnItemClassName = cx(
        classes.columnItem,
        disablePadding ? classes.disablePadding : undefined
    );

    const descriptionClassName = cx(
        classes.description,
        descriptionGreen ? classes.descriptionGreen : undefined,
        descriptionRed ? classes.descriptionRed : undefined,
        descriptionBold ? classes.descriptionBold : undefined
    );

    return (
        <Box className={columnItemClassName} sx={{marginTop: marginTop}}>
            <Typography className={classes.columnItemTitle}>
                {icon ? <span className={cx(classes.icon, classes.inline)}>{icon}</span> : null}

                {title ? title : displayEmptyTitle ? <span className={classes.inline}>&nbsp;</span> : null}
            </Typography>

            <Box className={classes.columnItemInner}>
                {description ? <Typography className={descriptionClassName}>{description}</Typography> : null}

                {subDescription ? <Typography className={classes.subDescription}>{subDescription}</Typography> : null}

                {children}
            </Box>
        </Box>
    );
}
