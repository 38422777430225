import {usePopupState} from "material-ui-popup-state/hooks";
import AppListItem from "../../listItems/AppListItem";
import {UserFullName, UserPhotoUrl, UserRoleTitle} from "../../../../service/UserService";
import AppIconButton from "../../buttons/AppIconButton";
import {bindMenu, bindTrigger} from "material-ui-popup-state";
import MoreFilledIcon from "../../../../icons/MoreFilledIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {Box, Theme} from "@mui/material";
import {kAppColors} from "../../../../styles/AppThemeProcessor";
import {tt} from "../../../../core/Localization";
import ClockIcon from "../../../../icons/ClockIcon";
import {
    CreateJobEmployeeTimesheetItemInput,
    EmployeeJoinedUserResponse, FileResponse,
    JobOfferSeatResponse
} from "../../../../generated/graphql/graphql";
import WorkerTimesheetModalBottomSheet from "../../modals/timesheet/WorkerTimesheetModalBottomSheet";
import React, {Dispatch, SetStateAction, useContext, useId, useState} from "react";
import {AppDataContext} from "../../../../AppData";
import {GetTotalTimesheetsChipsWithHourRate} from "../../../../service/CompanyService";
import {makeStyles} from "tss-react/mui";
import CloseIcon from "../../../../icons/CloseIcon";
import NewJobWorkerConflictsSection from "./NewJobWorkerConflictsSection";
import IVisitEvent from "../../../../model/VisitEvent";
import PermissionValid from "../../permissions/PermissionValid";
import {kActionCreate, kPermissionsTimesheets} from "../../../../core/constants";
import TimesheetsTotalChips from "../../timesheets/TimesheetsTotalChips";

const useStyles = makeStyles()((theme: Theme) => ({
    timesheet: {
        textAlign: 'right',
        paddingRight: 16,
    },
    timesheetSecondline: {
        color: '#6F6F6F',
        fontSize: 14,
    },
    chipsContainer: {
        display: "flex",
        flexWrap: "wrap",
        paddingLeft: 70,
    },
}));

export interface INewJobChosenWorkerItem {
    data: EmployeeJoinedUserResponse;
    newJobExistingData: CreateJobEmployeeTimesheetItemInput[];
    onDelete: (id: number) => void;
    onUpdateNewJobExistingData?: Dispatch<SetStateAction<CreateJobEmployeeTimesheetItemInput[]>>;
    visitsForConflicts?: IVisitEvent[] | NullOrUndefined;
    visitOfferSeatsForConflicts?: JobOfferSeatResponse[] | NullOrUndefined;
    visitsNewEvents?: IVisitEvent[] | NullOrUndefined;
    files: FileResponse[] | NullOrUndefined;
    onResetConfigurationOverrideActive: VoidPromiseCallback;
}

/**
 * worker item with dropdown menu.
 **/
export default function NewJobChosenWorkerItem(props: INewJobChosenWorkerItem) {
    const {
        data,
        newJobExistingData,
        onDelete,
        onUpdateNewJobExistingData,
        visitsForConflicts,
        visitOfferSeatsForConflicts,
        visitsNewEvents,
        files,
        onResetConfigurationOverrideActive,
    } = props;

    const appDataContext = useContext(AppDataContext);
    const {currency, language, storage} = appDataContext;

    const [timesheetModal, setTimesheetModal] = useState<boolean>(false);

    const {classes} = useStyles();

    const settingsPopupState = usePopupState({
        variant: 'popover',
        popupId: useId(),
    });

    return (
        <>
            <AppListItem
                key={data.id}
                variant={"smaller-title"}
                profileImage={
                    UserPhotoUrl(data?.user, files, storage.publicUrlsForFiles)
                }
                title={UserFullName(data?.name || data?.user?.name, data?.surname || data?.user?.surname)}
                description={UserRoleTitle(data.role)}
                actionWidget={
                    <>
                        <AppIconButton
                            variant={"greyBg"}
                            placement={"right"}
                            {...bindTrigger(settingsPopupState)}
                        >
                            <MoreFilledIcon/>
                        </AppIconButton>
                    </>
                }
                belowListItemWidget={
                    <>
                        <Box className={classes.chipsContainer}>
                            <TimesheetsTotalChips
                                timesheets={newJobExistingData || []}
                                hideZeroType={true}
                            />
                        </Box>

                        <NewJobWorkerConflictsSection
                            employeeId={data.id}
                            visitsForConflicts={visitsForConflicts}
                            visitOfferSeatsForConflicts={visitOfferSeatsForConflicts}
                            visitsNewEvents={visitsNewEvents}
                        />
                    </>
                }
            />

            <Menu {...bindMenu(settingsPopupState)}>
                <PermissionValid
                    permission={kPermissionsTimesheets}
                    requiredPermissions={[kActionCreate]}
                >
                    <MenuItem key={'workerDetailTimesheetKey' + data.id} onClick={() => {
                        settingsPopupState.close();

                        setTimesheetModal(true);
                    }}>
                        <ClockIcon/>
                        {tt('newJobChosenWorker.menu.editTimesheet')}
                    </MenuItem>
                </PermissionValid>

                <MenuItem key={'workerDetailDeleteKey' + data.id} onClick={() => {
                    settingsPopupState.close();
                    onDelete(data.id);
                }}>
                    <Box sx={{color: kAppColors.red.confirmButton}}><CloseIcon/></Box>
                    {tt('common.removeFromList')}
                </MenuItem>
            </Menu>

            <WorkerTimesheetModalBottomSheet
                isNewJobCreation={true}
                variant="new"
                open={timesheetModal}
                setOpen={setTimesheetModal}
                employee={data}
                newJobExistingData={newJobExistingData}
                onUpdateNewJobExistingData={onUpdateNewJobExistingData}
                files={files}
                onResetConfigurationOverrideActive={onResetConfigurationOverrideActive}
            />
        </>
    );
}
