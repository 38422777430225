import { kActionView } from "../../../core/constants";
import { useComponentDefaultProps } from "@mantine/core";
import { AppDataContext } from "../../../AppData";
import React, { useContext, useMemo } from "react";
import { Navigate } from "react-router-dom";

export interface IPermissionValidProps {
    permission?: string;
    children: React.ReactNode;
    requiredPermissions?: string[];
    anyRequiredPermission?: boolean;
    hasSomePermissionsParams?: IPermissionSetParams[];
    redirectTo?: string;
    forceValid?: boolean;
}

const defaultProps: Partial<IPermissionValidProps> = {
    requiredPermissions: [kActionView],
};

/**
 * Component which wraps other components and provides permission validation.
 * Other component is rendered only if user has required permissions.
 */
export default function PermissionValid(props: IPermissionValidProps) {
    const { permission, children, requiredPermissions, anyRequiredPermission, hasSomePermissionsParams, redirectTo, forceValid } = useComponentDefaultProps('PermissionValid', defaultProps, props);

    const appDataContext = useContext(AppDataContext);
    const { employeePermissionsMap } = appDataContext;

    const contentJSX = useMemo(() => {
        if (forceValid || (permission && hasPermission(permission, requiredPermissions!, employeePermissionsMap))) {
            return children;
        } else if (permission && anyRequiredPermission && hasAnyPermission(permission, requiredPermissions!, employeePermissionsMap)) {
            return children;
        } else if (hasSomePermissionsParams && hasSomePermissions(hasSomePermissionsParams, employeePermissionsMap)) {
            return children;
        }

        if (redirectTo) {
            return <Navigate to={redirectTo} />;
        } else {
            return null;
        }
    }, [children, employeePermissionsMap, permission, anyRequiredPermission, hasSomePermissionsParams, redirectTo, requiredPermissions, forceValid]);

    return <>{contentJSX}</>;
}

/**
 * Check if user has required permissions.
 */
export function hasPermission(permission: string, requiredPermissions: string[], employeePermissionsMap: Record<string, string[]> | NullOrUndefined) {
    if (employeePermissionsMap) {
        const permissions = employeePermissionsMap[permission];

        if (permissions && requiredPermissions) {
            const hasAllPermissions = requiredPermissions.every((requiredPermission) => permissions.includes(requiredPermission));

            if (hasAllPermissions) {
                return true;
            }
        }
    }

    return false;
}

/**
 * Check if user has any required permission.
 */
export function hasAnyPermission(permission: string, requiredPermissions: string[], employeePermissionsMap: Record<string, string[]> | NullOrUndefined) {
    if (employeePermissionsMap) {
        const permissions = employeePermissionsMap[permission];

        if (permissions && requiredPermissions) {
            const hasAnyPermission = requiredPermissions.some((requiredPermission) => permissions.includes(requiredPermission));

            if (hasAnyPermission) {
                return true;
            }
        }
    }

    return false;
}

export interface IPermissionSetParams {
    permission: string;
    requiredPermissions: string[];
}

/**
 * Check if user has some combination of required permissions.
 */
export function hasSomePermissions(permissions: IPermissionSetParams[], employeePermissionsMap: Record<string, string[]> | NullOrUndefined) {
    if (employeePermissionsMap) {
        for (const { permission, requiredPermissions } of permissions) {
            if (hasPermission(permission, requiredPermissions, employeePermissionsMap)) {
                return true;
            }
        }
    }

    return false;
}
