import React, { useContext } from "react";
import { AppContext } from "../App";
import { Navigate } from "react-router-dom";
import CompanyProcessor, { ICompanyProcessorProps } from "./CompanyProcessor";
import PermissionValid, { hasSomePermissions, IPermissionSetParams } from "../ui/components/permissions/PermissionValid";
import { kRedirectWhenInvalidPermission } from "./Router";
import { AppDataContext } from "../AppData";

export interface IProtectedRouteProps {
    children: React.ReactNode;
    redirectTo: string;
    companyProcessorProps?: Partial<ICompanyProcessorProps>;
    permission?: string;
    requiredPermissions?: string[];
    hasSomePermissionsParams?: IPermissionSetParams[];
}

/**
 * Route element wrapper to make sure only auth users can access.
 */
export default function ProtectedRoute(props: IProtectedRouteProps) {
    const { children, redirectTo, companyProcessorProps, permission, requiredPermissions, hasSomePermissionsParams } = props;

    const appContext = useContext(AppContext);
    const { authUser } = appContext;

    const appDataContext = useContext(AppDataContext);
    const { employeePermissionsMap } = appDataContext;

    if (authUser) {
        if (permission) {
            return (
                <CompanyProcessor {...companyProcessorProps}>
                    <PermissionValid
                        permission={permission}
                        requiredPermissions={requiredPermissions}
                        redirectTo={kRedirectWhenInvalidPermission}
                    >
                        {children}
                    </PermissionValid>
                </CompanyProcessor>
            );
        } else if (hasSomePermissionsParams) {
            return (
                <CompanyProcessor {...companyProcessorProps}>
                    <PermissionValid
                        hasSomePermissionsParams={hasSomePermissionsParams}
                        redirectTo={kRedirectWhenInvalidPermission}
                    >
                        {children}
                    </PermissionValid>
                </CompanyProcessor>
            );
        } else {
            return (
                <CompanyProcessor {...companyProcessorProps}>
                    {children}
                </CompanyProcessor>
            );
        }
    } else {
        return (
            <Navigate to={redirectTo} />
        );
    }
}
