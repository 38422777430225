import { Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../App";
import { tt } from "../../../core/Localization";
import ResponsiveContainer from "../../components/screens/ResponsiveContainer";
import ScreenContent from "../../components/screens/ScreenContent";
import MobileMenuItem from "../../components/MobileMenuItem";
import ClockIcon from "../../../icons/ClockIcon";
import WorkersIcon from "../../../icons/WorkersIcon";
import UserIcon from "../../../icons/UserIcon";
import SettingsIcon from "../../../icons/SettingsIcon";
import { EmployeeRole, UserResponse } from "../../../generated/graphql/graphql";
import { kProfileRoute } from "../profile/ProfileScreen";
import { useNavigate } from "react-router-dom";
import { kSettingsRoute } from "../settings/SettingsScreen";
import { kWorkersRoute } from "../workers/WorkersScreen";
import { kTimeSheetsRoute } from "../timesheets/TimeSheetsScreen";
import PaperAppbar from "../../components/paper/PaperAppbar";
import { AppDataContext } from "../../../AppData";
import AppPaper from "../../components/paper/AppPaper";
import GreyLabel from "../../components/decorations/GreyLabel";
import { kJobFormsRoute } from "../jobForms/JobFormsScreen";
import Icons8ClipBoardList from "../../../icons/Icons8ClipBoardList";
import HammerIcon from "../../../icons/HammerIcon";
import { kJobsRoute } from "../jobs/JobsScreen";
import PermissionValid from "../../components/permissions/PermissionValid";
import {
    kActionUpdate,
    kActionView,
    kPermissionsForms,
    kPermissionsJobs,
    kPermissionsMaterials,
    kPermissionsProducts,
    kPermissionsTimesheets,
    kPermissionsWorkers,
    kTopicUsers
} from "../../../core/constants";
import { RestApiClientContext } from "../../../core/RestApiProvider";
import { processQueryError } from "../../../service/ErrorService";
import IEventSystemNotification from "../../../model/firestore/EventSystemNotification";
import { kProductMaterialsRoute } from "../productMaterials/ProductMaterialListScreen";
import MergedProductMaterialIcon from "../../../icons/MergedProductMaterialIcon";
import { displayProductMaterialListTitle } from "../../../service/ProductMaterialService";

export const kMobileMenuRoute = '/mobile-menu';


export default function MobileMenuScreen() {
    const appContext = useContext(AppContext);

    const { setTitle } = appContext;

    useEffect(() => {
        setTitle(tt('mobileMenu.screen.title'));
    }, []);

    return (
        <ResponsiveContainer
            smallPhoneScreen={<Body />}
            largePhoneScreen={<Body />}
            tabletScreen={<Body />}
            smallDesktopScreen={<Body />}
            largeDesktopScreen={<Body />}
            extraLargeDesktopScreen={<Body />}
        />
    );
}

function Body() {
    const restApiClientContext = useContext(RestApiClientContext);
    const { subscribe } = restApiClientContext;

    const appContext = useContext(AppContext);
    const { authUser } = appContext;

    const navigate = useNavigate();

    const appDataContext = useContext(AppDataContext);
    const { companyId, companyData, employeePermissionsMap } = appDataContext;

    const [data, setData] = useState<UserResponse | NullOrUndefined>();
    useEffect(() => {
        const subscription = subscribe(
            kTopicUsers,
            {
                uri: '/user',
                params: {},
                onData: setData,
                onError: (error: any) => processQueryError(appDataContext, error),
            },
            (notifications: IEventSystemNotification[]) => {
                return notifications.some((notification) => {
                    return notification.action === kActionUpdate && notification.data['uid'] === authUser?.uid;
                });
            },
        );

        return () => subscription.cancel();
    }, [authUser?.uid]);

    let userName = authUser!!.email;
    if (data && data.name && data.surname) {
        userName = `${data.name} ${data.surname}`;
    }

    const isWorker = appDataContext.companyEmployeeRole === EmployeeRole.Worker;

    const noCompaniesMode = !companyId && authUser && authUser.signInResponse && authUser.signInResponse.data && authUser.signInResponse.data.companyIds.length == 0;

    const officeItemsJSX = noCompaniesMode || isWorker || !companyData.subscriptionIsValid ?
        null : <>
            <Box pb={1.5} />
            <GreyLabel
                horizontalPadding={true}
                text={tt('common.office')}
            />
            <PermissionValid
                permission={kPermissionsJobs}
            >
                <MobileMenuItem
                    title={tt('menu.jobs')}
                    onClick={() => navigate(kJobsRoute)}
                    icon={<HammerIcon />}
                    borderBottom={true}
                />
            </PermissionValid>
            <PermissionValid
                permission={kPermissionsWorkers}
            >
                <MobileMenuItem
                    title={tt('menu.workers')}
                    onClick={() => navigate(kWorkersRoute)}
                    icon={<WorkersIcon />}
                    borderBottom={true}
                />
            </PermissionValid>
            <PermissionValid
                permission={kPermissionsTimesheets}
            >
                <MobileMenuItem
                    title={tt('menu.timesheets')}
                    onClick={() => navigate(kTimeSheetsRoute)}
                    icon={<ClockIcon />}
                    borderBottom={true}
                />
            </PermissionValid>
            <PermissionValid
                permission={kPermissionsForms}
            >
                <MobileMenuItem
                    title={tt('common.jobForms')}
                    onClick={() => navigate(kJobFormsRoute)}
                    icon={<Icons8ClipBoardList />}
                    borderBottom={true}
                />
            </PermissionValid>

            <PermissionValid
                hasSomePermissionsParams={[
                    { permission: kPermissionsProducts, requiredPermissions: [kActionView] },
                    { permission: kPermissionsMaterials, requiredPermissions: [kActionView] },
                ]}
            >
                <MobileMenuItem
                    title={displayProductMaterialListTitle(employeePermissionsMap)}
                    onClick={() => navigate(kProductMaterialsRoute)}
                    icon={<MergedProductMaterialIcon />}
                />
            </PermissionValid>
        </>;

    return (
        <ScreenContent
            bottomBar={true}
            noContentPadding={true}>
            <AppPaper>
                <PaperAppbar
                    title={tt('common.menu')}
                    hideBackButton={true}
                    isMobile={true} />
                <MobileMenuItem
                    title={userName}
                    onClick={() => navigate(kProfileRoute)}
                    icon={<UserIcon />}
                    borderBottom={true} />
                <MobileMenuItem
                    title={tt('menu.settings')}
                    onClick={() => navigate(kSettingsRoute)}
                    icon={<SettingsIcon />} />
                {officeItemsJSX}
                <Box pb={1.5} />
            </AppPaper>
        </ScreenContent>
    );
}

