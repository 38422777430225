import React, {Dispatch, SetStateAction, useContext, useEffect, useMemo, useState} from "react";
import {
    getApplyPermissionsForAllLabel, getPermissionFromIndex,
    getPermissionIndex,
    getPermissionMessage,
    getPermissionsSectionIcon,
    getPermissionTitle
} from "../../../service/PermissionsService";
import AppListItem from "../listItems/AppListItem";
import ModalBottomSheet from "../ModalBottomSheet";
import BottomSheetModalAppbar from "../modals/BottomSheetModalAppbar";
import {tt} from "../../../core/Localization";
import {Box, Divider, Theme} from "@mui/material";
import FormBuilder, {IInputsData, InputType, ValidateForm} from "../form/FormBuilder";
import AppButton from "../buttons/AppButton";
import {HideEditPermissionsModal} from "../modals/AppModals";
import {AppDataContext} from "../../../AppData";
import {makeStyles} from "tss-react/mui";
import {EmployeeRole} from "../../../generated/graphql/graphql";
import {
    kActionCreate,
    kActionDelete,
    kActionUpdate,
    kActionView,
    kPermissionsClients,
    kPermissionsClientsOnCreateVisit,
    kPermissionsCompany,
    kPermissionsCompanySubscription,
    kPermissionsForms,
    kPermissionsFormsFillElement,
    kPermissionsJobs,
    kPermissionsMaterials,
    kPermissionsMobileCompanyView, kPermissionsProductMaterialsCosts, kPermissionsProductMaterialsPrices,
    kPermissionsProducts,
    kPermissionsTimesheetApprove,
    kPermissionsTimesheetApproveAllRoles,
    kPermissionsTimesheetExport,
    kPermissionsTimesheets,
    kPermissionsVisitCanBeResponsible,
    kPermissionsVisitClientDetails,
    kPermissionsVisitClientDetailsContacts,
    kPermissionsVisitMyStatus,
    kPermissionsVisitWorkers,
    kPermissionsVisitWorkersContacts,
    kPermissionsWorkers
} from "../../../core/constants";
import {Option} from "../form/InputProps";
import {hasPermission} from "./PermissionValid";
import DeleteIcon from "../../../icons/DeleteIcon";
import Icons8EditOutlined from "../../../icons/Icons8EditOutlined";
import Icons8InvisibleIcon from "../../../icons/Icons8Invisible";
import Icons8EyeOutlinedIcon from "../../../icons/Icons8EyeOutlined";

const useStyles = makeStyles()((theme: Theme) => ({
    horizontalPadding: {
        paddingLeft: 16,
        paddingRight: 16,
    },
    paddingTop: {
        paddingTop: 20,
    }
}));

export interface IEditPermissionsModalProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    permission: string;
    employeePermissionsMap: Record<string, string[]>;
    role: EmployeeRole;
    applyForAllOfRole?: EmployeeRole;
    updateLoading?: boolean;
    updatePermissions: (params: IUpdatePermissionsParams) => void;
}

export interface IUpdatePermissionsParams {
    role: EmployeeRole;
    permission: string;
    option: number | null;
    applyForAllOfRole?: EmployeeRole;
    otherPermissions?: Record<string, string[]>;
    updatedPermissions: Array<string>;
}

export default function (props: IEditPermissionsModalProps) {
    const {
        open,
        permission,
        employeePermissionsMap,
        role,
        applyForAllOfRole,
        updateLoading,
        updatePermissions,
    } = props;

    const {classes, cx} = useStyles();
    const appDataContext = useContext(AppDataContext);

    const permissions = useMemo(() => {
        return employeePermissionsMap[permission];
    }, [employeePermissionsMap, permission]);

    const [inputs, setInputs] = useState<IInputsData>({});

    const [timesheetApprovalInputs, setTimesheetApprovalInputs] = useState<IInputsData>({
        timesheetApproval: {
            className: classes.horizontalPadding,
            type: InputType.ChipSwitch,
            topLabel: tt('permissionsModal.label.approvingTimesheets'),
            label: '',
            value: 1,
            options: [
                {
                    label: tt('permissionsModal.timesheetsApprovalChipSwitch.label.denied'),
                    value: 1,
                    tooltip: tt('permissionsModal.timesheetsApprovalChipSwitch.tooltip.denied')
                },
                {
                    label: tt('permissionsModal.timesheetsApprovalChipSwitch.label.onlySubordinates'),
                    value: 2,
                    tooltip: tt('permissionsModal.timesheetsApprovalChipSwitch.tooltip.onlySubordinates')
                },
                {
                    label: tt('permissionsModal.timesheetsApprovalChipSwitch.label.all'),
                    value: 3,
                    tooltip: tt('permissionsModal.timesheetsApprovalChipSwitch.tooltip.all')
                },
            ],
        },
    });

    const [otherInputs, setOtherInputs] = useState<IInputsData>({});
    const [footerInputs, setFooterInputs] = useState<IInputsData>({});

    useEffect(() => {
        if (open) {
            setInputs(prev => {
                if (permission === kPermissionsMobileCompanyView) {
                    return {
                        permissionType: {
                            className: classes.horizontalPadding,
                            type: InputType.ChipSwitch,
                            label: '',
                            value: hasPermission(
                                kPermissionsMobileCompanyView,
                                [kActionView, kActionCreate, kActionUpdate, kActionDelete],
                                employeePermissionsMap,
                            ) ? 2 : 1,
                            options: [
                                {
                                    label: tt('permissionsModal.mobileCompanyView.label.onlyMine'),
                                    value: 1,
                                    tooltip: tt('permissionsModal.mobileCompanyView.tooltip.onlyMine')
                                },
                                {
                                    label: tt('permissionsModal.mobileCompanyView.label.all'),
                                    value: 2,
                                    tooltip: tt('permissionsModal.mobileCompanyView.tooltip.all')
                                },
                            ],
                        },
                    };
                } else if (permission === kPermissionsProducts || permission === kPermissionsMaterials) {
                    const options = [
                        {
                            label: getPermissionMessage([]),
                            value: 0,
                            icon: <Icons8InvisibleIcon/>,
                            showIconWithTooltip: true,
                        },
                        {
                            label: getPermissionMessage([kActionView]),
                            value: 1,
                            icon: <Icons8EyeOutlinedIcon/>,
                            showIconWithTooltip: true,
                        },
                        {
                            label: getPermissionMessage([kActionCreate, kActionUpdate]),
                            value: 2,
                            icon: <Icons8EditOutlined/>,
                            showIconWithTooltip: true,
                        },
                        {
                            label: getPermissionMessage([kActionDelete]),
                            value: 3,
                            icon: <DeleteIcon/>,
                            showIconWithTooltip: true,
                        },
                    ];

                    return {
                        permissionType: {
                            className: cx(classes.horizontalPadding, classes.paddingTop),
                            value: [getPermissionIndex(employeePermissionsMap[kPermissionsProducts])],
                            topLabel: tt('permissionsModal.label.products'),
                            topLabelPaddingNoTop: true,
                            label: '',
                            hideLabel: true,
                            type: InputType.FormToggleButtons,
                            singleValue: true,
                            fullWidth: false,
                            options,
                        },
                        permissionTypeMaterials: {
                            className: cx(classes.horizontalPadding, classes.paddingTop),
                            value: [getPermissionIndex(employeePermissionsMap[kPermissionsMaterials])],
                            topLabel: tt('permissionsModal.label.materials'),
                            topLabelPaddingNoTop: true,
                            label: '',
                            hideLabel: true,
                            type: InputType.FormToggleButtons,
                            singleValue: true,
                            fullWidth: false,
                            options,
                        },
                    } as IInputsData;
                } else {
                    let options: Option[];

                    switch (permission) {
                        case kPermissionsCompany:
                        case kPermissionsJobs:
                            options = [
                                {
                                    label: getPermissionMessage([kActionView]),
                                    value: 1,
                                    icon: <Icons8EyeOutlinedIcon/>,
                                    showIconWithTooltip: true,
                                },
                                {
                                    label: getPermissionMessage([kActionCreate, kActionUpdate]),
                                    value: 2,
                                    icon: <Icons8EditOutlined/>,
                                    showIconWithTooltip: true,
                                },
                                {
                                    label: getPermissionMessage([kActionDelete]),
                                    value: 3,
                                    icon: <DeleteIcon/>,
                                    showIconWithTooltip: true,
                                },
                            ];
                            break;
                        default:
                            options = [
                                {
                                    label: getPermissionMessage([]),
                                    value: 0,
                                    icon: <Icons8InvisibleIcon/>,
                                    showIconWithTooltip: true,
                                },
                                {
                                    label: getPermissionMessage([kActionView]),
                                    value: 1,
                                    icon: <Icons8EyeOutlinedIcon/>,
                                    showIconWithTooltip: true,
                                },
                                {
                                    label: getPermissionMessage([kActionCreate, kActionUpdate]),
                                    value: 2,
                                    icon: <Icons8EditOutlined/>,
                                    showIconWithTooltip: true,
                                },
                                {
                                    label: getPermissionMessage([kActionDelete]),
                                    value: 3,
                                    icon: <DeleteIcon/>,
                                    showIconWithTooltip: true,
                                },
                            ];
                            break;
                    }

                    return {
                        permissionType: {
                            className: cx(classes.horizontalPadding, classes.paddingTop),
                            testId: 'switchButtonButtonGroupPermissionTypeTestId',
                            value: [getPermissionIndex(permissions)],
                            label: '',
                            hideLabel: true,
                            type: InputType.FormToggleButtons,
                            singleValue: true,
                            fullWidth: false,
                            options,
                        },
                    };
                }
            });

            if (permission === kPermissionsTimesheets) {
                setTimesheetApprovalInputs(prev => {
                    let value = 1;
                    if (hasPermission(
                        kPermissionsTimesheetApprove,
                        [kActionUpdate],
                        employeePermissionsMap,
                    )) {
                        value = 2;

                        if (hasPermission(
                            kPermissionsTimesheetApproveAllRoles,
                            [kActionUpdate],
                            employeePermissionsMap,
                        )) {
                            value = 3;
                        }
                    }

                    return {
                        ...prev,
                        timesheetApproval: {
                            ...prev.timesheetApproval,
                            value,
                        },
                    };
                });
            }

            setOtherInputs(prev => {
                let otherPermissions: IInputsData = {};

                switch (permission) {
                    case kPermissionsCompany:
                        otherPermissions = {
                            companySubscription: {
                                className: classes.horizontalPadding,
                                type: InputType.Switch,
                                switchVariant: "Android12Switch",
                                topLabel: tt('permissionsModal.label.other'),
                                label: tt('permissionsModal.label.companySubscription'),
                                value: hasPermission(
                                    kPermissionsCompanySubscription,
                                    [kActionView, kActionCreate, kActionUpdate, kActionDelete],
                                    employeePermissionsMap,
                                ),
                            },
                        };
                        break;
                    case kPermissionsJobs:
                        otherPermissions = {
                            visitCanBeResponsible: {
                                className: classes.horizontalPadding,
                                type: InputType.Switch,
                                switchVariant: "Android12Switch",
                                topLabel: tt('permissionsModal.label.other'),
                                label: tt('permissionsModal.label.visitCanBeResponsible'),
                                value: hasPermission(
                                    kPermissionsVisitCanBeResponsible,
                                    [kActionUpdate],
                                    employeePermissionsMap,
                                ),
                            },
                            visitMyStatus: {
                                className: classes.horizontalPadding,
                                type: InputType.Switch,
                                switchVariant: "Android12Switch",
                                label: tt('permissionsModal.label.visitMyStatus'),
                                value: hasPermission(
                                    kPermissionsVisitMyStatus,
                                    [kActionUpdate],
                                    employeePermissionsMap,
                                ),
                            },
                        };
                        break;
                    case kPermissionsClients:
                        otherPermissions = {
                            clientsJobVisitsClientDetails: {
                                className: classes.horizontalPadding,
                                type: InputType.Switch,
                                switchVariant: "Android12Switch",
                                topLabel: tt('permissionsModal.label.other'),
                                label: tt('permissionsModal.label.clients.jobVisits.clientDetails'),
                                value: hasPermission(
                                    kPermissionsVisitClientDetails,
                                    [kActionView],
                                    employeePermissionsMap,
                                ),
                                hidden: true,
                            },
                            clientsJobVisitsClientDetailsContacts: {
                                className: classes.horizontalPadding,
                                type: InputType.Switch,
                                switchVariant: "Android12Switch",
                                label: tt('permissionsModal.label.clients.jobVisits.clientDetailsContacts'),
                                value: hasPermission(
                                    kPermissionsVisitClientDetailsContacts,
                                    [kActionView],
                                    employeePermissionsMap,
                                ),
                                hidden: true,
                            },
                            clientsOnCreateVisit: {
                                className: classes.horizontalPadding,
                                type: InputType.Switch,
                                switchVariant: "Android12Switch",
                                label: tt('permissionsModal.label.clients.jobVisits.clientsOnCreateVisit'),
                                value: hasPermission(
                                    kPermissionsClientsOnCreateVisit,
                                    [kActionCreate],
                                    employeePermissionsMap,
                                ),
                                hidden: true,
                            },
                        };
                        break;
                    case kPermissionsWorkers:
                        otherPermissions = {
                            workersJobVisitsWorkers: {
                                className: classes.horizontalPadding,
                                type: InputType.Switch,
                                switchVariant: "Android12Switch",
                                topLabel: tt('permissionsModal.label.other'),
                                label: tt('permissionsModal.label.clients.jobVisits.visitWorkers'),
                                value: hasPermission(
                                    kPermissionsVisitWorkers,
                                    [kActionView],
                                    employeePermissionsMap,
                                ),
                                hidden: true,
                            },
                            workersJobVisitsWorkersContacts: {
                                className: classes.horizontalPadding,
                                type: InputType.Switch,
                                switchVariant: "Android12Switch",
                                label: tt('permissionsModal.label.clients.jobVisits.visitWorkersContacts'),
                                value: hasPermission(
                                    kPermissionsVisitWorkersContacts,
                                    [kActionView],
                                    employeePermissionsMap,
                                ),
                                hidden: true,
                            },
                        };
                        break;
                    case kPermissionsTimesheets:
                        otherPermissions = {
                            timesheetExport: {
                                className: classes.horizontalPadding,
                                type: InputType.Switch,
                                switchVariant: "Android12Switch",
                                topLabel: tt('permissionsModal.label.other'),
                                label: tt('permissionsModal.label.timesheetExport'),
                                value: hasPermission(
                                    kPermissionsTimesheetExport,
                                    [kActionCreate],
                                    employeePermissionsMap,
                                ),
                                hidden: true,
                            },
                        };
                        break;
                    case kPermissionsForms:
                        otherPermissions = {
                            formsFillElement: {
                                className: classes.horizontalPadding,
                                type: InputType.Switch,
                                switchVariant: "Android12Switch",
                                topLabel: tt('permissionsModal.label.other'),
                                label: tt('permissionsModal.label.formsFillElement'),
                                value: hasPermission(
                                    kPermissionsFormsFillElement,
                                    [kActionUpdate],
                                    employeePermissionsMap,
                                ),
                                hidden: true,
                            },
                        };
                        break;
                    case kPermissionsProducts:
                    case kPermissionsMaterials:
                        otherPermissions = {
                            productMaterialsPrices: {
                                className: classes.horizontalPadding,
                                type: InputType.Switch,
                                switchVariant: "Android12Switch",
                                topLabel: tt('permissionsModal.label.other'),
                                label: tt('permissionsModal.label.productMaterialsPrices'),
                                value: hasPermission(
                                    kPermissionsProductMaterialsPrices,
                                    [kActionView, kActionCreate, kActionUpdate, kActionDelete],
                                    employeePermissionsMap,
                                ),
                                hidden: true,
                            },
                            productMaterialsCosts: {
                                className: classes.horizontalPadding,
                                type: InputType.Switch,
                                switchVariant: "Android12Switch",
                                label: tt('permissionsModal.label.productMaterialsCosts'),
                                value: hasPermission(
                                    kPermissionsProductMaterialsCosts,
                                    [kActionView, kActionCreate, kActionUpdate, kActionDelete],
                                    employeePermissionsMap,
                                ),
                                hidden: true,
                            },
                        };
                        break;
                }

                return {
                    ...otherPermissions,
                };
            });

            setFooterInputs(prev => {
                return {
                    applyForAll: {
                        className: classes.horizontalPadding,
                        testId: 'applyPermissionForAllCheckboxTestId',
                        label: getApplyPermissionsForAllLabel(applyForAllOfRole),
                        value: false,
                        hidden: !applyForAllOfRole,
                        type: InputType.CheckBox,
                    },
                };
            });
        }
    }, [open]);

    useEffect(() => {
        if (open && inputs.permissionType) {
            if (permission === kPermissionsClients) {
                const clientsVisible = inputs.permissionType.value[0] !== 0;
                const clientDetailsVisible = otherInputs.clientsJobVisitsClientDetails.value;

                if (!clientsVisible && otherInputs.clientsJobVisitsClientDetails.hidden) {
                    setOtherInputs(prev => {
                        return {
                            ...prev,
                            clientsJobVisitsClientDetails: {
                                ...prev.clientsJobVisitsClientDetails,
                                hidden: false,
                            },
                            clientsOnCreateVisit: {
                                ...prev.clientsOnCreateVisit,
                                hidden: false,
                            },
                        };
                    });
                } else if (clientsVisible && !otherInputs.clientsJobVisitsClientDetails.hidden) {
                    setOtherInputs(prev => {
                        return {
                            ...prev,
                            clientsJobVisitsClientDetails: {
                                ...prev.clientsJobVisitsClientDetails,
                                hidden: true,
                            },
                            clientsJobVisitsClientDetailsContacts: {
                                ...prev.clientsJobVisitsClientDetailsContacts,
                                hidden: true,
                            },
                            clientsOnCreateVisit: {
                                ...prev.clientsOnCreateVisit,
                                hidden: true,
                            },
                        };
                    });
                }

                if (!clientsVisible && clientDetailsVisible && otherInputs.clientsJobVisitsClientDetailsContacts.hidden) {
                    setOtherInputs(prev => {
                        return {
                            ...prev,
                            clientsJobVisitsClientDetailsContacts: {
                                ...prev.clientsJobVisitsClientDetailsContacts,
                                hidden: false,
                            },
                        };
                    });
                } else if (!clientsVisible && !clientDetailsVisible && !otherInputs.clientsJobVisitsClientDetailsContacts.hidden) {
                    setOtherInputs(prev => {
                        return {
                            ...prev,
                            clientsJobVisitsClientDetailsContacts: {
                                ...prev.clientsJobVisitsClientDetailsContacts,
                                hidden: true,
                            },
                        };
                    });
                }
            }
        }
    }, [inputs, otherInputs]);

    useEffect(() => {
        if (open && inputs.permissionType) {
            if (permission === kPermissionsWorkers) {
                const workersVisible = inputs.permissionType.value[0] !== 0;
                const visitsWorkersVisible = otherInputs.workersJobVisitsWorkers.value;

                if (!workersVisible && otherInputs.workersJobVisitsWorkers.hidden) {
                    setOtherInputs(prev => {
                        return {
                            ...prev,
                            workersJobVisitsWorkers: {
                                ...prev.workersJobVisitsWorkers,
                                hidden: false,
                            },
                        };
                    });
                } else if (workersVisible && !otherInputs.workersJobVisitsWorkers.hidden) {
                    setOtherInputs(prev => {
                        return {
                            ...prev,
                            workersJobVisitsWorkers: {
                                ...prev.workersJobVisitsWorkers,
                                hidden: true,
                            },
                            workersJobVisitsWorkersContacts: {
                                ...prev.workersJobVisitsWorkersContacts,
                                hidden: true,
                            },
                        };
                    });
                }

                if (!workersVisible && visitsWorkersVisible && otherInputs.workersJobVisitsWorkersContacts.hidden) {
                    setOtherInputs(prev => {
                        return {
                            ...prev,
                            workersJobVisitsWorkersContacts: {
                                ...prev.workersJobVisitsWorkersContacts,
                                hidden: false,
                            },
                        };
                    });
                } else if (!workersVisible && !visitsWorkersVisible && !otherInputs.workersJobVisitsWorkersContacts.hidden) {
                    setOtherInputs(prev => {
                        return {
                            ...prev,
                            workersJobVisitsWorkersContacts: {
                                ...prev.workersJobVisitsWorkersContacts,
                                hidden: true,
                            },
                        };
                    });
                }
            }
        }
    }, [inputs, otherInputs]);

    useEffect(() => {
        if (open && inputs.permissionType) {
            if (permission === kPermissionsTimesheets) {
                const timesheetsVisible = inputs.permissionType.value[0] !== 0;

                if (!timesheetsVisible && !otherInputs.timesheetExport.hidden) {
                    setOtherInputs(prev => {
                        return {
                            ...prev,
                            timesheetExport: {
                                ...prev.timesheetExport,
                                hidden: true,
                            },
                        };
                    });
                } else if (timesheetsVisible && otherInputs.timesheetExport.hidden) {
                    setOtherInputs(prev => {
                        return {
                            ...prev,
                            timesheetExport: {
                                ...prev.timesheetExport,
                                hidden: false,
                            },
                        };
                    });
                }
            }
        }
    }, [inputs, otherInputs]);

    useEffect(() => {
        if (open && inputs.permissionType) {
            if (permission === kPermissionsForms) {
                const formsVisible = (permission === kPermissionsForms && inputs.permissionType.value[0] !== 0);

                if (!formsVisible && !otherInputs.formsFillElement.hidden) {
                    setOtherInputs(prev => {
                        return {
                            ...prev,
                            formsFillElement: {
                                ...prev.formsFillElement,
                                hidden: true,
                            },
                        };
                    });
                } else if (formsVisible && otherInputs.formsFillElement.hidden) {
                    setOtherInputs(prev => {
                        return {
                            ...prev,
                            formsFillElement: {
                                ...prev.formsFillElement,
                                hidden: false,
                            },
                        };
                    });
                }
            }
        }
    }, [inputs, otherInputs]);

    useEffect(() => {
        if (open && inputs.permissionType) {
            if (permission === kPermissionsProducts || permission === kPermissionsMaterials) {
                const productMaterialsVisible = inputs.permissionType.value[0] !== 0
                    || inputs.permissionTypeMaterials.value[0] !== 0;

                if (!productMaterialsVisible && !otherInputs.productMaterialsPrices.hidden) {
                    setOtherInputs(prev => {
                        return {
                            ...prev,
                            productMaterialsPrices: {
                                ...prev.productMaterialsPrices,
                                hidden: true,
                            },
                            productMaterialsCosts: {
                                ...prev.productMaterialsCosts,
                                hidden: true,
                            },
                        };
                    });
                } else if (productMaterialsVisible && otherInputs.productMaterialsPrices.hidden) {
                    setOtherInputs(prev => {
                        return {
                            ...prev,
                            productMaterialsPrices: {
                                ...prev.productMaterialsPrices,
                                hidden: false,
                            },
                            productMaterialsCosts: {
                                ...prev.productMaterialsCosts,
                                hidden: false,
                            },
                        };
                    });
                }
            }
        }
    }, [inputs, otherInputs]);

    /**
     * Submit form and process form data to correct params.
     */
    const onSubmit = () => {
        if (ValidateForm(inputs, setInputs)) {
            let otherPermissions: Record<string, string[]> = {};

            switch (permission) {
                case kPermissionsMobileCompanyView:
                    otherPermissions[kPermissionsMobileCompanyView] = parseInt(inputs.permissionType.value) === 2 ? [kActionView, kActionCreate, kActionUpdate, kActionDelete] : [];
                    break;
                case kPermissionsCompany:
                    otherPermissions[kPermissionsCompanySubscription] = otherInputs.companySubscription.value ? [kActionView, kActionCreate, kActionUpdate, kActionDelete] : [];
                    break;
                case kPermissionsJobs:
                    otherPermissions[kPermissionsVisitCanBeResponsible] = otherInputs.visitCanBeResponsible.value ? [kActionUpdate] : [];

                    otherPermissions[kPermissionsVisitMyStatus] = otherInputs.visitMyStatus.value ? [kActionUpdate] : [];
                    break;
                case kPermissionsClients:
                    otherPermissions[kPermissionsVisitClientDetails] = otherInputs.clientsJobVisitsClientDetails.value ? [kActionView] : [];

                    otherPermissions[kPermissionsVisitClientDetailsContacts] = otherInputs.clientsJobVisitsClientDetails.value && otherInputs.clientsJobVisitsClientDetailsContacts.value ? [kActionView] : [];

                    otherPermissions[kPermissionsClientsOnCreateVisit] = otherInputs.clientsOnCreateVisit.value ? [kActionCreate] : [];
                    break;
                case kPermissionsWorkers:
                    otherPermissions[kPermissionsVisitWorkers] = otherInputs.workersJobVisitsWorkers.value ? [kActionView] : [];

                    otherPermissions[kPermissionsVisitWorkersContacts] = otherInputs.workersJobVisitsWorkers.value && otherInputs.workersJobVisitsWorkersContacts.value ? [kActionView] : [];
                    break;
                case kPermissionsTimesheets:
                    const approve = timesheetApprovalInputs.timesheetApproval.value;
                    if (approve == 1) {
                        otherPermissions[kPermissionsTimesheetApprove] = [];
                        otherPermissions[kPermissionsTimesheetApproveAllRoles] = [];
                    } else if (approve == 2) {
                        otherPermissions[kPermissionsTimesheetApprove] = [kActionUpdate];
                        otherPermissions[kPermissionsTimesheetApproveAllRoles] = [];
                    } else if (approve == 3) {
                        otherPermissions[kPermissionsTimesheetApprove] = [kActionUpdate];
                        otherPermissions[kPermissionsTimesheetApproveAllRoles] = [kActionUpdate];
                    }

                    otherPermissions[kPermissionsTimesheetExport] = otherInputs.timesheetExport.value ? [kActionCreate] : [];
                    break;
                case kPermissionsForms:
                    otherPermissions[kPermissionsFormsFillElement] = otherInputs.formsFillElement.value ? [kActionUpdate] : [];
                    break;
                case kPermissionsProducts:
                case kPermissionsMaterials:
                    otherPermissions[kPermissionsMaterials] = getPermissionFromIndex(parseInt(inputs.permissionTypeMaterials.value[0]));
                    otherPermissions[kPermissionsProductMaterialsPrices] = otherInputs.productMaterialsPrices.value ? [kActionView, kActionCreate, kActionUpdate, kActionDelete] : [];
                    otherPermissions[kPermissionsProductMaterialsCosts] = otherInputs.productMaterialsCosts.value ? [kActionView, kActionCreate, kActionUpdate, kActionDelete] : [];
                    break;
            }

            const updatedPermissions: Array<string> = [permission, ...Object.keys(otherPermissions)];

            const option = permission !== kPermissionsMobileCompanyView ? parseInt(inputs.permissionType.value[0]) : null;

            updatePermissions({
                role,
                permission,
                option: option,
                applyForAllOfRole: footerInputs.applyForAll.value && !footerInputs.applyForAll.hidden ? applyForAllOfRole : undefined,
                otherPermissions,
                updatedPermissions,
            });
        }
    };

    return (
        <>
            <ModalBottomSheet
                blurBackdrop={true}
                hideHeader={true}
                open={open}
                setOpen={() => {
                    HideEditPermissionsModal(appDataContext);
                }}
                modalAboveModals={true}
                children={
                    <>
                        <BottomSheetModalAppbar
                            title={tt('editPermissionsBottomSheet.title')}
                            noBorderBottom={true}
                            onClose={() => HideEditPermissionsModal(appDataContext)}
                        />
                        <AppListItem
                            customAvatarInCircle={getPermissionsSectionIcon(permission)}
                            title={getPermissionTitle(permission)}
                            actionWidget={<></>
                            }
                        />
                        <Divider/>

                        <Box pb={1}/>

                        <FormBuilder
                            inputs={inputs}
                            setInputs={setInputs}
                        />

                        {permission === kPermissionsTimesheets
                            ? <FormBuilder
                                inputs={timesheetApprovalInputs}
                                setInputs={setTimesheetApprovalInputs}
                            /> : null
                        }

                        <FormBuilder
                            inputs={otherInputs}
                            setInputs={setOtherInputs}
                        />

                        <FormBuilder
                            inputs={footerInputs}
                            setInputs={setFooterInputs}
                        />

                        <Box p={2}>
                            <AppButton
                                variant={"contained"}
                                fullWidth={true}
                                onClick={onSubmit}
                                isLoading={updateLoading}
                            >
                                {tt('common.save')}
                            </AppButton>
                        </Box>
                    </>
                }
            />
        </>
    );
}
