import {useContext, useEffect, useMemo, useState} from "react";
import {CreateMaterialInput, CreateProductInput} from "../../../../generated/graphql/graphql";
import {
    combineProductMaterials,
    convertCreateProductMaterialInputs,
    displayProductMaterialListTitle
} from "../../../../service/ProductMaterialService";
import HeadlineWithButton from "../../../screenSections/detailListPreviewSection/HeadlineWithButton";
import {AppDataContext} from "../../../../AppData";
import {hasSomePermissions} from "../../permissions/PermissionValid";
import {kActionCreate, kPermissionsMaterials, kPermissionsProducts} from "../../../../core/constants";
import Icons8Plus from "../../../../icons/Icons8Plus";
import {tt} from "../../../../core/Localization";
import ProductMaterialListItem, {
    IProductMaterialListItemActionsProps
} from "../../productMaterials/ProductMaterialListItem";
import {IProductMaterialByTemplate, ProductMaterialType} from "../../../../model/ProductMaterial";
import AddProductMaterialModalBottomSheet from "../../modals/productMaterial/AddProductMaterialModalBottomSheet";
import EditProductMaterialModalBottomSheet from "../../modals/productMaterial/EditProductMaterialModalBottomSheet";
import ICreateProductMaterialInput from "../../../../model/CreateProductMaterialInput";
import {IInputsData} from "../../form/FormBuilder";
import VisitProductMaterialsTotalSection from "./VisitProductMaterialsTotalSection";

export interface INewJobProductMaterialsSectionProps {
    products: CreateProductInput[];
    setProducts: React.Dispatch<React.SetStateAction<CreateProductInput[]>>;
    materials: CreateMaterialInput[];
    setMaterials: React.Dispatch<React.SetStateAction<CreateMaterialInput[]>>;
}

/**
 * Combined Product/Materials section for multiple new created.
 */
export default function NewJobProductMaterialsSection(props: INewJobProductMaterialsSectionProps) {
    const {products, materials, setProducts, setMaterials} = props;

    const appDataContext = useContext(AppDataContext);
    const {employeePermissionsMap, language} = appDataContext;

    const [addProductMaterialModal, setAddProductMaterialModal] = useState(false);
    const [editCombined, setEditCombined] = useState<IProductMaterialByTemplate>();
    const [editCombinedModal, setEditCombinedModal] = useState(false);

    /**
     * Add item to either Products or Materials list.
     */
    const handleAdd = (params: {
        product?: CreateProductInput;
        material?: CreateMaterialInput;
    }) => {
        const {product, material} = params;

        if (product) {
            const existing = products.find((item) => {
                const itemIdentifier = `${item.price}-${item.vatRate}-${item.cost}`;
                const productIdentifier = `${product.price}-${product.vatRate}-${product.cost}`;

                return item.templateId && item.templateId === product.templateId && item.type === product.type && itemIdentifier === productIdentifier;
            });

            if (existing) {
                existing.unitCount! += product.unitCount || 0;

                setProducts([...products]);
            } else {
                setProducts([product, ...products]);
            }
        } else {
            const existing = materials.find((item) => {
                const itemIdentifier = `${item.price}-${item.vatRate}-${item.cost}`;
                const materialIdentifier = `${material!.price}-${material!.vatRate}-${material!.cost}`;

                return item.templateId && item.templateId === material!.templateId && itemIdentifier === materialIdentifier;
            });

            if (existing) {
                existing.unitCount! += material!.unitCount || 0;

                setMaterials([...materials]);
            } else {
                setMaterials([material!, ...materials]);
            }
        }

        setAddProductMaterialModal(false);
    };

    /**
     * Delete item from either Products or Materials list.
     */
    const handleDelete = (params: IProductMaterialListItemActionsProps) => {
        const {combined, createInputs} = params;

        if (combined.type === ProductMaterialType.Material) {
            const newMaterials = materials.filter((material) => {
                return !createInputs!.some((input) => {
                    return input.material === material;
                })
            });

            setMaterials(newMaterials);
        } else {
            const newProducts = products.filter((product) => {
                return !createInputs!.some((input) => {
                    return input.product === product;
                })
            });

            setProducts(newProducts);
        }
    };

    /**
     * Open modal for edit combined Product/Material.
     */
    const handleEditCombined = (combined: IProductMaterialByTemplate) => {
        setEditCombined(combined);
        setEditCombinedModal(true);
    };

    /**
     * Combined Product/Material edit modal, handle save common data for all items of modal.
     */
    const handleSaveAllItems = (data: IProductMaterialByTemplate, inputs: IInputsData) => {
        if (data.type === ProductMaterialType.Material) {
            const newMaterials = materials.map((material) => {
                if (data.inputs.some((input) => {
                    return input.material?.uuid === material.uuid;
                })) {
                    return {
                        ...material,
                        name: inputs.name.value,
                        unitName: inputs.unitName.value,
                        description: inputs.description.value,
                    };
                } else {
                    return material;
                }
            });

            setMaterials(newMaterials);
        } else {
            const newProducts = products.map((product) => {
                if (data.inputs.some((input) => {
                    return input.product?.uuid === product.uuid;
                })) {
                    return {
                        ...product,
                        name: inputs.name.value,
                        unitName: inputs.unitName.value,
                        description: inputs.description.value,
                    };
                } else {
                    return product;
                }
            });

            setProducts(newProducts);
        }
    };

    /**
     * Combined Product/Material edit modal, handle delete all items action from either list for combined open.
     */
    const handleDeleteAllItems = (data: IProductMaterialByTemplate) => {
        if (data.type === ProductMaterialType.Material) {
            const newMaterials = materials.filter((material) => {
                return !data.inputs.some((input) => {
                    return input.material?.uuid === material.uuid;
                })
            });

            setMaterials(newMaterials);
        } else {
            const newProducts = products.filter((product) => {
                return !data.inputs.some((input) => {
                    return input.product?.uuid === product.uuid;
                })
            });

            setProducts(newProducts);
        }

        setEditCombinedModal(false);
    };

    /**
     * Combined Product/Material edit modal, handle add item action to either list.
     */
    const handleSaveItem = (createInput: ICreateProductMaterialInput, isNew?: boolean) => {
        const {product, material} = createInput;

        if (isNew) {
            if (product) {
                const existing = products.find((item) => {
                    const itemIdentifier = `${item.price}-${item.vatRate}-${item.cost}`;
                    const productIdentifier = `${product.price}-${product.vatRate}-${product.cost}`;

                    return item.templateId && item.templateId === product.templateId && item.type === product.type && itemIdentifier === productIdentifier;
                });

                if (existing) {
                    existing.unitCount! += product.unitCount || 0;

                    setProducts([...products]);
                } else {
                    setProducts([product, ...products]);
                }
            } else {
                const existing = materials.find((item) => {
                    const itemIdentifier = `${item.price}-${item.vatRate}-${item.cost}`;
                    const materialIdentifier = `${material!.price}-${material!.vatRate}-${material!.cost}`;

                    return item.templateId && item.templateId === material!.templateId && itemIdentifier === materialIdentifier;
                });

                if (existing) {
                    existing.unitCount! += material!.unitCount || 0;

                    setMaterials([...materials]);
                } else {
                    setMaterials([material!, ...materials]);
                }
            }
        } else {
            if (product) {
                const newProducts = products.map((item) => {
                    if (item.uuid === product.uuid) {
                        return {
                            ...item,
                            name: product.name,
                            unitName: product.unitName,
                            description: product.description,
                            unitCount: product.unitCount,
                            price: product.price,
                            cost: product.cost,
                            vatRate: product.vatRate,
                            type: product.type,
                            templateId: product.templateId,
                            uuid: product.uuid,
                            sortOrder: 0,
                        };
                    } else {
                        return item;
                    }
                });

                setProducts(newProducts);

                if (!product.templateId) {
                    setEditCombinedModal(false);
                }
            } else {
                const newMaterials = materials.map((item) => {
                    if (item.uuid === material!.uuid) {
                        return {
                            ...item,
                            name: material!.name,
                            unitName: material!.unitName,
                            description: material!.description,
                            unitCount: material!.unitCount,
                            price: material!.price,
                            cost: material!.cost,
                            vatRate: material!.vatRate,
                            templateId: material!.templateId,
                            uuid: material!.uuid,
                            sortOrder: 0,
                        };
                    } else {
                        return item;
                    }
                });

                setMaterials(newMaterials);

                if (!material!.templateId) {
                    setEditCombinedModal(false);
                }
            }
        }
    };

    /**
     * Combined Product/Material edit modal, handle delete item action from either list.
     */
    const handleDeleteItem = (createInput: ICreateProductMaterialInput) => {
        const {product, material} = createInput;

        if (product) {
            const newProducts = products.filter((item) => {
                return item.uuid !== product.uuid;
            });

            setProducts(newProducts);
        } else {
            const newMaterials = materials.filter((item) => {
                return item.uuid !== material!.uuid;
            });

            setMaterials(newMaterials);
        }
    };

    const alreadyUsedProductTemplates = useMemo(() => {
        return products
            .filter((product) => product.templateId);
    }, [products]);
    const alreadyUsedMaterialTemplates = useMemo(() => {
        return materials
            .filter((material) => material.templateId);
    }, [materials]);

    const combined = useMemo(() => {
        const items = convertCreateProductMaterialInputs({products, materials});

        return combineProductMaterials({createInputs: items, employees: []});
    }, [products, materials]);

    useEffect(() => {
        if (editCombinedModal && editCombined) {
            const updatedCombined = combined.find((item) => {
                return (item.productMaterialId && item.productMaterialId === editCombined.productMaterialId) || (item.productMaterialUuid && item.productMaterialUuid === editCombined.productMaterialUuid);
            });

            if (updatedCombined) {
                setEditCombined(updatedCombined);
            } else {
                setEditCombined(undefined);
                setEditCombinedModal(false);
            }
        }
    }, [combined]);

    const headlineJSX = useMemo(() => {
        const canCreate = hasSomePermissions([
            {permission: kPermissionsProducts, requiredPermissions: [kActionCreate]},
            {permission: kPermissionsMaterials, requiredPermissions: [kActionCreate]},
        ], employeePermissionsMap);

        return (
            <HeadlineWithButton
                title={displayProductMaterialListTitle(employeePermissionsMap)}
                iconJSX={<Icons8Plus/>}
                buttonText={tt('newProductMaterials.section.addItem')}
                onClick={canCreate ? () => setAddProductMaterialModal(true) : undefined}
            />
        );
    }, [employeePermissionsMap, language]);

    const itemsJSX = useMemo(() => {
        if (combined.length > 0) {
            return combined.map((item, index) => {
                return (
                    <ProductMaterialListItem
                        key={index}
                        combined={item}
                        onEditCombined={handleEditCombined}
                        onDelete={handleDelete}
                    />
                );
            });
        }

        return undefined;
    }, [combined]);

    return (
        <>
            {headlineJSX}

            {itemsJSX}

            <VisitProductMaterialsTotalSection
                products={products}
                materials={materials}
            />

            <AddProductMaterialModalBottomSheet
                open={addProductMaterialModal}
                setOpen={setAddProductMaterialModal}
                onAddCreateInput={handleAdd}
                alreadyUsedProductTemplates={alreadyUsedProductTemplates}
                alreadyUsedMaterialTemplates={alreadyUsedMaterialTemplates}
            />

            <EditProductMaterialModalBottomSheet
                open={editCombinedModal}
                setOpen={setEditCombinedModal}
                data={editCombined}
                onSave={handleSaveAllItems}
                onDelete={handleDeleteAllItems}
                onSaveItem={handleSaveItem}
                onDeleteItem={handleDeleteItem}
            />
        </>
    );
}
