import React, {createContext, Dispatch, SetStateAction, useEffect, useState} from "react";
import {InitLanguage, kDefaultLanguage} from "./core/Localization";
import {InitTheme, kDefaultTheme} from "./service/ThemeService";
import {IAppModalsProps} from "./ui/components/modals/AppModals";
import {
    CompanyResponse,
    CompanySubscriptionStatusResponse,
    EmployeeJoinedUserResponse,
    EmployeeRole,
    FileResponse,
    UserPreferencesItemResponse
} from "./generated/graphql/graphql";
import IBreadcrumb from "./model/Breadcrumb";
import {currencyCodeToLabel, kAvailableCurrencies} from "./service/CompanyService";
import {changeGleapLanguage, initGleap} from "./service/GleapService";
import {useSetState} from "@mantine/hooks";
import EventSystemProcessor from "./core/EventSystemProcessor";
import INotification from "./model/firestore/Notification";
import { kLargePhoneBreakpoint } from "./styles/AppThemeProcessor";
import {v4 as uuidv4} from "uuid";

export interface IAppDataContext {
    language: string;
    setLanguage: Dispatch<SetStateAction<string>>;
    currency: string;
    setCurrency: Dispatch<SetStateAction<string>>;
    isMobile: boolean;
    setIsMobile: Dispatch<SetStateAction<boolean>>;
    theme: string;
    setTheme: Dispatch<SetStateAction<string>>;
    darkMode: boolean;
    companyId?: number;
    setCompanyId: Dispatch<SetStateAction<number | undefined>>;
    companyData: IAppDataCompanyData;
    setCompanyData: (statePartial: (Partial<IAppDataCompanyData> | ((currentState: IAppDataCompanyData) => Partial<IAppDataCompanyData>))) => void;
    company?: CompanyResponse;
    setCompany: Dispatch<SetStateAction<CompanyResponse | undefined>>;
    switchToCompanyId?: number;
    setSwitchToCompanyId: Dispatch<SetStateAction<number | undefined>>;
    switchToCompanyMessage?: string;
    setSwitchToCompanyMessage: Dispatch<SetStateAction<string | undefined>>;
    employeeId?: number;
    setEmployeeId: Dispatch<SetStateAction<number | undefined>>;
    companyEmployeeRole?: EmployeeRole;
    setCompanyEmployeeRole: Dispatch<SetStateAction<EmployeeRole | undefined>>;
    employeePermissionsMap?: Record<string, string[]>;
    setEmployeePermissionsMap: Dispatch<SetStateAction<Record<string, string[]> | undefined>>;
    appModals?: IAppModalsProps;
    setAppModals: Dispatch<SetStateAction<IAppModalsProps | undefined>>;
    breadcrumbs: IBreadcrumb[];
    setBreadcrumbs: Dispatch<SetStateAction<IBreadcrumb[]>>;
    clientsFullTextSearch: string;
    setClientsFullTextSearch: Dispatch<SetStateAction<string>>;
    companySubscriptionStatus?: CompanySubscriptionStatusResponse;
    setCompanySubscriptionStatus: Dispatch<SetStateAction<CompanySubscriptionStatusResponse | undefined>>;
    userPreferences: UserPreferencesItemResponse[];
    setUserPreferences: Dispatch<SetStateAction<UserPreferencesItemResponse[]>>;
    storage: IAppDataStorage;
    setStorage: (statePartial: (Partial<IAppDataStorage> | ((currentState: IAppDataStorage) => Partial<IAppDataStorage>))) => void;
}

export interface IAppDataStorage {
    filesToProcess: FileResponse[];
    notificationsToProcess: INotification[];
    publicUrlsForFiles: Record<string, string>;
    publicUrlsForIds: Record<number, string>;
}

//TODO refactor all company/subs data to here
export interface IAppDataCompanyData {
    subscriptionIsValid?: boolean;
    subscriptionIsTrial?: boolean;
    employee?: EmployeeJoinedUserResponse;
}

export const AppDataContext = createContext<IAppDataContext>({} as IAppDataContext);

/**
 * Reset app data state.
 * Used when User is signedOut.
 */
export function AppDataReset(appDataContext: IAppDataContext) {
    const {setCompanyId, setCompanyData, setCompany, setEmployeeId, setCompanyEmployeeRole, setEmployeePermissionsMap, setCompanySubscriptionStatus} = appDataContext;

    setCompanyId(undefined);
    setCompanyData({});
    setCompany(undefined);
    setEmployeeId(undefined);
    setCompanyEmployeeRole(undefined);
    setEmployeePermissionsMap(undefined);
    setCompanySubscriptionStatus(undefined);
}

export interface IAppDataProps {
    children?: React.ReactNode;
}

/**
 * Component which is App data state container.
 */
export default function AppData(props: IAppDataProps) {
    const {children} = props;

    const [language, setLanguage] = useState<string>(kDefaultLanguage);
    const [currency, setCurrency] = useState<string>(currencyCodeToLabel(kAvailableCurrencies[0].value));
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [isMobile, setIsMobile] = useState<boolean>(windowSize.innerWidth <= kLargePhoneBreakpoint);
    const [theme, setTheme] = useState<string>(kDefaultTheme);
    const [darkMode, setDarkMode] = useState<boolean>(false);
    const [companyId, setCompanyId] = useState<number>();
    const [companyData, setCompanyData] = useSetState<IAppDataCompanyData>({});
    const [company, setCompany] = useState<CompanyResponse>();
    const [switchToCompanyId, setSwitchToCompanyId] = useState<number>();
    const [switchToCompanyMessage, setSwitchToCompanyMessage] = useState<string>();
    const [employeeId, setEmployeeId] = useState<number>();
    const [companyEmployeeRole, setCompanyEmployeeRole] = useState<EmployeeRole>();
    const [employeePermissionsMap, setEmployeePermissionsMap] = useState<Record<string, string[]>>();
    const [appModals, setAppModals] = useState<IAppModalsProps>();
    const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>([]);
    const [clientsFullTextSearch, setClientsFullTextSearch] = useState<string>('');
    const [companySubscriptionStatus, setCompanySubscriptionStatus] = useState<CompanySubscriptionStatusResponse>();
    const [userPreferences, setUserPreferences] = useState<UserPreferencesItemResponse[]>([]);
    const [storage, setStorage] = useSetState<IAppDataStorage>({
        filesToProcess: [],
        notificationsToProcess: [],
        publicUrlsForFiles: {},
        publicUrlsForIds: {},
    });

    const appDataContext: IAppDataContext = {
        language, setLanguage,
        currency, setCurrency,
        isMobile, setIsMobile,
        theme, setTheme,
        darkMode,
        companyId, setCompanyId,
        companyData, setCompanyData,
        company, setCompany,
        switchToCompanyId, setSwitchToCompanyId,
        switchToCompanyMessage, setSwitchToCompanyMessage,
        employeeId, setEmployeeId,
        companyEmployeeRole, setCompanyEmployeeRole,
        employeePermissionsMap, setEmployeePermissionsMap,
        appModals, setAppModals,
        breadcrumbs, setBreadcrumbs,
        clientsFullTextSearch, setClientsFullTextSearch,
        companySubscriptionStatus, setCompanySubscriptionStatus,
        userPreferences, setUserPreferences,
        storage, setStorage,
    };
// console.log('TCH_d', companyId, uuidv4()); //TODO remove
    useEffect(() => {
        InitLanguage(setLanguage);

        InitTheme(setTheme);

        initGleap(appDataContext);
    }, []);

    useEffect(() => {
        const callback = () => {
            setWindowSize(getWindowSize());
        };

        window.addEventListener('resize', callback);

        return () => {
            window.removeEventListener('resize', callback);
        };
    }, []);

    useEffect(() => {
        if (windowSize.innerWidth > kLargePhoneBreakpoint && isMobile) {
            setIsMobile(false);
        } else if (windowSize.innerWidth <= kLargePhoneBreakpoint && !isMobile) {
            setIsMobile(true);
        }
    }, [windowSize]);

    useEffect(() => {
        if (theme === 'light' && darkMode) {
            setDarkMode(false);
            document.body.classList.remove('dark');
            document.body.classList.add('light');
        }
        if (theme === 'dark' && !darkMode) {
            setDarkMode(true);
            document.body.classList.remove('light');
            document.body.classList.add('dark');
        }
    }, [theme]);

    useEffect(() => {
        changeGleapLanguage(language);
    }, [language]);

    return (
        <AppDataContext.Provider value={appDataContext}>
            <EventSystemProcessor>
                {children}
            </EventSystemProcessor>
        </AppDataContext.Provider>
    );
}

/**
 * Get current window size.
 */
function getWindowSize() {
    const {innerWidth, innerHeight} = window;

    return {innerWidth, innerHeight};
}

/**
 * Set new list of breadcrumbs.
 */
export function SetAppBreadcrumbs(appDataContext: IAppDataContext, breadcrumbs: IBreadcrumb[]) {
    const {setBreadcrumbs} = appDataContext;

    setBreadcrumbs(breadcrumbs);
}
