import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material";
import {kAppColors} from "./AppThemeProcessor";

export const iconUseStyles = makeStyles()((theme: Theme) => ({
    icon: {
        width: 28,
        height: 28,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    iconInline: {
        display: "inline-flex",
    },
    iconBigger: {
        width: 48,
        height: 48,
    },
    iconSmaller: {
        width: '18px !important',
        height: '18px !important',
    },
    green: {
        color: kAppColors.green.profit,
        "svg": {
            color: `${kAppColors.green.profit} !important`,
        }
    },
    red: {
        color: kAppColors.red.loss,
        "svg": {
            color: `${kAppColors.red.loss} !important`,
        }
    },
    rotate180: {
        transform: 'rotate(180deg)'
    }
}));
