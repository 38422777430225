import {FetchPolicy, IRestApiClientContext, IRestApiSubscription} from "../core/RestApiProvider";
import {
    CompanyConfigurationResponse,
    CompanyConfigurationResponsePage,
    CompanyConfigurationType, CreateCompanyConfigurationInput,
    CreateJobEmployeeTimesheetItemInput, EmployeeJoinedUserResponsePage,
    GetCompanyConfigurationInput, GetVisitConfigurationInput,
    ItemPaymentType
} from "../generated/graphql/graphql";
import {IAppDataContext} from "../AppData";
import {kCompanyConfigurationAutoJobEmployeeTimesheets, kTopicCompanyConfiguration} from "../core/constants";
import {processQueryError} from "./ErrorService";
import {DateTime} from "luxon";
import {v4 as uuidv4} from "uuid";

/**
 * Using restApi get CompanyConfigurationResponsePage.
 * Gets only Company default, not Visits related, etc.
 */
export function getCompanyConfigurationResponsePage(
    restApiClientContext: IRestApiClientContext,
    appDataContext: IAppDataContext,
    params: {
        fetchPolicy?: FetchPolicy;
        input: GetCompanyConfigurationInput;
        setLoading?: (loading: boolean) => void;
        onData: (data: CompanyConfigurationResponsePage | NullOrUndefined) => void;
    },
) {
    const {restApiGet} = restApiClientContext;
    const {fetchPolicy, input, setLoading, onData} = params;

    restApiGet({
        uri: '/company-configuration/search',
        fetchPolicy,
        params: input,
        setLoading,
        onData,
        onError: (error) => {
            processQueryError(appDataContext, error);
        },
    });
}

/**
 * Using restApi get CompanyConfigurationResponsePage.
 */
export function getCompanyConfigurationResponsePageForVisit(
    restApiClientContext: IRestApiClientContext,
    appDataContext: IAppDataContext,
    params: {
        fetchPolicy?: FetchPolicy;
        input: GetVisitConfigurationInput;
        setLoading?: (loading: boolean) => void;
        onData: (data: CompanyConfigurationResponsePage | NullOrUndefined) => void;
    },
) {
    const {restApiGet} = restApiClientContext;
    const {fetchPolicy, input, setLoading, onData} = params;

    restApiGet({
        uri: '/company-configuration/visit',
        fetchPolicy,
        params: input,
        setLoading,
        onData,
        onError: (error) => {
            processQueryError(appDataContext, error);
        },
    });
}

/**
 * Using restApi subscribe to CompanyConfigurationResponsePage.
 * Gets only Company default, not Visits related, etc.
 */
export function subscribeToCompanyConfigurationResponsePage(
    restApiClientContext: IRestApiClientContext,
    appDataContext: IAppDataContext,
    params: {
        input: GetCompanyConfigurationInput;
        setLoading?: (loading: boolean) => void;
        onData: (data: CompanyConfigurationResponsePage | NullOrUndefined) => void;
    },
): IRestApiSubscription {
    const {subscribe} = restApiClientContext;
    const {input, setLoading, onData} = params;

    return subscribe(
        kTopicCompanyConfiguration,
        {
            uri: '/company-configuration/search',
            params: input,
            setLoading,
            onData,
            onError: (error) => {
                processQueryError(appDataContext, error);
            },
        },
        () => true,
    );
}

/**
 * During new Visit creation, handle JobEmployeeTimesheets based on set Company configuration.
 * if kCompanyConfigurationAutoJobEmployeeTimesheets, then manage timesheets with max 14h.
 */
export function companyConfigurationJobEmployeeTimesheets(
    params: {
        currentEmployeeId: number;
        configurations: CompanyConfigurationResponsePage | NullOrUndefined;
        requiredEmployeeIds: number[];
        requiredEmployeesData: NullOrUndefined | EmployeeJoinedUserResponsePage;
        employeeTimesheets: CreateJobEmployeeTimesheetItemInput[];
        setEmployeeTimesheets: React.Dispatch<React.SetStateAction<CreateJobEmployeeTimesheetItemInput[]>>;
        visitStart: DateTime;
        visitEnd: DateTime | NullOrUndefined;
        visitStartTime: DateTime | NullOrUndefined;
        visitEndTime: DateTime | NullOrUndefined;
        visitConfiguration: CreateCompanyConfigurationInput | NullOrUndefined;
    },
) {
    const {
        currentEmployeeId,
        configurations,
        requiredEmployeeIds,
        requiredEmployeesData,
        employeeTimesheets,
        setEmployeeTimesheets,
        visitStart,
        visitEnd,
        visitStartTime,
        visitEndTime,
        visitConfiguration
    } = params;

    if ((visitConfiguration || configurations) && requiredEmployeeIds.length > 0 && requiredEmployeesData) {
        const theConfig = visitConfiguration || configurations!.content
            .find((config) => config.type === CompanyConfigurationType.JobEmployeeTimesheets && !config.visitId);

        if (theConfig) {
            const theValue = JSON.parse(theConfig.valueJSON);

            if (theValue[kCompanyConfigurationAutoJobEmployeeTimesheets] === true) {
                const newEmployeeTimesheets: CreateJobEmployeeTimesheetItemInput[] = employeeTimesheets
                    .filter((timesheet) => requiredEmployeeIds.includes(timesheet.employeeId));

                for (const employeeId of requiredEmployeeIds) {
                    const employee = requiredEmployeesData.content
                        .find((employee) => employee.id === employeeId);
                    let existing = newEmployeeTimesheets
                        .find((timesheet) => timesheet.employeeId === employeeId && timesheet.managedByConfiguration);

                    const canApplyRates = !existing;

                    if (!existing) {
                        existing = {
                            uuid: uuidv4(),
                            paymentType: ItemPaymentType.Hourly,
                            fixedPrice: 0,
                            hours: 0,
                            minutes: 0,
                            hourRate: 0,
                            approved: true,
                            startTime: 0,
                            endTime: 0,
                            employeeId,
                            createdByEmployeeId: currentEmployeeId,
                            note: '',
                            distance: 0,
                            distanceRate: 0,
                            managedByConfiguration: true,
                            configurationOverrideActive: false,
                        };

                        newEmployeeTimesheets.push(existing);
                    }

                    if (!existing.configurationOverrideActive) {
                        if (visitStartTime && visitEndTime) {
                            const diff = visitEndTime.diff(visitStartTime);

                            existing.hours = Math.floor(diff.as('hours'));
                            existing.minutes = Math.floor(diff.as('minutes')) % 60;
                        } else if (visitStart && visitEnd) {
                            const diff = visitEnd.diff(visitStart);

                            existing.hours = Math.floor(diff.as('hours'));
                            existing.minutes = Math.floor(diff.as('minutes')) % 60;
                        } else {
                            existing.hours = 0;
                            existing.minutes = 0;
                        }

                        if (existing.hours > 14) {
                            existing.hours = 14;
                        }
                        if (existing.hours == 14) {
                            existing.minutes = 0;
                        }

                        if (canApplyRates) {
                            existing.hourRate = employee?.hourRate || 0;
                            existing.distanceRate = employee?.distanceRate || 0;
                        }
                    }
                }

                setEmployeeTimesheets(newEmployeeTimesheets);

                return;
            }
        }
    }

    const newEmployeeTimesheets: CreateJobEmployeeTimesheetItemInput[] = employeeTimesheets
        .filter((timesheet) => !timesheet.managedByConfiguration);

    setEmployeeTimesheets(newEmployeeTimesheets);
}

/**
 * Check if CompanyConfiguration is default for Company and not related to some item.
 */
export function isCompanyConfigurationDefault(
    companyConfiguration: CompanyConfigurationResponse | NullOrUndefined,
): boolean {
    if (companyConfiguration) {
        return !companyConfiguration.jobId && !companyConfiguration.visitId && !companyConfiguration.clientId;
    }

    return false;
}
