import {Box, Theme, Typography} from "@mui/material";
import AppChip from "../chips/AppChip";
import React from "react";
import {makeStyles} from "tss-react/mui";
import {kAppColors} from "../../../styles/AppThemeProcessor";


export const useStyles = makeStyles()((theme: Theme) => ({
    banner: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 16,
        marginRight: 16,
        marginLeft: 16,
        marginBottom: 8,
        borderRadius: 10,
        background: kAppColors.yellow.main,
        "@media (max-width: 767px)": {
            flexDirection: "column",
            justifyContent: "center",
        },
        'p': {
            fontWeight: 500,
            color: "black",
            marginRight: 16,
            "@media (max-width: 767px)": {
                marginRight: 0,
                marginBottom: 20,
                textAlign: "center",
            }
        }
    },
    trialContainer: {
        background: kAppColors.trial.main,
        'p': {
            color: "white",
        }
    },
    blueWithArrow: {
        backgroundColor: kAppColors.background.blue,
        'p': {
            color: "white",
        },
        '::after': {
            content: '""',
            position: "absolute",
            top: -14,
            left: 0,
            marginLeft: 78,
            borderWidth: 7,
            borderStyle: "solid",
            borderColor: `transparent transparent ${kAppColors.background.blue} transparent`,
        }
    },
}));

export interface IBannerTextAndButtonProps {
    text: string;
    onTap?: Function;
    buttonText?: string;
    loading?: boolean;
    variant?: 'trial' | 'default' | 'blueWithArrow';
}

export default function BannerTextAndButton(props: IBannerTextAndButtonProps) {
    const {
        buttonText,
        text,
        onTap,
        loading,
        variant,
    } = props;

    const {classes, cx} = useStyles();

    const containerClass = cx(
        classes.banner,
        variant === "trial" ? classes.trialContainer : null,
        variant === "blueWithArrow" ? classes.blueWithArrow : null
    );

    return (
        <Box className={containerClass}>
            <Typography>{text}</Typography>

            {onTap ? (
                <AppChip
                    isLoading={loading}
                    onClick={() => onTap()}
                    chipstyle={"tertiary"}
                    label={buttonText}
                />
            ) : null}
        </Box>
    );
}
