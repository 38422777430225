import {makeStyles} from "tss-react/mui";
import {ProductMaterialType} from "../../../model/ProductMaterial";
import {Box, Divider, Menu, MenuItem, Theme} from "@mui/material";
import {useContext, useEffect, useId, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {AppContext} from "../../../App";
import {AppDataContext} from "../../../AppData";
import {FetchPolicy, RestApiClientContext} from "../../../core/RestApiProvider";
import {
    DeleteMaterialTemplateInput,
    DeleteProductTemplateInput,
    GetMaterialInput,
    GetProductInput,
    MaterialResponse,
    ProductResponse
} from "../../../generated/graphql/graphql";
import {
    kActionDelete,
    kActionUpdate,
    kActionView,
    kPermissionsMaterials, kPermissionsProductMaterialsCosts,
    kPermissionsProductMaterialsPrices,
    kPermissionsProducts,
    kTopicMaterialTemplates,
    kTopicProductTemplates
} from "../../../core/constants";
import {processMutationError, processQueryError} from "../../../service/ErrorService";
import IEventSystemNotification from "../../../model/firestore/EventSystemNotification";
import {getBackRoute, routeWithCurrentAsParam} from "../../../utils/Utils";
import {kProductMaterialsRoute} from "./ProductMaterialListScreen";
import ScreenContent from "../../components/screens/ScreenContent";
import {kAppColors, kContentWidthMedium} from "../../../styles/AppThemeProcessor";
import AppPaper from "../../components/paper/AppPaper";
import PaperAppbar from "../../components/paper/PaperAppbar";
import {
    calculateProductMaterialProfit,
    convertProductMaterial,
    productMaterialTypeDisplay
} from "../../../service/ProductMaterialService";
import PermissionValid, {hasPermission} from "../../components/permissions/PermissionValid";
import {kDashboardRoute} from "../dashboard/DashboardScreen";
import DetailScreenShimmer from "../../components/shimmers/DetailScreenShimmer";
import AppListItem from "../../components/listItems/AppListItem";
import ProductMaterialIcon from "../../components/productMaterials/ProductMaterialIcon";
import DetailColumnItem from "../../screenSections/userStatussRow/DetailColumnItem";
import {tt} from "../../../core/Localization";
import {
    AddVatToPrice,
    GetVatAmount,
    numberDisplay,
    PriceDisplay,
    PriceWithVatDisplay
} from "../../../service/CompanyService";
import Icons8Cost from "../../../icons/Icons8Cost";
import AppTabsComponent from "../../components/AppTabsComponent";
import DetailContactRow from "../../screenSections/contactSection/DetailContactRow";
import AppIconButton from "../../components/buttons/AppIconButton";
import PencilIcon from "../../../icons/PencilIcon";
import {bindMenu, bindTrigger, usePopupState} from "material-ui-popup-state/hooks";
import MoreFilledIcon from "../../../icons/MoreFilledIcon";
import DeleteIcon from "../../../icons/DeleteIcon";
import {productMaterialEditRoute} from "./ProductMaterialEditScreen";
import {SuccessToast} from "../../../service/ToastService";
import {HideConfirmModal, SetConfirmModal, SetConfirmModalLoading} from "../../components/modals/AppModals";

export const kProductMaterialDetailRoute = '/product-materials/:productMaterialType/:productMaterialId';

/**
 * Create route with parameters.
 */
export function productMaterialDetailRoute(type: ProductMaterialType, id: string | number) {
    return kProductMaterialDetailRoute
        .replace(':productMaterialType', type)
        .replace(':productMaterialId', `${id}`);
}

const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        display: "flex",
        overflowX: "auto",
        alignItems: "start",
    },
}));

/**
 * Screen component for display of combined Product/Material detail screen.
 */
export default function ProductMaterialDetailScreen() {
    const restApiClientContext = useContext(RestApiClientContext);
    const {subscribe, restApiDelete} = restApiClientContext;

    const appContext = useContext(AppContext);
    const {setTitle} = appContext;

    const appDataContext = useContext(AppDataContext);
    const {isMobile, employeePermissionsMap, currency, language, company} = appDataContext;

    const companyHasVat = company?.hasVat || false;

    const {classes, cx} = useStyles();
    const navigate = useNavigate();
    const {productMaterialType, productMaterialId} = useParams();
    const type = productMaterialType ? productMaterialType as ProductMaterialType : undefined;

    const [screenTitle, setScreenTitle] = useState<string>();

    useEffect(() => {
        if (type === ProductMaterialType.Material) {
            if (!hasPermission(kPermissionsMaterials, [kActionView], employeePermissionsMap)) {
                navigate(kDashboardRoute);
            }
        } else {
            if (!hasPermission(kPermissionsProducts, [kActionView], employeePermissionsMap)) {
                navigate(kDashboardRoute);
            }
        }
    }, [type, employeePermissionsMap]);

    const [loading, setLoading] = useState(true);
    const [productData, setProductData] = useState<ProductResponse | NullOrUndefined>();
    const [materialData, setMaterialData] = useState<MaterialResponse | NullOrUndefined>();
    useEffect(() => {
        if (type && productMaterialId) {
            if (type === ProductMaterialType.Material) {
                setProductData(null);

                const subscription = subscribe(
                    kTopicMaterialTemplates,
                    {
                        uri: '/material',
                        params: {
                            materialId: parseInt(productMaterialId!),
                        } as GetMaterialInput,
                        setLoading,
                        onData: setMaterialData,
                        onError: (error: any) => processQueryError(appDataContext, error),
                    },
                    (notification: IEventSystemNotification[]) => {
                        const isDeleted = notification.some(n => n.action === kActionDelete && n.data.id === parseInt(productMaterialId!));

                        if (isDeleted) {
                            navigate(getBackRoute(kProductMaterialsRoute));
                        }

                        return !isDeleted;
                    },
                );

                return () => {
                    subscription.cancel();
                };
            } else {
                setMaterialData(null);

                const subscription = subscribe(
                    kTopicProductTemplates,
                    {
                        uri: '/product',
                        params: {
                            productId: parseInt(productMaterialId!),
                        } as GetProductInput,
                        setLoading,
                        onData: setProductData,
                        onError: (error) => processQueryError(appDataContext, error),
                    },
                    (notification: IEventSystemNotification[]) => {
                        const isDeleted = notification.some(n => n.action === kActionDelete && n.data.id === parseInt(productMaterialId!));

                        if (isDeleted) {
                            navigate(getBackRoute(kProductMaterialsRoute));
                        }

                        return !isDeleted;
                    },
                );

                return () => subscription.cancel();
            }
        } else {
            setLoading(false);
            setProductData(null);
            setMaterialData(null);
        }
    }, [type, productMaterialId]);

    useEffect(() => {
        const newTitle = productMaterialTypeDisplay(type);

        if (newTitle !== screenTitle) {
            setScreenTitle(newTitle);

            setTitle(newTitle);
        }
    }, [type]);

    /**
     * Delete either Product or Material to BE.
     */
    const handleDelete = () => {
        SetConfirmModal(appDataContext, {
            open: true,
            title: tt('productMaterialDetail.popup.title'),
            subtitle: tt('productMaterialDetail.popup.description'),
            cancelButtonText: tt('common.close'),
            confirmationButtonText: tt('productMaterialDetail.popup.confirm'),
            onConfirm: () => {
                try {
                    if (type === ProductMaterialType.Material) {
                        restApiDelete({
                            uri: '/material/template',
                            params: {
                                materialId: parseInt(productMaterialId!),
                            } as DeleteMaterialTemplateInput,
                            fetchPolicy: FetchPolicy.NetworkOnly,
                            setLoading: (value) => SetConfirmModalLoading(appDataContext, value),
                            onData: (data) => {
                                if (data) {
                                    SuccessToast(tt('productMaterialDetail.delete.success'));

                                    HideConfirmModal(appDataContext);
                                }
                            },
                            onError: (error: any) => processMutationError(error),
                        });
                    } else {
                        restApiDelete({
                            uri: '/product/template',
                            params: {
                                productId: parseInt(productMaterialId!),
                            } as DeleteProductTemplateInput,
                            fetchPolicy: FetchPolicy.NetworkOnly,
                            setLoading: (value) => SetConfirmModalLoading(appDataContext, value),
                            onData: (data) => {
                                if (data) {
                                    SuccessToast(tt('productMaterialDetail.delete.success'));

                                    HideConfirmModal(appDataContext);
                                }
                            },
                            onError: (error: any) => processMutationError(error),
                        });
                    }
                } catch (e) {
                    processMutationError(e);
                }
            },
            children: <></>,
        });
    };

    const theData = useMemo(() => {
        if (productData || materialData) {
            return convertProductMaterial({
                product: productData,
                material: materialData,
            });
        }

        return null;
    }, [productData, materialData]);

    const contentJSX = useMemo(() => {
        const canPrices = hasPermission(kPermissionsProductMaterialsPrices, [kActionView], employeePermissionsMap);
        const canCosts = hasPermission(kPermissionsProductMaterialsCosts, [kActionView], employeePermissionsMap);

        const profitStats = theData ? calculateProductMaterialProfit(theData) : null;
        const description = theData?.product?.description || theData?.material?.description;

        const unitName = theData?.product?.unitName || theData?.material?.unitName;
        const vatRate = theData?.product?.vatRate || theData?.material?.vatRate;
        const subTitle = unitName ? [unitName] : [];

        if (companyHasVat && vatRate) {
            subTitle.push(tt('common.vatSum').replaceAll('$price', `${numberDisplay(vatRate, language, true)!} %`));
        }

        return loading && !theData ? (
            <DetailScreenShimmer/>
        ) : (
            <>
                <AppListItem
                    title={theData!.name}
                    description={subTitle.join(' · ')}
                    customAvatarInCircle={<ProductMaterialIcon type={theData!.type}/>}
                    actionWidget={<></>}
                />

                <Box pb={1}/>

                <Divider/>

                <Box className={cx('styledScrollbar', classes.container)}>
                    {canPrices ? (
                        <DetailColumnItem
                            icon={<Icons8Cost iconInline={true} iconSmaller={true} green={true} rotate180={true}/>}
                            title={tt('productMaterialDetail.screen.price')}
                            description={PriceDisplay(theData!.price, currency, language, true)}
                            subDescription={companyHasVat ? PriceWithVatDisplay(AddVatToPrice(theData!.price, theData!.vatRate || 0), currency, language, true) : undefined}
                        />
                    ) : null}

                    {canPrices && canCosts ? (
                        <DetailColumnItem
                            title={''}
                            displayEmptyTitle={true}
                            description={'-'}
                            disablePadding={true}
                            marginTop={"-3px"}
                        />
                    ) : null}

                    {canCosts ? (
                        <DetailColumnItem
                            icon={<Icons8Cost iconInline={true} iconSmaller={true} red={true}/>}
                            title={tt('productMaterialDetail.screen.cost')}
                            description={PriceDisplay(theData!.cost, currency, language, true)}
                            subDescription={companyHasVat ? PriceWithVatDisplay(AddVatToPrice(theData!.cost, theData!.vatRate || 0), currency, language, true) : undefined}
                        />
                    ) : null}

                    {canPrices && canCosts ? (
                        <>
                            <DetailColumnItem
                                title={''}
                                displayEmptyTitle={true}
                                description={'='}
                                disablePadding={true}
                                marginTop={"-3px"}
                            />

                            <DetailColumnItem
                                title={(profitStats!.isNegative ? tt('productMaterialDetail.screen.loss') : tt('productMaterialDetail.screen.profit')).replaceAll('$percent', `${numberDisplay(profitStats!.profitPercent, language, true, true)}`)}
                                description={PriceDisplay(profitStats!.profit, currency, language, true)}
                                descriptionGreen={profitStats!.profit > 0 && !profitStats!.isNegative}
                                descriptionRed={profitStats!.isNegative}
                                descriptionBold={true}
                                /*subDescription={companyHasVat ? PriceWithVatDisplay(profitStats!.profitVat, currency, language, true, true) : undefined}*/
                            />
                        </>
                    ) : null}
                </Box>

                {description ? (
                    <>
                        <Divider/>

                        <AppTabsComponent
                            data={[{
                                label: tt('common.description'),
                                content: <Box sx={{mb: 1.25}}>
                                    <DetailContactRow
                                        title={description}
                                        disabled={!description}
                                        notFilledText={tt('common.noDescription')}
                                    />
                                </Box>
                            }]}
                        />
                    </>
                ) : null}
            </>
        );
    }, [loading, theData, currency, language, companyHasVat, employeePermissionsMap]);

    const productPopupState = usePopupState({
        variant: 'popover',
        popupId: useId(),
    });
    const materialPopupState = usePopupState({
        variant: 'popover',
        popupId: useId(),
    });

    return (
        <ScreenContent
            appBar={!isMobile}
            noContentPadding={isMobile}
            navigationDrawer={!isMobile}
            bottomBar={isMobile}
            centerHorizontally={true}
        >
            <AppPaper
                sx={{maxWidth: isMobile ? undefined : kContentWidthMedium}}
            >
                <PaperAppbar
                    backRoute={getBackRoute(kProductMaterialsRoute)}
                    isMobile={isMobile}
                    title={screenTitle || ""}
                >
                    {type === ProductMaterialType.Material && hasPermission(kPermissionsMaterials, [kActionUpdate], employeePermissionsMap) ? (
                        <AppIconButton
                            onClick={() => {
                                navigate(
                                    routeWithCurrentAsParam(
                                        productMaterialEditRoute(type, productMaterialId!)
                                    )
                                );
                            }}>
                            <PencilIcon/>
                        </AppIconButton>
                    ) : null}

                    {type !== ProductMaterialType.Material && hasPermission(kPermissionsProducts, [kActionUpdate], employeePermissionsMap) ? (
                        <AppIconButton
                            onClick={() => {
                                navigate(
                                    routeWithCurrentAsParam(
                                        productMaterialEditRoute(type!, productMaterialId!)
                                    )
                                );
                            }}>
                            <PencilIcon/>
                        </AppIconButton>
                    ) : null}

                    {type === ProductMaterialType.Material && hasPermission(kPermissionsMaterials, [kActionDelete], employeePermissionsMap) ? (
                        <AppIconButton
                            {...bindTrigger(materialPopupState)}
                        >
                            <MoreFilledIcon/>
                        </AppIconButton>
                    ) : null}

                    {type !== ProductMaterialType.Material && hasPermission(kPermissionsProducts, [kActionDelete], employeePermissionsMap) ? (
                        <AppIconButton
                            {...bindTrigger(productPopupState)}
                        >
                            <MoreFilledIcon/>
                        </AppIconButton>
                    ) : null}

                    <Menu {...bindMenu(materialPopupState)}>
                        <PermissionValid
                            permission={kPermissionsMaterials}
                            requiredPermissions={[kActionDelete]}
                        >
                            <MenuItem onClick={() => {
                                materialPopupState.close();

                                handleDelete();
                            }}>
                                <Box color={kAppColors.red.confirmButton}><DeleteIcon/></Box>
                                {tt('common.delete')}
                            </MenuItem>
                        </PermissionValid>
                    </Menu>

                    <Menu {...bindMenu(productPopupState)}>
                        <PermissionValid
                            permission={kPermissionsProducts}
                            requiredPermissions={[kActionDelete]}
                        >
                            <MenuItem onClick={() => {
                                productPopupState.close();

                                handleDelete();
                            }}>
                                <Box color={kAppColors.red.confirmButton}><DeleteIcon/></Box>
                                {tt('common.delete')}
                            </MenuItem>
                        </PermissionValid>
                    </Menu>
                </PaperAppbar>

                {contentJSX}
            </AppPaper>
        </ScreenContent>
    );
}
