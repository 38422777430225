import {Box, Divider, Theme, Typography} from "@mui/material";
import React, {useContext, useEffect, useMemo, useState} from "react";
import IProductMaterial from "../../../model/ProductMaterial";
import {EmployeeJoinedUserResponse, FileResponse} from "../../../generated/graphql/graphql";
import {DateTime} from "luxon";
import {tt} from "../../../core/Localization";
import {UserFullName} from "../../../service/UserService";
import {makeStyles} from "tss-react/mui";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import FormBuilder, {IInputsData, InputType, ValidateForm} from "../form/FormBuilder";
import ProductMaterialListItemChips from "./ProductMaterialListItemChips";
import HeadlineWithButton from "../../screenSections/detailListPreviewSection/HeadlineWithButton";
import AppIconButton from "../buttons/AppIconButton";
import DeleteIcon from "../../../icons/DeleteIcon";
import PencilIcon from "../../../icons/PencilIcon";
import AppChip from "../chips/AppChip";
import CloseIcon from "../../../icons/CloseIcon";
import {AppDataContext} from "../../../AppData";
import IAddProductMaterialToVisitInput from "../../../model/AddProductMaterialToVisitInput";
import {
    AddVatToPrice,
    GetVatAmount,
    numberDisplay,
    PriceDisplay,
    PriceWithVatDisplay
} from "../../../service/CompanyService";
import {calculateProductMaterialProfitRaw} from "../../../service/ProductMaterialService";
import ICreateProductMaterialInput from "../../../model/CreateProductMaterialInput";
import {hasPermission} from "../permissions/PermissionValid";
import {
    kActionView,
    kPermissionsProductMaterialsCosts,
    kPermissionsProductMaterialsPrices
} from "../../../core/constants";

export const useStyles = makeStyles()((theme: Theme) => ({
    headerIconsContainer: {
        display: "flex",
        alignItems: "center",
    },
    saveChip: {
        height: 30,
    },
    deleteIcon: {
        color: kAppColors.red.confirmButton,
    },
    footerText: {
        color: kAppColors.text.secondary(theme.palette.mode === "dark"),
        fontSize: 12,
        fontWeight: 600
    },
    bold: {
        fontWeight: 'bold'
    },
    green: {
        color: kAppColors.green.profit,
    },
    red: {
        color: kAppColors.red.loss,
    }
}));

export interface IEditProductMaterialSectionActionsParams {
    item?: IProductMaterial;
    input?: IAddProductMaterialToVisitInput;
    createInput?: ICreateProductMaterialInput;
}

export interface IEditProductMaterialSectionProps {
    startEditMode?: boolean;
    triggerCloseEditMode: number;
    item?: IProductMaterial;
    input?: IAddProductMaterialToVisitInput;
    createInput?: ICreateProductMaterialInput;
    createdByEmployee?: EmployeeJoinedUserResponse;
    onSave: (params: IEditProductMaterialSectionActionsParams) => void;
    onDelete: (params: IEditProductMaterialSectionActionsParams) => void;
    singleNotTemplateGroup: boolean;
    files?: FileResponse[] | NullOrUndefined;
    disableCloseEdit?: boolean;
}

/**
 * Component for combined Product/Material used as item edit section.
 */
export default function EditProductMaterialSection(props: IEditProductMaterialSectionProps) {
    const {startEditMode, triggerCloseEditMode, item, input, createInput, createdByEmployee, onSave, onDelete, singleNotTemplateGroup, files, disableCloseEdit} = props;

    const appDataContext = useContext(AppDataContext);
    const { currency, language, company, employeePermissionsMap } = appDataContext;

    const canPrices = hasPermission(kPermissionsProductMaterialsPrices, [kActionView], employeePermissionsMap);
    const canCosts = hasPermission(kPermissionsProductMaterialsCosts, [kActionView], employeePermissionsMap);

    const companyHasVat = company?.hasVat || false;

    const {classes, cx} = useStyles();

    const [editMode, setEditMode] = useState<boolean>(startEditMode || singleNotTemplateGroup || false);
    const [profitFocused, setProfitFocused] = useState<boolean>(false);
    const [inputs, setInputs] = useState<IInputsData>({
        name: {
            type: InputType.Text,
            label: tt('productMaterial.form.label.name') + '*',
            value: '',
            required: singleNotTemplateGroup,
            hidden: !singleNotTemplateGroup,
            grid: {
                sm: 7,
                xs: 12,
            },
        },
        unitCount: {
            type: InputType.Text,
            numbersOnly: true,
            inputMode: "decimal",
            label: `${tt('productMaterial.form.label.unitCount')}` + '*',
            value: '',
            required: true,
            grid: singleNotTemplateGroup ? {
                sm: 2,
                xs: 6,
            } : {
                sm: 12,
                xs: 12,
            },
        },
        unitName: {
            type: InputType.Text,
            label: tt('productMaterial.form.label.unitName'),
            placeholder: tt('productMaterial.form.placeholder.unitName'),
            value: '',
            required: false,
            hidden: !singleNotTemplateGroup,
            grid: {
                sm: 3,
                xs: 6,
            },
        },
        description: {
            type: InputType.ButtonTextField,
            label: tt('common.description'),
            toggleButtonText: tt('common.description'),
            value: '',
            minRows: 4,
            hidden: !singleNotTemplateGroup,
            showCharCounter: true,
            maxChars: 300,
        },
        price: {
            type: InputType.Text,
            numbersOnly: true,
            inputMode: "decimal",
            label: `${tt('productMaterial.form.label.price')} (${currency})`,
            value: '',
            required: false,
            hidden: !canPrices,
            grid: {
                sm: companyHasVat ? 3 : 4,
                xs: 6,
            },
        },
        vatRate: {
            type: InputType.Text,
            numbersOnly: true,
            inputMode: "decimal",
            label: `${tt('productMaterial.form.label.vatRate')}`,
            value: '',
            hidden: !companyHasVat || (!canPrices && !canCosts),
            grid: {
                sm: companyHasVat ? 3 : 4,
                xs: 6,
            },
        },
        cost: {
            type: InputType.Text,
            numbersOnly: true,
            inputMode: "decimal",
            label: `${tt('productMaterial.form.label.cost')} (${currency})`,
            value: '',
            required: false,
            hidden: !canCosts,
            grid: {
                sm: companyHasVat ? 3 : 4,
                xs: 6,
            },
        },
        profit: {
            type: InputType.Text,
            numbersOnly: true,
            inputMode: "decimal",
            label: `${tt('productMaterial.form.label.profit')} (%)`,
            value: '',
            required: false,
            hidden: !canPrices || !canCosts,
            grid: {
                sm: companyHasVat ? 3 : 4,
                xs: 6,
            },
            onFocus: () => {
                setProfitFocused(true);
            },
            onBlur: () => {
                setProfitFocused(false);
            },
        },
    });

    const thePrice = parseFloat(inputs.price.value || '0');
    const theCost = parseFloat(inputs.cost.value || '0');
    const theVatRate = parseFloat(inputs.vatRate.value || '0');
    const theProfit = parseFloat(inputs.profit.value || '0');

    useEffect(() => {
        if (companyHasVat) {
            setInputs(prev => {
                return {
                    ...prev,
                    price: {
                        ...prev.price,
                        helperText: `${PriceWithVatDisplay(AddVatToPrice(thePrice, theVatRate), currency, language, true)}`,
                    },
                    vatRate: {
                        ...prev.vatRate,
                        helperText: `${PriceWithVatDisplay(GetVatAmount(thePrice, theVatRate), currency, language, true, true)}`,
                    },
                };
            });
        }
    }, [companyHasVat, thePrice, theVatRate, currency, language]);

    useEffect(() => {
        if (thePrice > 0 && theCost > 0) {
            const profitStats = calculateProductMaterialProfitRaw(
                thePrice,
                theCost,
                theVatRate,
            );

            const helperText = profitStats.isNegative ? (
                <span className={cx(classes.red, classes.bold)}>- {PriceDisplay(profitStats.profit, currency, language, true)}</span>
            ) : (
                <span className={cx(classes.green, classes.bold)}>+ {PriceDisplay(profitStats.profit, currency, language, true)}</span>
            );

            setInputs(prev => {
                return {
                    ...prev,
                    profit: {
                        ...prev.profit,
                        helperText,
                    },
                };
            });
        } else if (inputs.profit.helperText !== '') {
            setInputs(prev => {
                return {
                    ...prev,
                    profit: {
                        ...prev.profit,
                        helperText: '',
                    }
                }
            });
        }
    }, [companyHasVat, thePrice, theCost, theVatRate, theProfit, currency, language]);

    useEffect(() => {
        if (!profitFocused) {
            const profitStats = calculateProductMaterialProfitRaw(
                thePrice,
                theCost,
                theVatRate,
            );
            const calculatedProfit = profitStats.profitPercent;

            if (calculatedProfit !== theProfit && thePrice > 0 && theCost > 0) {
                setInputs(prev => {
                    return {
                        ...prev,
                        profit: {
                            ...prev.profit,
                            value: profitStats.isNegative ? `-${numberDisplay(calculatedProfit, language, true)?.replaceAll(',', '.')}` : numberDisplay(calculatedProfit, language, true)?.replaceAll(',', '.'),
                        },
                    };
                });
            } else if (inputs.profit.value !== '') {
                setInputs(prev => {
                    return {
                        ...prev,
                        profit: {
                            ...prev.profit,
                            value: '',
                        },
                    };
                });
            }
        }
    }, [thePrice, theCost, theVatRate, language]);

    useEffect(() => {
        if (profitFocused && thePrice > 0) {
            const calculatedCost = thePrice - (thePrice * (theProfit / 100));

            if (theCost !== calculatedCost) {
                setInputs(prev => {
                    return {
                        ...prev,
                        cost: {
                            ...prev.cost,
                            value: numberDisplay(calculatedCost, language, true)?.replaceAll(',', '.')
                        },
                    };
                });
            }
        }
    }, [theProfit, language]);

    /**
     * Update form inputs for current data.
     */
    const updateForm = () => {
        setInputs((prev) => {
            if (input) {
                const unitCount = input?.product?.unitCount || input?.material?.unitCount;

                return {
                    ...prev,
                    name: {
                        ...prev.name,
                        value: input?.product?.name || input?.material?.name || '',
                    },
                    unitName: {
                        ...prev.unitName,
                        value: input?.product?.unitName || input?.material?.unitName || '',
                    },
                    unitCount: {
                        ...prev.unitCount,
                        value: unitCount?.toString() || '',
                    },
                    description: {
                        ...prev.description,
                        value: input?.product?.description || input?.material?.description || '',
                    },
                    price: {
                        ...prev.price,
                        value: input?.price || '',
                    },
                    vatRate: {
                        ...prev.vatRate,
                        value: input?.vatRate || '',
                    },
                    cost: {
                        ...prev.cost,
                        value: input?.cost || '',
                    },
                };
            } else if (createInput) {
                const unitCount = createInput?.product?.unitCount || createInput?.material?.unitCount;

                return {
                    ...prev,
                    name: {
                        ...prev.name,
                        value: createInput?.product?.name || createInput?.material?.name || '',
                    },
                    unitName: {
                        ...prev.unitName,
                        value: createInput?.product?.unitName || createInput?.material?.unitName || '',
                    },
                    unitCount: {
                        ...prev.unitCount,
                        value: unitCount?.toString() || '',
                    },
                    description: {
                        ...prev.description,
                        value: createInput?.product?.description || createInput?.material?.description || '',
                    },
                    price: {
                        ...prev.price,
                        value: createInput?.product?.price || createInput?.material?.price || '',
                    },
                    vatRate: {
                        ...prev.vatRate,
                        value: createInput?.product?.vatRate || createInput?.material?.vatRate || '',
                    },
                    cost: {
                        ...prev.cost,
                        value: createInput?.product?.cost || createInput?.material?.cost || '',
                    },
                };
            } else {
                const unitCount = item?.product?.unitCount || item?.material?.unitCount;

                return {
                    ...prev,
                    name: {
                        ...prev.name,
                        value: item?.product?.name || item?.material?.name || '',
                    },
                    unitName: {
                        ...prev.unitName,
                        value: item?.product?.unitName || item?.material?.unitName || '',
                    },
                    unitCount: {
                        ...prev.unitCount,
                        value: unitCount?.toString() || '',
                    },
                    description: {
                        ...prev.description,
                        value: item?.product?.description || item?.material?.description || '',
                    },
                    price: {
                        ...prev.price,
                        value: item?.price || '',
                    },
                    vatRate: {
                        ...prev.vatRate,
                        value: item?.vatRate || '',
                    },
                    cost: {
                        ...prev.cost,
                        value: item?.cost || '',
                    },
                };
            }
        });
    };

    useEffect(() => {
        updateForm();
    }, [item, input, createInput]);

    useEffect(() => {
        if (editMode && item && triggerCloseEditMode > 0 && !singleNotTemplateGroup) {
            setEditMode(false);
        }
    }, [triggerCloseEditMode]);

    /**
     * Handle save, submit form with correct data for callback.
     */
    const handleSave = () => {
        if (ValidateForm(inputs, setInputs)) {
            onSave({
                item: item ? {
                    ...item,
                    product: item.product ? {
                        ...item.product,
                        name: inputs.name.value,
                        unitName: inputs.unitName.value,
                        description: inputs.description.value,
                        unitCount: parseFloat(inputs.unitCount.value || '0'),
                        price: parseFloat(inputs.price.value || '0'),
                        vatRate: parseFloat(inputs.vatRate.value || '0'),
                        cost: parseFloat(inputs.cost.value || '0'),
                    } : undefined,
                    material: item.material ? {
                        ...item.material,
                        name: inputs.name.value,
                        unitName: inputs.unitName.value,
                        description: inputs.description.value,
                        unitCount: parseFloat(inputs.unitCount.value || '0'),
                        price: parseFloat(inputs.price.value || '0'),
                        vatRate: parseFloat(inputs.vatRate.value || '0'),
                        cost: parseFloat(inputs.cost.value || '0'),
                    } : undefined,
                } : undefined,
                input: input ? {
                    ...input,
                    product: input.product ? {
                        ...input.product,
                        name: inputs.name.value,
                        unitName: inputs.unitName.value,
                        description: inputs.description.value,
                        unitCount: parseFloat(inputs.unitCount.value || '0'),
                        price: parseFloat(inputs.price.value || '0'),
                        vatRate: parseFloat(inputs.vatRate.value || '0'),
                        cost: parseFloat(inputs.cost.value || '0'),
                    } : undefined,
                    material: input.material ? {
                        ...input.material,
                        name: inputs.name.value,
                        unitName: inputs.unitName.value,
                        description: inputs.description.value,
                        unitCount: parseFloat(inputs.unitCount.value || '0'),
                        price: parseFloat(inputs.price.value || '0'),
                        vatRate: parseFloat(inputs.vatRate.value || '0'),
                        cost: parseFloat(inputs.cost.value || '0'),
                    } : undefined,
                } : undefined,
                createInput: createInput ? {
                    ...createInput,
                    product: createInput.product ? {
                        ...createInput.product,
                        name: inputs.name.value,
                        unitName: inputs.unitName.value,
                        description: inputs.description.value,
                        unitCount: parseFloat(inputs.unitCount.value || '0'),
                        price: parseFloat(inputs.price.value || '0'),
                        vatRate: parseFloat(inputs.vatRate.value || '0'),
                        cost: parseFloat(inputs.cost.value || '0'),
                    } : undefined,
                    material: createInput.material ? {
                        ...createInput.material,
                        name: inputs.name.value,
                        unitName: inputs.unitName.value,
                        description: inputs.description.value,
                        unitCount: parseFloat(inputs.unitCount.value || '0'),
                        price: parseFloat(inputs.price.value || '0'),
                        vatRate: parseFloat(inputs.vatRate.value || '0'),
                        cost: parseFloat(inputs.cost.value || '0'),
                    } : undefined,
                } : undefined,
            });

            if (!singleNotTemplateGroup) {
                setEditMode(false);
            }
        }
    };

    const actionsJSX = useMemo(() => {
        return editMode ? (
            <>
                <AppChip
                    className={classes.saveChip}
                    onClick={handleSave}
                    chipstyle={"primary"}
                    label={tt('common.save')}
                />

                {(item || createInput) && !singleNotTemplateGroup && !disableCloseEdit ? (
                    <>
                        <Box pl={1}/>

                        <AppIconButton
                            tooltip={tt('common.close')}
                            onClick={() => {
                                setEditMode(!editMode);
                            }}
                        >
                            <CloseIcon/>
                        </AppIconButton>
                    </>
                ) : null}
            </>
        ) : (
            <AppIconButton
                tooltip={tt('common.edit')}
                onClick={() => {
                    setEditMode(!editMode);
                }}
            >
                <PencilIcon/>
            </AppIconButton>
        );
    }, [editMode, item, inputs, disableCloseEdit]);

    const mainJSX = useMemo(() => {
        return (
            <Box sx={{pr: 2, pl: 2}}>
                {editMode ? (
                    <Box pt={1}>
                        <FormBuilder inputs={inputs} setInputs={setInputs}/>
                    </Box>
                ) : (
                    <ProductMaterialListItemChips
                        variant="statsOnly"
                        productMaterial={item}
                        createInput={createInput}
                        displayCount={true}
                        employee={createdByEmployee}
                        files={files}
                    />
                )}
            </Box>
        );
    }, [editMode, inputs, createdByEmployee, files]);

    const authoredJSX = useMemo(() => {
        if (item) {
            const createdAd = item.product?.createdAt || item.material?.createdAt;
            const isTodayCreated = createdAd && DateTime.fromMillis(createdAd).hasSame(DateTime.now(), 'day');
            const createdAtText = createdAd ? (
                isTodayCreated ? `${tt('common.today')}, ${DateTime.fromMillis(createdAd!).toFormat('H:mm')}` : DateTime.fromMillis(createdAd!).toFormat('H:mm · d.M.yyyy')
            ) : tt('common.date.now');

            return createdByEmployee ? (
                <Box p={2}>
                    <Typography className={classes.footerText}>
                        {tt('jobDetail.screen.footerNote.created')}
                        &nbsp;
                        {createdAtText}
                        &nbsp;
                        ·
                        &nbsp;
                        {UserFullName(createdByEmployee.name || createdByEmployee.user?.name, createdByEmployee.surname || createdByEmployee.user?.surname)}
                    </Typography>
                </Box>
            ) : <Box p={1} />;
        }

        if (createInput) {
            return <Box p={1} />;
        }

        return null;
    }, [item, createdByEmployee, createInput]);

    return (
        <>
            <Divider/>

            <HeadlineWithButton
                title={" "}
                customAction={
                    <Box className={classes.headerIconsContainer}>
                        {actionsJSX}

                        <Box pl={1}/>

                        <AppIconButton
                            tooltip={tt('common.delete')}
                            onClick={() => {
                                onDelete({
                                    input,
                                    item,
                                    createInput,
                                })
                            }}
                        >
                            <DeleteIcon className={classes.deleteIcon}/>
                        </AppIconButton>
                    </Box>
                }
            />

            {mainJSX}

            {authoredJSX}
        </>
    );
}
