import {ReactNode, SyntheticEvent, useContext, useEffect, useMemo, useRef, useState} from "react";
import IProductMaterial, {ProductMaterialType} from "../../../model/ProductMaterial";
import {AppDataContext} from "../../../AppData";
import FormBuilder, {IInputsData, InputType, ValidateForm} from "../form/FormBuilder";
import {Option} from "../form/InputProps";
import {makeStyles} from "tss-react/mui";
import {Theme} from "@mui/material";
import AppButton from "../buttons/AppButton";
import {tt} from "../../../core/Localization";
import SplitButton from "../buttons/SplitButton";
import WorkerEditScreenShimmer from "../shimmers/screenSpecificShimmers/WorkerEditScreenShimmer";
import {
    calculateProductMaterialProfitRaw,
    displayProductMaterialListTitle,
    productMaterialTypeDisplay
} from "../../../service/ProductMaterialService";
import {
    AddVatToPrice,
    GetVatAmount,
    numberDisplay,
    PriceDisplay,
    PriceWithVatDisplay
} from "../../../service/CompanyService";
import {hasPermission} from "../permissions/PermissionValid";
import {
    kActionView,
    kPermissionsMaterials, kPermissionsProductMaterialsCosts, kPermissionsProductMaterialsPrices,
    kPermissionsProducts,
    kUserInputShortDebounce
} from "../../../core/constants";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import {AutocompleteChangeDetails, AutocompleteChangeReason} from "@mui/base/useAutocomplete/useAutocomplete";
import Debouncer from "../../../utils/Debouncer";
import ProductMaterialIcon from "./ProductMaterialIcon";

export const useStyles = makeStyles()((theme: Theme) => ({
    submitRightContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
    },
    button: {
        "@media (max-width: 767px)": {
            flexGrow: 1,
            width: '100%',
        }
    },
    submitRightSplitButton: {
        flexShrink: 1,
        width: "unset",
        marginLeft: "auto",
        "@media (max-width: 767px)": {
            flexShrink: 0,
            marginLeft: 0,
            flexGrow: 1,
            width: '100%',
        }
    },
    bold: {
        fontWeight: 'bold'
    },
    green: {
        color: kAppColors.green.profit,
    },
    red: {
        color: kAppColors.red.loss,
    }
}));

export interface IProductMaterialFormProps {
    variant: 'createTemplate' | 'editTemplate' | 'addToVisit';
    type?: ProductMaterialType;
    existing?: IProductMaterial | NullOrUndefined;
    loading?: boolean;
    submitLoading?: boolean;
    onSubmit: (inputs: IInputsData, createAnother?: boolean, saveAsTemplate?: boolean) => void;
    submitRight?: boolean;
    createAnotherAble?: boolean;
    canSaveAsTemplateAble?: boolean;
    isModal?: boolean;
    resetFormTrigger?: number;
    onUpdateInputs?: (inputs: IInputsData) => void;
    productMaterialsForQuery?: IProductMaterial[];
    onSelectProductMaterial?: (type?: ProductMaterialType, id?: number) => void;
    disableTemplateInputs?: boolean;
    nameTypeIconHideTypeSelect?: ReactNode;
    hideTypeSelect?: boolean;
    instantSetParentInputs?: boolean;
}

/**
 * Combined Product/Material form component.
 */
export default function ProductMaterialForm(props: IProductMaterialFormProps) {
    const {
        variant,
        type,
        existing,
        loading,
        submitLoading,
        onSubmit,
        submitRight,
        createAnotherAble,
        canSaveAsTemplateAble,
        isModal,
        resetFormTrigger,
        onUpdateInputs,
        productMaterialsForQuery,
        onSelectProductMaterial,
        disableTemplateInputs,
        nameTypeIconHideTypeSelect,
        hideTypeSelect,
        instantSetParentInputs,
    } = props;

    const appDataContext = useContext(AppDataContext);
    const {currency, language, company, employeePermissionsMap} = appDataContext;

    const canPrices = hasPermission(kPermissionsProductMaterialsPrices, [kActionView], employeePermissionsMap);
    const canCosts = hasPermission(kPermissionsProductMaterialsCosts, [kActionView], employeePermissionsMap);

    const companyHasVat = company?.hasVat || false;

    const {classes, cx} = useStyles();

    const nameAsAutoComplete = variant === 'addToVisit';
    const displayUnitCount = variant === 'addToVisit';

    const typeOptionsForPermissions = useMemo(() => {
        const options: Option[] = [];

        if (hasPermission(kPermissionsProducts, [kActionView], employeePermissionsMap)) {
            options.push(
                {label: productMaterialTypeDisplay(ProductMaterialType.Service), value: ProductMaterialType.Service},
                {label: productMaterialTypeDisplay(ProductMaterialType.Product), value: ProductMaterialType.Product},
            );
        }

        if (hasPermission(kPermissionsMaterials, [kActionView], employeePermissionsMap)) {
            options.push({
                label: productMaterialTypeDisplay(ProductMaterialType.Material),
                value: ProductMaterialType.Material
            });
        }

        return options;
    }, [employeePermissionsMap]);

    const setParentInputsDebouncer = useRef(new Debouncer(kUserInputShortDebounce));
    useEffect(() => {
        return () => {
            setParentInputsDebouncer.current?.dispose();
        };
    }, []);
    const [profitFocused, setProfitFocused] = useState<boolean>(false);
    const [inputs, setInputs] = useState<IInputsData>({
        name: {
            type: nameAsAutoComplete ? InputType.TextAutocomplete : InputType.Text,
            label: tt('productMaterial.form.label.name') + '*',
            value: '',
            required: true,
            autocompleteOptions: [],
            grid: displayUnitCount ? {
                sm: 7,
                xs: 12,
            } : {
                sm: 9,
                xs: 6,
            },
            onAutocompleteValueChanged: (event: SyntheticEvent, value: any, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<any>) => {
            },
            onNewValueAdded: (value) => {
            },
            innerSuffixJSX: nameTypeIconHideTypeSelect,
        },
        unitCount: {
            type: InputType.Text,
            numbersOnly: true,
            inputMode: "decimal",
            label: `${tt('productMaterial.form.label.unitCount')}` + (displayUnitCount ? '*' : ''),
            value: '',
            required: displayUnitCount,
            hidden: !displayUnitCount,
            grid: {
                sm: 2,
                xs: 6,
            },
        },
        unitName: {
            type: InputType.Text,
            label: tt('productMaterial.form.label.unitName'),
            placeholder: tt('productMaterial.form.placeholder.unitName'),
            value: '',
            required: false,
            grid: {
                sm: 3,
                xs: 6,
            },
            readOnly: disableTemplateInputs,
            hidden: disableTemplateInputs,
        },
        unitNameDummy: {
            type: InputType.CustomRenderer,
            label: '',
            value: '',
            render: () => <></>,
            grid: {
                sm: 3,
                xs: 6,
            },
            hidden: !disableTemplateInputs,
        },
        type: {
            type: InputType.ChipSwitch,
            label: '',
            value: type || existing?.type || typeOptionsForPermissions[0]?.value,
            options: typeOptionsForPermissions,
            hidden: variant === 'editTemplate' || !!nameTypeIconHideTypeSelect || hideTypeSelect || typeOptionsForPermissions.length < 2,
            readOnly: disableTemplateInputs,
            hideNotSelected: disableTemplateInputs,
        },
        description: {
            type: InputType.ButtonTextField,
            label: tt('common.description'),
            toggleButtonText: tt('common.description'),
            value: existing?.product?.description || existing?.material?.description || '',
            minRows: 4,
            readOnly: disableTemplateInputs,
            hidden: disableTemplateInputs,
            showCharCounter: true,
            maxChars: 300,
        },
        price: {
            type: InputType.Text,
            numbersOnly: true,
            inputMode: "decimal",
            label: companyHasVat ? `${tt('productMaterial.form.label.price.withoutVat')} (${currency})` : `${tt('productMaterial.form.label.price')} (${currency})`,
            value: '',
            required: false,
            hidden: !canPrices,
            grid: {
                sm: companyHasVat ? 3 : 4,
                xs: 6,
            },
        },
        vatRate: {
            type: InputType.Text,
            numbersOnly: true,
            inputMode: "decimal",
            label: `${tt('productMaterial.form.label.vatRate')}`,
            value: '',
            hidden: !companyHasVat || (!canPrices && !canCosts),
            grid: {
                sm: companyHasVat ? 3 : 4,
                xs: 6,
            },
        },
        cost: {
            type: InputType.Text,
            numbersOnly: true,
            inputMode: "decimal",
            label: companyHasVat ? `${tt('productMaterial.form.label.cost.withoutVat')} (${currency})` : `${tt('productMaterial.form.label.cost')} (${currency})`,
            value: '',
            required: false,
            hidden: !canCosts,
            grid: {
                sm: companyHasVat ? 3 : 4,
                xs: 6,
            },
        },
        profit: {
            type: InputType.Text,
            numbersOnly: true,
            inputMode: "decimal",
            label: `${tt('productMaterial.form.label.profit')} (%)`,
            value: '',
            required: false,
            hidden: !canPrices || !canCosts,
            grid: {
                sm: companyHasVat ? 3 : 4,
                xs: 6,
            },
            onFocus: () => {
                setProfitFocused(true);
            },
            onBlur: () => {
                setProfitFocused(false);
            },
        },
    });

    useEffect(() => {
        if (onUpdateInputs) {
            if (instantSetParentInputs) {
                onUpdateInputs(inputs);
            } else {
                setParentInputsDebouncer.current!.run(() => {
                    onUpdateInputs(inputs);
                });
            }
        }
    }, [inputs]);

    const thePrice = parseFloat(inputs.price.value || '0');
    const theCost = parseFloat(inputs.cost.value || '0');
    const theVatRate = parseFloat(inputs.vatRate.value || '0');
    const theProfit = parseFloat(inputs.profit.value || '0');

    useEffect(() => {
        setInputs(prev => {
            return {
                ...prev,
                name: {
                    ...prev.name,
                    innerSuffixJSX: nameTypeIconHideTypeSelect,
                },
                type: {
                    ...prev.type,
                    hidden: variant === 'editTemplate' || !!nameTypeIconHideTypeSelect || hideTypeSelect || typeOptionsForPermissions.length < 2,
                },
            };
        });
    }, [nameTypeIconHideTypeSelect, hideTypeSelect, typeOptionsForPermissions]);

    useEffect(() => {
        setInputs(prev => {
            return {
                ...prev,
                type: {
                    ...prev.type,
                    options: typeOptionsForPermissions
                }
            }
        });
    }, [employeePermissionsMap, typeOptionsForPermissions]);

    useEffect(() => {
        setInputs(prev => {
            return {
                ...prev,
                unitName: {
                    ...prev.unitName,
                    readOnly: disableTemplateInputs,
                    hidden: disableTemplateInputs,
                },
                unitNameDummy: {
                    ...prev.unitNameDummy,
                    hidden: !disableTemplateInputs,
                },
                type: {
                    ...prev.type,
                    readOnly: disableTemplateInputs,
                    hideNotSelected: disableTemplateInputs,
                },
                description: {
                    ...prev.description,
                    readOnly: disableTemplateInputs,
                    hidden: disableTemplateInputs,
                },
                price: {
                    ...prev.price,
                    hidden: !canPrices,
                    grid: {
                        sm: companyHasVat ? 3 : 4,
                        xs: 6,
                    },
                },
                vatRate: {
                    ...prev.vatRate,
                    hidden: !companyHasVat || (!canPrices && !canCosts),
                    grid: {
                        sm: companyHasVat ? 3 : 4,
                        xs: 6,
                    },
                },
                cost: {
                    ...prev.cost,
                    hidden: !canCosts,
                    grid: {
                        sm: companyHasVat ? 3 : 4,
                        xs: 6,
                    },
                },
                profit: {
                    ...prev.profit,
                    hidden: !canPrices || !canCosts,
                    grid: {
                        sm: companyHasVat ? 3 : 4,
                        xs: 6,
                    },
                },
            };
        });
    }, [companyHasVat, canPrices, canCosts, disableTemplateInputs]);

    useEffect(() => {
        if (existing) {
            setInputs((prev) => {
                const newInputs: any = {
                    ...prev,
                    unitCount: {
                        ...prev.unitCount,
                        value: existing.product?.unitCount || existing.material?.unitCount || '',
                    },
                    unitName: {
                        ...prev.unitName,
                        value: existing.product?.unitName || existing.material?.unitName || '',
                    },
                    type: {
                        ...prev.type,
                        value: existing.type || typeOptionsForPermissions[0]?.value,
                    },
                    description: {
                        ...prev.description,
                        value: existing.product?.description || existing.material?.description || '',
                    },
                    price: {
                        ...prev.price,
                        value: existing.price || '',
                    },
                    vatRate: {
                        ...prev.vatRate,
                        value: existing.vatRate || '',
                    },
                    cost: {
                        ...prev.cost,
                        value: existing.cost || '',
                    },
                };

                if (variant !== 'addToVisit') {
                    newInputs.name = {
                        ...prev.name,
                        value: existing.name || '',
                    };
                }

                if (variant === 'addToVisit') {
                    newInputs.unitCount = {
                        ...prev.unitCount,
                        value: 1,
                    };
                }

                return newInputs;
            });
        }
    }, [existing]);

    useEffect(() => {
        setInputs((prev) => ({
            ...prev,
            name: {
                ...prev.name,
                value: '',
            },
            unitCount: {
                ...prev.unitCount,
                value: '',
            },
            unitName: {
                ...prev.unitName,
                value: '',
            },
            type: {
                ...prev.type,
                value: typeOptionsForPermissions[0]?.value,
            },
            description: {
                ...prev.description,
                value: '',
            },
            price: {
                ...prev.price,
                value: '',
            },
            vatRate: {
                ...prev.vatRate,
                value: '',
            },
            cost: {
                ...prev.cost,
                value: '',
            },
            profit: {
                ...prev.profit,
                value: '',
            },
        }));
    }, [resetFormTrigger]);

    const [lastProductMaterialsForQuery, setLastProductMaterialsForQuery] = useState<IProductMaterial[]>([]);
    useEffect(() => {
        if (lastProductMaterialsForQuery !== productMaterialsForQuery && nameAsAutoComplete && productMaterialsForQuery && onSelectProductMaterial) {
            setLastProductMaterialsForQuery(productMaterialsForQuery);

            setInputs(prev => {
                return {
                    ...prev,
                    name: {
                        ...prev.name,
                        autocompleteOptions: productMaterialsForQuery
                            .map(item => {
                                return {
                                    value: `${item.type}-${item.id}`,
                                    label: item.name,
                                    smallIcon: <ProductMaterialIcon type={item.type}/>,
                                };
                            }),
                        onAutocompleteValueChanged: (event: SyntheticEvent, value: any, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<any>) => {
                            if (reason === "selectOption") {
                                try {
                                    const parts = value.value.split('-');

                                    onSelectProductMaterial(parts[0] as ProductMaterialType, parseInt(parts[1]));
                                } catch (e) {
                                    onSelectProductMaterial(undefined, undefined);
                                }
                            }
                        },
                    },
                };
            });
        }
    }, [nameAsAutoComplete, productMaterialsForQuery, onSelectProductMaterial]);

    useEffect(() => {
        if (companyHasVat) {
            setInputs(prev => {
                return {
                    ...prev,
                    price: {
                        ...prev.price,
                        helperText: `${PriceWithVatDisplay(AddVatToPrice(thePrice, theVatRate), currency, language, true)}`,
                    },
                    vatRate: {
                        ...prev.vatRate,
                        helperText: `${PriceWithVatDisplay(GetVatAmount(thePrice, theVatRate), currency, language, true, true)}`,
                    },
                };
            });
        }
    }, [companyHasVat, thePrice, theVatRate, currency, language]);

    useEffect(() => {
        if (thePrice > 0 && theCost > 0) {
            const profitStats = calculateProductMaterialProfitRaw(
                thePrice,
                theCost,
                theVatRate,
            );

            const helperText = profitStats.profit > 0 && !profitStats.isNegative
                ? (
                    <span
                        className={cx(classes.green, classes.bold)}>+ {PriceDisplay(profitStats.profit, currency, language, true)}</span>
                ) : profitStats.isNegative ? (
                    <span
                        className={cx(classes.red, classes.bold)}>- {PriceDisplay(profitStats.profit, currency, language, true)}</span>
                ) : (
                    <span
                        className={classes.bold}>+ {PriceDisplay(profitStats.profit, currency, language, true)}</span>
                );

            setInputs(prev => {
                return {
                    ...prev,
                    profit: {
                        ...prev.profit,
                        helperText,
                    },
                };
            });
        } else if (inputs.profit.helperText !== '') {
            setInputs(prev => {
                return {
                    ...prev,
                    profit: {
                        ...prev.profit,
                        helperText: '',
                    }
                }
            });
        }
    }, [companyHasVat, thePrice, theCost, theVatRate, theProfit, currency, language]);

    useEffect(() => {
        if (!profitFocused) {
            const profitStats = calculateProductMaterialProfitRaw(
                thePrice,
                theCost,
                theVatRate,
            );
            const calculatedProfit = profitStats.profitPercent;

            if (thePrice > 0 && theCost > 0) {
                if (calculatedProfit !== theProfit) {
                    setInputs(prev => {
                        return {
                            ...prev,
                            profit: {
                                ...prev.profit,
                                value: profitStats.isNegative ? `-${numberDisplay(calculatedProfit, language, true)?.replaceAll(',', '.')}` : numberDisplay(calculatedProfit, language, true)?.replaceAll(',', '.'),
                            },
                        };
                    });
                }
            } else if (inputs.profit.value !== '') {
                setInputs(prev => {
                    return {
                        ...prev,
                        profit: {
                            ...prev.profit,
                            value: '',
                        },
                    };
                });
            }
        }
    }, [thePrice, theCost, theVatRate, language]);

    useEffect(() => {
        if (profitFocused && thePrice > 0) {
            const calculatedCost = thePrice - (thePrice * (theProfit / 100));

            if (theCost !== calculatedCost) {
                setInputs(prev => {
                    return {
                        ...prev,
                        cost: {
                            ...prev.cost,
                            value: `${calculatedCost}`.replaceAll(',', '.')
                        },
                    };
                });
            }
        }
    }, [theProfit, language]);

    let submitJSX = createAnotherAble ? (
        <SplitButton
            className={cx(classes.button, submitRight ? classes.submitRightSplitButton : null)}
            isInModal={isModal}
            onChange={(index) => {
                if (ValidateForm(inputs, setInputs)) {
                    onSubmit(inputs, true);
                }
            }}
            disabled={loading}
            isLoading={submitLoading}
            onClick={(index, value) => {
                if (ValidateForm(inputs, setInputs)) {
                    onSubmit(inputs, false);
                }
            }}
            text={variant === 'editTemplate' ? tt('common.save') : tt('common.create')}
            options={[
                tt('addProductTemplateModal.saveAndCreateAnother'),
            ]}
        />
    ) : canSaveAsTemplateAble ? (
        <SplitButton
            className={cx(classes.button, submitRight ? classes.submitRightSplitButton : null)}
            isInModal={isModal}
            onChange={(index) => {
                if (ValidateForm(inputs, setInputs)) {
                    onSubmit(inputs, false, true);
                }
            }}
            disabled={loading}
            isLoading={submitLoading}
            onClick={(index, value) => {
                if (ValidateForm(inputs, setInputs)) {
                    onSubmit(inputs, false, false);
                }
            }}
            text={tt('common.add')}
            options={[
                tt('addProductTemplateModal.saveAndSaveAsTemplate')
                    .replaceAll('{title}', displayProductMaterialListTitle(employeePermissionsMap)),
            ]}
        />
    ) : (
        <AppButton
            className={classes.button}
            variant={"contained"}
            fullWidth={!submitRight}
            onClick={() => {
                if (ValidateForm(inputs, setInputs)) {
                    onSubmit(inputs);
                }
            }}
            disabled={loading}
            isLoading={submitLoading}
        >
            {variant === 'createTemplate' ? tt('common.create') : tt('common.save')}
        </AppButton>
    );

    submitJSX = !submitRight ? submitJSX : (
        <div className={classes.submitRightContainer}>
            {submitJSX}
        </div>
    );

    return (
        <>
            {loading ? <WorkerEditScreenShimmer/> : (
                <>
                    <FormBuilder inputs={inputs} setInputs={setInputs}/>
                </>
            )}

            {submitJSX}
        </>
    );
}
