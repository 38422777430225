import {
    CreateJobEmployeeTimesheetItemInput,
    CreateJobOfferSeatInput,
    CreateMaterialInput,
    CreateProductInput,
    VisitDetailResponse
} from "../../../../generated/graphql/graphql";
import React, {ReactNode, useContext, useMemo} from "react";
import {makeStyles} from "tss-react/mui";
import {Box, Theme, Tooltip, Typography} from "@mui/material";
import {kAppColors} from "../../../../styles/AppThemeProcessor";
import {AppDataContext} from "../../../../AppData";
import ICreateProductMaterialInput from "../../../../model/CreateProductMaterialInput";
import {convertCreateProductMaterialInputs, convertProductMaterials} from "../../../../service/ProductMaterialService";
import {tt} from "../../../../core/Localization";
import {
    AddVatToPrice,
    calculateTimesheets,
    CalculateTotalPrice,
    distanceDisplay,
    HoursMinutesDisplayRaw,
    numberDisplay,
    paymentTypeToItemPaymentType,
    PriceDisplay,
    PriceWithVatDisplay
} from "../../../../service/CompanyService";
import Icons8Cost from "../../../../icons/Icons8Cost";
import DetailColumnItem from "../../../screenSections/userStatussRow/DetailColumnItem";
import {hasPermission, hasSomePermissions} from "../../permissions/PermissionValid";
import {
    kActionView,
    kPermissionsMaterials, kPermissionsProductMaterialsCosts, kPermissionsProductMaterialsPrices,
    kPermissionsProducts, kPermissionsTimesheets,
    kPermissionsWorkers
} from "../../../../core/constants";

const useStyles = makeStyles()((theme: Theme) => ({
    containerWrap: {
        display: "flex",
        overflowX: "auto",
        whiteSpace: "nowrap",
    },
    container: {
        borderTop: `solid 4px ${theme.palette.mode === "dark" ? 'white' : 'black'}`,
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 16,
        paddingRight: 16,
        display: "flex",
        width: '100%',
        justifyContent: "end",
        alignItems: "flex-start",
        '@media (max-width: 767px)': {
            width: "auto",
            marginLeft: "auto",
        },
    },
    totalWithoutProfit: {
        fontSize: 20,
        color: kAppColors.text.primary(theme.palette.mode === "dark"),
        fontWeight: 600,
    },
}));

export interface IVisitTotalSectionProps {
    requiredEmployeeIds?: number[];
    timesheets?: CreateJobEmployeeTimesheetItemInput[];
    jobOfferSeats?: CreateJobOfferSeatInput[];
    products?: CreateProductInput[];
    materials?: CreateMaterialInput[];
    multiplier?: number;
    visitData?: VisitDetailResponse | NullOrUndefined;
}

/**
 * Job/Visit total section component.
 */
export default function VisitTotalSection(props: IVisitTotalSectionProps) {
    const {requiredEmployeeIds, timesheets, jobOfferSeats, products, materials, multiplier, visitData} = props;

    const {classes, cx} = useStyles();
    const appDataContext = useContext(AppDataContext);
    const {currency, language, isMobile, company, employeePermissionsMap} = appDataContext;

    const companyHasVat = company?.hasVat || false;

    return useMemo(() => {
        const theFilterByEmployeeIds = requiredEmployeeIds || visitData?.visit.repeatingDayData?.employeeIds || visitData?.visit.employeeIds;

        const createItems: ICreateProductMaterialInput[] | NullOrUndefined = products && materials ? convertCreateProductMaterialInputs({
            products,
            materials,
        }) : null;
        const combinedItems = visitData ? convertProductMaterials({
            products: visitData.products,
            materials: visitData.materials,
        }) : null;

        if (theFilterByEmployeeIds?.length || timesheets?.length || jobOfferSeats?.length || createItems?.length || combinedItems?.length || visitData) {
            const canSeeTimesheets = hasPermission(kPermissionsTimesheets, [kActionView], employeePermissionsMap);

            let totalPrice = 0;
            let totalPriceWithVat = 0;
            let totalCost = 0;
            let totalCostWithVat = 0;
            let totalCostEmployees = 0;
            let profitPercent = 0;
            let profit = 0;
            let isNegative = false;

            let totalHours = 0;
            let totalMinutes = 0;
            let distance = 0;

            if (canSeeTimesheets) {
                const statsForTimesheets = calculateTimesheets({
                    timesheets: timesheets || visitData?.employeeTimesheet || [],
                    countNotApproved: true,
                    filterByEmployeeIds: theFilterByEmployeeIds,
                });

                totalHours = statsForTimesheets.hours;
                totalMinutes = statsForTimesheets.minutes;
                distance = statsForTimesheets.travel.distance;

                const theJobOfferSeats = visitData?.jobOfferSeat.filter(seat => !seat.completed && !seat.deleted && !seat.cancelled && seat.acceptedIds.length === 0);

                jobOfferSeats?.forEach((value) => {
                    totalHours += value.hours || 0;
                    totalMinutes += value.minutes || 0;
                });
                theJobOfferSeats?.forEach((value) => {
                    totalHours += value.hours || 0;
                    totalMinutes += value.minutes || 0;
                });

                totalHours += Math.floor(totalMinutes / 60);
                totalMinutes = totalMinutes % 60;

                totalCost += statsForTimesheets.totalPrice;
                totalCostWithVat += statsForTimesheets.totalPrice;
                totalCostEmployees += statsForTimesheets.totalPrice;

                const jobOfferSeatsTotal = CalculateTotalPrice({
                    timesheets: jobOfferSeats?.map(seat => ({
                        paymentType: paymentTypeToItemPaymentType(seat.paymentType),
                        hours: seat.hours,
                        minutes: seat.minutes,
                        hourRate: seat.hourRate,
                        fixedPrice: seat.fixedPrice,
                        approved: true,
                    })) || theJobOfferSeats?.map(seat => ({
                        paymentType: paymentTypeToItemPaymentType(seat.paymentType),
                        hours: seat.hours,
                        minutes: seat.minutes,
                        hourRate: seat.hourRate,
                        fixedPrice: seat.fixedPrice,
                        approved: true,
                    })) || [],
                    currency,
                    language,
                });

                totalCost += jobOfferSeatsTotal;
                totalCostWithVat += jobOfferSeatsTotal;
                totalCostEmployees += jobOfferSeatsTotal;
            }

            if (createItems) {
                for (const item of createItems) {
                    const price = item.product?.price || item.material?.price || 0;
                    const cost = item.product?.cost || item.material?.cost || 0;
                    const vatRate = item.product?.vatRate || item.material?.vatRate || 0;
                    const unitCount = item.product?.unitCount || item.material?.unitCount || 0;

                    const priceWithCount = price * unitCount;
                    const costWithCount = cost * unitCount;

                    totalPrice += priceWithCount;
                    totalCost += costWithCount;

                    if (companyHasVat) {
                        totalPriceWithVat += AddVatToPrice(priceWithCount, vatRate);
                        totalCostWithVat += AddVatToPrice(costWithCount, vatRate);
                    }
                }
            }

            if (combinedItems) {
                for (const item of combinedItems) {
                    const price = item.product?.price || item.material?.price || 0;
                    const cost = item.product?.cost || item.material?.cost || 0;
                    const vatRate = item.product?.vatRate || item.material?.vatRate || 0;
                    const unitCount = item.product?.unitCount || item.material?.unitCount || 0;

                    const priceWithCount = price * unitCount;
                    const costWithCount = cost * unitCount;

                    totalPrice += priceWithCount;
                    totalCost += costWithCount;

                    if (companyHasVat) {
                        totalPriceWithVat += AddVatToPrice(priceWithCount, vatRate);
                        totalCostWithVat += AddVatToPrice(costWithCount, vatRate);
                    }
                }
            }

            profit = Math.abs(totalPrice - totalCost);
            isNegative = totalPrice < totalCost;

            const theProfit = totalPrice - totalCost;
            if (theProfit >= 0) {
                if (totalPrice > 0 && totalCost > 0) {
                    profitPercent = (theProfit / totalPrice) * 100;
                } else if (totalPrice > 0) {
                    profitPercent = 100;
                }
            } else {
                if (totalPrice > 0 && totalCost > 0) {
                    profitPercent = (theProfit / totalPrice) * 100;
                } else if (totalPrice > 0) {
                    profitPercent = 100;
                }
            }

            const canSeeProductMaterials = hasPermission(kPermissionsProducts, [kActionView], employeePermissionsMap)
                && hasPermission(kPermissionsMaterials, [kActionView], employeePermissionsMap);
            const canPrices = hasPermission(kPermissionsProductMaterialsPrices, [kActionView], employeePermissionsMap);
            const canCosts = hasPermission(kPermissionsProductMaterialsCosts, [kActionView], employeePermissionsMap);

            if (!canSeeProductMaterials || !canPrices || !canCosts) {
                if (canSeeTimesheets) {
                    if (visitData) {
                        return (
                            <Tooltip title={tt('visit.totalCostEmployees.cost.tooltip.detail')}>
                                <div>
                                    <DetailColumnItem
                                        icon={<Icons8Cost iconInline={true} iconSmaller={true} red={true}/>}
                                        title={tt('visit.totalCostEmployees.cost')}
                                        description={PriceDisplay(totalCostEmployees, currency, language, true)}
                                        subDescription={`${HoursMinutesDisplayRaw({
                                            hours: totalHours,
                                            minutes: totalMinutes,
                                            returnZeroHoursInsteadOfUndefined: true,
                                            language,
                                        })} · ${distanceDisplay(distance, language, true)}`}
                                    />
                                </div>
                            </Tooltip>
                        );
                    } else {
                        return (
                            <Box className={classes.container}>
                                <Typography className={classes.totalWithoutProfit}>
                                    {multiplier ? `${tt('visit.total.multiplier').replaceAll('$number', multiplier)} ` : null}
                                    {PriceDisplay(totalCostEmployees, currency, language, true)}
                                </Typography>
                            </Box>
                        );
                    }
                }
            } else {
                if (visitData) {
                    return (
                        <>
                            <Tooltip title={tt('visit.total.price.tooltip.detail')}>
                                <div>
                                    <DetailColumnItem
                                        icon={<Icons8Cost iconInline={true} iconSmaller={true} green={true}
                                                          rotate180={true}/>}
                                        title={tt('visit.total.price')}
                                        description={PriceDisplay(totalPrice, currency, language, true)}
                                        subDescription={companyHasVat ? PriceWithVatDisplay(totalPriceWithVat, currency, language, true) : undefined}
                                    />
                                </div>
                            </Tooltip>

                            <DetailColumnItem
                                title={''}
                                displayEmptyTitle={true}
                                description={'-'}
                                disablePadding={true}
                                marginTop={"-3px"}
                            />

                            <Tooltip title={tt('visit.total.cost.tooltip.detail')}>
                                <div>
                                    <DetailColumnItem
                                        icon={<Icons8Cost iconInline={true} iconSmaller={true} red={true}/>}
                                        title={tt('visit.total.cost')}
                                        description={PriceDisplay(totalCost, currency, language, true)}
                                        subDescription={companyHasVat ? PriceWithVatDisplay(totalCostWithVat, currency, language, true) : undefined}
                                    />
                                </div>
                            </Tooltip>

                            <DetailColumnItem
                                title={''}
                                displayEmptyTitle={true}
                                description={'='}
                                disablePadding={true}
                                marginTop={"-3px"}
                            />

                            <DetailColumnItem
                                title={(isNegative ? tt('visit.total.loss') : tt('visit.total.profit')).replaceAll('$percent', `${numberDisplay(profitPercent, language, true, true)}`)}
                                description={PriceDisplay(profit, currency, language, true)}
                                descriptionGreen={profit > 0 && !isNegative}
                                descriptionRed={isNegative}
                                descriptionBold={true}
                            />
                        </>
                    );
                } else {
                    return (
                        <Box className={cx('styledScrollbar', classes.containerWrap)}>
                            <Box className={classes.container}>
                                <Tooltip title={tt('visit.total.price.tooltip.detail')}>
                                    <div>
                                        <DetailColumnItem
                                            icon={<Icons8Cost iconInline={true} iconSmaller={true} green={true}
                                                              rotate180={true}/>}
                                            title={tt('visit.total.price')}
                                            description={`${multiplier ? tt('visit.total.multiplier').replaceAll('$number', multiplier) : ''} ${PriceDisplay(totalPrice, currency, language, true)}`}
                                            subDescription={companyHasVat ? `${multiplier ? tt('visit.total.multiplier').replaceAll('$number', multiplier) : ''} ${PriceWithVatDisplay(totalPriceWithVat, currency, language, true)}` : undefined}
                                        />
                                    </div>
                                </Tooltip>

                                <DetailColumnItem
                                    title={''}
                                    displayEmptyTitle={true}
                                    description={'-'}
                                    disablePadding={true}
                                    marginTop={"-3px"}
                                />

                                <Tooltip title={tt('visit.total.cost.tooltip.detail')}>
                                    <div>
                                        <DetailColumnItem
                                            icon={<Icons8Cost iconInline={true} iconSmaller={true} red={true}/>}
                                            title={tt('visit.total.cost')}
                                            description={`${multiplier ? tt('visit.total.multiplier').replaceAll('$number', multiplier) : ''} ${PriceDisplay(totalCost, currency, language, true)}`}
                                            subDescription={companyHasVat ? `${multiplier ? tt('visit.total.multiplier').replaceAll('$number', multiplier) : ''} ${PriceWithVatDisplay(totalCostWithVat, currency, language, true)}` : undefined}
                                        />
                                    </div>
                                </Tooltip>

                                <DetailColumnItem
                                    title={''}
                                    displayEmptyTitle={true}
                                    description={'='}
                                    disablePadding={true}
                                    marginTop={"-3px"}
                                />

                                <DetailColumnItem
                                    title={(isNegative ? tt('visit.total.loss') : tt('visit.total.profit')).replaceAll('$percent', `${numberDisplay(profitPercent, language, true, true)}`)}
                                    description={`${multiplier ? tt('visit.total.multiplier').replaceAll('$number', multiplier) : ''} ${PriceDisplay(profit, currency, language, true)}`}
                                    descriptionGreen={profit > 0 && !isNegative}
                                    descriptionRed={isNegative}
                                    descriptionBold={true}
                                />
                            </Box>
                        </Box>
                    );
                }
            }
        }

        return null;
    }, [requiredEmployeeIds, timesheets, jobOfferSeats, products, materials, multiplier, visitData, currency, language, isMobile, companyHasVat, employeePermissionsMap]);
}
