import {makeStyles} from "tss-react/mui";
import {Box, Theme, Tooltip, Typography, useTheme} from "@mui/material";
import {kAppColors, kVisitStatusColors} from "../../../../../../styles/AppThemeProcessor";
import IVisitEvent from "../../../../../../model/VisitEvent";
import {EventHoveringArg} from "@fullcalendar/core";
import {
    ClientPureResponse,
    EmployeeJoinedUserResponse,
    FileResponse,
    JobEmployeeTimesheetItemResponse,
    JobFormPureResponse,
    JobOfferSeatResponse,
    JobPureResponse,
    LocationPureResponse,
    MaterialResponse,
    ProductResponse,
    VisitRepeating,
    VisitStatus,
    VisitStatusModifier
} from "../../../../../../generated/graphql/graphql";
import React, {ReactNode, useContext, useEffect, useMemo, useRef, useState} from "react";
import {AppDataContext} from "../../../../../../AppData";
import {tt} from "../../../../../../core/Localization";
import AppChip from "../../../../chips/AppChip";
import AppAvatar from "../../../../AppAvatar";
import {JobNameOrSequenceId} from "../../../../../../service/JobService";
import {visitDateTimes, VisitNameOrSequenceId} from "../../../../../../service/VisitService";
import VisitPreviewStartEndWidget from "./VisitPreviewStartEndWidget";
import VisitStatusChip from "../../../../chips/VisitStatusChip";
import SmallAppListItem from "../../../../listItems/SmallAppListItem";
import Icons8Company from "../../../../../../icons/Icons8Company";
import UserIcon from "../../../../../../icons/UserIcon";
import {LocationNameDisplay, LocationTypeDisplay} from "../../../../../../service/LocationService";
import Icons8Location from "../../../../../../icons/Icons8Location";
import {UserFullName, UserPhotoUrl} from "../../../../../../service/UserService";
import Icons8ClipBoardList from "../../../../../../icons/Icons8ClipBoardList";
import {CalculateProductsPrice, PriceDisplay} from "../../../../../../service/CompanyService";
import WorkersIcon from "../../../../../../icons/WorkersIcon";
import Icons8Invoice from "../../../../../../icons/Icons8Invoice";
import Icons8Attachment from "../../../../../../icons/Icons8Attachment";
import CustomStatusLikeChip from "../../../../chips/CustomStatusLikeChip";
import Icons8MediumRisk from "../../../../../../icons/Icons8MediunRisk";
import Icons8Reset from "../../../../../../icons/Icons8Reset";
import Icons8Layers from "../../../../../../icons/Icons8Layers";
import {combineMaterialsByTemplate} from "../../../../../../service/MaterialService";
import PermissionValid, {hasSomePermissions} from "../../../../permissions/PermissionValid";
import {
    kActionView,
    kPermissionsClients,
    kPermissionsForms,
    kPermissionsMaterials,
    kPermissionsProducts,
    kPermissionsTimesheets,
    kPermissionsVisitAttachments,
    kPermissionsVisitClientDetails,
    kPermissionsVisitProtocols,
    kPermissionsWorkers
} from "../../../../../../core/constants";
import MediaIcon from "../../../../../../icons/MediaIcon";
import {addressToSingleLine} from "../../../../../../utils/AddressUtils";
import VisitItemChips from "../../../../jobs/visits/VisitItemChips";

export const useStyles = makeStyles()((theme: Theme) => ({
    chipsContainer: {
        display: "flex",
        flexWrap: "wrap",
        paddingTop: 6,
    },
    singleChipContainer: {
        marginBottom: 4,
        marginRight: 4,
    },
    removeHorizontalMargin: {
        marginRight: -8,
        marginLeft: -8,
    },
    listItemsContainer: {
        '.MuiListItem-root': {
            paddingLeft: 8,
            paddingRight: 8,
            paddingTop: 0,
            paddingBottom: 0,
        }
    },
    title: {
        fontWeight: 600,
    },
    noTitle: {
        fontWeight: 600,
        color: kAppColors.text.secondary(theme.palette.mode === "dark")
    },
    offsetLocationIcon: {
        position: "relative",
        top: 2,
    }
}));

export interface IVisitPreviewModalProps {
    data: IVisitEvent;
    open: boolean;
    mouseEvent: EventHoveringArg;
    job?: JobPureResponse;
    client?: ClientPureResponse;
    location?: LocationPureResponse;
    responsiblePerson?: EmployeeJoinedUserResponse;
    employees: EmployeeJoinedUserResponse[];
    allEmployees: EmployeeJoinedUserResponse[];
    jobForms: JobFormPureResponse[];
    timesheets: JobEmployeeTimesheetItemResponse[];
    products: ProductResponse[];
    jobOfferSeats?: JobOfferSeatResponse[];
    materials: MaterialResponse[];
    files?: FileResponse[] | NullOrUndefined;
}

export default function VisitPreviewModal(props: IVisitPreviewModalProps) {
    const {
        open,
        data,
        mouseEvent,
        jobForms,
        timesheets,
        products,
        job,
        client,
        location,
        responsiblePerson,
        employees,
        allEmployees,
        jobOfferSeats,
        materials,
        files,
    } = props;

    const appDataContext = useContext(AppDataContext);
    const {employeePermissionsMap, language, company, currency, storage, setStorage} = appDataContext;
    const companyHasVat = company?.hasVat || false;
    const {darkMode} = appDataContext;
    const {classes} = useStyles();
    const repeatDay = data.visitRepeatDay;

    const theStatus = repeatDay?.status || data?.status;
    const theStatusModifier = repeatDay?.statusModifier || data?.statusModifier;
    const isRepeating = data?.repeating !== VisitRepeating.Never;

    useEffect(() => {
        if (files && open) {
            setStorage((prev) => {
                return {
                    filesToProcess: [
                        ...prev.filesToProcess,
                        ...(files || []),
                    ],
                };
            });
        }
    }, [files, open]);

    const {innerWidth: windowWidth, innerHeight: windowHeight} = window;
    const ref = useRef<HTMLDivElement>(null)

    const [opacity, setOpacity] = useState<number>(0);
    const [top, setTop] = useState<number | undefined>(undefined);
    const [bottom, setBottom] = useState<number | undefined>(0);
    const [left, setLeft] = useState<number | undefined>(0);
    const [right, setRight] = useState<number | undefined>(0);

    const theme = useTheme();

    const jobChipJSX = <AppChip
        chipstyle={"secondaryThinSmallerRadius"}
        icon={responsiblePerson ? (
            <AppAvatar
                img={
                    UserPhotoUrl(responsiblePerson.user, files, storage.publicUrlsForFiles)
                }
                tooltip={UserFullName(responsiblePerson.name || responsiblePerson.user?.name, responsiblePerson.surname || responsiblePerson.user?.surname)}
                tooltipDescription={tt('common.responsiblePerson')}
                title={UserFullName(responsiblePerson.name || responsiblePerson.user?.name, responsiblePerson.surname || responsiblePerson.user?.surname)}
                variant={"tiny"}
            />
        ) : undefined}
        label={`${tt('common.job')} ${job ? JobNameOrSequenceId(job) : ''}`}
    />;

    const titleJSX = VisitNameOrSequenceId(data, data.visitRepeatDay) == '' ?
        <Typography className={classes.noTitle}>
            {tt('visitItem.withoutTitle')}
        </Typography>
        : <Typography className={classes.title}>
            {VisitNameOrSequenceId(data, data.visitRepeatDay)}
        </Typography>

    const dateTimes = useMemo(() => {
        return visitDateTimes(data, repeatDay, data.repeatingDay, undefined);
    }, [data, repeatDay, data.repeatingDay]);

    const isWholeDay = data.visitRepeatDay ? data.visitRepeatDay.wholeDay : data.wholeDay;

    const startEndJSX = <Box className={classes.removeHorizontalMargin}>
        <VisitPreviewStartEndWidget
            start={dateTimes.start}
            end={dateTimes.end}
            isWholeDay={isWholeDay}
        />
    </Box>;

    const isRepeatingJSX = isRepeating ? <Box
            className={classes.singleChipContainer}
        >
            <Tooltip
                title={tt('visitItem.chipTooltip.isRepeating')}
                slotProps={{
                    popper: {
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, -4],
                                },
                            },
                        ],
                    },
                }}
            >
                <Box>
                    <AppChip
                        chipstyle={"outlined"}
                        icon={<Icons8Reset/>}
                        noiconmargin={"true"}
                    />
                </Box>
            </Tooltip>
        </Box> :
        null;

    const statusChipJSX = (
        <Box className={classes.singleChipContainer}>
            <VisitStatusChip
                status={theStatus}
                statusModifier={theStatus === VisitStatus.Closed && theStatusModifier && theStatusModifier !== VisitStatusModifier.None ? theStatusModifier : undefined}
            />
        </Box>
    );

    const clientJSX = hasSomePermissions([{
        permission: kPermissionsClients,
        requiredPermissions: [kActionView]
    }, {permission: kPermissionsVisitClientDetails, requiredPermissions: [kActionView]}], employeePermissionsMap) ? (
        <Box className={classes.removeHorizontalMargin}>
            <SmallAppListItem
                title={client?.name || ''}
                customAvatar={client?.company ? <Icons8Company/> : <UserIcon/>}
            />
        </Box>
    ) : null;

    const locationJSX = (
        <PermissionValid
            permission={kPermissionsClients}
        >
            <Box className={classes.removeHorizontalMargin}>
                <SmallAppListItem
                    title={LocationNameDisplay(location?.name, location?.type || '')}
                    customAvatar={<Box className={classes.offsetLocationIcon}><Icons8Location/></Box>}
                    description={`${location?.name ? LocationTypeDisplay(location?.type || '') : ''}\n${addressToSingleLine(location?.address)}`.trim()}
                />
            </Box>
        </PermissionValid>
    );

    const firstFourEmployees = employees.slice(0, 4);
    const avatarsJSX = employees.length > 0 ? (
        <PermissionValid
            permission={kPermissionsWorkers}
        >
            <Box className={classes.singleChipContainer}>
                <AppChip
                    chipstyle={"outlined"}
                    icon={(
                        <>
                            {firstFourEmployees.map((employee, index) => {
                                return (
                                    <Box
                                        key={employee.id}
                                        pr={index != firstFourEmployees.length - 1 ? 0.5 : undefined}
                                    >
                                        <AppAvatar
                                            title={UserFullName(employee.name || employee.user?.name, employee.surname || employee.user?.surname)}
                                            variant={"tiny"}
                                            tooltip={UserFullName(employee.name || employee.user?.name, employee.surname || employee.user?.surname)}
                                            img={
                                                UserPhotoUrl(employee.user, files, storage.publicUrlsForFiles)
                                            }
                                        />
                                    </Box>
                                );
                            })}

                            {employees.length > 4 ? (
                                <>
                                    <Box pr={0.5}/>

                                    <Typography>+{employees.length - 4}</Typography>
                                </>
                            ) : undefined}
                        </>
                    )}
                />
            </Box>
        </PermissionValid>
    ) : undefined;

    const jobOfferSeatsJSX = useMemo(() => {
        if (jobOfferSeats && jobOfferSeats.length > 0) {
            let requiredAvatarsJSX: ReactNode[] = [];
            let substituteAcceptedAvatarsJSX: ReactNode[] = [];
            let substituteAvatarsJSX: ReactNode[] = [];

            for (const jobOfferSeatOf of jobOfferSeats) {
                let requiredEmployee = false;
                let substituteCount = jobOfferSeatOf.substituteCount;

                for (const acceptedOf of jobOfferSeatOf.acceptedIds) {
                    if (employees && employees.find((employee) => employee.id === acceptedOf)) {
                        if (!requiredEmployee) {
                            requiredEmployee = true;
                        } else {
                            substituteCount--;
                        }

                        continue;
                    }

                    const employee = allEmployees.find((employee) => employee.id === acceptedOf);

                    if (employee) {
                        const avatarJSX = (
                            <Box
                                key={employee.id}
                            >
                                <AppAvatar
                                    title={UserFullName(employee.name || employee.user?.name, employee.surname || employee.user?.surname)}
                                    variant={"tiny"}
                                    tooltip={UserFullName(employee.name || employee.user?.name, employee.surname || employee.user?.surname)}
                                    img={
                                        UserPhotoUrl(employee.user, files, storage.publicUrlsForFiles)
                                    }
                                />
                            </Box>
                        );

                        if (!requiredEmployee) {
                            requiredEmployee = true;
                            requiredAvatarsJSX.push(avatarJSX);
                        } else {
                            substituteCount--;
                            substituteAvatarsJSX.push(avatarJSX);
                        }
                    }
                }

                if (!requiredEmployee) {
                    substituteAcceptedAvatarsJSX.push(
                        <Box
                            key={`backup-required-${jobOfferSeatOf.uuid}`}
                        >
                            <AppAvatar
                                title={'? ?'}
                                variant={"tiny"}
                                tooltip={tt('singeJob.card.tooltip.lookingForWorker')}
                                img={null}
                            />
                        </Box>
                    );
                }

                for (let i = 0; i < substituteCount; i++) {
                    substituteAvatarsJSX.push(
                        <Box
                            key={`backup-${i}-${jobOfferSeatOf.uuid}`}
                        >
                            <AppAvatar
                                variant={"tiny"}
                                img={null}
                                tooltip={tt('singeJob.card.tooltip.lookingForBackup')}
                                title={'? ?'}
                            />
                        </Box>
                    );
                }
            }

            return (
                <PermissionValid
                    permission={kPermissionsWorkers}
                >
                    {requiredAvatarsJSX.length || substituteAcceptedAvatarsJSX.length || substituteAvatarsJSX.length ?
                        <Box className={classes.singleChipContainer}>
                            <AppChip
                                chipstyle={"outlined"}
                                icon={(
                                    <>
                                        {requiredAvatarsJSX}
                                        {substituteAcceptedAvatarsJSX}
                                        {substituteAvatarsJSX}
                                    </>
                                )}
                            />
                        </Box> : null
                    }
                </PermissionValid>
            );
        }

        return undefined;
    }, [jobOfferSeats, employees, allEmployees, storage.publicUrlsForFiles]);

    const jobFormsJSX = jobForms.length > 0 ? (
        <PermissionValid
            permission={kPermissionsForms}
        >
            <Box
                className={classes.singleChipContainer}
            >
                <AppChip chipstyle={"outlined"}
                         icon={<Icons8ClipBoardList/>}
                         label={jobForms.length}
                />
            </Box>
        </PermissionValid>
    ) : null;

    const theProtocolCount = isRepeating ? data.visitRepeatDay?.visitProtocolsCount : data.visitProtocolsCount;
    const theDefectCount = isRepeating ? data.visitRepeatDay?.visitDefectsCount : data.visitDefectsCount;

    const protocolsChipJSX = theProtocolCount ? (
        <PermissionValid
            permission={kPermissionsVisitProtocols}
        >
            <Box className={classes.singleChipContainer}>
                <Tooltip
                    title={tt('visit.listItem.chipTooltip.protocols')}
                    slotProps={{
                        popper: {
                            modifiers: [
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [0, -4],
                                    },
                                },
                            ],
                        },
                    }}
                >
                    <Box>
                        <AppChip
                            chipstyle={"outlined"}
                            icon={<MediaIcon/>}
                            label={theProtocolCount}
                        />
                    </Box>
                </Tooltip>
            </Box>
        </PermissionValid>
    ) : null;

    const defectsChipJSX = theDefectCount ? (
        <PermissionValid
            permission={kPermissionsVisitProtocols}
        >
            <Box className={classes.singleChipContainer}>
                <Tooltip
                    title={tt('visit.listItem.chipTooltip.defect')}
                    slotProps={{
                        popper: {
                            modifiers: [
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [0, -4],
                                    },
                                },
                            ],
                        },
                    }}
                >
                    <Box>
                        <CustomStatusLikeChip
                            textColor={kVisitStatusColors.scheduleLater.text}
                            backgroundColor={theme.palette.mode === "dark" ? '#202020' : "white"}
                            icon={<Icons8MediumRisk/>}
                            variant={"outlined"}
                        />
                    </Box>
                </Tooltip>
            </Box>
        </PermissionValid>
    ) : null;

    useEffect(() => {
        if (open) {
            setTimeout(() => setOpacity(1), 1);
        } else {
            setTimeout(() => setOpacity(0), 1);
        }
    }, [open]);


    useEffect(() => {
        const cursorPosX = mouseEvent.jsEvent.x;
        const cursorPosY = mouseEvent.jsEvent.y;

        if (opacity) {
            const previewHeight = ref?.current?.clientHeight;
            if (previewHeight) {
                if (windowHeight - cursorPosY < ref?.current?.clientHeight) {
                    setBottom(windowHeight - cursorPosY);
                    setTop(undefined);
                } else {
                    setBottom(undefined);
                    setTop(cursorPosY);
                }
            } else {
                setBottom(undefined);
                setTop(cursorPosY);
            }

            const previewWidth = ref?.current?.clientWidth;
            if (previewWidth) {
                if (windowWidth - cursorPosX < ref?.current?.clientWidth) {
                    setRight(windowWidth - cursorPosX);
                    setLeft(undefined);
                } else {
                    setRight(undefined);
                    setLeft(cursorPosX);
                }
            } else {
                setRight(undefined);
                setLeft(cursorPosX);
            }
        }
    }, [mouseEvent, opacity]);

    return (
        <Box
            ref={ref}
            sx={{
                minWidth: 120,
                maxWidth: 280,
                padding: 1.5,
                background: kAppColors.background.menuPaper(darkMode),
                visibility: open ? null : 'hidden',
                opacity: opacity.toString(),
                pointerEvents: 'none',
                position: 'fixed',
                zIndex: 1301,
                borderRadius: 1.25,
                border: kAppColors.border(darkMode),
                boxShadow: '0px 3px 6px #00000021',
                top: top,
                left: left,
                right: right,
                bottom: bottom,
            }}
        >
            {jobChipJSX}

            <Box pt={0.5} pb={0.5}>{titleJSX}</Box>

            <Box className={classes.listItemsContainer}>
                {clientJSX}
                {location ? locationJSX : null}
                {startEndJSX}
            </Box>

            <Box className={classes.chipsContainer}>
                {statusChipJSX}
                {isRepeatingJSX}
                {avatarsJSX}
                {jobOfferSeatsJSX}

                <VisitItemChips
                    data={data}
                    timesheets={timesheets}
                    jobOfferSeats={jobOfferSeats || []}
                    products={products}
                    materials={materials}
                />

                {jobFormsJSX}
                {data.attachmentFileIds && data.attachmentFileIds.length > 0 ? (
                    <PermissionValid
                        permission={kPermissionsVisitAttachments}
                    >
                        <Box className={classes.singleChipContainer}>
                            <AppChip chipstyle={"outlined"}
                                     icon={<Icons8Attachment/>}
                                     label={data.attachmentFileIds.length}
                            />
                        </Box>
                    </PermissionValid>
                ) : undefined}
                {protocolsChipJSX}
                {defectsChipJSX}
            </Box>
        </Box>

    );
}
