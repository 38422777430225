import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {
    CompanyConfigurationResponse,
    CompanyConfigurationResponsePage,
    CompanyConfigurationType,
    JobUpdateRepeats, UpdateVisitCompanyConfigurationInput, UpdateVisitDetailsMutationVariables, VisitResponse
} from "../../../../../generated/graphql/graphql";
import ModalBottomSheet from "../../../ModalBottomSheet";
import BottomSheetModalAppbar from "../../BottomSheetModalAppbar";
import {tt} from "../../../../../core/Localization";
import FormBuilder, {IInputsData, InputType, ValidateForm} from "../../../form/FormBuilder";
import {Box, Theme} from "@mui/material";
import AppButton from "../../../buttons/AppButton";
import {makeStyles} from "tss-react/mui";
import {FetchPolicy, RestApiClientContext} from "../../../../../core/RestApiProvider";
import {AppDataContext} from "../../../../../AppData";
import {
    getCompanyConfigurationResponsePage,
    getCompanyConfigurationResponsePageForVisit, isCompanyConfigurationDefault
} from "../../../../../service/CompanyConfigurationService";
import {kCompanyConfigurationAutoJobEmployeeTimesheets} from "../../../../../core/constants";
import {SuccessToast} from "../../../../../service/ToastService";
import {processMutationError} from "../../../../../service/ErrorService";
import {IOnUpdateVisitIdParams} from "./VisitDetailModal";
import {v4 as uuidv4} from "uuid";

const useStyles = makeStyles()((theme: Theme) => ({
    horizontalPadding: {
        paddingLeft: 16,
        paddingRight: 16,
    },
}));

export interface IEditVisitConfigurationsModalBottomSheetProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    visitId: number;
    repeatingDay?: number;
    repeats: JobUpdateRepeats;
    onUpdateVisitId: (params: IOnUpdateVisitIdParams) => void;
}

/**
 * Modal bottom sheet for edit Visit linked CompanyConfigurations.
 */
export default function EditVisitConfigurationsModalBottomSheet(props: IEditVisitConfigurationsModalBottomSheetProps) {
    const {
        open,
        setOpen,
        visitId,
        repeatingDay,
        repeats,
        onUpdateVisitId,
    } = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiPost} = restApiClientContext;

    const appDataContext = useContext(AppDataContext);
    const {companyId} = appDataContext;

    const {classes, cx} = useStyles();

    const [inputs, setInputs] = useState<IInputsData>({
        autoJobEmployeeTimesheets: {
            className: classes.horizontalPadding,
            type: InputType.Switch,
            switchVariant: "Android12Switch",
            label: tt('visitConfigurations.modal.autoJobEmployeeTimesheets.label'),
            value: false,
        },
    });

    const [visitConfiguration, setVisitConfiguration] = useState<CompanyConfigurationResponse | NullOrUndefined>(null);

    const [companyConfigurationLoading, setCompanyConfigurationLoading] = useState<boolean>(false);
    useEffect(() => {
        if (open) {
            getCompanyConfigurationResponsePageForVisit(
                restApiClientContext,
                appDataContext,
                {
                    input: {
                        visitId,
                        repeatingDay,
                        includeDefaultCompanyConfigurations: true,
                    },
                    setLoading: setCompanyConfigurationLoading,
                    onData: (data: CompanyConfigurationResponsePage | NullOrUndefined) => {
                        if (data) {
                            const visitConfig = data.content
                                .find((config) => config.type === CompanyConfigurationType.JobEmployeeTimesheets && config.visitId === visitId);
                            const theConfig = visitConfig || data.content
                                    .find((config) => config.type === CompanyConfigurationType.JobEmployeeTimesheets && isCompanyConfigurationDefault(config));

                            setVisitConfiguration(visitConfig);

                            if (theConfig) {
                                const theValue = JSON.parse(theConfig.valueJSON);

                                setInputs({
                                    ...inputs,
                                    autoJobEmployeeTimesheets: {
                                        ...inputs.autoJobEmployeeTimesheets,
                                        value: theValue[kCompanyConfigurationAutoJobEmployeeTimesheets] === true,
                                    },
                                });

                                return;
                            }
                        }

                        setVisitConfiguration(null);

                        setInputs({
                            ...inputs,
                            autoJobEmployeeTimesheets: {
                                ...inputs.autoJobEmployeeTimesheets,
                                value: false,
                            },
                        });
                    },
                },
            );
        }
    }, [open, visitId]);

    const [sendLoading, setSendLoading] = useState<boolean>(false);
    /**
     * Process form and save new configurations for Visit to BE.
     */
    const onSubmit = () => {
        if (ValidateForm(inputs, setInputs)) {
            try {
                restApiPost({
                    uri: '/job/visit/update-company-configuration',
                    params: {
                        visitId: visitId,
                        repeatingDay: repeatingDay,
                        repeats,
                        uuid: visitConfiguration?.uuid || uuidv4(),
                        type: visitConfiguration?.type || CompanyConfigurationType.JobEmployeeTimesheets,
                        priority: visitConfiguration?.priority || 0,
                        valueJSON: JSON.stringify({
                            [kCompanyConfigurationAutoJobEmployeeTimesheets]: inputs.autoJobEmployeeTimesheets.value,
                        }),
                    } as UpdateVisitCompanyConfigurationInput,
                    fetchPolicy: FetchPolicy.NetworkOnly,
                    setLoading: setSendLoading,
                    onData: (data: VisitResponse | NullOrUndefined) => {
                        if (data) {
                            SuccessToast(tt('visitConfigurations.modal.success'));

                            setTimeout(() => {
                                setOpen(false);

                                if (data!.id) {
                                    onUpdateVisitId({
                                        visitId: data!.id,
                                        repeatingDay: repeatingDay!,
                                    });
                                }
                            }, 1);
                        }
                    },
                    onError: (error) => processMutationError(error),
                });
            } catch (e) {
                processMutationError(e);
            }
        }
    };

    return (
        <ModalBottomSheet
            blurBackdrop={true}
            hideHeader={true}
            open={open}
            setOpen={setOpen}
            modalAboveModals={true}>
            <BottomSheetModalAppbar
                title={tt('visitConfigurations.modal.title')}
                noBorderBottom={true}
                onClose={() => setOpen(false)}
            />

            <FormBuilder
                inputs={inputs}
                setInputs={setInputs}
            />

            <Box p={2}>
                <AppButton
                    variant={"contained"}
                    fullWidth={true}
                    onClick={onSubmit}
                    disabled={companyConfigurationLoading}
                    isLoading={sendLoading}
                >
                    {tt('common.save')}
                </AppButton>
            </Box>
        </ModalBottomSheet>
    );
}
