import {Box, Theme, Tooltip} from "@mui/material";
import {getPermissionMessage, getPermissionTypeAllIcons} from "../../../service/PermissionsService";
import AppChip from "../chips/AppChip";
import React, {ReactNode, useMemo} from "react";
import {makeStyles} from "tss-react/mui";
import {
    kActionCreate,
    kActionDelete,
    kActionUpdate,
    kActionView,
    kPermissionsClients,
    kPermissionsClientsOnCreateVisit,
    kPermissionsCompany,
    kPermissionsCompanySubscription,
    kPermissionsForms,
    kPermissionsFormsFillElement,
    kPermissionsJobs,
    kPermissionsMaterials,
    kPermissionsMobileCompanyView,
    kPermissionsProductMaterialsCosts,
    kPermissionsProductMaterialsPrices,
    kPermissionsProducts,
    kPermissionsTimesheetApprove,
    kPermissionsTimesheetApproveAllRoles,
    kPermissionsTimesheetExport,
    kPermissionsTimesheets,
    kPermissionsVisitCanBeResponsible,
    kPermissionsVisitClientDetails,
    kPermissionsVisitClientDetailsContacts,
    kPermissionsVisitMyStatus,
    kPermissionsVisitWorkers,
    kPermissionsVisitWorkersContacts,
    kPermissionsWorkers
} from "../../../core/constants";
import {tt} from "../../../core/Localization";
import {hasPermission} from "./PermissionValid";

const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        maxWidth: '100%',
        paddingTop: 10,
        display: "flex",
        flexWrap: "wrap",
    },
    singleChipContainer: {
        maxWidth: '100%',
        marginBottom: 4,
        marginRight: 4,
    },
}));

export interface IPermissionsListItemChipsSectionProps {
    permission: string;
    employeePermissionsMap: Record<string, string[]>;
}

export default function PermissionsListItemChipsSection(props: IPermissionsListItemChipsSectionProps) {
    const {
        permission,
        employeePermissionsMap,
    } = props;

    const {classes} = useStyles();

    const permissions = useMemo(() => {
        return employeePermissionsMap[permission];
    }, [employeePermissionsMap, permission]);

    let mainPermissionJSX: ReactNode | null = null;

    if (permission === kPermissionsProducts || permission === kPermissionsMaterials) {
        mainPermissionJSX = (
            <>
                <Box
                    className={classes.singleChipContainer}
                >
                    <Tooltip
                        title={getPermissionMessage(employeePermissionsMap[kPermissionsProducts], kPermissionsProducts)}
                        slotProps={{
                            popper: {
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, -4],
                                        },
                                    },
                                ],
                            },
                        }}
                    >
                        <Box>
                            <AppChip
                                chipstyle={"outlined"}
                                icon={
                                    <>
                                        {getPermissionTypeAllIcons(employeePermissionsMap[kPermissionsProducts]).map((value, index) =>
                                            <Box
                                                key={index}
                                                pr={index == getPermissionTypeAllIcons(employeePermissionsMap[kPermissionsProducts]).length - 1 ? 0 : 0.5}>
                                                {value}
                                            </Box>)}
                                    </>}
                                noiconmargin={"true"}
                            />
                        </Box>
                    </Tooltip>
                </Box>

                <Box
                    className={classes.singleChipContainer}
                >
                    <Tooltip
                        title={getPermissionMessage(employeePermissionsMap[kPermissionsMaterials], kPermissionsMaterials)}
                        slotProps={{
                            popper: {
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, -4],
                                        },
                                    },
                                ],
                            },
                        }}
                    >
                        <Box>
                            <AppChip
                                chipstyle={"outlined"}
                                icon={
                                    <>
                                        {getPermissionTypeAllIcons(employeePermissionsMap[kPermissionsMaterials]).map((value, index) =>
                                            <Box
                                                key={index}
                                                pr={index == getPermissionTypeAllIcons(employeePermissionsMap[kPermissionsMaterials]).length - 1 ? 0 : 0.5}>
                                                {value}
                                            </Box>)}
                                    </>}
                                noiconmargin={"true"}
                            />
                        </Box>
                    </Tooltip>
                </Box>
            </>
        );
    } else if (permission !== kPermissionsMobileCompanyView) {
        mainPermissionJSX = (
            <Box
                className={classes.singleChipContainer}
            >
                <Tooltip
                    title={getPermissionMessage(permissions)}
                    slotProps={{
                        popper: {
                            modifiers: [
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [0, -4],
                                    },
                                },
                            ],
                        },
                    }}
                >
                    <Box>
                        <AppChip
                            chipstyle={"outlined"}
                            icon={
                                <>
                                    {getPermissionTypeAllIcons(permissions).map((value, index) => <Box
                                        key={index}
                                        pr={index == getPermissionTypeAllIcons(permissions).length - 1 ? 0 : 0.5}>
                                        {value}
                                    </Box>)}
                                </>}
                            noiconmargin={"true"}
                        />
                    </Box>
                </Tooltip>
            </Box>
        );
    }

    const otherOptions = () => {
        const otherPermissionsValues = [];

        switch (permission) {
            case kPermissionsCompany:
                if (hasPermission(
                    kPermissionsCompanySubscription,
                    [kActionView, kActionCreate, kActionUpdate, kActionDelete],
                    employeePermissionsMap,
                )) {
                    otherPermissionsValues.push(tt('permissionsListItem.label.companySubscription'));
                }
                break;
            case kPermissionsMobileCompanyView:
                if (hasPermission(kPermissionsMobileCompanyView, [kActionView, kActionCreate, kActionUpdate, kActionDelete], employeePermissionsMap)) {
                    otherPermissionsValues.push(tt('permissionsListItem.label.mobileCompanyView.all'));
                } else {
                    otherPermissionsValues.push(tt('permissionsListItem.label.mobileCompanyView.onlyMine'));
                }
                break;
            case kPermissionsJobs:
                if (hasPermission(
                    kPermissionsVisitCanBeResponsible,
                    [kActionUpdate],
                    employeePermissionsMap,
                )) {
                    otherPermissionsValues.push(tt('permissionsListItem.label.visitCanBeResponsible'));
                }
                if (hasPermission(
                    kPermissionsVisitMyStatus,
                    [kActionUpdate],
                    employeePermissionsMap,
                )) {
                    otherPermissionsValues.push(tt('permissionsListItem.label.visitMyStatus'));
                }
                break;
            case kPermissionsClients:
                if (!hasPermission(kPermissionsClients, [kActionView], employeePermissionsMap)) {
                    if (hasPermission(
                        kPermissionsVisitClientDetails,
                        [kActionView],
                        employeePermissionsMap,
                    )) {
                        otherPermissionsValues.push(tt('permissionsListItem.label.clients.jobVisits.clientDetails'));

                        if (hasPermission(
                            kPermissionsVisitClientDetailsContacts,
                            [kActionView],
                            employeePermissionsMap,
                        )) {
                            otherPermissionsValues.push(tt('permissionsListItem.label.clients.jobVisits.clientDetailsContacts'));
                        }
                    }

                    if (hasPermission(
                        kPermissionsClientsOnCreateVisit,
                        [kActionCreate],
                        employeePermissionsMap,
                    )) {
                        otherPermissionsValues.push(tt('permissionsListItem.label.clients.onCreateVisit'));
                    }
                }
                break;
            case kPermissionsWorkers:
                if (!hasPermission(kPermissionsWorkers, [kActionView], employeePermissionsMap)) {
                    if (hasPermission(
                        kPermissionsVisitWorkers,
                        [kActionView],
                        employeePermissionsMap,
                    )) {
                        otherPermissionsValues.push(tt('permissionsListItem.label.clients.jobVisits.visitWorkers'));

                        if (hasPermission(
                            kPermissionsVisitWorkersContacts,
                            [kActionView],
                            employeePermissionsMap,
                        )) {
                            otherPermissionsValues.push(tt('permissionsListItem.label.clients.jobVisits.visitWorkersContacts'));
                        }
                    }
                }
                break;
            case kPermissionsTimesheets:
                if (hasPermission(
                    kPermissionsTimesheets,
                    [kActionView],
                    employeePermissionsMap,
                )) {
                    if (hasPermission(
                        kPermissionsTimesheetApprove,
                        [kActionUpdate],
                        employeePermissionsMap,
                    ) && hasPermission(
                        kPermissionsTimesheets,
                        [kActionUpdate],
                        employeePermissionsMap,
                    )) {
                        if (hasPermission(kPermissionsTimesheetApproveAllRoles, [kActionUpdate], employeePermissionsMap)) {
                            otherPermissionsValues.push(tt('permissionsListItem.label.timesheetApproveAllRoles'));
                        } else {
                            otherPermissionsValues.push(tt('permissionsListItem.label.timesheetApprove'));
                        }
                    }

                    if (hasPermission(
                        kPermissionsTimesheetExport,
                        [kActionCreate],
                        employeePermissionsMap,
                    )) {
                        otherPermissionsValues.push(tt('permissionsListItem.label.timesheetExport'));
                    }
                }
                break;
            case kPermissionsForms:
                if (hasPermission(
                    kPermissionsForms,
                    [kActionView],
                    employeePermissionsMap,
                )) {
                    if (hasPermission(
                        kPermissionsFormsFillElement,
                        [kActionUpdate],
                        employeePermissionsMap,
                    )) {
                        otherPermissionsValues.push(tt('permissionsListItem.label.formsFillElement'));
                    }
                }
                break;
            case kPermissionsProducts:
            case kPermissionsMaterials:
                if (hasPermission(
                    kPermissionsProducts,
                    [kActionView],
                    employeePermissionsMap,
                ) || hasPermission(
                    kPermissionsMaterials,
                    [kActionView],
                    employeePermissionsMap,
                )) {
                    if (hasPermission(
                        kPermissionsProductMaterialsPrices,
                        [kActionView, kActionCreate, kActionUpdate, kActionDelete],
                        employeePermissionsMap,
                    )) {
                        otherPermissionsValues.push(tt('permissionsListItem.label.productMaterialsPrices'));
                    }
                    if (hasPermission(
                        kPermissionsProductMaterialsCosts,
                        [kActionView, kActionCreate, kActionUpdate, kActionDelete],
                        employeePermissionsMap,
                    )) {
                        otherPermissionsValues.push(tt('permissionsListItem.label.productMaterialsCosts'));
                    }
                }
                break;
        }

        return otherPermissionsValues.map((value, index) => {
            return (<Box
                key={index}
                className={classes.singleChipContainer}>
                <AppChip
                    chipstyle={"outlined"}
                    label={value}
                    noiconmargin={"true"}
                />
            </Box>);
        });
    }

    return (
        <Box className={classes.container}>
            <>
                {mainPermissionJSX}

                {otherOptions()}
            </>
        </Box>
    );
}
