import {useContext, useEffect, useId, useMemo, useState} from "react";
import {AppContext} from "../../../App";
import {AppDataContext} from "../../../AppData";
import {RestApiClientContext} from "../../../core/RestApiProvider";
import {useNavigate, useSearchParams} from "react-router-dom";
import {tt} from "../../../core/Localization";
import ScreenContent from "../../components/screens/ScreenContent";
import AppPaper from "../../components/paper/AppPaper";
import {kContentWidthMedium} from "../../../styles/AppThemeProcessor";
import PaperAppbar from "../../components/paper/PaperAppbar";
import {kMobileMenuRoute} from "../mobile/MobileMenuScreen";
import {Box, Divider, InputAdornment, Menu, MenuItem, Theme} from "@mui/material";
import {IInputsData, InputType} from "../../components/form/FormBuilder";
import SearchIcon from "../../../icons/SearchIcon";
import ProductMaterial, {ProductMaterialType} from "../../../model/ProductMaterial";
import {makeStyles} from "tss-react/mui";
import FilterButton from "../../components/buttons/FilterButton";
import {bindMenu, usePopupState} from "material-ui-popup-state/hooks";
import {
    convertProductMaterialResponsePage,
    displayProductMaterialListTitle,
    productMaterialTypeDisplay,
    subscribeToProductMaterialResponsePage
} from "../../../service/ProductMaterialService";
import {GetProductMaterialsByCompanyInput, ProductMaterialResponsePage} from "../../../generated/graphql/graphql";
import ListShimmer from "../../components/shimmers/ListShimmer";
import EmptyListText from "../../components/textComponents/EmptyListText";
import AppListItem from "../../components/listItems/AppListItem";
import AppChip from "../../components/chips/AppChip";
import ProductMaterialIcon from "../../components/productMaterials/ProductMaterialIcon";
import {
    kActionCreate,
    kActionUpdate,
    kActionView,
    kListMaxDisplayItemsLimit,
    kPermissionsCompany,
    kPermissionsMaterials,
    kPermissionsProducts,
    kUserInputShortDebounce
} from "../../../core/constants";
import PermissionValid, {hasPermission} from "../../components/permissions/PermissionValid";
import DebouncedForm from "../../components/form/DebouncedForm";
import {AddOutlined} from "@mui/icons-material";
import AppIconButton from "../../components/buttons/AppIconButton";
import {productMaterialDetailRoute} from "./ProductMaterialDetailScreen";
import {routeWithCurrentAsParam} from "../../../utils/Utils";
import CreateProductMaterialTemplateModal
    from "../../components/modals/productMaterial/CreateProductMaterialTemplateModal";
import CogIcon from "../../../icons/CogIcon";
import ProductMaterialListItemChips from "../../components/productMaterials/ProductMaterialListItemChips";

export const kProductMaterialsRoute = '/product-materials';

export const useStyles = makeStyles()((theme: Theme) => ({
    chipsContainer: {
        display: 'flex',
        paddingBottom: 16,
        overflowX: "auto",
        'button': {
            flexShrink: 0,
            marginRight: 8,
        },
    },
    menuOffset: {
        transform: 'translateY(8px)',
    },
}));

/**
 * Screen component for display of combined Products and Materials.
 */
export default function ProductMaterialListScreen() {
    const restApiClientContext = useContext(RestApiClientContext);

    const appContext = useContext(AppContext);
    const {setTitle} = appContext;

    const appDataContext = useContext(AppDataContext);
    const {isMobile, companyId, company, currency, language, employeePermissionsMap} = appDataContext;

    const [searchParams, setSearchParams] = useSearchParams();
    const {classes} = useStyles();
    const navigate = useNavigate();

    useEffect(() => {
        setTitle(displayProductMaterialListTitle(employeePermissionsMap));
    }, [employeePermissionsMap]);

    const [type, setType] = useState<ProductMaterialType | undefined>(searchParams.get('type') ? searchParams.get('type')! as ProductMaterialType : undefined);
    const [orderAlphabetically, setOrderAlphabetically] = useState<boolean>(searchParams.get('orderAlphabetically') ? searchParams.get('orderAlphabetically')! === 'true' : true);
    const [createModal, setCreateModal] = useState<boolean>(false);

    const [inputs, setInputs] = useState<IInputsData>({
        search: {
            type: InputType.Text,
            label: '',
            placeholder: tt('productMaterials.screen.search.placeholder'),
            hideLabel: true,
            inputVariant: 'standard',
            extraStyle: 'thin',
            value: '',
            required: true,
            isClearable: true,
            innerPrefixJSX: (
                <InputAdornment position={"start"}>
                    <SearchIcon/>
                </InputAdornment>
            ),
        },
    });

    useEffect(() => {
        setSearchParams(prev => {
            prev.set('type', type || '');
            prev.set('orderAlphabetically', orderAlphabetically ? 'true' : 'false');

            return prev;
        });
    }, [type, orderAlphabetically]);

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<ProductMaterialResponsePage | NullOrUndefined>();
    useEffect(() => {
        if (companyId) {
            const subscription = subscribeToProductMaterialResponsePage(
                restApiClientContext,
                appDataContext,
                {
                    input: {
                        companyId: companyId,
                        isTemplate: true,
                        includeProducts: (!type || type !== ProductMaterialType.Material) && hasPermission(kPermissionsProducts, [kActionView], employeePermissionsMap),
                        includeMaterials: (!type || type === ProductMaterialType.Material) && hasPermission(kPermissionsMaterials, [kActionView], employeePermissionsMap),
                        orderAlphabetically: orderAlphabetically,
                        query: inputs.search.value.trim(),
                        pageSize: kListMaxDisplayItemsLimit,
                    } as GetProductMaterialsByCompanyInput,
                    setLoading,
                    onData: setData,
                },
            );

            return () => subscription.cancel();
        } else {
            setLoading(false);
            setData(null);
        }
    }, [companyId, type, orderAlphabetically, inputs.search.value, employeePermissionsMap]);

    /**
     * On combined Product/Material created, go to detail.
     */
    const handleCreated = (productMaterial: ProductMaterial, createAnother?: boolean) => {
        if (!createAnother) {
            navigate(productMaterialDetailRoute(productMaterial.type, productMaterial.id));
        }
    };

    const typeMenu = usePopupState({
        variant: 'popover',
        popupId: useId(),
    });

    const filtersJSX = (
        <Box
            className={classes.chipsContainer}>
            <FilterButton
                title={productMaterialTypeDisplay(type, true)}
                onClick={(e) => {
                    typeMenu.setOpen(true, e);
                }}
                isSelected={!!type}
            />

            <Menu className={classes.menuOffset}  {...bindMenu(typeMenu)}>
                <MenuItem
                    onClick={() => {
                        typeMenu.setOpen(false);

                        setType(undefined);
                    }}
                >
                    {productMaterialTypeDisplay(undefined, true)}
                </MenuItem>

                <PermissionValid
                    permission={kPermissionsProducts}
                >
                    <MenuItem
                        onClick={() => {
                            typeMenu.setOpen(false);

                            setType(ProductMaterialType.Product);
                        }}
                    >
                        {productMaterialTypeDisplay(ProductMaterialType.Product)}
                    </MenuItem>

                    <MenuItem
                        onClick={() => {
                            typeMenu.setOpen(false);

                            setType(ProductMaterialType.Service);
                        }}
                    >
                        {productMaterialTypeDisplay(ProductMaterialType.Service)}
                    </MenuItem>
                </PermissionValid>

                <PermissionValid
                    permission={kPermissionsMaterials}
                >
                    <MenuItem
                        onClick={() => {
                            typeMenu.setOpen(false);

                            setType(ProductMaterialType.Material);
                        }}
                    >
                        {productMaterialTypeDisplay(ProductMaterialType.Material)}
                    </MenuItem>
                </PermissionValid>
            </Menu>

            <FilterButton
                title={tt('common.filter.orderAlphabetically')}
                onClick={() =>
                    setOrderAlphabetically(!orderAlphabetically)
                }
                isSelected={orderAlphabetically}
                hideChevronDown={true}
            />
        </Box>
    );

    const listItemsJSX = useMemo(() => {
        if (loading && !data) {
            return <ListShimmer items={2}/>;
        } else {
            const items = convertProductMaterialResponsePage({
                productMaterialResponsePage: data,
                orderAlphabetically,
                filterByType: type,
            }).slice(0, kListMaxDisplayItemsLimit);

            if (items.length > 0) {
                return items.map(item => {
                    return (
                        <AppListItem
                            key={`${item.type}-${item.id}`}
                            variant={"smaller-title"}
                            customAvatarInCircle={
                                <ProductMaterialIcon type={item.type}/>
                            }
                            onClick={() => {
                                navigate(
                                    routeWithCurrentAsParam(
                                        productMaterialDetailRoute(item.type, item.id)
                                    )
                                );
                            }}
                            title={item.name}
                            description={productMaterialTypeDisplay(item.type)}
                            customBottomWidget={
                                <ProductMaterialListItemChips
                                    variant="statsOnly"
                                    productMaterial={item}
                                />
                            }
                        />
                    );
                });
            } else {
                return <EmptyListText text={tt('productMaterials.screen.emptyListMessage')}/>;
            }
        }
    }, [loading, data, type, orderAlphabetically, company, currency, language]);

    return (
        <>
            <ScreenContent
                appBar={!isMobile}
                noContentPadding={isMobile}
                navigationDrawer={!isMobile}
                bottomBar={isMobile}
                centerHorizontally={true}>
                <AppPaper
                    sx={{maxWidth: isMobile ? undefined : kContentWidthMedium}}>
                    <PaperAppbar
                        noMarginBottom={true}
                        isMobile={isMobile}
                        title={displayProductMaterialListTitle(employeePermissionsMap)}
                        hideBackButton={!isMobile}
                        backRoute={isMobile ? kMobileMenuRoute : undefined}
                        bottomContent={(
                            <Box pt={1}>
                                <DebouncedForm
                                    inputs={inputs}
                                    setInputs={setInputs}
                                    initialized={true}
                                    delay={kUserInputShortDebounce}
                                />

                                {filtersJSX}

                                <Divider sx={{mr: -2, ml: -2}}/>
                            </Box>
                        )}
                    >
                        <PermissionValid
                            hasSomePermissionsParams={[
                                {permission: kPermissionsProducts, requiredPermissions: [kActionCreate]},
                                {permission: kPermissionsMaterials, requiredPermissions: [kActionCreate]},
                            ]}
                        >
                            {isMobile ? (
                                <AppIconButton
                                    variant={"primaryBg"}
                                    onClick={() => setCreateModal(true)}
                                >
                                    <AddOutlined/>
                                </AppIconButton>
                            ) : (
                                <AppChip
                                    onClick={() => setCreateModal(true)}
                                    label={tt('productMaterials.screen.addProductMaterial')}
                                    icon={<AddOutlined/>}
                                />
                            )}
                        </PermissionValid>
                    </PaperAppbar>

                    {listItemsJSX}

                    <Box sx={{pb: 0.5}}/>
                </AppPaper>
            </ScreenContent>

            <CreateProductMaterialTemplateModal
                open={createModal}
                setOpen={setCreateModal}
                onCreated={handleCreated}
            />
        </>
    );
}
