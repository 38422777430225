import React, {Dispatch, SetStateAction, useContext, useEffect, useMemo, useState} from "react";
import {IProductMaterialByTemplate, ProductMaterialType} from "../../../../model/ProductMaterial";
import ModalBottomSheet from "../../ModalBottomSheet";
import BottomSheetModalAppbar from "../BottomSheetModalAppbar";
import {tt} from "../../../../core/Localization";
import ProductMaterialListItem from "../../productMaterials/ProductMaterialListItem";
import {Box, Divider, Theme} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import AppButton from "../../buttons/AppButton";
import PencilIcon from "../../../../icons/PencilIcon";
import AppIconButton from "../../buttons/AppIconButton";
import DeleteIcon from "../../../../icons/DeleteIcon";
import {kAppColors} from "../../../../styles/AppThemeProcessor";
import EditProductMaterialSection, {
    IEditProductMaterialSectionActionsParams
} from "../../productMaterials/EditProductMaterialSection";
import FormBuilder, {IInputsData, InputType} from "../../form/FormBuilder";
import AppChip from "../../chips/AppChip";
import CloseIcon from "../../../../icons/CloseIcon";
import {
    AddVisitMaterialInput,
    AddVisitProductInput,
    DeleteVisitMaterialInput,
    DeleteVisitProductInput,
    DeleteVisitProductMaterialsInput,
    FileResponse,
    JobUpdateRepeats,
    ProductType,
    UpdateVisitMaterialInput,
    UpdateVisitProductInput,
    UpdateVisitProductMaterialsInput,
    UpdateVisitProductMaterialsInputItemInput,
    VisitResponse
} from "../../../../generated/graphql/graphql";
import IAddProductMaterialToVisitInput from "../../../../model/AddProductMaterialToVisitInput";
import {
    convertAddProductMaterialToVisitInput,
    displayProductMaterialListTitle, productMaterialTypeDisplay
} from "../../../../service/ProductMaterialService";
import {v4 as uuidv4} from "uuid";
import {Loader} from "@mantine/core";
import {FetchPolicy, RestApiClientContext} from "../../../../core/RestApiProvider";
import {IOnUpdateVisitIdParams} from "../job/visits/VisitDetailModal";
import {SuccessToast} from "../../../../service/ToastService";
import {processMutationError} from "../../../../service/ErrorService";
import {AppDataContext} from "../../../../AppData";
import {HideConfirmModal, SetConfirmModal} from "../AppModals";
import IconPlus from "../../../../icons/IconPlus";
import ICreateProductMaterialInput from "../../../../model/CreateProductMaterialInput";

const useStyles = makeStyles()((theme: Theme) => ({
    toolsContainer: {
        paddingLeft: 16,
        paddingRight: 8,
        height: 48,
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
    },
    flexItems: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    saveChip: {
        height: 30,
    },
    deleteIcon: {
        color: kAppColors.red.confirmButton,
    },
}));

export interface IEditProductMaterialModalBottomSheetProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    data: IProductMaterialByTemplate | NullOrUndefined;
    visitDetail?: {
        visitId: number;
        repeatingDay: number | NullOrUndefined;
        repeats: JobUpdateRepeats;
        onUpdateVisitId: (params: IOnUpdateVisitIdParams) => void;
    };
    files?: FileResponse[] | NullOrUndefined;
    onSave?: (data: IProductMaterialByTemplate, inputs: IInputsData) => void;
    onDelete?: (data: IProductMaterialByTemplate) => void;
    onSaveItem?: (createInput: ICreateProductMaterialInput, isNew?: boolean) => void;
    onDeleteItem?: (createInput: ICreateProductMaterialInput) => void;
}

/**
 * Modal bottom sheet component to edit grouped combined Product/Materials.
 */
export default function EditProductMaterialModalBottomSheet(props: IEditProductMaterialModalBottomSheetProps) {
    const {open, setOpen, data, visitDetail, files, onSave, onDelete, onSaveItem, onDeleteItem} = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiPost, restApiDelete} = restApiClientContext;

    const appDataContext = useContext(AppDataContext);
    const {employeeId} = appDataContext;

    const {classes} = useStyles();

    const templateId = data?.productMaterialId;

    const [createInputs, setCreateInputs] = useState<ICreateProductMaterialInput[]>([]);
    const [addProductMaterials, setAddProductMaterials] = useState<IAddProductMaterialToVisitInput[]>([]);

    const [mainEditMode, setMainEditMode] = useState(false);
    const [triggerCloseEditMode, setTriggerCloseEditMode] = useState(0);
    const [inputs, setInputs] = useState<IInputsData>({
        name: {
            type: InputType.Text,
            label: tt('productMaterial.form.label.name') + '*',
            value: '',
            required: true,
            grid: {
                sm: 9,
                xs: 6,
            },
        },
        unitName: {
            type: InputType.Text,
            label: tt('productMaterial.form.label.unitName'),
            placeholder: tt('productMaterial.form.placeholder.unitName'),
            value: '',
            required: false,
            grid: {
                sm: 3,
                xs: 6,
            },
        },
        description: {
            type: InputType.ButtonTextField,
            label: tt('common.description'),
            toggleButtonText: tt('common.description'),
            value: '',
            minRows: 4,
            showCharCounter: true,
            maxChars: 300,
        },
    });

    /**
     * Update form inputs for current data.
     */
    const updateForm = () => {
        setInputs((prev) => {
            return {
                ...prev,
                name: {
                    ...prev.name,
                    value: data?.name || '',
                },
                unitName: {
                    ...prev.unitName,
                    value: data?.unitName || '',
                },
                description: {
                    ...prev.description,
                    value: data?.description || '',
                },
            };
        });
    };

    useEffect(() => {
        updateForm();
    }, [data]);

    useEffect(() => {
        if (open) {
            setCreateInputs([]);
            setAddProductMaterials([]);

            setMainEditMode(false);
            setTriggerCloseEditMode((prev) => prev + 1);
        }
    }, [open]);

    const [addLoading, setAddLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);

    /**
     * Add ProductMaterial input, for current template and type.
     */
    const handleAdd = () => {
        if (data && templateId) {
            if (!visitDetail) {
                const firstItem = data.inputs[0];

                setCreateInputs((prev) => {
                    return [
                        ...prev,
                        {
                            type: data.type,
                            product: data.type !== ProductMaterialType.Material ? {
                                type: data.type === ProductMaterialType.Service ? ProductType.Service : ProductType.Product,
                                uuid: uuidv4(),
                                name: firstItem.name,
                                description: firstItem.product?.description || firstItem.material?.description,
                                unitCount: 1,
                                unitName: firstItem.product?.unitName || firstItem.material?.unitName || '',
                                price: firstItem.product?.price || firstItem.material?.price,
                                vatRate: firstItem.product?.vatRate || firstItem.material?.vatRate,
                                cost: firstItem.product?.cost || firstItem.material?.cost,
                                sortOrder: 0,
                                employeeId: employeeId!,
                            } : undefined,
                            material: data.type === ProductMaterialType.Material ? {
                                templateId: templateId,
                                uuid: uuidv4(),
                                name: firstItem.name,
                                description: firstItem.product?.description || firstItem.material?.description,
                                unitCount: 1,
                                unitName: firstItem.product?.unitName || firstItem.material?.unitName,
                                price: firstItem.product?.price || firstItem.material?.price,
                                vatRate: firstItem.product?.vatRate || firstItem.material?.vatRate,
                                cost: firstItem.product?.cost || firstItem.material?.cost,
                                sortOrder: 0,
                                employeeId: employeeId!,
                            } : undefined,
                            name: firstItem.name,
                            description: firstItem.product?.description || firstItem.material?.description,
                        },
                    ];
                });
            } else {
                const firstItem = data.items[0];

                let product: Partial<AddVisitProductInput> | undefined;
                let material: Partial<AddVisitMaterialInput> | undefined;

                if (data.type === ProductMaterialType.Material) {
                    material = {
                        templateId: templateId,
                        uuid: uuidv4(),
                        name: firstItem.name,
                        description: firstItem.product?.description || firstItem.material?.description,
                        unitCount: 1,
                        unitName: firstItem.product?.unitName || firstItem.material?.unitName,
                        price: firstItem.price,
                        vatRate: firstItem.vatRate,
                        cost: firstItem.cost,
                    };
                } else {
                    product = {
                        type: data.type === ProductMaterialType.Service ? ProductType.Service : ProductType.Product,
                        uuid: uuidv4(),
                        name: firstItem.name,
                        description: firstItem.product?.description || firstItem.material?.description,
                        unitCount: 1,
                        unitName: firstItem.product?.unitName || firstItem.material?.unitName || '',
                        price: firstItem.price,
                        vatRate: firstItem.vatRate,
                        cost: firstItem.cost,
                    };
                }

                setAddProductMaterials((prev) => {
                    return [
                        ...prev,
                        convertAddProductMaterialToVisitInput({
                            product,
                            material,
                        }),
                    ];
                });
            }
        }
    };

    /**
     * Handle update all main data for addInputs or existing items to BE.
     */
    const handleSave = () => {
        if (onSave && data) {
            onSave(data, inputs);
        } else {
            setAddProductMaterials((prev) => {
                return prev.map((item) => {
                    return {
                        ...item,
                        product: item.product ? {
                            ...item.product,
                            name: inputs.name.value,
                            unitName: inputs.unitName.value,
                            description: inputs.description.value,
                        } : undefined,
                        material: item.material ? {
                            ...item.material,
                            name: inputs.name.value,
                            unitName: inputs.unitName.value,
                            description: inputs.description.value,
                        } : undefined,
                        name: inputs.name.value,
                        unitName: inputs.unitName.value,
                        description: inputs.description.value,
                    };
                });
            });

            const products: UpdateVisitProductMaterialsInputItemInput[] = data!.items
                .filter((item) => item.type !== ProductMaterialType.Material)
                .map((item) => {

                    return {
                        ...item.product!,
                        uuid: item.product!.uuid!,
                        employeeId: item.product!.employeeId!,
                        name: inputs.name.value,
                        unitName: inputs.unitName.value,
                        description: inputs.description.value,
                        unitCount: item.product!.unitCount!,
                    };
                });
            const materials: UpdateVisitProductMaterialsInputItemInput[] = data!.items
                .filter((item) => item.type === ProductMaterialType.Material)
                .map((item) => {

                    return {
                        ...item.material!,
                        uuid: item.material!.uuid!,
                        employeeId: item.material!.employeeId!,
                        name: inputs.name.value,
                        unitName: inputs.unitName.value,
                        description: inputs.description.value,
                        unitCount: item.material!.unitCount!,
                    };
                });

            restApiPost({
                uri: '/job/visit/update-product-materials',
                params: {
                    visitId: visitDetail!.visitId!,
                    repeatingDay: visitDetail!.repeatingDay,
                    repeats: visitDetail!.repeats,
                    products,
                    materials,
                } as UpdateVisitProductMaterialsInput,
                fetchPolicy: FetchPolicy.NetworkOnly,
                setLoading: setSaveLoading,
                onData: (data: VisitResponse) => {
                    if (data) {
                        SuccessToast(tt('editProductMaterial.modal.saveAll.success'));

                        if (data!.id) {
                            visitDetail!.onUpdateVisitId({
                                visitId: data!.id,
                                repeatingDay: visitDetail!.repeatingDay!,
                            });
                        }
                    }
                },
                onError: (error) => processMutationError(error),
            });
        }

        setMainEditMode(false);
    };

    /**
     * Handle delete all ProductMaterials for this modal session to BE.
     */
    const handleDelete = () => {
        if (onDelete && data) {
            onDelete(data);
        } else {
            SetConfirmModal(appDataContext, {
                open: true,
                title: tt('editProductMaterial.modal.deleteAll.title'),
                subtitle: tt('editProductMaterial.modal.deleteAll.description'),
                cancelButtonText: tt('common.close'),
                confirmationButtonText: tt('editProductMaterial.modal.deleteAll.confirm'),
                onConfirm: async () => {
                    const productUuids = data!.items
                        .filter((item) => item.type !== ProductMaterialType.Material)
                        .map((item) => item.product!.uuid);

                    const materialUuids = data!.items
                        .filter((item) => item.type === ProductMaterialType.Material)
                        .map((item) => item.material!.uuid);

                    restApiPost({
                        uri: '/job/visit/delete-product-materials',
                        params: {
                            visitId: visitDetail!.visitId!,
                            repeatingDay: visitDetail!.repeatingDay,
                            repeats: visitDetail!.repeats,
                            productUuids,
                            materialUuids,
                        } as DeleteVisitProductMaterialsInput,
                        fetchPolicy: FetchPolicy.NetworkOnly,
                        setLoading: setDeleteLoading,
                        onData: (data: VisitResponse) => {
                            if (data) {
                                SuccessToast(tt('editProductMaterial.modal.deleteAll.success'));

                                if (data!.id) {
                                    visitDetail!.onUpdateVisitId({
                                        visitId: data!.id,
                                        repeatingDay: visitDetail!.repeatingDay!,
                                    });
                                }
                            }
                        },
                        onError: (error) => processMutationError(error),
                    });

                    HideConfirmModal(appDataContext);
                },
                children: <></>,
            });
        }
    };

    /**
     * Handle save single item to BE.
     */
    const handleSaveItem = (params: IEditProductMaterialSectionActionsParams, isNew?: boolean) => {
        const {input, item, createInput} = params;

        if (createInput && onSaveItem) {
            onSaveItem({
                ...createInput,
                product: createInput.product ? {
                    ...createInput.product,
                    templateId: templateId,
                } : undefined,
                material: createInput.material ? {
                    ...createInput.material,
                    templateId: templateId,
                } : undefined,
            }, isNew);

            if (createInput.product) {
                setCreateInputs((prev) => {
                    return prev.filter((item) => item.product && item.product.uuid !== createInput.product!.uuid);
                });
            }

            if (createInput.material) {
                setCreateInputs((prev) => {
                    return prev.filter((item) => item.material && item.material.uuid !== createInput.material!.uuid);
                });
            }
        }

        if (input) {
            if (input.product) {
                restApiPost({
                    uri: '/job/visit/add-product',
                    params: {
                        visitId: visitDetail!.visitId!,
                        repeatingDay: visitDetail!.repeatingDay,
                        repeats: visitDetail!.repeats,
                        uuid: input.product.uuid,
                        templateId: templateId!,
                        employeeId: employeeId!,
                        type: input.product.type,
                        name: input.product.name,
                        unitName: input.product.unitName,
                        unitCount: input.product.unitCount,
                        price: input.product.price,
                        vatRate: input.product.vatRate,
                        description: input.product.description,
                        cost: input.product.cost,
                        saveAsTemplate: false,
                    } as AddVisitProductInput,
                    fetchPolicy: FetchPolicy.NetworkOnly,
                    setLoading: setAddLoading,
                    onData: (data: VisitResponse) => {
                        if (data) {
                            SuccessToast(tt('editProductMaterial.modal.add.success'));

                            setAddProductMaterials((prev) => {
                                return prev.filter((item) => item.product && item.product.uuid !== input.product!.uuid);
                            });

                            if (data!.id) {
                                visitDetail!.onUpdateVisitId({
                                    visitId: data!.id,
                                    repeatingDay: visitDetail!.repeatingDay!,
                                });
                            }
                        }
                    },
                    onError: (error) => processMutationError(error),
                });
            }

            if (input.material) {
                restApiPost({
                    uri: '/job/visit/add-material',
                    params: {
                        visitId: visitDetail!.visitId!,
                        repeatingDay: visitDetail!.repeatingDay,
                        repeats: visitDetail!.repeats,
                        uuid: input.material.uuid,
                        templateId: templateId!,
                        employeeId: employeeId!,
                        name: input.material.name,
                        unitName: input.material.unitName,
                        unitCount: input.material.unitCount,
                        price: input.material.price,
                        vatRate: input.material.vatRate,
                        description: input.material.description,
                        cost: input.material.cost,
                        saveAsTemplate: false,
                    } as AddVisitMaterialInput,
                    fetchPolicy: FetchPolicy.NetworkOnly,
                    setLoading: setAddLoading,
                    onData: (data: VisitResponse) => {
                        if (data) {
                            SuccessToast(tt('editProductMaterial.modal.add.success'));

                            setAddProductMaterials((prev) => {
                                return prev.filter((item) => item.material && item.material.uuid !== input.material!.uuid);
                            });

                            if (data!.id) {
                                visitDetail!.onUpdateVisitId({
                                    visitId: data!.id,
                                    repeatingDay: visitDetail!.repeatingDay!,
                                });
                            }
                        }
                    },
                    onError: (error) => processMutationError(error),
                });
            }
        }

        if (item) {
            if (item.product) {
                restApiPost({
                    uri: '/job/visit/update-product',
                    params: {
                        visitId: visitDetail!.visitId!,
                        repeatingDay: visitDetail!.repeatingDay,
                        repeats: visitDetail!.repeats,
                        productUuid: item.product.uuid,
                        employeeId: employeeId!,
                        name: item.product.name,
                        unitName: item.product.unitName,
                        unitCount: item.product.unitCount,
                        productId: item.product.id,
                        price: item.product.price,
                        vatRate: item.product.vatRate,
                        description: item.product.description,
                        cost: item.product.cost,
                    } as UpdateVisitProductInput,
                    fetchPolicy: FetchPolicy.NetworkOnly,
                    setLoading: setSaveLoading,
                    onData: (data: VisitResponse) => {
                        if (data) {
                            SuccessToast(tt('editProductMaterial.modal.update.success'));

                            if (data!.id) {
                                visitDetail!.onUpdateVisitId({
                                    visitId: data!.id,
                                    repeatingDay: visitDetail!.repeatingDay!,
                                });
                            }

                            if (!templateId) {
                                setOpen(false);
                            }
                        }
                    },
                    onError: (error) => processMutationError(error),
                });
            }

            if (item.material) {
                restApiPost({
                    uri: '/job/visit/update-material',
                    params: {
                        visitId: visitDetail!.visitId!,
                        repeatingDay: visitDetail!.repeatingDay,
                        repeats: visitDetail!.repeats,
                        uuid: item.material.uuid,
                        employeeId: employeeId!,
                        name: item.material.name,
                        unitName: item.material.unitName,
                        unitCount: item.material.unitCount,
                        materialId: item.material.id,
                        price: item.material.price,
                        vatRate: item.material.vatRate,
                        description: item.material.description,
                        cost: item.material.cost,
                    } as UpdateVisitMaterialInput,
                    fetchPolicy: FetchPolicy.NetworkOnly,
                    setLoading: setSaveLoading,
                    onData: (data: VisitResponse) => {
                        if (data) {
                            SuccessToast(tt('editProductMaterial.modal.update.success'));

                            if (data!.id) {
                                visitDetail!.onUpdateVisitId({
                                    visitId: data!.id,
                                    repeatingDay: visitDetail!.repeatingDay!,
                                });
                            }

                            if (!templateId) {
                                setOpen(false);
                            }
                        }
                    },
                    onError: (error) => processMutationError(error),
                });
            }
        }
    };

    /**
     * Handle delete single item, either remove addInput or existing item to BE.
     */
    const handleDeleteItem = (params: IEditProductMaterialSectionActionsParams) => {
        const {input, item, createInput} = params;

        if (createInput && onDeleteItem) {
            onDeleteItem(createInput);

            if (createInput.product) {
                setCreateInputs((prev) => {
                    return prev.filter((item) => item.product && item.product.uuid !== createInput.product!.uuid);
                });
            }

            if (createInput.material) {
                setCreateInputs((prev) => {
                    return prev.filter((item) => item.material && item.material.uuid !== createInput.material!.uuid);
                });
            }
        }

        if (input) {
            setAddProductMaterials((prev) => {
                return prev.filter((item) => item !== input);
            });
        }

        if (item) {
            SetConfirmModal(appDataContext, {
                open: true,
                title: tt('editProductMaterial.modal.delete.title'),
                subtitle: tt('editProductMaterial.modal.delete.description'),
                cancelButtonText: tt('common.close'),
                confirmationButtonText: tt('editProductMaterial.modal.delete.confirm'),
                onConfirm: async () => {
                    if (item.product) {
                        restApiDelete({
                            uri: '/job/visit/delete-product',
                            params: {
                                visitId: visitDetail!.visitId!,
                                repeatingDay: visitDetail!.repeatingDay,
                                repeats: visitDetail!.repeats,
                                productId: item.product.id,
                                productUuid: item.product.uuid,
                            } as DeleteVisitProductInput,
                            fetchPolicy: FetchPolicy.NetworkOnly,
                            setLoading: setDeleteLoading,
                            onData: (data: VisitResponse) => {
                                if (data) {
                                    SuccessToast(tt('editProductMaterial.modal.delete.success'));

                                    if (data!.id) {
                                        visitDetail!.onUpdateVisitId({
                                            visitId: data!.id,
                                            repeatingDay: visitDetail!.repeatingDay!,
                                        });
                                    }

                                    if (!templateId) {
                                        setOpen(false);
                                    }
                                }
                            },
                            onError: (error) => processMutationError(error),
                        });
                    }

                    if (item.material) {
                        restApiDelete({
                            uri: '/job/visit/delete-material',
                            params: {
                                visitId: visitDetail!.visitId!,
                                repeatingDay: visitDetail!.repeatingDay,
                                repeats: visitDetail!.repeats,
                                materialId: item.material.id,
                                uuid: item.material.uuid,
                            } as DeleteVisitMaterialInput,
                            fetchPolicy: FetchPolicy.NetworkOnly,
                            setLoading: setDeleteLoading,
                            onData: (data: VisitResponse) => {
                                if (data) {
                                    SuccessToast(tt('editProductMaterial.modal.delete.success'));

                                    if (data!.id) {
                                        visitDetail!.onUpdateVisitId({
                                            visitId: data!.id,
                                            repeatingDay: visitDetail!.repeatingDay!,
                                        });
                                    }

                                    if (!templateId) {
                                        setOpen(false);
                                    }
                                }
                            },
                            onError: (error) => processMutationError(error),
                        });
                    }

                    HideConfirmModal(appDataContext);
                },
                children: <></>,
            });
        }
    };

    const infoSectionJSX = useMemo(() => {
        if (data) {
            return (
                <Box sx={{pb: 1}}>
                    <ProductMaterialListItem
                        combined={data}
                        files={files}
                        modalHeaderStyle={true}
                    />
                </Box>
            );
        }

        return null;
    }, [data, files]);

    const mainSectionJSX = useMemo(() => {
        return templateId ? (
            <>
                <Divider/>

                <Box className={classes.toolsContainer}>
                    <div>
                        {templateId ? (
                            <>
                                <AppButton
                                    appVariant={"containedSecondary30"}
                                    onClick={handleAdd}
                                >
                                    <IconPlus/>
                                    {tt('common.add')}
                                </AppButton>
                            </>
                        ) : null}
                    </div>

                    <div className={classes.flexItems}>
                        {mainEditMode ? (
                            <>
                                <AppChip
                                    className={classes.saveChip}
                                    onClick={handleSave}
                                    chipstyle={"primary"}
                                    label={tt('common.save')}
                                />

                                <Box pl={1}/>

                                <AppIconButton
                                    tooltip={tt('common.close')}
                                    onClick={() => {
                                        setMainEditMode(false);
                                    }}
                                >
                                    <CloseIcon/>
                                </AppIconButton>
                            </>
                        ) : (
                            <AppIconButton
                                tooltip={tt('common.edit')}
                                onClick={() => {
                                    setMainEditMode(true);
                                }}
                            >
                                <PencilIcon/>
                            </AppIconButton>
                        )}

                        <Box pl={1}/>

                        <AppIconButton
                            tooltip={tt('common.delete')}
                            onClick={handleDelete}
                        >
                            <DeleteIcon className={classes.deleteIcon}/>
                        </AppIconButton>
                    </div>
                </Box>

                {mainEditMode ? (
                    <Box sx={{pr: 2, pl: 2, pt: 2}}>
                        <FormBuilder inputs={inputs} setInputs={setInputs}/>
                    </Box>
                ) : null}
            </>
        ) : null;
    }, [data, mainEditMode, inputs]);

    const sectionsForInputsNewJSX = useMemo(() => {
        if (createInputs.length > 0) {
            return createInputs.map((input) => {
                const uuid = input.product?.uuid || input.material?.uuid;

                return (
                    <EditProductMaterialSection
                        key={uuid}
                        createInput={input}
                        startEditMode={true}
                        triggerCloseEditMode={triggerCloseEditMode}
                        onSave={(params) => handleSaveItem(params, true)}
                        onDelete={handleDeleteItem}
                        singleNotTemplateGroup={!templateId}
                        disableCloseEdit={true}
                    />
                );
            });
        }

        return null;
    }, [createInputs, triggerCloseEditMode]);

    const sectionsForInputsJSX = useMemo(() => {
        if (data) {
            return data.inputs
                .map((input) => {
                    const uuid = input.product?.uuid || input.material?.uuid;

                    return (
                        <EditProductMaterialSection
                            key={uuid}
                            createInput={input}
                            triggerCloseEditMode={triggerCloseEditMode}
                            onSave={handleSaveItem}
                            onDelete={handleDeleteItem}
                            singleNotTemplateGroup={!templateId}
                        />
                    );
                });
        }

        return null;
    }, [data, triggerCloseEditMode]);

    const sectionsForNewJSX = useMemo(() => {
        if (addProductMaterials.length > 0) {
            return addProductMaterials.map((input) => {
                const uuid = input.product?.uuid || input.material?.uuid;

                return (
                    <EditProductMaterialSection
                        key={uuid}
                        input={input}
                        startEditMode={true}
                        triggerCloseEditMode={triggerCloseEditMode}
                        onSave={handleSaveItem}
                        onDelete={handleDeleteItem}
                        singleNotTemplateGroup={!templateId}
                    />
                );
            });
        }

        return null;
    }, [addProductMaterials, triggerCloseEditMode]);

    const sectionsJSX = useMemo(() => {
        if (data) {
            return data.items
                .sort((a, b) => b.createdAt - a.createdAt)
                .map((item) => {
                    const uuid = item.product?.uuid || item.material?.uuid;
                    const employeeId = item.product?.employeeId || item.material?.employeeId;
                    const employee = data.employees.find(employee => employee.id === employeeId);

                    return (
                        <EditProductMaterialSection
                            key={uuid}
                            item={item}
                            createdByEmployee={employee}
                            triggerCloseEditMode={triggerCloseEditMode}
                            onSave={handleSaveItem}
                            onDelete={handleDeleteItem}
                            singleNotTemplateGroup={!templateId}
                            files={files}
                        />
                    );
                });
        }

        return null;
    }, [data, triggerCloseEditMode, files]);

    const title = productMaterialTypeDisplay(data?.type || ProductMaterialType.Service);
    const saving = addLoading || saveLoading || deleteLoading;

    return (
        <ModalBottomSheet
            blurBackdrop={true}
            modalAboveModals={true}
            open={open}
            setOpen={setOpen}
            hideHeader={true}>
            <BottomSheetModalAppbar
                noBorderBottom={true}
                customClose={saving ? <Loader/> : undefined}
                onClose={() => setOpen(false)}
                title={title}>
            </BottomSheetModalAppbar>

            {infoSectionJSX}

            {mainSectionJSX}

            {sectionsForInputsNewJSX}

            {sectionsForInputsJSX}

            {sectionsForNewJSX}

            {sectionsJSX}

            <Box sx={{pb: 4}}/>
        </ModalBottomSheet>
    );
}
