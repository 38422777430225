import { iconUseStyles } from "../styles/IconStyles";

export interface IMergedProductMaterialIconProps {
    className?: string;
}

export default function MergedProductMaterialIcon(props: IMergedProductMaterialIconProps) {
    const { className } = props;

    const { classes, cx } = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 250 220.201">
                <path d="M141.635,203.612H64.018A22.952,22.952,0,0,1,55,201.775a23.656,23.656,0,0,1-12.429-12.429,22.816,22.816,0,0,1-1.837-9.033v-61.44s.021-.032.032-.032H56.223s.032.021.032.032v61.44a7.661,7.661,0,0,0,7.528,7.773h75.011s.032.011.032.032a47.133,47.133,0,0,0,2.8,15.5h0ZM120.5,92.467h26.705a8,8,0,0,1,8.126,7.56A7.843,7.843,0,0,1,147.572,108H120.5m85.956.16a41.1,41.1,0,0,0-15.493,0c-.021,0-.043-.011-.043-.032V69.008s-.021-.032-.032-.032H121.486s-.032-.021-.032-.032V53.472s.021-.032.032-.032h79.763s.032-.021.032-.032v-31s-.021-.032-.032-.032H121.486s-.032-.021-.032-.032V6.842s.021-.032.032-.032h82.379a12.757,12.757,0,0,1,5,1.025,13.24,13.24,0,0,1,6.919,6.919,12.57,12.57,0,0,1,1.025,5V56a12.677,12.677,0,0,1-.79,4.41,13.17,13.17,0,0,1-9.535,8.265.034.034,0,0,0-.032.032v39.454h.011Z" transform="translate(2.76 0.462)" />
                <g transform="translate(152.562 118.779)">
                    <path d="M208.963,151.987A20.954,20.954,0,0,0,197.9,140.924a20.636,20.636,0,0,0-16.145,0,20.917,20.917,0,0,0-11.062,11.062,20.641,20.641,0,0,0,0,16.134,20.954,20.954,0,0,0,11.062,11.062,20.641,20.641,0,0,0,16.134,0,20.954,20.954,0,0,0,11.062-11.062,20.641,20.641,0,0,0,0-16.134Zm-8.393,12.621a11.586,11.586,0,0,1-10.742,7.09h0a11.7,11.7,0,0,1-4.559-.908,11.567,11.567,0,0,1-6.182-6.182,11.842,11.842,0,0,1,0-9.119,11.587,11.587,0,0,1,6.182-6.193,11.842,11.842,0,0,1,9.119,0,11.567,11.567,0,0,1,6.182,6.182,11.842,11.842,0,0,1,0,9.119Z" transform="translate(-141.104 -109.34)" />
                    <path d="M238.467,171.655l-5.9-4.324a6.662,6.662,0,0,1,0-10.753l5.894-4.324a4.568,4.568,0,0,0,1.666-5,49.6,49.6,0,0,0-4.677-10.72,51.128,51.128,0,0,0-6.9-9.236,4.557,4.557,0,0,0-5.157-1.057l-6.652,2.926a6.542,6.542,0,0,1-1.954.523,6.677,6.677,0,0,1-7.357-5.9l-.79-7.24a4.559,4.559,0,0,0-3.492-3.94,49.567,49.567,0,0,0-23.075-.011,4.547,4.547,0,0,0-3.492,3.951l-.8,7.24a7.041,7.041,0,0,1-.523,1.965,6.666,6.666,0,0,1-8.788,3.406l-.16-.064h0l-6.492-2.862a4.533,4.533,0,0,0-1.837-.384,4.6,4.6,0,0,0-3.331,1.441,51.5,51.5,0,0,0-6.9,9.236,49.828,49.828,0,0,0-4.677,10.72,4.562,4.562,0,0,0,1.666,5l5.9,4.324a6.593,6.593,0,0,1,1.442,1.441,6.672,6.672,0,0,1-1.442,9.322l-5.9,4.324a4.568,4.568,0,0,0-1.666,5,49.6,49.6,0,0,0,4.677,10.72,51.128,51.128,0,0,0,6.9,9.236,4.573,4.573,0,0,0,5.168,1.057l6.652-2.926a6.524,6.524,0,0,1,1.965-.523,6.677,6.677,0,0,1,7.357,5.9l.79,7.24a4.559,4.559,0,0,0,3.492,3.94,49.522,49.522,0,0,0,23.064,0,4.533,4.533,0,0,0,3.492-3.94l.8-7.24a6.66,6.66,0,0,1,9.3-5.382l6.652,2.926a4.557,4.557,0,0,0,5.157-1.057,51.5,51.5,0,0,0,6.9-9.236,50.058,50.058,0,0,0,4.677-10.72,4.568,4.568,0,0,0-1.666-5ZM223.8,187.874l-3.385-1.495a15.517,15.517,0,0,0-4.634-1.239,15.788,15.788,0,0,0-17.415,13.977l-.4,3.695a28.253,28.253,0,0,1-12.728,0l-.406-3.695a15.517,15.517,0,0,0-1.239-4.634,15.788,15.788,0,0,0-20.811-8.094l-3.385,1.495a41.04,41.04,0,0,1-6.375-11l3-2.2a15.765,15.765,0,0,0,0-25.456l-3-2.2a41.326,41.326,0,0,1,6.375-11l3.385,1.495a15.516,15.516,0,0,0,4.634,1.239A15.788,15.788,0,0,0,184.833,124.8l.4-3.695a28.252,28.252,0,0,1,12.728,0l.4,3.695a15.789,15.789,0,0,0,22.05,12.728l3.385-1.495a40.876,40.876,0,0,1,6.375,11l-3.011,2.2a15.782,15.782,0,0,0,0,25.445l3.011,2.2a41.205,41.205,0,0,1-6.375,11Z" transform="translate(-142.878 -111.24)" />
                </g>
                <g>
                    <path d="M110.9,6.3a5.088,5.088,0,0,0-3.556-1.441h-.149A5.082,5.082,0,0,0,104.3,5.873a115.579,115.579,0,0,1-14.148,8.478,129.947,129.947,0,0,1-15.365,6.716,105.386,105.386,0,0,1-17.565,4.741,28.4,28.4,0,0,0-2.573-9.29A28.767,28.767,0,0,0,28.7,0,28.439,28.439,0,0,0,17.544,2.264a28.906,28.906,0,0,0-15.28,15.28A28.439,28.439,0,0,0,0,28.7V86.372a4.894,4.894,0,0,0,.043.683.52.52,0,0,1-.011.128c-.011.139-.032.31-.032.491a17.331,17.331,0,0,0,.673,4.794,19.232,19.232,0,0,0,1.869,4.367,21.2,21.2,0,0,0,2.851,3.823,24.675,24.675,0,0,0,3.63,3.2,30.38,30.38,0,0,0,8.991,4.41,36.486,36.486,0,0,0,10.667,1.559H28.7a3.947,3.947,0,0,0,.513-.021c.085,0,.16-.011.235-.011a4.657,4.657,0,0,0,.545.032H30a266.916,266.916,0,0,0,38.835-2.584,137.179,137.179,0,0,0,24.612-5.787,65.88,65.88,0,0,0,13.166-6.054,26.279,26.279,0,0,0,4.367-3.289l.021-.021a5.049,5.049,0,0,0,1.441-3.566V9.973A5.164,5.164,0,0,0,110.9,6.3ZM42.391,95.6a23.818,23.818,0,0,1-13.678,4.026H28.68A23.818,23.818,0,0,1,15,95.6c-3.139-2.264-4.794-5-4.794-7.912s1.666-5.649,4.8-7.912a23.813,23.813,0,0,1,13.678-4.015h.032A23.739,23.739,0,0,1,42.4,79.784c3.139,2.264,4.794,5,4.794,7.912s-1.666,5.649-4.8,7.912Zm4.794-24.911A31.109,31.109,0,0,0,39.39,67.1,36.486,36.486,0,0,0,28.723,65.54h-.032a36.784,36.784,0,0,0-10.667,1.548,31.354,31.354,0,0,0-7.805,3.588V28.7a18.489,18.489,0,0,1,36.977,0V70.687ZM102.24,86.063a9.71,9.71,0,0,1-1.014.673A56.14,56.14,0,0,1,90.11,91.807,114.951,114.951,0,0,1,74.339,95.94a193.329,193.329,0,0,1-20.715,2.766,20.515,20.515,0,0,0,2.082-3.524,17.853,17.853,0,0,0,1.644-6.716,6.231,6.231,0,0,0,.053-.779V36.155a114.078,114.078,0,0,0,29.236-8.617,115.819,115.819,0,0,0,15.6-8.457V86.073Z" />
                    <path d="M35.7,85.2a6.771,6.771,0,0,1-2.029,2.018,11.331,11.331,0,0,1-6.214,1.74,11.331,11.331,0,0,1-6.214-1.74A6.9,6.9,0,0,1,19.211,85.2a4.849,4.849,0,0,1,0-5.339A6.9,6.9,0,0,1,21.24,77.84a11.3,11.3,0,0,1,6.214-1.73,11.3,11.3,0,0,1,6.214,1.73A7.025,7.025,0,0,1,35.7,79.858,4.849,4.849,0,0,1,35.7,85.2Z" transform="translate(1.248 5.158)" />
                </g>
            </svg>
        </span>
    );
}
